<template>
  <b-container fluid class="has-text-left">
    <b-row class="ml-5">
      <header-block
        title="Update Ticket Steps in Bulk"
        description="By uploading a CSV of ticket ids paired with a valid
        ticket step code and ticket description you will update these tickets
        to the new step code and the given description will be appended to the
        existing ticket's description. The ticket summary will stay the same
        for each ticket."
      ></header-block>
    </b-row>
    <page-section title="Upload ticket ids, ticket step code, and ticket description csv">
      <template slot="content">
        <p>
          Upload a csv containing three columns, the first: "TICKET_ID", the
           second: "TICKET_STEP_CODE", and the third: "TICKET_DESCRIPTION".
        </p>
        <div class="upload-csv">
          <UploadCsv
            :parser="parser"
            :api-call="processCsvApiCall"
            v-on="$listeners"
          ></UploadCsv>
        </div>
      </template>
    </page-section>
    <page-section title="Errors">
      <template slot="content">
        <div>
          <table>
            <tr>
              <th>Ticket Id</th>
              <th>Error Reason</th>
            </tr>
            <tr v-for="result in errors.list" :key="result">
              <template v-for="data in result.data">
                <td :key="data">{{ data }}</td>
              </template>
              <td>{{ result.error }}</td>
            </tr>
          </table>
        </div>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import UploadCsv from "../../components/csv/UploadCsv.vue"
import { csvToFormData } from "@/helpers/csv/parsers"
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import JuniferTickets from "@/api/JuniferTickets.js"

export default {
  components: {
    pageSection,
    headerBlock,
    UploadCsv,
  },
  data() {
    return {
      parser: csvToFormData,
      JuniferTickets: JuniferTickets,
      errors: {
        list: [],
        fields: [],
      },
    }
  },
  methods: {
    processCsvApiCall(csv) {
      return JuniferTickets.updateTicketStepBulk(csv).then((response) => {
        this.errors = response.data
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-csv {
  background-color: $day-dark;
  max-width: 450px;
  padding: 1rem;
  border-radius: 0.25rem;
}
.error-msg {
  color: $fire;
  font-weight: $weight-medium;
}
</style>
