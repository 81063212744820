import OpeningReadings from "@/api/OpeningReadings"
import Jobs from "@/api/Jobs"
import email from "@/constants/email"
import { gasDisputeTolChecks } from "@/helpers/rejectedReadings/gasDisputeTolerance"

const state = {
  mpanAccountSearch: [],
  mprnAccountSearch: [],
  electricity_readings: [],
  gas_readings: [],
  isImportingElecAccounts: "",
  isImportingGasAccounts: "",
  isSendingElecEmails: "",
  isSendingGasEmails: "",
  isClearingElecAccounts: "",
  isClearingGasAccounts: "",
}

const mutations = {
  ADD_ELEC_ACCOUNTS(state, accounts) {
    state.electricity_readings = accounts
  },
  ADD_GAS_ACCOUNTS(state, accounts) {
    state.gas_readings = accounts
  },
  DELETE_ELEC_ACCOUNT(state, index) {
    state.electricity_readings.splice(index, 1)
  },
  DELETE_GAS_ACCOUNT(state, index) {
    state.gas_readings.splice(index, 1)
  },
  UPDATE_ACCOUNT(state, data) {
    if (data.type === "elec" && state.electricity_readings.length) {
      let customerIndex = state.electricity_readings.findIndex(
        (item) => item.id === data.customer.id
      )
      state.electricity_readings[customerIndex] = data.customer
    } else if (data.type === "gas" && state.gas_readings.length) {
      let customerIndex = state.gas_readings.findIndex(
        (item) => item.id === data.customer.id
      )
      state.gas_readings[customerIndex] = data.customer
    }
  },
  IS_IMPORTING_ELEC_ACCOUNTS(state, status) {
    state.isImportingElecAccounts = status
  },
  IS_IMPORTING_GAS_ACCOUNTS(state, status) {
    state.isImportingGasAccounts = status
  },
  IS_SENDING_ELEC_EMAILS(state, status) {
    state.isSendingElecEmails = status
  },
  IS_SENDING_GAS_EMAILS(state, status) {
    state.isSendingGasEmails = status
  },
  IS_CLEARING_ELEC_ACCOUNTS(state, status) {
    state.isClearingElecAccounts = status
  },
  IS_CLEARING_GAS_ACCOUNTS(state, status) {
    state.isClearingGasAccounts = status
  },
  ADD_MPAN_SEARCH(state, results) {
    state.mpanAccountSearch = results
  },
  ADD_MPRN_SEARCH(state, results) {
    state.mprnAccountSearch = results
  },
  CLEAR_MPAN_SEARCH(state) {
    state.mpanAccountSearch = []
  },
  CLEAR_MPRN_SEARCH(state) {
    state.mprnAccountSearch = []
  },
  DISPUTED_SEARCH(state, results) {
    state.disputed_search = results
  },
}

const actions = {
  triggerElecReadingsImport({ commit, state }) {
    OpeningReadings.elecReadings()
      .then((response) => {
        let accounts = response.data
        let modifiedAccounts = accounts.map((e) => {
          e.edited = false
          e.disabled = false

          let arr = e.read_difference.split(",")

          if (arr.length === 1) {
            if (arr[0].split(".")[0].match(/\d+/g).map(Number) >= 250) {
              e.template = "OutofTolerance"
            } else {
              e.template = "WithinTolerance"
            }
          } else {
            if (
              arr[0].split(".")[0].match(/\d+/g).map(Number)[0] ==
              arr[1].split(".")[0].match(/\d+/g).map(Number)[0]
            ) {
              e.template = "Transposed"
            } else {
              if (
                arr[0].split(".")[0].match(/\d+/g).map(Number) >= 250 ||
                arr[1].split(".")[0].match(/\d+/g).map(Number) >= 250
              ) {
                e.template = "OutofTolerance"
              } else {
                e.template = "WithinTolerance"
              }
            }
          }

          return e
        })

        commit("ADD_ELEC_ACCOUNTS", modifiedAccounts)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  triggerGasReadingsImport({ commit, state }) {
    OpeningReadings.gasReadings()
      .then((response) => {
        response.data.forEach((e) => {
          gasDisputeTolChecks(e)
        })

        commit("ADD_GAS_ACCOUNTS", response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  triggerGetElecDelayedQueue({ commit, dispatch }) {
    Jobs.getDelayedJobQueue("estimated_opening_elec_read")
      .then((response) => {
        if (response.data.length) {
          let jobId = response.data[0].id
          dispatch("triggerGetElecDelayedJob", jobId)
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch("triggerElecReadingsImport")
      })
  },
  triggerGetGasDelayedQueue({ commit, dispatch }) {
    Jobs.getDelayedJobQueue("estimated_opening_gas_read")
      .then((response) => {
        if (response.data.length) {
          let jobId = response.data[0].id
          dispatch("triggerGetGasDelayedJob", jobId)
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch("triggerGasReadingsImport")
      })
  },
  triggerGetElecDelayedJob() {
    Jobs.getDelayedJob(jobId)
      .then(() => {})
      .catch((error) => {
        console.log(error)
      })
  },
  triggerGetGasDelayedJob() {
    Jobs.getDelayedJob(jobId)
      .then(() => {})
      .catch((error) => {
        console.log(error)
      })
  },
  triggerElecImportButton({ commit, dispatch }) {
    commit("IS_IMPORTING_ELEC_ACCOUNTS", "loading")
    OpeningReadings.importElecReadings()
      .then(() => {
        commit("IS_IMPORTING_ELEC_ACCOUNTS", "success")
        dispatch("triggerElecReadingsImport")
      })
      .catch((error) => {
        commit("IS_IMPORTING_ELEC_ACCOUNTS", "error")
        console.log(error)
      })
  },
  triggerGasImportButton({ commit, dispatch }) {
    commit("IS_IMPORTING_GAS_ACCOUNTS", "loading")
    OpeningReadings.importGasReadings()
      .then(() => {
        commit("IS_IMPORTING_GAS_ACCOUNTS", "success")
        dispatch("triggerGasReadingsImport")
      })
      .catch((error) => {
        commit("IS_IMPORTING_GAS_ACCOUNTS", "error")
        console.log(error)
      })
  },
  deleteElectricCustomer({ commit, state }, customerId) {
    let accounts = state.electricity_readings
    let accountIndex = accounts.findIndex(
      (account) => account.id === customerId
    )

    OpeningReadings.deleteElectricity(customerId)
      .then((response) => {
        commit("DELETE_ELEC_ACCOUNT", accountIndex)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  deleteGasCustomer({ commit, state }, customerId) {
    let accounts = state.gas_readings
    let accountIndex = accounts.findIndex(
      (account) => account.id === customerId
    )

    OpeningReadings.deleteGas(customerId)
      .then((response) => {
        commit("DELETE_GAS_ACCOUNT", accountIndex)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  sendAwaitingElecEmails({ commit, dispatch }, data) {
    commit("IS_SENDING_ELEC_EMAILS", "loading")
    OpeningReadings.sendAllElecReadEmails(data)
      .then(() => {
        commit("IS_SENDING_ELEC_EMAILS", "success")
      })
      .catch((error) => {
        console.log(error)
        commit("IS_SENDING_ELEC_EMAILS", "error")
      })
      .finally(() => {
        dispatch("triggerGetElecDelayedQueue")
      })
  },
  sendAwaitingGasEmails({ commit, dispatch }, data) {
    commit("IS_SENDING_GAS_EMAILS", "loading")
    OpeningReadings.sendAllGasReadEmails(data)
      .then(() => {
        commit("IS_SENDING_GAS_EMAILS", "success")
      })
      .catch((error) => {
        console.log(error)
        commit("IS_SENDING_GAS_EMAILS", "error")
      })
      .finally(() => {
        dispatch("triggerGetGasDelayedQueue")
      })
  },
  clearGasReads({ commit, state }, customerId) {
    commit("IS_CLEARING_GAS_ACCOUNTS", "loading")
    customerId.forEach(function (id) {
      OpeningReadings.deleteGas(id)
        .then(() => {
          commit("DELETE_GAS_ACCOUNT", id)
        })
        .catch((error) => {
          console.log(error)
          commit("IS_CLEARING_GAS_ACCOUNTS", "error")
        })
    })
  },
  clearElecReads({ commit, state }, customerId) {
    commit("IS_CLEARING_GAS_ACCOUNTS", "loading")
    customerId.forEach(function (id) {
      OpeningReadings.deleteElectricity(id)
        .then(() => {
          commit("DELETE_ELEC_ACCOUNT", id)
        })
        .catch((error) => {
          console.log(error)
          commit("IS_CLEARING_ELEC_ACCOUNTS", "error")
        })
    })
  },
  addMpanSearchResults({ commit }, results) {
    commit("ADD_MPAN_SEARCH", results)
  },
  addMprnSearchResults({ commit }, results) {
    commit("ADD_MPRN_SEARCH", results)
  },
  clearMpanSearchResults({ commit }) {
    commit("CLEAR_MPAN_SEARCH")
  },
  clearMprnSearchResults({ commit }) {
    commit("CLEAR_MPRN_SEARCH")
  },
}

const getters = {
  electricityCustomers: (state) => {
    let electricityCustomers = []

    if (state.mpanAccountSearch.length) {
      electricityCustomers = state.electricity_readings.filter(
        (account) => account.id == state.mpanAccountSearch[0].id
      )
    } else {
      electricityCustomers = state.electricity_readings
    }
    return electricityCustomers
  },
  gasCustomers: (state) => {
    let gasCustomers = []

    if (state.mprnAccountSearch.length) {
      gasCustomers = state.gas_readings.filter(
        (account) => account.id == state.mprnAccountSearch[0].id
      )
    } else {
      gasCustomers = state.gas_readings
    }
    return gasCustomers
  },
  searchMpanResultAccountList: (state) => {
    return state.mpanAccountSearch.map((account) => {
      return account.junifer_account_number
    })
  },
  searchMprnResultAccountList: (state) => {
    return state.mprnAccountSearch.map((account) => {
      return account.junifer_account_number
    })
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}
