export function followUpChecks(e) {
  try {
    var percChnage = Math.round(
      (e.suggested_payment_amount * 100) / e.current_payment_amount - 100,
      2
    )
    if (!e.follow_up_reads_check && percChnage > 150) {
      var z = { text: percChnage + "% increase", class: "danger" }
      e.notOkToSend = true
      e.tags.push(z)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
