<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Registrations rejected by industry"
        summary="Sends an email to customers letting them know that either one or all meterpoints have been rejected along with the reason why.
                             Their payment amount is also asjusted accordingly. Central industry can take upto 24 hours to respond to registration requests and
                             therefore customers need to be informed."
        description="Description to follow"
      >
      </header-block>
    </b-row>

    <page-section title="Individual account lookup">
      <template v-slot:content>
        <b-form inline>
          <input
            id="accountsearch"
            v-model="lookupValue"
            type="text"
            placeholder="Account ...."
            style="width: 200px"
            class="form-control"
          />
          <span class="input-group-btn">
            <input
              id="search"
              type="button"
              value="search"
              class="btn btn-light custom-button-width light-blue"
              @click="lookupAccount(lookupValue)"
            />
            <input
              id="clear"
              type="button"
              value="clear"
              class="btn btn-light custom-button-width red"
              @click="clear()"
            />
          </span>
        </b-form>

        <b-row v-show="lookupResults.length > 0" class="ml-5 mb-5">
          <div v-for="result in lookupResults" :key="result.id">
            <b-card
              bg-variant="light"
              class="mr-2 mt-2 d-flex align-items-stretch"
              header-tag="header"
            >
              {{ result }}
            </b-card>
          </div>
        </b-row>
      </template>
    </page-section>

    <page-section title="Bulk action">
      <template v-slot:content>
        <b-button
          v-if="progressBar.jobId === null"
          :disabled="indRejIsBusy"
          class="mr-2 mb-2 float-left"
          variant="outline-success"
          @click="importRecords()"
        >
          <div v-if="indRejIsBusy">Loading...</div>
          <div v-else>Import &#8250;</div>
        </b-button>

        <results-table
          :count-box-one="ind_rej_await.length"
          :count-box-two="ind_rej_error.length"
          :count-box-three="ind_rej_completed.length"
        >
          <div slot="box-one-slot">
            <b-button
              v-show="ind_rej_await.length !== 0"
              size="sm"
              variant="outline-light"
              :disabled="indRejIsBusy || progressBar.jobId !== null"
              @click="sendAll(ind_rej_await)"
            >
              Send all
            </b-button>
          </div>

          <div slot="box-three-slot">
            <b-button
              v-show="ind_rej_completed.length !== 0"
              size="sm"
              variant="outline-light"
              :disabled="indRejIsBusy || progressBar.jobId !== null"
              @click="deleteAll(ind_rej_completed)"
            >
              Clear all
            </b-button>
          </div>
        </results-table>

        <span v-show="progressBar.jobId !== null">
          Job: {{ progressBar.jobId }} - {{ progressBar.stage }} ({{
            progressBar.counter
          }}/{{ progressBar.max }})
          <b-progress
            v-if="progressBar.jobId !== null"
            b-progress
            :value="progressBar.counter"
            :max="progressBar.max"
            show-progress
            animated
          />
          <br />
          <br />
        </span>

        <div v-if="deliveryError.error" class="text-danger">
          <p>{{ deliveryError.message }}</p>
        </div>

        <b-table
          v-show="IndustryRejectedTable.items.length > 0"
          hover
          small
          responsive
          :busy="indRejIsBusy || progressBar.jobId !== null"
          :sort-by.sync="IndustryRejectedTable.sortBy"
          :sort-desc.sync="IndustryRejectedTable.sortDesc"
          :items="IndustryRejectedTable.items"
          :fields="IndustryRejectedTable.fields"
        >
          <div
            v-if="progressBar.jobId === null"
            slot="table-busy"
            class="text-center text-danger my-2"
          >
            <br />
            <br />
            <b-spinner class="align-middle"></b-spinner>
          </div>

          <template v-slot:cell(MPAN)="row">
            {{ row.item.rejected_mpxn[0].mpan }}
          </template>

          <template v-slot:cell(mpan_ssd)="row">
            <span v-show="row.item.rejected_mpxn[0].mpan_ssd">
              {{ row.item.rejected_mpxn[0].mpan_ssd | formattedDate }}
            </span>
          </template>

          <template v-slot:cell(mpan_supplystatus)="row">
            <div
              :class="classObject(row.item.rejected_mpxn[0].mpan_supplystatus)"
            >
              {{ row.item.rejected_mpxn[0].mpan_supplystatus }}
            </div>
          </template>

          <template v-slot:cell(MPRN)="row">
            {{ row.item.rejected_mpxn[0].mprn }}
          </template>

          <template v-slot:cell(mprn_ssd)="row">
            <span v-show="row.item.rejected_mpxn[0].mprn_ssd">
              {{ row.item.rejected_mpxn[0].mprn_ssd | formattedDate }}
            </span>
          </template>

          <template v-slot:cell(mprn_supplystatus)="row">
            <div
              :class="classObject(row.item.rejected_mpxn[0].mprn_supplystatus)"
            >
              {{ row.item.rejected_mpxn[0].mprn_supplystatus }}
            </div>
          </template>

          <template v-slot:cell(mpxn_ticket)="row">
            <div
              v-if="row.item.rejected_mpxn[0].mpan_rejection_ticket !== null"
            >
              {{ row.item.rejected_mpxn[0].mpan_rejection_ticket }}
            </div>
            <div v-else>
              {{ row.item.rejected_mpxn[0].mprn_rejection_ticket }}
            </div>
          </template>

          <template v-slot:cell(mpxn_rejection_reason)="row">
            <div
              v-if="row.item.rejected_mpxn[0].mpan_rejection_ticket !== null"
            >
              {{ row.item.rejected_mpxn[0].mpan_rejection_reason }}
            </div>
            <div v-else>
              {{ row.item.rejected_mpxn[0].mprn_rejection_reason }}
            </div>
          </template>

          <template v-slot:cell(last_updated)="row">
            <div
              v-if="
                row.item.rejected_mpxn[0].mpan_ticket_last_modified !== null
              "
            >
              {{
                row.item.rejected_mpxn[0].mpan_ticket_last_modified
                  | formattedDate
              }}
            </div>
            <div v-else>
              {{
                row.item.rejected_mpxn[0].mprn_ticket_last_modified
                  | formattedDate
              }}
            </div>
          </template>

          <template v-slot:cell(force_send)="row">
            <b-form-checkbox
              v-model="row.item.forceSendFl"
              :disabled="row.item.completedFl"
            />
          </template>

          <template v-slot:cell(old_dd_amount)="row">
            <span>{{ ddAmountText(row, row.item.old_dd_amount) }}</span>
          </template>

          <template v-slot:cell(new_payment_amount)="row">
            <div v-if="row.item.newPaymentAmount !== null">
              <span>{{ ddAmountText(row, row.item.newPaymentAmount) }}</span>
            </div>
          </template>

          <template v-slot:cell(show_details)="row">
            <b-button
              size="sm"
              class="mr-2"
              variant="outline-primary"
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? "Hide" : "Show" }}
            </b-button>
          </template>

          <template slot="row-details" slot-scope="row">
            <b-row class="text-left">
              <b-col> </b-col>
              <b-col>
                <p
                  class="border rounded"
                  style="
                    text-align: left;
                    background-color: white;
                    padding: 15px;
                  "
                  v-html="row.item.templateContent"
                ></p>
              </b-col>
            </b-row>
          </template>

          <template v-slot:cell(process)="row">
            <div
              v-if="
                row.item.completedFl === false &&
                row.item._rowVariant !== 'danger'
              "
            >
              <b-button
                size="sm"
                class="mr-2"
                variant="outline-primary"
                @click="send(row.item)"
              >
                Process
              </b-button>
            </div>
            <div v-else-if="row.item.completedFl === true">
              <b-icon icon="check-circle" variant="success" />
            </div>
          </template>

          <template v-slot:cell(delete)="row">
            <b-button
              size="sm"
              class="mr-2"
              variant="outline-danger"
              @click="deleteRow(row.item)"
            >
              Delete
            </b-button>
          </template>
        </b-table>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import dayjs from "dayjs"
import Registrations from "@/api/Registrations"
import Jobs from "@/api/Jobs"
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import resultsTable from "@/components/resultsTable"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

export default {
  data() {
    return {
      lookupValue: null,
      progressBar: {
        jobId: null,
        stage: null,
        counter: null,
        max: null,
      },
    }
  },
  created() {
    if (this.IndustryRejectedTable.items.length === 0) {
      this.$store.dispatch("industryRejections/getIndustryRejected")
    }

    var progress = this.progressBar
    if (progress.jobId === null) {
      Jobs.getDelayedJobQueue("industry_rejections")
        .then((response) => {
          if (response.data.length !== 0) {
            progress.jobId = response.data[0].id
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  computed: {
    ...mapGetters("industryRejections", [
      "IndustryRejectedTable",
      "indRejIsBusy",
      "lookupResults",
      "deliveryError",
    ]),
    ind_rej_await() {
      return this.IndustryRejectedTable.items.filter(
        (review) =>
          review.completedFl === false && review._rowVariant === undefined
      )
    },
    ind_rej_error() {
      return this.IndustryRejectedTable.items.filter(
        (review) =>
          review.completedFl === false && review._rowVariant === "danger"
      )
    },
    ind_rej_completed() {
      return this.IndustryRejectedTable.items.filter(
        (review) => review.completedFl === true
      )
    },
    paymentAdjustment() {
      var r = this.IndustryRejectedTable.items
      var w = []
      r.forEach(function (x) {
        w.push(x.paymentAdjFl)
      })
      return w
    },
  },
  methods: {
    ...mapActions("industryRejections", [
      "importRecords",
      "send",
      "sendAll",
      "deleteRow",
      "deleteAll",
      "lookupAccount",
    ]),
    clear() {
      var that = this
      this.lookupValue = null
      that.SetLookupResult([])
    },
    processIndRejAll(data) {
      Registrations.sendAllIndRejEmail(data)
        .then((response) => {
          this.progressBar.jobId = response.data.id
          this.progressBar.counter = response.data.progress_current
          this.progressBar.max = this.countAwaiting.length
          this.progressBar.stage = response.data.progress_stage
        })
        .catch((error) => {
          console.log(error)
          this.$store.dispatch("industryRejections/getIndustryRejected")
        })
    },
    ddAmountText(row, amount) {
      let ddAmountText = ""

      if (row.item.seasonalFl) {
        ddAmountText = `Summer: £${amount * 0.75}, Winter: £${amount * 1.25}`
      } else {
        ddAmountText = `£${amount}`
      }
      return ddAmountText
    },
    ...mapMutations({
      SetLookupResult: "industryRejections/SET_LOOKUP_RESULTS",
    }),
    classObject: function (row) {
      return {
        "text-success": row !== "NotSupplied",
        "text-danger": row === "NotSupplied",
      }
    },
  },
  filters: {
    formattedDate: function (date) {
      return dayjs(date).format("Do MMM YY")
    },
  },
  watch: {
    "progressBar.jobId": function (newValue) {
      if (newValue !== null) {
        var progress = this.progressBar
        var interval = setInterval(function () {
          Jobs.getDelayedJob(newValue)
            .then((job) => {
              if (job.data.last_error != null) {
                clearInterval(interval)
              }
              progress.stage = job.data.progress_stage
              progress.counter = job.data.progress_current
              progress.max = job.data.progress_max
            })
            .catch(() => {
              progress.jobId = null
              progress.stage = null
              progress.counter = null
              progress.max = null
              clearInterval(interval)
              this.elecReadings()
            })
        }, 1000)
      }
    },
  },
  components: {
    headerBlock,
    pageSection,
    resultsTable,
  },
}
</script>

<style lang="scss">
.header-description {
  color: $night;
}
</style>
