<template>
  <div class="manage-applications__flex-box">
    <div class="manage-applications__left-column">
      <div
        class="manage-applications__flex-box manage-applications__flex-right"
      >
        <p class="manage-applications__filter-text">Filter by:</p>

        <!-- Criteria One -->
        <DropdownSelection
          dropdown-type="filter"
          dropdown-name="criteria1"
          :options="dropdownOptions.criteria"
          placeholder="Criteria 1"
          @dropdownChange="updateFilters"
        ></DropdownSelection>

        <!-- Criteria Two -->
        <DropdownSelection
          dropdown-type="filter"
          dropdown-name="criteria2"
          :options="secondCriteria(criteria1Filter)"
          placeholder="Criteria 2"
          @dropdownChange="updateFilters"
        ></DropdownSelection>

        <!-- Assigned to -->
        <DropdownSelection
          dropdown-type="filter"
          dropdown-name="assignedTo"
          :options="dropdownOptions.assignedTo"
          placeholder="Assigned to"
          @dropdownChange="updateFilters"
        ></DropdownSelection>
      </div>

      <div class="manage-applications__tables">
        <GenericTable
          v-if="tableData(accounts).length"
          :table-header="ApplicationSuccessHeader"
          color-scheme="green"
          :is-load-more="isLoadMoreShown"
          :column-ratio="[3, 6, 10, 30, 30, 11, 10]"
          :separator-text="'Applications approved (' + accounts.length + ')'"
          :loading-status="loadMoreStatus.name"
          :loading-status-active="loadMoreStatus.type === 'approved'"
          @loadMoreTableRows="$emit('load-more', 'approved')"
        >
          <GenericTableRow
            v-for="(account, index) in tableData(accounts)"
            :key="index"
            :column-ratio="[3, 6, 10, 30, 30, 11, 10]"
            @dropdown-open="
              $emit('get-uploaded-images', {
                accountId: account.account_id,
                tableName: 'approvedApplicants',
              })
            "
          >
            <template v-slot:column-1>
              <div class="generic-table__checkbox">
                <input
                  v-model="selectedAccounts"
                  :value="account.junifer_account_number"
                  type="checkbox"
                  @click.stop
                />
              </div>
            </template>
            <template v-slot:column-2>
              <span class="generic-table__account-id">
                {{ account.junifer_account_number }}
              </span>
            </template>
            <template v-slot:column-3>
              {{ account.primary_whd_criterion_text }}
            </template>
            <template v-slot:column-4>
              {{ account.secondary_whd_criterion_text }}
            </template>
            <template v-slot:column-5>
              {{ account.agent }}
            </template>
            <template v-slot:column-6>
              {{ account.application_year }}
            </template>
            <template v-slot:dropdown>
              <form
                class="generic-table__dropdown"
                @submit.prevent="submitAccountUpdate($event, account)"
              >
                <h3>
                  <strong>First name: </strong>
                  {{ account.first_name }}
                </h3>
                <h3>
                  <strong>Last name: </strong>
                  {{ account.family_name }}
                </h3>
                <h3>
                  <strong>Email: </strong>
                  {{ account.email }}
                </h3>
                <h3>
                  <strong>Contact number: </strong>
                  {{ account.phone_number }}
                </h3>
                <h3>
                  <strong>Supply address: </strong>
                  {{ account.customer_details.address }},
                  {{ account.customer_details.postcode }}
                </h3>
                <h3>
                  <strong>Communication preference: </strong>
                  {{ account.communication_preference }}
                </h3>

                <h3 v-if="account.images">
                  <strong>Eligibility proof: </strong>
                  <strong v-if="account.images.length === 0">
                    No documents uploaded
                  </strong>
                  <a
                    v-else-if="account.images.length === 1"
                    href="javascript:;"
                    @click="openProofModal(account.images[0])"
                  >
                    {{ account.images[0].name }}
                  </a>
                  <div v-else class="generic-list">
                    <ul>
                      <li
                        v-for="(image, imageIndex) in account.images"
                        :key="imageIndex"
                      >
                        <a href="javascript:;" @click="openProofModal(image)">
                          {{ account.images[imageIndex].name }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </h3>

                <div class="generic-table__customer-criteria">
                  <h3>
                    <strong>Update customer criterias</strong>
                  </h3>
                  <p>Criteria One</p>
                  <DropdownSelection
                    dropdown-name="criteria1"
                    :options="dropdownOptions.criteria"
                    :set-selected="account.primary_whd_criterion_id.toString()"
                    :has-placeholder="false"
                    @dropdownChange="registerChange($event, account)"
                  ></DropdownSelection>

                  <p>Criteria Two</p>
                  <DropdownSelection
                    dropdown-name="criteria2"
                    :options="
                      secondCriteria(
                        account.primary_whd_criterion_id.toString()
                      )
                    "
                    :set-selected="
                      account.secondary_whd_criterion_id.toString()
                    "
                    :has-placeholder="false"
                    @dropdownChange="registerChange($event, account)"
                  ></DropdownSelection>
                </div>

                <div class="generic-table__flex-box">
                  <div class="generic-table__half-left-column">
                    <h3>
                      <strong>Update assigned agent</strong>
                      <DropdownSelection
                        dropdown-name="assignedTo"
                        :options="dropdownOptions.assignedTo"
                        :set-selected="account.agent"
                        :has-placeholder="false"
                        @dropdownChange="registerChange($event, account)"
                      ></DropdownSelection>
                    </h3>
                  </div>
                  <div class="generic-table__half-right-column">
                    <h3>
                      <strong>Update status of application</strong>
                      <DropdownSelection
                        dropdown-name="status"
                        :options="dropdownOptions.status"
                        :set-selected="account.ark_status"
                        :has-placeholder="false"
                        @dropdownChange="registerChange($event, account)"
                      ></DropdownSelection>
                    </h3>
                  </div>
                </div>

                <div
                  class="
                    generic-table__flex-box
                    generic-table__flex-right
                    generic-table__flex-wrap
                  "
                >
                  <button
                    class="button button--blue"
                    :disabled="!account.account_data_changed.all"
                    type="submit"
                  >
                    Save Changes
                  </button>
                  <div
                    v-if="checkAccountUpdate(account, 'fail')"
                    class="generic-table__submit-error"
                  >
                    <i class="fas fa-exclamation-triangle"></i>Update failed:
                    <span>Please try resubmitting the form</span>
                  </div>
                  <div
                    v-if="checkAccountUpdate(account, 'success')"
                    class="generic-table__submit-success"
                  >
                    <i class="fa fa-check-circle"></i>Successfully updated
                  </div>
                </div>
              </form>
            </template>
          </GenericTableRow>
        </GenericTable>

        <GenericTable
          v-if="tableData(creditedAccounts).length"
          :is-load-more="isLoadMoreShown"
          :column-ratio="[3, 5, 12, 30, 30, 12, 8]"
          :separator-text="'Credit applied (' + creditedAccounts.length + ')'"
          :is-attached="true"
          color-scheme="green"
          :loading-status="loadMoreStatus.name"
          :loading-status-active="loadMoreStatus.type === 'paid'"
          @loadMoreTableRows="$emit('load-more', 'paid')"
        >
          <GenericTableRow
            v-for="(account, index) in tableData(creditedAccounts)"
            :key="index"
            :column-ratio="[3, 5, 12, 30, 30, 12, 8]"
          >
            <template v-slot:column-1>
              <div class="generic-table__checkbox">
                <input
                  v-model="selectedCancelCreditAccounts"
                  :value="account.junifer_account_number"
                  type="checkbox"
                  @click.stop
                />
              </div>
            </template>
            <template v-slot:column-2>
              <span class="generic-table__account-id">
                {{ account.junifer_account_number }}
              </span>
            </template>
            <template v-slot:column-3>
              {{ account.primary_whd_criterion_text }}
            </template>
            <template v-slot:column-4>
              {{ account.secondary_whd_criterion_text }}
            </template>
            <template v-slot:column-5>
              {{ account.agent }}
            </template>
            <template v-slot:column-6>
              {{ account.created_at.substring(0, 10) }}
            </template>
          </GenericTableRow>
        </GenericTable>

        <p
          v-if="
            tableData(creditedAccounts).length === 0 &&
            tableData(accounts).length === 0
          "
        >
          We have no datas from the selected type.
        </p>
      </div>
    </div>

    <div class="manage-applications__right-column manage-applications__sidebar">
      <SidebarCard>
        <template v-slot:header>
          <i class="fas fa-info-circle manage-applications__info-icon"></i>
          Successful applicants
        </template>
        <p>
          Successful applicant have been informed they will receive a payment
          this winter. Credits should be applied throughout the winter in
          monthly batches. Change team will confirm the monthly limit.
        </p>

        <div
          class="
            manage-applications__select-all
            manage-applications__flex-box
            manage-applications__flex-right
          "
        >
          <button
            class="button button--blue select-button"
            :disabled="!succesfulApplicantsTotal"
          >
            <input
              id="selectAllAcc"
              v-model="selectAll"
              type="checkbox"
              class="checkbox select-button__select-checkbox"
              :disabled="!succesfulApplicantsTotal"
              @click="select"
            />
            <span>
              <template v-if="selectAll">
                All accounts selected
                <i class="fas fa-check manage-applications__check-icon"></i>
              </template>
              <template v-else> Select all accounts </template>
            </span>
          </button>
        </div>
      </SidebarCard>

      <SidebarCard>
        <template v-slot:header> Process Applications </template>
        <p>
          Once our <strong>audit is complete</strong> for the year, process all
          applications. We will check if accounts are closed, if they are
          eligible for core payment, or if they have not verified their
          documents, and remaining applications will be successful. All outcomes
          will be emailed to each customer.
        </p>
        <hr />
        <p class="manage-applications__selected">
          <strong>Selected</strong>
          <span class="manage-applications__selected-total">
            {{ selectedAccounts.length }}
          </span>
        </p>

        <div
          class="
            manage-applications__flex-box manage-applications__flex-right
            process-crediting
          "
        >
          <button
            class="button button--blue"
            :disabled="!selectedAccounts.length"
            @click="openWarningModal"
          >
            Process
          </button>
          <div
            v-if="applyCreditSuccessState"
            class="
              manage-applications__process
              manage-applications__process-successful
            "
          >
            <i class="fa fa-check-circle"></i>
            <span>Successfully processed</span>
          </div>
        </div>
      </SidebarCard>

      <SidebarCard>
        <template v-slot:header> Cancel Applications </template>
        <p>
          If the application is approved and no credit is applied, we will
          inform the customer their application has been closed.
        </p>
        <p>
          If credit has been applied we will inform the customer their
          application has been closed and credits will be removed from their
          Junifer account.
        </p>
        <hr />
        <p class="manage-applications__selected">
          <strong>Selected</strong>
          <span class="manage-applications__selected-total">
            {{ cancelApplicationTotal }}
          </span>
        </p>

        <div
          class="
            manage-applications__flex-box manage-applications__flex-right
            process-crediting
          "
        >
          <button
            class="button button--blue"
            :disabled="!cancelApplicationTotal"
            @click="openCancelCreditWarningModal"
          >
            Close applications
          </button>
          <div
            v-if="removeCreditSuccessState"
            class="
              manage-applications__process
              manage-applications__process-successful
            "
          >
            <i class="fa fa-check-circle"></i>
            <span>Successfully processed</span>
          </div>
        </div>
      </SidebarCard>

      <SidebarCard>
        <template v-slot:header>Update status of application</template>
        <p class="manage-applications__selected">
          <strong>Accounts selected</strong>
          <span class="manage-applications__selected-total">
            {{ selectedAccounts.length }}
          </span>
        </p>

        <form @submit.prevent="submitStatusUpdate">
          <DropdownSelection
            dropdown-name="processTo"
            :options="dropdownOptions.status"
            placeholder="Please select"
            @dropdownChange="processApplications"
          ></DropdownSelection>
          <div
            class="
              manage-applications__flex-box manage-applications__flex-right
              process-approved
            "
          >
            <button
              class="button button--blue"
              :disabled="
                selectedAccounts.length === 0 ||
                processType.length === 0 ||
                pendingProcessing
              "
              type="submit"
            >
              Process
            </button>
          </div>
        </form>

        <div class="manage-applications__process-container">
          <div
            v-if="failedToProcess"
            class="
              manage-applications__process manage-applications__process-failed
            "
          >
            <i class="fas fa-exclamation-triangle"></i>
            <span>Process failed, please retry</span>
          </div>

          <div
            v-if="pendingProcessing && !successfullyProcessed"
            class="manage-applications__loading"
          >
            <img src="@/assets/icons/loading-bar.gif" />
          </div>

          <div
            v-if="successfullyProcessed && !selectedAccounts.length"
            class="
              manage-applications__process
              manage-applications__process-successful
            "
          >
            <i class="fa fa-check-circle"></i>
            <span>Successfully processed</span>
          </div>
        </div>
      </SidebarCard>
    </div>

    <Modal
      :is-full-screen="true"
      :is-modal-open="isFilePreviewVisible"
      @closeModal="isFilePreviewVisible = false"
    >
      <template #body>
        <FilePreview :file-data="currentFile" />
        <p class="file-modal-text">{{ fileModalText }}</p>
      </template>
      <template #cancelButton>Close</template>
    </Modal>
  </div>
</template>

<script>
import { whdMixin } from "@/mixins/whdMixin"
import SidebarCard from "@/components/SidebarCard"
import GenericTable from "@/components/smartMeter/GenericTable"
import GenericTableRow from "@/components/smartMeter/GenericTableRow"
import DropdownSelection from "@/components/warmHomeDiscount/DropdownSelection"
import FilePreview from "@/components/FilePreview"
import Modal from "@soenergy/frontend-library/src/components/Modal"

export default {
  name: "ApplicationSuccess",
  components: {
    SidebarCard,
    GenericTable,
    GenericTableRow,
    DropdownSelection,
    FilePreview,
    Modal,
  },
  mixins: [whdMixin],
  props: {
    successfulAccounts: {
      type: Array,
      default: () => [],
    },
    creditedAccounts: {
      type: Array,
      default: () => [],
    },
    dropdownOptions: {
      type: Object,
      default: () => {},
    },
    loadMoreStatus: {
      type: Object,
      default: () => {},
    },
    succesfulApplicantsTotal: {
      type: Boolean,
      default: false,
    },
    applyCreditSuccess: {
      type: Boolean,
      default: false,
    },
    removeCreditSuccess: {
      type: Boolean,
      default: false,
    },
    failedProcessing: {
      type: Boolean,
      default: false,
    },
    pendingProcessing: {
      type: Boolean,
      default: false,
    },
    successfullyProcessed: {
      type: Boolean,
      default: false,
    },
    resetForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ApplicationSuccessHeader: [
        "",
        "Select",
        "Account Id",
        "Criteria 1",
        "Criteria 2",
        "Assigned",
        "Date",
      ],
      accounts: [],
      selectedAccounts: [],
      selectedCancelCreditAccounts: [],
      applyCreditSuccessState: this.applyCreditSuccess,
      removeCreditSuccessState: this.removeCreditSuccess,
      selectAll: false,
      failedToProcess: this.failedProcessing,
      processType: "",
      isFilePreviewVisible: false,
      currentFile: null,
    }
  },
  computed: {
    isLoadMoreShown() {
      return !this.searchSuccess
    },
    cancelApplicationTotal() {
      let cancellationTotal =
        this.selectedAccounts.length + this.selectedCancelCreditAccounts.length
      return cancellationTotal
    },
    fileModalText() {
      const fileName = this.currentFile ? this.currentFile.name : ""
      return "Uploaded file: " + fileName
    },
  },
  watch: {
    successfulAccounts(value) {
      this.accounts = this.successfulAccounts.map((account) => ({
        account_data_changed: {
          cr1: false,
          cr2: false,
          agent: false,
          status: false,
          all: false,
          originalPrimaryCriteria: account.primary_whd_criterion_id,
        },
        accountUpdateFail: false,
        accountUpdateSuccess: false,
        ...account,
      }))
    },
    applyCreditSuccess(value) {
      this.applyCreditSuccessState = value
      this.resetSelection()

      if (value) {
        setTimeout(() => (this.applyCreditSuccessState = false), 3000)
      } 
    },
    removeCreditSuccess(value) {
      this.removeCreditSuccessState = value

      if (value) {
        this.resetSelection()
        setTimeout(() => (this.removeCreditSuccessState = false), 3000)
      }
    },
    successfullyProcessed(value) {
      this.resetSelection()
      this.failedToProcess = false
    },
    failedProcessing(value) {
      this.failedToProcess = value
    },
    resetForm() {
      this.resetSelection()
    }
  },
  methods: {
    openWarningModal() {
      // open apply credit warning modal
      this.$emit("open", this.selectedAccounts)
    },
    openCancelCreditWarningModal() {
      this.cancellationSelection()
      this.$emit("openCancelCreditModal", this.selectedCancelCreditAccounts)
    },
    resetSelection() {
      this.selectedAccounts = []
      this.selectedCancelCreditAccounts = []
    },
    select() {
      this.resetSelection()
      if (!this.selectAll) {
        for (let i in this.successfulAccounts) {
          this.selectedAccounts.push(
            this.successfulAccounts[i].junifer_account_number
          )
        }
      }
    },
    cancellationSelection() {
      if (this.selectedAccounts.length) {
        for (let i in this.selectedAccounts) {
          this.selectedCancelCreditAccounts.push(this.selectedAccounts[i])
        }
        this.resetSelection()
      }
    },
    processApplications(selection) {
      switch (selection.name) {
        case "processTo":
          this.processType = selection.value
          break
        default:
          return
      }
    },
    submitStatusUpdate() {
      let submissionData = {
        processingTab: "successfulApplication",
        data: {
          warm_home_discounts_interface: {
            account_numbers: this.selectedAccounts,
            new_attributes: {
              ark_status: this.processType,
            },
          },
        },
      }
      this.$emit("process-applications", submissionData)
    },
    openProofModal(item) {
      this.isFilePreviewVisible = true
      this.currentFile = item
    },
  },
}
</script>

<style lang="scss" scoped>
.process-crediting {
  flex-wrap: wrap;

  .manage-applications__process-successful {
    width: 100%;
    margin: $spacing-5 0 0 0;
    text-align: center;
  }
}

.manage-applications {
  &__select-all {
    display: inline-block;
    margin-top: $spacing-4;

    label {
      border-radius: 20px;
      padding: $spacing-2 $spacing-4 $spacing-2 $spacing-2;
      font-weight: $weight-bold;

      &:hover {
        cursor: pointer;
      }
    }

    input {
      opacity: 0;
    }
  }

  &__check-icon {
    padding-left: $spacing-1;
  }

  &__flex-box {
    display: flex;
  }
  &__flex-right {
    justify-content: flex-end;
  }
}

.generic-list {
  color: $water-dark;
  margin-top: 18px;
  margin-bottom: -$space-2;
}
</style>
