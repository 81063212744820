import { refundAmount } from "@/helpers/paymentAdequacy/refund"
import { roundNum, extend } from "@/helpers/round"
import { fetchTemplate } from "@/helpers/paymentAdequacy/initialTemplates"
import { initialReview } from "@/helpers/paymentAdequacy/initialReview"
import { createFormatter } from "@/helpers/paymentAdequacy/createFormatter"
import { reviewFormatter } from "@/helpers/paymentAdequacy/reviewFormatter"

export const paymentAdequacyMixin = {
  methods: {
    round(value, decimals) {
      return roundNum(value, decimals)
    },
    refund(data) {
      return refundAmount(data)
    },
    extend(data) {
      return extend(data)
    },
    fetchTemplate(data) {
      return fetchTemplate(data)
    },
    initialReview(data) {
      return initialReview(data)
    },
    createFormatter(data) {
      return createFormatter(data)
    },
    reviewFormatter(data) {
      return reviewFormatter(data)
    },
  },
}
