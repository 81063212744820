<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Emergency Contacts"
        summary="List of contacts to recieve notifications when an email is sent into emergency@so.energy"
      >
        <template v-slot:description-body>
          <p>
            Only active contacts will receive notificaitons. See
            <a
              href="https://app.lucidchart.com/documents/edit/1317d756-2b85-4f10-b285-902264144c78/0_0"
              target="_blank"
              >Emergency Wireframe</a
            >
            for more information.
          </p>
          <p>Please contact engineering to permanently a contact.</p>
        </template>
      </header-block>
    </b-row>

    <page-section title="All emergency contacts">
      <template v-slot:content>
        <b-row>
          <b-table
            hover
            small
            responsive
            fixed
            :items="dataTable.items"
            :fields="dataTable.fields"
          >
            <template v-slot:cell(name)="row">
              <span v-if="row.item.editable">
                <input v-model="row.item.name" type="string" />
              </span>
              <span v-else>
                {{ row.item.name }}
              </span>
            </template>

            <template v-slot:cell(phone)="row">
              <span v-if="row.item.editable">
                <input v-model="row.item.phone" type="string" />
              </span>
              <span v-else>
                {{ row.item.phone }}
              </span>
            </template>

            <template v-slot:cell(active)="row">
              <b-form-checkbox
                v-model="row.item.active"
                size="lg"
                :disabled="!row.item.editable"
              />
            </template>

            <template v-slot:cell(edit)="row">
              <b-button
                size="sm"
                variant="outline-dark"
                @click="editable(row.item)"
                >{{ row.item.editable ? "Save" : "Edit" }}</b-button
              >
            </template>
          </b-table>
        </b-row>
      </template>
    </page-section>

    <page-section title="Create new contact">
      <template v-slot:content>
        <b-row>
          <div>
            <b-form inline @submit="onSubmit" @reset="onReset">
              <label class="ml-3 mr-3" for="name">Name</label>
              <b-input
                id="name"
                v-model="contact.name"
                required
                :disabled="contactSubmittedFl"
                class="mr-3"
                type="string"
                placeholder="Jane Doe"
              ></b-input>
              <label class="mr-3" for="phone">Phone</label>
              <b-input
                id="phone"
                v-model="contact.phone"
                required
                :disabled="contactSubmittedFl"
                class="mr-3"
                type="string"
                placeholder="+447"
              ></b-input>
              <label class="mr-3" for="active">Is Active?</label>
              <b-form-checkbox
                id="active"
                v-model="contact.active"
                :disabled="contactSubmittedFl"
                class="mr-3"
                size="lg"
              ></b-form-checkbox>
              <b-button
                size="sm"
                type="submit"
                :disabled="contactSubmittedFl"
                class="mr-3"
                variant="success"
                >{{ !contactSubmittedFl ? "Save" : "Sent!" }}</b-button
              >
              <b-button
                size="sm"
                type="reset"
                class="mr-3"
                variant="outline-danger"
                >Clear</b-button
              >
            </b-form>
          </div>
        </b-row>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import Emergencies from "@/api/Emergencies.js"
import Vue from "vue"

export default {
  components: {
    headerBlock,
    pageSection,
  },
  data() {
    return {
      contactSubmittedFl: false,
      contact: {
        name: "",
        phone: "",
        active: true,
        contact_preference_id: 1,
      },
      isBusy: false,
      contacts: [],
      error: null,
    }
  },
  computed: {
    dataTable() {
      var table = {
        sortBy: "name",
        fields: [
          { key: "name", label: "Name", sortable: true },
          { key: "phone", label: "Phone", sortable: true },
          { key: "active", label: "Active", sortable: false },
          { key: "edit", label: "", sortable: false },
        ],
        items: this.contacts,
      }
      return table
    },
  },
  created() {
    var that = this
    Emergencies.fetchContacts().then((response) => {
      response.data.forEach(function (option) {
        response.data.editable = false
      })
      this.contacts = response.data
    })
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      this.create_record(this.contact)
    },
    onReset(evt) {
      evt.preventDefault()
      this.contact.name = ""
      this.contact.phone = ""
      this.disabledFl = true
      this.contactSubmittedFl = false
    },
    send(id, payload) {
      Emergencies.updateContact(id, payload)
    },
    editable(row) {
      row.editable = !row.editable
      var index = this.contacts.findIndex((s) => s.id == row.id)
      Vue.set(this.contacts, index, row)
      if (!row.editable) {
        this.send(row.id, {
          name: row.name,
          phone: row.phone,
          active: row.active,
        })
      }
    },
    create_record(payload) {
      Emergencies.createContact(payload).then((response) => {
        response.data.editable = false
        this.contacts.push(response.data)
        this.contactSubmittedFl = true
      })
    },
  },
}
</script>
