<template>
  <div class="account-container">
    <section class="account-search account-search__head">
      <div class="account-number-search">
        <div>
          <InputField
            v-model="accountNumberSearch"
            class="account-number-search__input"
            label="Customer account number"
            name="customer-account-number"
            inputmode="numeric"
            maxlength="50"
            placeholder="12345678"
            pattern="[\d ]{10,30}"
            required
          />
        </div>
        <div>
          <AppButton
            label="Search"
            type="submit"
            @click="fetchCustomerData()"
          />
        </div>
      </div>
    </section>
    <Tabs
      v-if="!searchError && Object.keys(accountDetails).length"
      class="tab account-search__tab"
      :contained="false"
    >
      <Tab heading="Account">
        <section ref="accountDetails" class="account-search">
          <div class="details-container">
            <h3 class="details-container__title">Customer Details</h3>
            <div
              class="details-container__card details-container__customer-info"
            >
              <div>
                <div class="details-container__key">Name:</div>
                <div>{{ nameFormatted }}</div>
              </div>
              <div>
                <div class="details-container__key">Email:</div>
                <div>{{ accountDetails.contact_info.email }}</div>
              </div>
              <div>
                <InputField
                  v-model="accountDetails.contact_info.number"
                  label="Phone:"
                  name="customer-account-number"
                  inputmode="numeric"
                  maxlength="12"
                  required
                />
              </div>
              <div>
                <div class="details-container__key">Address:</div>
                <div>{{ addressFormatted }}</div>
              </div>
            </div>
          </div>

          <div class="details-container">
            <h3 class="details-container__title">Meter Details</h3>

            <div
              ref="detailsContainerCardWrapper"
              class="details-container__card-wrapper"
            >
              <div
                v-for="(meterPoint, meterPointIndex) in allMeterPoints"
                :key="meterPointIndex"
                ref="detailsContainerCard"
                class="details-container__card"
                :class="{
                  'details-container__card--wide': meterPoint.meters.length > 1,
                }"
              >
                <div
                  ref="detailsContainerTitle"
                  class="details-container__meter-id"
                >
                  <i :class="'icon ' + getIconName(meterPoint.type)" />
                  <strong class="details-container__key"
                    >{{ meterPoint.type }}:
                  </strong>
                  <span>{{ meterPoint.identifier }}</span>
                </div>

                <div
                  ref="detailsContainerMeterpoints"
                  class="details-container__meterpoints"
                >
                  <div
                    v-for="(meter, meterIndex) in meterPoint.meters"
                    :key="meterIndex"
                    class="details-container__meterpoint"
                  >
                    <p>
                      <strong class="details-container__key">Meter: </strong>
                      <span ref="detailsContainerMeter">
                        {{ meter.identifier }}
                      </span>
                    </p>
                    <p
                      v-for="(register, registerIndex) in meter.registers"
                      :key="registerIndex"
                    >
                      <strong class="details-container__key">Register: </strong>
                      <span ref="detailsContainerRegister">
                        {{ register.identifier || "N/A" }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FeedbackDialog v-if="hasActiveBooking" variant="info" full-width>
            There is an upcoming appointment for this customer, if this
            appointment is incorrect or needs rescheduling, cancel this before
            booking a new one
          </FeedbackDialog>
        </section>
      </Tab>
      <Tab heading="Appointments">
        <img
          v-if="customerPending"
          src="@/assets/icons/loading-bar.gif"
          class="mt-8"
        />
        <section
          v-else-if="bookingData.bookings && bookingData.bookings.length"
          ref="appointments"
          class="account-appointments"
        >
          <div
            v-for="(booking, index) in bookingData.bookings"
            :key="index"
            ref="appointments-card"
            class="account-appointments__card"
          >
            <p class="account-appointments__card-title">
              {{ getBookingJobDetails(booking) }}
            </p>
            <p class="account-appointments__card-job">
              <span>Reference Number - </span>
              {{ booking["aes-job-number"] }}
            </p>

            <div class="account-appointments__card-details">
              <div class="account-appointments__meter">
                <p v-if="booking.mpan">
                  <span>MPAN - </span>{{ booking.mpan }}
                </p>
                <p v-if="booking.mprn">
                  <span>MPRN - </span>{{ booking.mprn }}
                </p>
                <p v-if="booking['electricity-meter-serial-number']">
                  <span>Meter - </span>
                  {{ booking["electricity-meter-serial-number"] }}
                </p>
                <p v-if="booking['gas-meter-serial-number']">
                  <span>Meter - </span>{{ booking["gas-meter-serial-number"] }}
                </p>
              </div>
              <div class="account-appointments__time">
                <p>{{ appointmentDate(booking["slot-start-datetime"]) }}</p>
                <p>
                  {{
                    appointmentTime(
                      booking["slot-start-datetime"],
                      booking["slot-end-datetime"]
                    )
                  }}
                </p>
              </div>
              <div class="account-appointments__state">
                <label
                  class="account-appointments__label"
                  :class="
                    'account-appointments__label' +
                    getBookingState(booking).className
                  "
                >
                  {{ getBookingState(booking).text }}
                </label>
              </div>
              <div class="account-appointments__cta">
                <AppButton
                  variant="secondary"
                  size="xs"
                  @click="openBookingDetailsModal(booking)"
                >
                  Details
                </AppButton>
              </div>
            </div>
          </div>
        </section>
        <div
          v-else
          ref="missing-bookings"
          class="account-appointments__failure"
        >
          <p>
            <i class="fas fa-exclamation-triangle mr-1"></i>
            {{ appointmentErrorMessage }}
          </p>
        </div>
        <CancellationModal
          :show="showCancellationModal"
          :booking="currentBooking"
          :booking-state="getBookingState(currentBooking).text"
          :eligible-to-cancel="eligibleToCancel(currentBooking)"
          :cancel-pending="cancellation.cancelPending"
          :cancel-success="cancellation.cancelSuccess"
          :cancel-error="cancellation.cancelError"
          @close-cancellation-modal="showCancellationModal = false"
          @cancel-appointment="
            (jobNumber) => $emit('cancel-appointment', jobNumber)
          "
        />
      </Tab>
    </Tabs>
    <section v-else ref="errorMessage">
      <div v-if="searchError" class="details-container__failure">
        <p>
          <i class="fas fa-exclamation-triangle mr-1"></i> {{ searchError }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import InputField from "@soenergy/frontend-library/src/components/InputField"
import Tabs from "@soenergy/frontend-library/src/components/Tabs/Tabs"
import Tab from "@soenergy/frontend-library/src/components/Tabs/Tab"
import CancellationModal from "./CancellationModal.vue"
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog"
import dayjs from "dayjs"
import jobTypes from "@/constants/imbp_jobs"
import { dateFormatMixin } from "../../mixins/dateFormatMixin"
import { mapGetters } from "vuex"

export default {
  components: {
    AppButton,
    InputField,
    Tabs,
    Tab,
    CancellationModal,
    FeedbackDialog,
  },
  mixins: [dateFormatMixin],
  props: {
    accountDetails: {
      type: Object,
      default: () => ({}),
    },
    searchError: {
      type: String,
      default: "",
    },
    cancellation: {
      type: Object,
      default: () => ({}),
    },
    bookingData: {
      type: Object,
      default: () => ({}),
    },
    customerPending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accountNumberSearch: "",
      showCancellationModal: false,
      currentBooking: {},
    }
  },
  computed: {
    ...mapGetters({ hasActiveBooking: "imbp/hasActiveBooking" }),
    appointmentErrorMessage() {
      if (this.bookingData?.bookings?.length === 0) {
        return "No past or future appointments"
      } else {
        return "Unable to fetch booking data"
      }
    },
    allMeterPoints() {
      const { electric_meterpoint_data = [], gas_meterpoint_data = [] } =
        this.accountDetails || {}
      return [...electric_meterpoint_data, ...gas_meterpoint_data]
    },
    nameFormatted() {
      if (this.accountDetails.name === undefined) {
        return ""
      } else {
        const title = this.accountDetails.name.title
        const forename = this.accountDetails.name.forename
        const surname = this.accountDetails.name.surname
        return `${title} ${forename} ${surname}`
      }
    },
    addressFormatted() {
      if (this.accountDetails.address === undefined) {
        return ""
      } else {
        const address1 = this.accountDetails.address.address1
        const address2 = this.accountDetails.address.address2
        const address3 = this.accountDetails.address.address3
        const postcode = this.accountDetails.address.postcode
        return `${address1} ${address2}, ${address3}, ${postcode}`
      }
    },
    pageIsValid() {
      return (
        !!Object.keys(this.accountDetails)?.length &&
        this.accountDetails?.contact_info?.number?.length > 7 &&
        !isNaN(this.accountDetails?.contact_info?.number) &&
        !this.hasActiveBooking
      )
    },
  },
  watch: {
    cancellation: {
      handler(value) {
        if (value.closeModal) {
          this.showCancellationModal = false
        }
      },
      deep: true,
    },
    pageIsValid: {
      handler(value) {
        if (value) {
          this.$emit("mark-page-valid")
        } else {
          this.$emit("mark-page-invalid")
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$emit("mark-page-invalid")
  },
  methods: {
    fetchCustomerData() {
      this.$emit("get-account-details", {
        accountNumberSearch: this.accountNumberSearch,
      })
    },
    getIconName(type) {
      return type === "MPRN" ? "icon-gas" : "icon-electricity"
    },
    getBookingState(booking) {
      let bookingInfo = {}
      if (booking["aes-status"] === "booked") {
        bookingInfo.text = "Booked"
        bookingInfo.className = "--pending"
      } else if (booking["aes-status"] === "installed") {
        bookingInfo.text = "Completed"
        bookingInfo.className = "--done"
      } else if (booking["aes-status"] === "failed") {
        bookingInfo.text = "Failed"
        bookingInfo.className = "--failed"
      } else if (booking["aes-status"] === "cancelled") {
        bookingInfo.text = "Cancelled"
        bookingInfo.className = "--failed"
      }
      return bookingInfo
    },
    openBookingDetailsModal(booking) {
      this.currentBooking = booking
      this.currentBooking.jobDetails = this.getBookingJobDetails(booking)
      this.showCancellationModal = true
    },
    appointmentDate(slotStartDateTime) {
      return this.appointmentDateFormatted(slotStartDateTime)
    },
    appointmentTime(slotStartDateTime, slotEndDateTime) {
      return this.appointmentTimeFormatted(slotStartDateTime, slotEndDateTime)
    },
    eligibleToCancel(currentBooking) {
      const slotStartDateTime = currentBooking?.["slot-start-datetime"]
      if (!slotStartDateTime || !dayjs(slotStartDateTime).isValid()) {
        return false
      }
      const timeCheck = dayjs(slotStartDateTime)
        .subtract(24, "hour")
        .isAfter(dayjs(), "minute")
      //TODO: Add status check for booked appointments when BE endpoint is updated [SO-15978]
      return timeCheck
    },
    getBookingJobDetails(booking) {
      const appointmentType = jobTypes.appointmentTypesAES.find(
        (item) => item.value === booking["appointment-type"]
      )
      const jobType = jobTypes.meterTypesAES.find(
        (item) => item.label === booking["meter-type"]
      )
      const appointment = appointmentType ? appointmentType.label : ""
      const job = jobType ? jobType.label : ""
      return `${job} - ${appointment}`
    },
  },
}
</script>

<style scoped lang="scss">
.account-search {
  padding-bottom: $space-4;

  &__head {
    position: absolute;
    top: $space-12;
    right: $space-15;
  }

  .account-number-search {
    display: flex;
    align-items: flex-end;

    &__input {
      margin: 0;
    }
  }

  &__tab {
    ::v-deep .tabs__button-container {
      margin-left: -$space-15;
      margin-right: -$space-15;
    }
    ::v-deep .tabs__button-container .container {
      max-width: 956px;
    }
  }

  ::v-deep .form-group__input {
    border-color: $grey-600;
    border-radius: $space-1 $space-0 $space-0 $space-1;
    border-width: 1px;
    height: 64px;
    min-width: 323px;
  }

  ::v-deep .button {
    border-radius: $space-0 $space-1 $space-1 $space-0;
    height: 64px;
  }
}
.details-container {
  margin: $space-12 $space-0 $space-12 $space-0;
  font-size: $font-size-4;

  &__failure {
    color: $red-600;
    margin-top: $space-3;
  }

  &__title {
    font-weight: $weight-medium;
    font-size: $font-size-5;
    margin-bottom: $space-5;
  }

  &__card-wrapper {
    display: flex;
    width: calc(100% + #{$space-4});
    flex-wrap: wrap;
    margin: 0 (-$space-2);
  }

  &__card {
    background: $grey-100;
    border-radius: $space-1;
    padding: $space-3;
    line-height: 2;
    margin: $space-2 $space-2 $space-6;
    width: calc(50% - #{$space-4});

    &--wide {
      width: 100%;
    }
  }

  &__customer-info {
    display: grid;
    column-gap: $space-6;
    grid-template-columns: 1fr 2fr 1fr 2fr;
    margin: 0 0 $space-6;
    width: 100%;
  }

  &__key {
    font-weight: $weight-medium;
  }

  &__meter-id {
    .icon {
      font-weight: bold;
      margin-right: $space-2;
    }
  }

  &__meterpoints {
    margin-left: $space-7;
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    p {
      margin: 0;
    }
  }

  &__meterpoint {
    min-width: 50%;
  }
}

.account-appointments {
  margin-top: $space-6;

  &__failure {
    color: $red-600;
    margin-top: $space-8;
  }

  &__card {
    border-bottom: 1px solid $grey-500;
    padding: $space-6 0;
  }

  &__card-job {
    font-size: $font-size-3;

    span {
      font-weight: $weight-medium;
    }
  }
  &__card-title {
    font-weight: $weight-medium;
    font-size: $font-size-4;
    margin-bottom: $space-3;
  }
  &__card-details {
    display: flex;
  }
  &__meter,
  &__time {
    width: 25%;
    font-size: $font-size-3;
    line-height: 28px;

    p {
      margin: 0;
    }

    span {
      font-weight: $weight-medium;
    }
  }
  &__state {
    width: 25%;
    align-items: center;
    display: flex;
  }
  &__label {
    padding: $space-1 $space-3 6px;
    border-radius: 15px;
    height: 30px;
    font-weight: $weight-medium;

    &--pending {
      background-color: $yellow-100;
      color: $sun-dark;
    }
    &--done {
      background-color: $green-200;
      color: $green-800;
    }
    &--failed {
      background-color: $red-100;
      color: $red-500;
    }
  }

  &__cta {
    width: 25%;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }
}
</style>
