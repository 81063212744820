import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { postcodeValidation } from "@soenergy/frontend-library/src/helpers/postcodeValidationHelper"

dayjs.extend(utc)

const getDefaultState = () => {
  return {
    currentStepNumber: 0,
    steps: [
      {
        name: "Introduction",
        pageTitle: "Internal Meter Booking Portal",
        trackingName: "",
        isPageValid: true,
      },
      {
        name: "AccountSearch",
        pageTitle: "Customer Search",
        trackingName: "",
        isPageValid: false,
      },
      {
        name: "ChooseJobType",
        trackingName: "",
        pageTitle: "Select Meter and Job",
        isPageValid: false,
      },
      {
        name: "ChooseAppointment",
        trackingName: "",
        pageTitle: "Choose Appointment",
        isPageValid: false,
      },
      {
        name: "ExtraInformation",
        trackingName: "",
        pageTitle: "Extra Information",
        isPageValid: true,
      },
      {
        name: "AppointmentSummary",
        trackingName: "",
        pageTitle: "Job Summary",
        isPageValid: true,
      },
      {
        name: "BookingConfirmation",
        pageTitle: "",
        trackingName: "",
        isPageValid: true,
      },
    ],
    bookingData: {},
    installer: "",
    fuelType: "",
    // Form values
    activeTime: {},
    selectedDate: null,
    hasMedicalEquipment: false,
    accountDetails: {},
    accountSearchError: "",
    selectedJobs: {
      selectedMeterTypeAES: null,
      selectedInstallationType: null,
      selectedAppointmentType: null,
    },
    reservationId: "",
  }
}
const state = getDefaultState()

const mutations = {
  SET_CURRENT_STEP_NUMBER(state, stepNumber) {
    state.currentStepNumber = stepNumber
  },
  SET_TIME(state, activeTime) {
    state.activeTime = activeTime
  },
  SET_DATE(state, selectedDate) {
    state.selectedDate = selectedDate
  },
  SET_BOOKING_DATA(state, data) {
    state.bookingData = data
  },
  SET_ACCOUNT_DETAILS(state, data) {
    state.accountDetails = data
  },
  SET_INSTALLER(state, installer) {
    state.installer = installer
  },
  SET_FUEL_TYPE(state, fuelType) {
    state.fuelType = fuelType
  },
  SET_ACCOUNT_SEARCH_ERROR(state, error) {
    state.accountSearchError = error
  },
  SET_MEDICAL_EQUIPMENT(state, hasMedicalEquipment) {
    state.hasMedicalEquipment = hasMedicalEquipment
  },
  SET_METER_TYPE_AES(state, data) {
    state.selectedJobs.selectedMeterTypeAES = data
  },
  SET_INSTALLATION_TYPE(state, data) {
    state.selectedJobs.selectedInstallationType = data
  },
  SET_APPOINTMENT_TYPE(state, data) {
    state.selectedJobs.selectedAppointmentType = data
  },
  SET_PAGE_VALIDITY(state, data) {
    state.steps[data.pageIndex].isPageValid = data.isPageValid
  },
  SET_RESERVATION_ID(state, id) {
    state.reservationId = id
  },
}

const getters = {
  formattedPostcode(state) {
    return state.accountDetails?.address?.postcode
      ? postcodeValidation(state.accountDetails.address.postcode)
      : null
  },
  startDateTime(state) {
    return state.selectedDate && state.activeTime.startTime
      ? dayjs(`${state.selectedDate} ${state.activeTime.startTime}`).toDate()
      : null
  },
  endDateTime(state) {
    return state.selectedDate && state.activeTime.endTime
      ? dayjs(`${state.selectedDate} ${state.activeTime.endTime}`).toDate()
      : null
  },
  timeRange(state, getters) {
    return getters.startDateTime && getters.endDateTime
      ? `${dayjs(getters.startDateTime).format("ha")} - ${dayjs(
          getters.endDateTime
        ).format("ha")}`
      : null
  },
  customerAddress(state) {
    const { address1, address2, address3 } = state.accountDetails?.address ?? {}
    return [address1, address2, address3].filter(Boolean).join(", ")
  },
  gasMeter(state, getters) {
    return state.accountDetails.gas_meterpoint_data?.reduce(
      (meterData, meterpoint) => {
        const meterSerialNumber = meterpoint.meters?.find(
          (meter) => meter.identifier
        )

        if (meterSerialNumber && meterpoint.identifier) {
          meterData = {
            id: meterpoint.identifier,
            serialNumber: meterSerialNumber.identifier,
          }
        }
        return meterData
      },
      {}
    )
  },
  electricMeter(state, getters) {
    return state.accountDetails.electric_meterpoint_data?.reduce(
      (meterData, meterpoint) => {
        const meterSerialNumber = meterpoint.meters?.find(
          (meter) => meter.identifier
        )

        if (meterSerialNumber && meterpoint.identifier) {
          meterData = {
            id: meterpoint.identifier,
            serialNumber: meterSerialNumber.identifier,
          }
        }
        return meterData
      },
      {}
    )
  },
  gasMeterSerialNumber(state, getters) {
    return getters.gasMeter?.serialNumber.toString() || ""
  },
  electricityMeterSerialNumber(state, getters) {
    return getters.electricMeter?.serialNumber.toString() || ""
  },
  mprn(state, getters) {
    return getters.gasMeter?.id.toString() || ""
  },
  mpan(state, getters) {
    return getters.electricMeter?.id.toString() || ""
  },
  hasActiveBooking(state, getters) {
    return state.bookingData?.bookings?.some((booking) =>
      dayjs(booking["slot-start-datetime"]).isAfter(dayjs())
    )
  },
}

const actions = {
  resetForm({ commit, state }) {
    commit("SET_CURRENT_STEP_NUMBER", 1)

    commit("SET_BOOKING_DATA", {})
    commit("SET_INSTALLER", "")
    commit("SET_FUEL_TYPE", "")

    commit("SET_ACCOUNT_DETAILS", {})
    commit("SET_TIME", {})
    commit("SET_DATE", null)
    commit("SET_MEDICAL_EQUIPMENT", false)
    commit("SET_METER_TYPE_AES", null)
    commit("SET_INSTALLATION_TYPE", null)
    commit("SET_APPOINTMENT_TYPE", null)

    commit("SET_PAGE_VALIDITY", { pageIndex: 1, isPageValid: false })
    commit("SET_PAGE_VALIDITY", { pageIndex: 2, isPageValid: false })
    commit("SET_PAGE_VALIDITY", { pageIndex: 3, isPageValid: false })

    window.scrollTo(0, 0)
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}
