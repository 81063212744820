import { render, staticRenderFns } from "./WarningModal.vue?vue&type=template&id=52a2278b&scoped=true"
import script from "./WarningModal.vue?vue&type=script&lang=js"
export * from "./WarningModal.vue?vue&type=script&lang=js"
import style0 from "./WarningModal.vue?vue&type=style&index=0&id=52a2278b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52a2278b",
  null
  
)

export default component.exports