<template>
  <div>
    <b-row class="ml-3 mr-3">
      <h3>{{ title }}</h3>
    </b-row>

    <b-row class="ml-3 mr-3 mb-3">
      <b-card
        style="width: 100%; margin: 0 auto"
        class="ml-0 mr-0 p-0 payment-ad-card__card"
        border-variant="dark"
      >
        <slot name="content"> </slot>
      </b-card>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    body: {
      type: String,
      default: "",
    },
  },
}
</script>

<style lang="scss">
.payment-ad-card .payment-ad-card__card .card-body {
  min-height: 134px;
}
</style>
