<template>
  <AppModal :show="show" class="warning-modal" @close="closeModal">
    <template v-slot:header>
      <h2 class="warning-modal__header" v-html="modalSettings.title"></h2>
    </template>

    <template v-slot:body>
      <div class="warning-modal__body">
        <p v-html="modalSettings.body"></p>

        <p><strong>Do you wish to continue?</strong></p>
      </div>
    </template>

    <template v-slot:footer>
      <div class="warning-modal__footer">
        <button class="button button--grey-outlined" @click="closeModal">
          Cancel
        </button>
        <slot></slot>
      </div>
    </template>
  </AppModal>
</template>

<script>
import AppModal from "@/components/smartMeter/AppModal"
export default {
  components: {
    AppModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    variation: {
      type: String,
      default: "applyCredit",
    },
  },
  data() {
    return {
      applyCredit: {
        title:
          "Applications should only be processed once this year’s audit is completed",
        body: "Each application will be checked to see if the account is closed, or if it’s eligible for core payment. Outcome emails will be sent to all applicants.",
      },
      cancelCredit: {
        title: "Are you sure you want to cancel the selected applications?",
        body: "If the application is approved and no credit is applied, we will inform the customer their application has been closed. <br /> <br /> If credit has been applied we will inform the customer their application has been closed and credits will be removed from their Junifer account.",
      },
    }
  },
  computed: {
    modalSettings() {
      return this[this.variation]
    },
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss">
.warning-modal {
  .modal-wrapper {
    padding: $spacing-4 $spacing-7;
  }

  &__header {
    margin-top: $spacing-2;
    padding-left: 3.5rem;
    font-size: $size-6;
    font-weight: $weight-bold;
    color: $night-light;
    position: relative;

    &:before {
      font-family: "Font Awesome 5 Free";
      font-weight: $weight-bold;
      content: "\f071";
      color: $fire;
      position: absolute;
      left: 0px;
      top: 16px;
    }
  }

  &__header--icon {
    font-size: $size-6;
  }

  &__body {
    margin: $spacing-5 $spacing-5 $spacing-5 3.5rem;
    font-size: $size-7;
    color: $night-light;
  }

  &__footer {
    margin: $spacing-5 $spacing-5 $spacing-5 3.5rem;

    .button--grey-outlined {
      margin-right: $spacing-4;
    }
  }
}
</style>
