import dayjs from "dayjs"

export const dateFormatMixin = {
  methods: {
    appointmentDateFormatted(slotStartDateTime) {
      if (!slotStartDateTime || !dayjs(slotStartDateTime).isValid()) {
        return "No date"
      }
      return dayjs(slotStartDateTime).format("D MMM YYYY")
    },
    appointmentTimeFormatted(slotStartDateTime, slotEndDateTime) {
      if (
        !slotStartDateTime ||
        (!dayjs(slotStartDateTime).isValid() && !slotEndDateTime) ||
        !dayjs(slotEndDateTime).isValid()
      ) {
        return "No time"
      }
      const startTime = dayjs(slotStartDateTime).format("HH:mm")
      const endTime = dayjs(slotEndDateTime).format("HH:mm")
      return `${startTime} - ${endTime}`
    },
  },
}
