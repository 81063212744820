import axios from "axios"
import apiV2 from "./ApiV2"

const server =
  process.env.VUE_APP_ROOT_API === "http://localhost:3000/ark/api/v1"
    ? "https://services.staging.soenergy.co/ark/api/v1"
    : process.env.VUE_APP_ROOT_API
const appURL = server + "/internal-meter/"

const apiSmart = axios.create({
  baseURL: appURL,
  timeout: 100000,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Headers":
      "Content-Type, Accept, Authorization, X-Request-With",
  },
})

export default {
  getAccountDetails(accountNumber) {
    return apiSmart.get(`${accountNumber}/fetch-account`)
  },
  reserveSlots(data) {
    return apiV2.post(`smart-meter/reserve-slot`, data)
  },
  getAvailableSlots(postcode) {
    return apiV2.get(`smart-meter/available-slots?postcode=${postcode}`)
  },
  createAppointment(data) {
    return apiV2.post(`smart-meter/book-reservation`, data)
  },
  getBookings(billingAccountId) {
    return apiV2.get(`smart-meter/${billingAccountId}/get-bookings`)
  },
  cancelBooking(data, billingAccountId) {
    return apiV2.post(`smart-meter/${billingAccountId}/cancel-booking`, data)
  },
}
