import axios from "axios"
import dayjs from "dayjs"

const appURL = process.env.VUE_APP_HASURA_WHD_API

const createApiInstance = async () => {
  return axios.create({
    baseURL: appURL,
    timeout: 1000000,
    withCredentials: false,
  })
}

const apiRequest = async (url, method = "get", data) => {
  const instance = await createApiInstance()
  return instance[method](url, data)
}

export default {
  getCriteria() {
    return apiRequest("criteria")
  },
  getAccounts(data) {
    return apiRequest("applications", "post", data)
  },
  updateAccount(data) {
    // TODO: this endpoint is currently not working
    return apiRequest("applications", "patch", data)
  },
  updateManyApplications(data) {
    return apiRequest("update_many_applications", "post", data)
  },
  passAudit(data) {
    return apiRequest("accounts_for_audit", "post", data)
  },
  closeNoEvidence(data) {
    return apiRequest("close_no_evidence", "post", data)
  },
  updateAccountsAuditReturn(data) {
    return apiRequest("update_many_applications", "post", data)
  },
  applyCredit(data) {
    return apiRequest("apply_credit", "post", data)
  },
  cancelCredit(data) {
    return apiRequest("reject_approved_applications", "post", data)
  },
  updateVulnerabilityJunifer(accountNumbers) {
    return apiRequest("add_core_group_vulnerability", "post", {
      warm_home_discounts_interface: {
        account_numbers: accountNumbers,
      },
    })
  },
  getArkStatusTotals() {
    const thisYear = dayjs().year()
    const isBeforeApril = dayjs().month < 3
    if (isBeforeApril) {
      const previousYear = dayjs().add(-1, "year").year()
      return apiRequest(`totals?year=${previousYear}-${thisYear}`)
    } else {
      const nextYear = dayjs().add(1, "year").year()
      return apiRequest(`totals?year=${thisYear}-${nextYear}`)
    }
  },
  searchAccount(accountId) {
    return apiRequest("applications", "post", {
      warm_home_discounts_interface: {
        account_numbers: [accountId],
        skip_account_numbers: [],
      },
    })
  },
  downloadYearReport(startingYear) {
    let endYear = parseInt(startingYear, 10) + 1
    return apiRequest(
      `applications/export_data?year=${startingYear}-${endYear}`,
    )
  },
  downloadAuditCSV() {
    return apiRequest(`/applications/export_audit_candidates`)
  },
  getUploadedImages(account_id) {
    return apiRequest("/psr/images", "post", {
      account_id: account_id,
    })
  },
}
