import Rejections from "@/api/Registrations"
import email from "@/constants/email"

const state = {
  rejectedRegistrations: [],
  rejectedRegistrationsIsBusy: true,
  deliveryError: { error: false },
}

const mutations = {
  SET_REGISTRATION_DATA(state, data) {
    state.rejectedRegistrations = data
  },
  SET_REGISTRATION(state, data) {
    var index = state.rejectedRegistrations.findIndex(
      (s) => s.email == data.email
    )
    state.rejectedRegistrations[index].completedFl = data.completedFl
    state.rejectedRegistrations[index].error_reason = data.error_reason
  },
  DELETE_ROW(state, data) {
    var index = state.rejectedRegistrations.findIndex(
      (s) => s.email == data.email
    )
    state.rejectedRegistrations.splice(index, 1)
  },
  SET_DELIVERY_ERROR_STATUS(state, data) {
    state.deliveryError = data
  },
}

const getters = {
  registrations: (state) => {
    return {
      sortDesc: false,
      fields: [
        "last_updated_date",
        { key: "mprn", label: "MPRN", sortable: false },
        { key: "mpan", label: "MPAN", sortable: false },
        { key: "rejection_reason", label: "Rejection Reason", sortable: false },
        {
          key: "error_description",
          label: "Error Description",
          sortable: false,
        },
        { key: "email", label: "Email", sortable: false },
        "first_name",
        { key: "customer_name", label: "Name", sortable: false },
        "show_details",
        "send_email",
        "edit",
        "delete",
      ],
      items: state.rejectedRegistrations,
    }
  },
  registrationsState: (state) => {
    return state.rejectedRegistrationsIsBusy
  },
  deliveryError: (state) => {
    return state.deliveryError
  },
}

const setters = {}
const actions = {
  getAll(context) {
    Rejections.rejectedRegistrations()
      .then((response) => {
        var footer = email.footer
        response.data.forEach(function (e) {
          var fuel, fuel_a_an
          e.clicked = false
          e.edited = false
          e.disabled = false
          var customer_firstname = e.customer_forename
          var mprn = e.mprn
          var mpan = e.mpan

          if (e.error_description === "MPAN") {
            fuel = "electricity"
            fuel_a_an = "an electricity"
          } else {
            fuel = "gas"
            fuel_a_an = "a gas"
          }

          if (e.rejection_reason === "NoMeter") {
            e.templateContent = `<html>
                                <body>
                                  <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                    Hi ${customer_firstname},
                                    <br>
                                    <br>I hope you are well.
                                    <br>
                                    <br>We have recently received a request that you would like to switch your energy supply over to us, So Energy. Unfortunately we have been unable to process this request due to your ${fuel} supply point not having a meter attached to it on the national database.
                                    <br>
                                    <br>If you believe that you do have ${fuel_a_an} meter at your property and still wish for us to take over supply, you will need to contact your current supplier and request that they update the national ${fuel} database accordingly. Once your current supplier has confirmed to you that the ${fuel} industry database has been updated, you will be able to submit a new request to switch over to So Energy.
                                    <br>
                                    <br>Please don't hesitate to get in contact if you have any questions.
                                  </div>
                                  ${footer}
                                </body>
                              </html>`
          } else if (e.rejection_reason === "PrePayRejected") {
            e.templateContent = `<html>
                                <body>
                                    <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                        Hi ${customer_firstname},
                                        <br>
                                        <br>I hope you are well.
                                        <br>
                                        <br>We have recently received a request that you would like to switch your energy supply over to us, So Energy. Unfortunately we have been unable to process this request due to your ${fuel} meter being listed as a prepayment meter on the national ${fuel} database that all suppliers use. Currently we only support credit meters, therefore we are unable to take over the supply of prepayment meters.
                                        <br>
                                        <br>If you believe that you do have ${fuel_a_an} credit meter and still wish for us to take over supply, you will need to contact your current supplier and request that they update the national ${fuel} database accordingly. Once your current supplier has confirmed to you that the national ${fuel} database has been updated, you will be able to submit a new request to switch your supply to So Energy.
                                        <br>
                                        <br>Please don't hesitate to get in contact if you have any questions.
                                    </div>
                                    ${footer}
                                </body>
                            </html>`
          } else if (e.rejection_reason === "HalfHourlyProfileClass") {
            e.templateContent = `<html>
                                <body>
                                    <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                      Hi ${customer_firstname},
                                      <br>
                                      <br>I hope you are well.
                                      <br>
                                      <br>We have recently received a request that you would like to switch your energy supply over to us, So Energy. Unfortunately we have been unable to process this request due to your electricity meter being listed as an industrial meter on the national gas industry database that all suppliers use. Currently we only support domestic customers, therefore we are unable to take over the supply of industrial meter points.
                                      <br>
                                      <br>If you believe that you do have a domestic meter point and still wish for us to take over supply, you will need to contact your current supplier and request that they update the national gas database accordingly. Once your current supplier has confirmed to you that the national gas industry database has been updated, you will be able to submit another request to switch to So Energy.
                                      <br>
                                      <br>Please don't hesitate to get in contact if you have any questions.
                                    </div>
                                    ${footer}
                                  </body>
                                </html>`
          } else if (e.rejection_reason === "NotAcceptedProfileClass") {
            e.templateContent = `<html>
                                <body>
                                  <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                    Hi ${customer_firstname},
                                    <br>
                                    <br>I hope you are well.
                                    <br>
                                    <br>We have recently received a request that you would like to switch your energy supply over to us, So Energy. Unfortunately we have been unable to process this request due to your electricity meter being listed as an industrial meter on the national electricity industry database that all suppliers use. Currently we only support domestic customers, therefore we are unable to take over the supply of industrial meter points.
                                    <br>
                                    <br>If you believe that you do have a domestic meter point and still wish for us to take over supply, you will need to contact your current supplier and request that they update the national electricity database accordingly. Once your current supplier has confirmed to you that the national electricity industry database has been updated, you will be able to submit another request to switch to So Energy.
                                    <br>
                                    <br>Please don't hesitate to get in contact if you have any questions.
                                  </div>
                                  ${footer}
                                  </body>
                                </html>`
          } else if (e.rejection_reason === "NonDomestic") {
            e.templateContent = `<html>
                                <body>
                                  <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                    Hi ${customer_firstname},
                                    <br>
                                    <br>I hope you are well.
                                    <br>
                                    <br>We have recently received a request that you would like to switch your energy supply over to us, So Energy. Unfortunately we have been unable to process this request due to your gas meter being listed as an industrial meter on the national gas database that all suppliers use. Currently we only support domestic customers, therefore we are unable to take over the supply of industrial meter points.
                                    <br>
                                    <br>If you believe that you do have a domestic gas meter point and still wish for us to take over supply, you will need to contact your current supplier and request that they update the national gas database accordingly. Once your current supplier has confirmed to you that the national gas database has been updated, you will be able to submit another request to switch to So Energy.
                                    <br>
                                    <br>Please don't hesitate to get in contact if you have any questions.
                                  </div>
                                  ${footer}
                                </body>
                              </html>`
          } else if (e.rejection_reason === "DeEnergisedMPAN") {
            e.templateContent = `<html>
                                <body>
                                  <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                    Hi ${customer_firstname},
                                    <br>
                                    <br>I hope you are well.
                                    <br>
                                    <br>We have recently received a request that you would like to switch your energy supply over to us, So Energy. Unfortunately we have been unable to process this request due to your electricity meter being listed as De-energised on the national electricity database that all suppliers use. As an energy supplier, we can only take over supply of energised meter points.
                                    <br>
                                    <br>If you believe that you do have an energised electricity meter point and still wish for us to take over supply, you will need to contact your current supplier and request that they update the national electricity database accordingly. Once your current supplier has confirmed to you that the national electricity database has been updated, you will be able to submit another request to switch to So Energy.
                                    <br>
                                    <br>Please don't hesitate to get in contact if you have any questions.
                                  </div>
                                  ${footer}
                                </body>
                            </html>`
          } else if (e.rejection_reason === "NotInEcoes") {
            e.templateContent = `<html>
                                <body>
                                  <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                    Hi ${customer_firstname},
                                    <br>
                                    <br>I hope you are well.
                                    <br>
                                    <br>We have recently received a request that you would like to switch your energy supply over to us, So Energy. Unfortunately we have been unable to process this request due to your electricity Meter Point Administration Number (MPAN – ${mpan}) not existing on the national database, we also can't locate your correct address on the national database.
                                    <br>
                                    <br>If you still wish to come on supply with So Energy, can I kindly request you provide us with your correct MPAN, this can be found on any previous electricity statements. If you are unsure of this, you can also send a photo of your electricity meter with the meter serial number (sequence of numbers and letters on the face of the meter) clearly visible, we will then be able to investigate if we can register for your supply.
                                    <br>
                                    <br>Please don't hesitate to get in contact if you have any questions.
                                  </div>
                                  ${footer}
                                </body>
                              </html>`
          } else if (e.rejection_reason === "NotInDes") {
            e.templateContent = `<html>
                                <body>
                                  <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                    Hi ${customer_firstname},
                                    <br>
                                    <br>I hope you are well.
                                    <br>
                                    <br>We have recently received a request that you would like to switch your energy supply over to us, So Energy. Unfortunately we have been unable to process this request due to your gas Meter Point Reference Number provided (MPRN – ${mprn}) not existing on the national database that all energy supplier use, we also can't locate your correct address.
                                    <br>
                                    <br>If you still wish to come on supply with So Energy, can I kindly request you provide us with your correct MPRN, this can be found on any previous gas statements. If you are unsure of this, you can also send a photo of your gas meter with the meter serial number (sequence of numbers and letters on the face of the meter) clearly visible, we will then be able to investigate if we can register for your supply.
                                    <br>
                                    <br>Please don't hesitate to get in contact if you have any questions.
                                  </div>
                                  ${footer}
                                </body>
                              </html>`
          } else if (e.rejection_reason === "MpanNotOnSupplyAddress") {
            e.templateContent = `<html>
                                <body>
                                  <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                    Hi ${customer_firstname},
                                    <br>
                                    <br>I hope you are well.
                                    <br>
                                    <br>We have recently received a request that you would like to switch your energy supply over to us, So Energy. Unfortunately we have been unable to process this request due to your electricity Meter Point Administration Number (MPAN – ${mpan}) not being associated to the address on your application, we also can't locate your correct address on the national database that all energy suppliers use.
                                    <br>
                                    <br>If you still wish to come on supply with So Energy, can I kindly request you confirm your MPAN, this can be found on any previous electricity statements. If you are unsure of this, you can also send a photo of your electricity meter with the meter serial number (sequence of numbers and letters on the face of the meter) clearly visible, we will then be able to investigate if we can register for your supply.
                                    <br>
                                    <br>Please don't hesitate to get in contact if you have any questions.
                                  </div>
                                  ${footer}
                                </body>
                              </html>`
          } else if (e.rejection_reason === "MprnNotOnSupplyAddress") {
            e.templateContent = `<html>
                                <body>
                                  <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                    Hi ${customer_firstname},
                                    <br>
                                    <br>I hope you are well.
                                    <br>
                                    <br>We have recently received a request that you would like to switch your energy supply over to us, So Energy. Unfortunately we have been unable to process this request due to your gas Meter Point Reference Number (MPRN – ${mprn}) not being associated to the address on your application, we also can't locate your correct address on the national database that all energy suppliers use.
                                    <br>
                                    <br>If you still wish to come on supply with So Energy, can I kindly request you confirm your MPRN, this can be found on any previous gas statements. If you are unsure of this, you can also send a photo of your gas meter with the meter serial number (sequence of numbers and letters on the face of the meter) clearly visible, we will then be able to investigate if we can register for your supply.
                                    <br>
                                    <br>Please don't hesitate to get in contact if you have any questions.
                                  </div>
                                  ${footer}
                                </body>
                              </html>`
          }

          if (!e.templateContent) {
            e._rowVariant = "danger"
          }
        })
        context.commit("SET_DELIVERY_ERROR_STATUS", { error: false })
        context.commit("SET_REGISTRATION_DATA", response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  deleteAll: function (context) {
    context.commit("SET_DELIVERY_ERROR_STATUS", { error: false })
    var completedReviews = state.rejectedRegistrations.filter(
      (e) => e.completedFl === true
    )
    completedReviews.forEach(function (x) {
      Rejections.rejectedRegistrationSoftDelete(x)
      context.commit("DELETE_ROW", x)
    })
  },
  send(context, data) {
    context.commit("SET_DELIVERY_ERROR_STATUS", { error: false })
    Rejections.rejectedRegistrationSendEmail(data.item.id, data.item)
      .then((response) => {
        if (response.data.error) {
          context.commit("SET_DELIVERY_ERROR_STATUS", response.data)
        }
        if (response.data.completedFl == true) {
          context.commit("SET_REGISTRATION", response.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  deleteRow(context, data) {
    context.commit("SET_DELIVERY_ERROR_STATUS", { error: false })
    Rejections.rejectedRegistrationSoftDelete(data.item.id)
      .then((response) => {
        context.commit("DELETE_ROW", data.item)
      })
      .catch((error) => {
        console.log(error.response)
      })
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  setters,
  state,
}
