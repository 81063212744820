import Freshdesk from "@/api/Freshdesk"

const state = {
  busyStatus: false,
  agents: [],
  groups: [],
  roles: [],
}

const mutations = {
  SET_STATUS(state, data) {
    state.busyStatus = data
  },
  SET_ALL_AGENTS(state, data) {
    state.agents = data
  },
  SET_AGENT(state, data) {
    var index = state.agents.findIndex((s) => s.id == data.id)
    state.agents[index].edited = data.status
  },
  SET_GROUPS(state, data) {
    state.groups = data
  },
  SET_ROLES(state, data) {
    state.roles = data
  },
}

const getters = {
  agents: (state) => {
    return state.agents
  },
  groups: (state) => {
    return state.groups
  },
  roles: (state) => {
    return state.roles
  },
  status: (state) => {
    return state.busyStatus
  },
}

const setters = {}

const actions = {
  async getAllGroups({ commit }) {
    commit("SET_STATUS", true)
    await Freshdesk.getGroups()
      .then((response) => {
        commit("SET_GROUPS", response.data)
        commit("SET_STATUS", false)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getAllRoles({ commit }) {
    await Freshdesk.getAllRoles()
      .then((response) => {
        commit("SET_ROLES", response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getAllAgents({ state, commit, dispatch }) {
    await dispatch("getAllGroups")
    await dispatch("getAllRoles")
    await Freshdesk.getAllAgents().then((response) => {
      console.log(response)
      var agentFullList = []
      for (const e of response) {
        let res = Freshdesk.getAgentDetails(e.id)
        var agent = res.data
        var group_names = []

        agent.group_ids.forEach((id) => {
          var index = state.groups.findIndex((s) => s.id == id)
          group_names.push(state.groups[index].name)
        })
        var role_names = []

        agent.role_ids.forEach((id) => {
          var index = state.roles.findIndex((s) => s.id == id)
          role_names.push(state.roles[index].name)
        })

        agent.team = agent.contact.job_title
        agent.email = agent.contact.email
        agent.name = agent.contact.name
        agent.group_names = group_names
        agent.role_names = role_names
        agent.edited = false
        delete agent.group_ids
        delete agent.role_ids
        delete agent.available
        delete agent.occasional
        delete agent.updated_at
        delete agent.created_at
        delete agent.ticket_scope
        delete agent.available_since
        delete agent.type
        delete agent.contact
        delete agent.signature
        agentFullList.push(agent)
      }
      commit("SET_ALL_AGENTS", agentFullList)
    })
    console.log(resp)
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  setters,
  state,
}
