<template>
  <label :class="dropdownClass" class="dropdown-selection__wrapper">
    <select v-model="selected" :name="dropdownName" @change="dropdownChange">
      <option v-if="hasPlaceholder" value="">{{ placeholder }}</option>
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
      >
        {{ option.text }}
      </option>
    </select>
  </label>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    dropdownName: {
      type: String,
      default: "",
    },
    dropdownType: {
      type: String,
      default: "",
    },
    setSelected: {
      type: String,
      default: "",
    },
    hasPlaceholder: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Please Select",
    },
  },
  data() {
    return {
      selected: this.setSelected,
    }
  },
  computed: {
    dropdownClass() {
      return this.dropdownType
        ? "dropdown-selection__wrapper dropdown-selection__wrapper--" +
            this.dropdownType
        : ""
    },
  },
  watch: {
    options(value) {
      if (!value.length) {
        this.selected = ""
      } else {
        this.selected = this.setSelected
      }
    },
  },
  methods: {
    dropdownChange(event) {
      this.$emit("dropdownChange", {
        name: this.dropdownName,
        value: event.target.value,
      })
    },
  },
}
</script>

<style lang="scss">
.dropdown-selection {
  &__wrapper {
    width: 100%;
    height: 40px;
    position: relative;
    display: block;
    border: 1px solid $night-light-2;
    border-radius: 4px;
    margin-top: $spacing-4;

    select {
      height: 38px;
      padding: 0 $spacing-2;
      border: 0;
      font-size: $size-7;
      width: 100%;
      background: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      color: $night-light;
    }

    &:after {
      position: absolute;
      right: 0;
      top: 8px;
      z-index: 1;
      width: 30px;
      height: 100%;
      pointer-events: none;
      font-family: "Font Awesome 5 Free";
      font-weight: $weight-bold;
      content: "\f0d7";
      color: $night-light-2;
    }

    &:last-of-type {
      margin-bottom: $spacing-5;
    }

    &--filter {
      margin: 0 0 0 $spacing-4;
      max-width: 200px;

      select {
        font-weight: $weight-bold;
        font-size: 14px;
      }
    }
  }
}

.sidebar-card__dropdown-select {
  margin: 0;
  max-width: unset;
}
</style>
