<template>
  <AppModal :show="show" style-modifiers="modal--error" @close="closeModal">
    <template slot="header">
      <h2 class="modal__header">Error updating accounts</h2>
      <p class="modal__subheader">
        We were unable to update the following accounts:
      </p>
    </template>

    <template slot="body">
      <div class="errors-displayed">
        <div class="errors-displayed__listed">
          <table>
            <tr v-for="(user, index) in accountNumbers" :key="index">
              <td class="errors-displayed__listed--account">
                {{ user }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </template>
  </AppModal>
</template>

<script>
import AppModal from "./AppModal.vue"

export default {
  components: {
    AppModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    accountNumbers: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
  },
}
</script>

<style scoped lang="scss">
.modal-mask {
  overflow: hidden;
}

.modal__header,
.modal__subheader {
  font-weight: $weight-bold;
}

.modal__header {
  font-size: $size-5;
  color: $night-light;
  margin-bottom: $spacing-2;
}

.modal__subheader {
  font-size: $size-7;
  color: $night-light-2;
  margin-bottom: $spacing-6;
}

.errors-displayed {
  border: 1px solid $yellow-50;
  border-radius: 4px;
  margin-bottom: $spacing-2;

  &__listed {
    padding: $spacing-4 $spacing-4 $spacing-7 3.25rem;
    max-height: 400px;
    overflow-y: scroll;

    &--account {
      font-weight: $weight-medium;
      height: 30px;

      color: $night-light-2;
    }
  }
}
</style>
