import PaymentAdequacy from "@/api/PaymentAdequacy"
import { fetchTemplate } from "@/helpers/paymentAdequacy/initialTemplates"
import { initialReview } from "@/helpers/paymentAdequacy/initialReview"
import { reviewFormatter } from "@/helpers/paymentAdequacy/reviewFormatter"
import Vue from "vue"

const state = {
  dayOneReviewTotal: 0,
  lookupResults: [],
  dayOneReview: [],
  paymentAdequacy6MonthsResultsIsBusy: false,
  refundWeight: [],
  refundAdjustment: 0.5,
}

const mutations = {
  SET_DAYONEDATA(state, data) {
    state.dayOneReview = data
  },
  SET_REFUNDWEIGHTING(state, data) {
    state.refundWeight = data
  },
  SET_STATE(state, data) {
    state.paymentAdequacy6MonthsResultsIsBusy = data
  },
  SET_CLICKEDREVIEW(state, data) {
    var index = state.dayOneReview.indexOf(data.item)
    state.dayOneReview[index] = data.item
  },
  EDIT_ROW(state, data) {
    var item
    item = state.dayOneReview.find((item) => item.id == data.id)
    if (item === undefined) {
      item = state.lookupResults.find((item) => item.id == data.id)
    }
    Object.assign(item, data)
  },
  SET_LOOKUP_RESULTS(state, data) {
    state.lookupResults = data
  },
  SET_REFRESH(state, data) {
    var id, info
    if (data.item === undefined) {
      id = data.id
      info = data
    } else {
      id = data.item.id
      info = data.item
    }

    var index = state.dayOneReview.findIndex((s) => s.id == id)
    if (index !== undefined) {
      Vue.set(state.dayOneReview, index, info)
    }

    var lookupIndex = state.lookupResults.findIndex((s) => s.id == id)
    if (lookupIndex !== undefined) {
      Vue.set(state.lookupResults, lookupIndex, info)
    }
  },
  DELETE_ROW(state, data) {
    var index = state.dayOneReview.findIndex((s) => s.id == data.item.id)
    state.dayOneReview.splice(index, 1)
  },
  DELETE_ROW_ID(state, data) {
    var index = state.dayOneReview.findIndex((s) => s.id == data)
    state.dayOneReview.splice(index, 1)
  },
  SET_DAYONE_TOTAL(state, data) {
    state.dayOneReviewTotal = data
  },
}
const getters = {
  lookupResults: (state) => {
    return state.lookupResults
  },
  data: (state) => {
    return state.dayOneReview
  },
  isBusy: (state) => {
    return state.paymentAdequacy6MonthsResultsIsBusy
  },
  totalReviews: (state) => {
    return state.dayOneReviewTotal
  },
  refundTable: (state) => {
    return state.refundWeight
  },
  refundAdj: (state) => {
    return state.refundAdjustment
  },
}

const setters = {}
const actions = {
  async getDayOneReviewTotal({ commit }) {
    PaymentAdequacy.getDayOneReviewsTotal().then((response) => {
      commit("SET_DAYONE_TOTAL", response.data)
    })
  },
  async getDayOneData(context, filter) {
    context.commit("SET_STATE", true)
    PaymentAdequacy.getDayOneReviews(filter).then((response) => {
      var arr = []
      // eslint-disable-next-line no-console
      response.data.forEach(function (e) {
        initialReview(e)
        var d = reviewFormatter(e)
        d._showDetails = false
        arr.push(d)
      })
      context.commit("SET_DAYONEDATA", arr)
      context.commit("SET_STATE", false)
    })
  },
  async fetchRowInfo(context, data) {
    return PaymentAdequacy.getReview(data.id)
      .then((response) => {
        if (response.data.html_content) {
          data.templateContent = response.data.html_content
          data.emailSubject = response.data.subject
          context.commit("EDIT_ROW", data)
        } else {
          // eslint-disable-next-line no-undef
          var newOb = _.merge(response.data, data)
          fetchTemplate(response.data)
          // eslint-disable-next-line no-console
          context.commit("EDIT_ROW", newOb)
        }
      })
      .catch((error) => {
        console.log("fetchRowInfo failed", error)
      })
  },
  findRowInfo(context, rowId) {
    let dataSet = []
    if (context.state.dayOneReview.length) {
      dataSet = context.state.dayOneReview
    }
    if (context.state.lookupResults.length) {
      dataSet = context.state.lookupResults
    }
    return dataSet.find((item) => item.id === rowId)
  },
  paymentscript1dayaction: function (context, data) {
    data.review.process_disabled = true
    context.commit("SET_REFRESH", data.review)
    PaymentAdequacy.process(data.review.id, data)
      .then(() => {
        data = data.review
        data.initial_review_completed = true
        data.edited = false
        data.process_disabled = false
        context.commit("SET_REFRESH", data)
      })
      .catch(() => {})
  },
  softdelete: function (context, data) {
    PaymentAdequacy.softDelete(data.item.id)
      .then(() => {
        context.commit("DELETE_ROW", data)
      })
      .catch(() => {})
  },
  restore: function (context, data) {
    if (data.item === undefined) {
      data.item = data
    }
    PaymentAdequacy.restore(data.item.id)
      .then(() => {
        data.deleted_fl = true
        context.commit("SET_CLICKEDREVIEW", data)
      })
      .catch(() => {})
  },
  getRefundWeight(context) {
    PaymentAdequacy.refundWeight()
      .then((response) => {
        context.commit("SET_REFUNDWEIGHTING", response.data)
      })
      .catch(() => {})
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  setters,
  state,
}
