const meterTypesAES = [
  { id: "meterTypeElec", label: "Electricity", value: "ELEC_ONLY" },
  { id: "meterTypeGas", label: "Gas", value: "GAS_ONLY" },
  { id: "meterTypeDual", label: "Dual", value: "DUAL" },
]

const installationTypesAES = [
  { label: "Traditional", value: "TRADITIONAL" },
  { label: "SMETS1", value: "SMETS1" },
  { label: "SMETS2", value: "SMETS2" },
]

const appointmentTypesAES = [
  { label: "New Connection", value: "NEW_CONNECTION" },
  { label: "Meter Exchange", value: "METER_EXCHANGE" },
  { label: "Warrant", value: "WARRANT" },
  { label: "Commission/Join", value: "COMMISSION_JOIN" },
  { label: "Faults", value: "FAULTS" },
  { label: "Emergency Fault", value: "EMERGENCY_FAULTS" },
  { label: "Emergency Meter Exchange", value: "EMERGENCY_METER_EXCHANGE" },
  { label: "Warrant Meter Exchange", value: "WARRANT_METER_EXCHANGE" },
  { label: "Meter Removal", value: "METER_REMOVAL" },
  { label: "Warrant Removal", value: "WARRANT_REMOVAL" },
  { label: "Investigation", value: "INVESTIGATION" },
  { label: "Emergency Investigation", value: "EMERGENCY_INVESTIGATION" },
]
export default {
  meterTypesAES,
  installationTypesAES,
  appointmentTypesAES,
}
