<template>
  <div class="">
    <div class="no-availability__calendar">
      <p><b>Export date range</b></p>
      <Calendar
        is-range
        show-dates
        :available-dates="calendarSettings[0].dates"
        @selected-date="handleSelectedRange"
      />
    </div>

    <div class="no-availability__chosen-dates">
      <span class="no-availability__chosen-dates--span">
        <p><b>From:</b></p>
        <p v-if="range">
          <b>{{ range.start }}</b>
        </p>
      </span>
      <span class="no-availability__chosen-dates--span">
        <p><b>To:</b></p>
        <p v-if="range">
          <b>{{ range.end }}</b>
        </p>
      </span>
    </div>
    <p v-if="rangeHasError" class="error-msg">
      Please enter a valid date range
    </p>
    <p><b>So Energy email recipient</b></p>
    <InputField
      v-model="emailInput"
      label="Email"
      name="email"
      :error-message="emailErrorMessage"
      :has-error="emailHasError"
    />
    <button
      ref="send-request"
      class="button button--blue"
      type="submit"
      @click="validateData"
    >
      Export no availability data
    </button>
  </div>
</template>

<script>
import dayjs from "dayjs"
import SmartMeterApi from "@/api/SmartMeter.js"
import Calendar from "@soenergy/frontend-library/src/components/Calendar"
import InputField from "@soenergy/frontend-library/src/components/InputField"

export default {
  components: {
    Calendar,
    InputField,
  },
  data() {
    return {
      range: null,
      selectedFromDate: null,
      selectedToDate: null,
      emailInput: "",
      emailErrorMessage: "",
      emailHasError: false,
      rangeHasError: false,
      calendarSettings: [
        {
          key: "today",
          highlight: true,
          dates: [],
        },
      ],
    }
  },
  methods: {
    isSoEnergyEmailValid(string) {
      const stripped = string?.toString()
      const reg = /^[a-zA-Z0-9.!#$£%&'*+/=?^_`{|}~-]+@so.energy/
      return reg.test(stripped)
    },
    async apiCall() {
      const data = {
        smart_meter_booking_interface: {
          from_date: this.formattedDate(this.range.start),
          to_date: this.formattedDate(this.range.end),
          email: this.emailInput,
          error_type: "no_slots_available",
        },
      }
      SmartMeterApi.getNoAvailabilityData(data)
        .then(() => {
          // sends email
        })
        .catch((error) => {
          throw error
        })
    },
    validateData() {
      const rangerValid = this.validateRange()
      const emailValid = this.validateEmail()
      if (emailValid && rangerValid) {
        this.apiCall()
      }
    },
    validateEmail() {
      if (this.isSoEnergyEmailValid(this.emailInput)) {
        this.emailHasError = false
        this.emailErrorMessage = ""
        return true
      } else {
        this.emailHasError = true
        this.emailErrorMessage =
          "Please enter a @so.energy email address to export this report"
        return false
      }
    },
    validateRange() {
      if (this.range && this.range.start && this.range.end) {
        this.rangeHasError = false
        return true
      } else {
        this.rangeHasError = true
        return false
      }
    },
    formattedDate(date) {
      return dayjs(date).format("YYYY-MM-DD")
    },
    handleSelectedRange(data) {
      this.range = data
      this.validateRange()
    },
  },
}
</script>

<style lang="scss" scoped>
.no-availability {
  &__calendar {
    h4 {
      margin: 0;
    }
  }
  &__chosen-dates {
    max-width: 170px;
    p {
      margin: 0 0 0 $spacing-4;
    }
    &--button {
      margin-bottom: $spacing-4;
    }
    &--span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $spacing-4;
    }
  }
}

.error-msg {
  color: $danger-02;
  margin-left: $spacing-4;
}
</style>
