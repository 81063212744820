<template>
  <!-- TODO: we dont need this additional table as soon as we have proper support(vue 3) for multi root components. Don't use vue-fragment! -->
  <tr>
    <td :colspan="columnLength()">
      <table width="100%">
        <tr :class="primaryRowClass" @click="toggleDropdown">
          <td
            class="generic-table__column generic-table__column--arrow"
            :width="columnRatio.length ? columnRatio[0] + '%' : false"
          >
            <i v-if="secondaryRow()" :class="iconClass"></i>
          </td>
          <td
            v-for="(item, index) in primaryRow()"
            :key="index"
            :width="columnRatio.length ? columnRatio[index + 1] + '%' : false"
            class="generic-table__column"
          >
            <slot :name="item"></slot>
          </td>
        </tr>
        <tr v-if="secondaryRow()" :class="secondaryRowClass">
          <td></td>
          <td :colspan="columnLength() - 1" class="generic-table__column">
            <slot name="dropdown"></slot>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    columnRatio: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isDropdownOpen: false,
    }
  },
  computed: {
    primaryRowClass() {
      const dropdownState = this.isDropdownOpen
        ? "generic-table__row--active"
        : ""
      return "generic-table__row generic-table__row--primary " + dropdownState
    },
    secondaryRowClass() {
      const dropdownState = this.isDropdownOpen
        ? "generic-table__row--open"
        : ""
      return "generic-table__row generic-table__row--secondary " + dropdownState
    },
    iconClass() {
      const dropdownState = this.isDropdownOpen
        ? "arrow--open"
        : "arrow--closed"
      return "fa fa-caret-right arrow " + dropdownState
    },
  },
  methods: {
    toggleDropdown() {
      if (this.secondaryRow()) {
        this.isDropdownOpen = !this.isDropdownOpen
        if (this.isDropdownOpen) {
          this.$emit("dropdown-open")
        }
      }
    },
    primaryRow() {
      let slots = Object.keys(this.$slots)
      return slots.filter((slot) => slot.indexOf("column") != -1)
    },
    secondaryRow() {
      let slots = Object.keys(this.$slots)
      return slots.includes("dropdown")
    },
    columnLength() {
      return Object.keys(this.$slots).length + (this.secondaryRow() ? 0 : 1)
    },
  },
}
</script>

<style lang="scss">
.generic-table {
  &--blue {
    @include table-color($blue-100, $water);
  }

  &--green {
    @include table-color($green-100, $earth);
  }

  &--orange {
    @include table-color($yellow-50, $yellow-500);
  }

  &--red {
    @include table-color($red-100, $fire);
  }

  &__row {
    &--primary {
      vertical-align: middle;
      &:hover {
        cursor: pointer;
      }
      label {
        font-weight: $weight-medium;
        display: block;
        padding: 0 $spacing-4;
        margin: 0;
        border-radius: 4px;
        text-align: center;
      }
      label.generic-table__basic-label {
        color: $night-light;
        background-color: $day-dark;
      }
      .generic-table__column {
        font-weight: $weight-medium;
        padding: $spacing-3 $spacing-2;
      }
    }
    &--secondary {
      .generic-table__column {
        padding: 0 $spacing-5;

        .generic-table__dropdown {
          opacity: 0;
          max-height: 0px;
          overflow-y: hidden;
          transition: all 0.4s ease;
        }
      }
    }
    &--open {
      .generic-table__column {
        padding: $spacing-6 $spacing-5;

        .generic-table__dropdown {
          opacity: 1;
          max-height: 1000px;
          padding-bottom: $spacing-5;
          border-bottom: 1px solid $night-light-2;
        }
      }
    }
  }

  &__column {
    &--arrow {
      text-align: right;
    }
  }
}
</style>
