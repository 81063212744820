<template>
  <transition name="modal">
    <div
      v-if="show"
      class="modal-mask"
      @click.self="closeModal"
      @keydown="checkKeyEvent($event, $el)"
    >
      <div class="modal-wrapper">
        <div
          ref="modal"
          :class="'modal ' + styleModifiers"
          role="dialog"
          aria-modal="true"
          tabindex="0"
          @keydown.esc="closeModal"
        >
          <div class="buttons">
            <a
              class="buttons__close"
              href="#"
              @click.prevent="closeModal"
              @keydown.esc="closeModal"
            >
              <i class="fas fa-times buttons__close-icon"></i>
            </a>
          </div>

          <slot name="header" />

          <slot name="body" />

          <div>
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { trapFocus } from "../../mixins/trapFocus"

export default {
  name: "Modal",
  mixins: [trapFocus],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    styleModifiers: {
      type: String,
      default: "",
    },
  },
  watch: {
    show(value) {
      this.preventBodyScroll(value)
      if (value && this.$refs.modal) {
        this.$nextTick(function () {
          this.$refs.modal.focus()
        })
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
    preventBodyScroll(preventScroll) {
      const body = document.body

      if (preventScroll) {
        body.classList.add("no-scroll")
      } else {
        body.classList.remove("no-scroll")
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-wrapper {
  max-width: 800px;
  width: 100%;
  padding: $spacing-5;
  background-color: $day;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  text-align: left;
}

.modal {
  padding: 3rem $spacing-5;
  background-color: $day;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  display: contents;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.buttons {
  height: 40px;

  &__close-icon {
    font-size: $size-6;
    transition: 0.3s;
    float: right;
    color: $night-light-2;

    &:hover {
      color: $night-light;
    }
  }
}
</style>
