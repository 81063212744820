<template>
  <header class="navigation">
    <div class="navigation__inner">
      <router-link to="/" class="navigation__home-link">
        <div>
          <div
            style="float: left; border-right: 5px solid black"
            class="pr-2 mr-2"
          >
            <img
              src="https://soenergy-so-watt.s3.eu-west-2.amazonaws.com/SOE_Ark_Platform_Logo.png"
              width="70"
            />
          </div>
          <div style="height: 35px; width: 320px">
            <h3 class="caps ml-1 h4"><b>BACK OFFICE</b></h3>
          </div>
        </div>
      </router-link>

      <button class="burger-button" @click="openSidebarPanel">
        <i class="fas fa-bars burger-button__icon"></i>
      </button>
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    openSidebarPanel() {
      this.$emit("open")
    },
  },
}
</script>

<style lang="scss">
.navigation {
  z-index: 1;
  height: 62px;
  width: 100%;
  position: relative;
  background: $day;
  box-shadow: $box-shadow;

  &__inner {
    position: relative;
    max-width: 1200px;
    margin: auto;
  }

  .burger-button {
    background: none;
    border: none;
    position: absolute;
    right: 0;
    top: 14px;
    padding: 2px;

    &__icon {
      font-size: $size-5;
      color: $water;

      &:hover {
        color: $water-light;
      }
    }
  }

  &__home-link {
    position: relative;
    color: $night;
    left: 0;
    top: 10px;

    &:hover {
      text-decoration: none;
    }
  }

  &__main-logo {
    max-width: 115px;
  }
}
</style>
