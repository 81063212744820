<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Communications"
        summary="Summary of all communications sent by ARK"
      >
        <template v-slot:description-body> </template>
      </header-block>
    </b-row>

    <page-section>
      <template v-slot:content>
        <commit-chart :chart-data="mainChart" :options="chartOptions" />
      </template>
    </page-section>

    <page-section title="Search communication by template">
      <template v-slot:content>
        <b-row>
          <b-col>
            <b-form inline>
              <b-form-select
                id="inline-form-custom-select-pref"
                v-model="temaplateId"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="temaplates"
              >
                <template v-slot:first>
                  <option :value="null" disabled>
                    -- Please select an option --
                  </option>
                </template>
              </b-form-select>
              <span class="input-group-btn">
                <b-button
                  id="search"
                  :disabled="isBusy"
                  variant="outline-primary"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  @click="templateLookup(temaplateId)"
                >
                  <span v-show="!isBusy">Search</span>
                  <span v-show="isBusy"><b-spinner small /></span>
                </b-button>
                <b-button
                  id="clear"
                  :disabled="isBusy"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  variant="outline-danger"
                  @click="clear()"
                >
                  Clear
                </b-button>
              </span>
            </b-form>
          </b-col>
          <b-col>
            <div v-if="accountComms.length > 0" class="float-right">
              <b-form inline>
                <input
                  id="accountsearch"
                  v-model="filter"
                  type="text"
                  :disabled="isBusy"
                  placeholder="Account number...."
                  style="width: 300px"
                  class="form-control mb-2 mr-sm-2 mb-sm-0"
                />
                <p class="m-2">Total: {{ accountComms.length }}</p>
              </b-form>
            </div>
          </b-col>
        </b-row>

        <table-preview
          :results-table="dataTable"
          :is-busy="isBusy"
          :per-page="perPage"
          :current-page="currentPage"
          show-item="body_raw"
        />
      </template>
    </page-section>
  </b-container>
</template>

<script>
import CommitChart from "@/components/charts/CommitChart"
import Communication from "@/api/Communication"
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import tablePreview from "@/components/tablePreview"

export default {
  components: {
    headerBlock,
    pageSection,
    CommitChart,
    tablePreview,
  },
  data() {
    return {
      filter: null,
      communicationShow: null,
      isBusy: false,
      accountComms: [],
      perPage: 20,
      currentPage: 1,
      temaplateId: null,
      temaplates: [],
      results: [],
      chartOptions: {
        scales: {
          yAxes: [
            {
              stacked: false,
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },
  computed: {
    filteredResults() {
      let filteredAccounts = this.accountComms
      if (this.filter !== null) {
        filteredAccounts = this.accountComms.filter((comms) => {
          return comms.account_number.includes(this.filter)
        })
      }
      return filteredAccounts
    },
    dataTable() {
      var t = {
        sortBy: "Status",
        sortDesc: false,
        fields: [
          { key: "id", label: "ID", sortable: false },
          { key: "account_number", label: "Account", sortable: false },
          {
            key: "communication_content_id",
            label: "Content ID",
            sortable: true,
          },
          {
            key: "communication_provider_id",
            label: "Carrier",
            sortable: false,
          },
          { key: "contact", label: "Contact", sortable: true },
          { key: "created_at", label: "Sent", sortable: false },
          { key: "external_id", label: "3rd Party Id", sortable: false },
        ],
        items: this.filteredResults,
      }
      return t
    },
    dates() {
      var data = this.results.filter((x) => x.label === "date")
      return data[0].data
    },
    mainChart() {
      var chart
      var data = this.results.filter((x) => x.label !== "date")
      data.forEach(function (x) {
        x.fill = false
        x.pointRadius = 0
      })
      if (this.results.length !== 0) {
        chart = {
          labels: this.dates,
          datasets: data,
        }
      }
      return chart
    },
  },
  created() {
    Communication.getSentCommunications()
      .then((response) => {
        this.results = response.data
      })
      .catch((error) => {
        console.log(error)
      })

    Communication.getAllContentTemplates()
      .then((response) => {
        var arr = []
        response.data.forEach(function (x) {
          var y = { value: x.id, text: x.name }
          arr.push(y)
        })
        this.temaplates = arr
      })
      .catch((error) => {
        console.log(error)
      })
  },
  methods: {
    templateLookup(id) {
      this.accountComms = []
      this.isBusy = true
      Communication.templateLookup(id)
        .then((response) => {
          this.accountComms = response.data
          this.isBusy = false
        })
        .catch((error) => {
          console.log(error), (this.isBusy = false)
        })
    },
    clear() {
      this.temaplateId = null
      this.accountComms = []
      this.communicationShow = null
      this.filter = null
    },
  },
}
</script>
