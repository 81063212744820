<template>
  <div :class="readingTasksClass">
    <p class="reading-tasks__counter">
      Selected: <span class="reading-tasks__amount">{{ taskAmount }}</span>
    </p>
    <button
      v-if="!readingTaskApi"
      :class="buttonClass"
      :disabled="!taskAmount"
      @click="sendTasks"
    >
      Send all selected
    </button>
    <div v-if="readingTaskApi === 'loading'" class="reading-tasks__loading">
      <img src="@/assets/icons/loading-bar.gif" />
    </div>
    <p v-else-if="readingTaskApi === 'error'" class="reading-tasks__error">
      <i class="fas fa-exclamation-triangle"></i>Send awaiting failed
      <span>Retry import or submit issue to operations</span>
      <button :class="buttonClass" @click="sendTasks">Retry</button>
    </p>
    <p v-else-if="readingTaskApi === 'success'" class="reading-tasks__success">
      <i class="fa fa-check-circle"></i><span>Successfully imported</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    colorScheme: {
      type: String,
      default: "blue",
    },
    taskType: {
      type: String,
      default: "",
    },
    taskAmount: {
      type: Number,
      default: 0,
    },
    readingTaskApi: {
      type: String,
      default: "",
    },
  },
  computed: {
    readingTasksClass() {
      let colorModifier = " reading-tasks--" + this.colorScheme
      return "reading-tasks" + colorModifier
    },
    buttonClass() {
      let colorModifier = " button--" + this.colorScheme
      return "button" + colorModifier
    },
  },
  methods: {
    sendTasks() {
      this.$emit("sendReadingTasks", this.taskType)
    },
  },
}
</script>

<style lang="scss" scoped>
.reading-tasks {
  padding: $spacing-2 0 $spacing-5;

  &__counter {
    font-size: $size-7;
    line-height: 20px;
    font-weight: $weight-bold;
  }
  &__amount {
    color: $night-light;
  }

  &__error {
    color: $error-text;
    font-weight: $weight-bold;
    margin-bottom: 0;

    span {
      color: $night-light;
      font-weight: $weight-normal;
      margin-left: $spacing-5;
      display: inline-block;
      font-size: $size-7;
    }

    i {
      margin-right: $spacing-1;
    }

    .button {
      margin: $spacing-2 0 0 $spacing-5;
    }
  }

  &__success {
    font-weight: $weight-bold;
    color: $earth;
    background-color: $green-100;
    text-align: center;
    padding: $spacing-1 0;
    margin-bottom: 0;

    span {
      margin-left: $spacing-2;
    }
  }
}
</style>
