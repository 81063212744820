<template>
  <div>
    <h3 align="left" class="m-4">Meter reading type</h3>

    <b-form-group
      label-cols-sm="3"
      label="Sequence"
      label-align-sm="right"
      label-for="readingSequenceType"
    >
      <b-form-select
        v-model="post.readingSequenceType"
        class="col-6 float-left"
        :options="options.readingSequenceType"
        required
      />
    </b-form-group>

    <slot name="juniferRead"></slot>
    <slot name="previousRead"></slot>

    <h3 align="left" class="m-4">Dispute</h3>

    <b-form-group
      label-cols-sm="3"
      label="Dispute Reason"
      label-align-sm="right"
      label-for="reasonCode"
    >
      <b-form-select
        v-model="post.reasonCode"
        class="col-6 float-left"
        :options="options.reasonCodeOptions"
      />
    </b-form-group>

    <b-form-group
      label-cols-sm="3"
      label="Reading Type"
      label-align-sm="right"
      label-for="readingTypeCode"
    >
      <b-form-select
        v-model="post.readingTypeCode"
        required
        class="col-6 float-left"
        :options="options.readingTypeCodeOptions"
      />
    </b-form-group>

    <b-form-group
      label-cols-sm="3"
      label="Proposed Reading"
      label-align-sm="right"
      label-for="proposedReading"
    >
      <b-form-input
        v-model="post.proposedReading"
        class="col-6"
        type="number"
        required
      />
    </b-form-group>

    <slot name="readDifference"></slot>
  </div>
</template>

<script>
export default {
  props: ["post", "options"],
}
</script>
