import axios from "axios"

const appURL = process.env.VUE_APP_API_GATEWAY_URL
const apiArk2 = axios.create({
  baseURL: appURL,
  timeout: 1000000,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Headers":
      "Content-Type, Content-Disposition, Accept, Authorization, X-Request-With",
  },
})

export default apiArk2
