import axios from "axios"
import apiArk2 from "@/api/ApiV2"

const server =
  process.env.VUE_APP_ROOT_API === "http://localhost:3000/ark/api/v1"
    ? "https://services.staging.soenergy.co/ark/api/v1"
    : process.env.VUE_APP_ROOT_API
const appURL = server + "/smart_meter/"

const apiSmart = axios.create({
  baseURL: appURL,
  timeout: 1000000,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Headers":
      "Content-Type, Accept, Authorization, X-Request-With",
  },
})

export default {
  getAccounts(data) {
    return apiSmart.post("status", data)
  },
  getArkStatusTotals() {
    return apiSmart.get("totals")
  },
  addAccounts(data) {
    return apiSmart.post("add-accounts-enqueue-job", data)
  },
  addAccountsIneligible(data) {
    return apiSmart.post("add-accounts-as-ineligible-enqueue-job", data)
  },
  findAccounts(accoundId) {
    return apiSmart.get(accoundId + "/status")
  },
  markEligible(accounts) {
    // ineligible TO eligible
    return apiSmart.post("mark-as-eligible", accounts)
  },
  markIneligible(accounts) {
    // failed or eligible TO ineligible
    return apiSmart.post("mark-as-ineligible", accounts)
  },
  restart(accounts) {
    // eligible ended TO eligible in progress
    return apiSmart.post("restart", accounts)
  },
  reActivate(accounts) {
    // failed TO eligible
    return apiSmart.post("allow-new-booking", accounts)
  },
  getNoAvailabilityData(data) {
    return apiSmart.post("/shown-errors/request-report", data)
  },
  createUncommissionedTicketsCsv(csv) {
    return apiArk2.post("/tickets/create/uncommissioned", csv)
  },
}
