<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Rejected Junifer enrolments"
        summary="Customers who signup via PCWs do not get notified that Sends an email to customers letting them know that their electricity reading has failed validation."
        description="ABC"
      ></header-block>
    </b-row>

    <b-row class="ml-5">
      <b-button v-b-modal.modal-1 size="lg" variant="outline-success">
        Import CSV file &#8250;
      </b-button>
      <b-modal id="modal-1" title="Import CSV">
        <div v-if="formSubmit.status === null">
          <vue-csv-import
            :url="submittedUrl"
            :map-fields="[
              'mpan',
              'mprn',
              'email',
              'rejection_reason',
              'customer_name',
              'customer_forename',
              'address',
              'brokercode',
              'error_description',
            ]"
            :callback="onCallback"
            submit-btn-text="Upload"
            load-btn-text="Import"
            checkbox-class=""
          ></vue-csv-import>
        </div>
        <div v-else>
          <p>Response status: {{ formSubmit.status }}</p>
          <div v-if="formSubmit.dataSubmitted.success.length > 0">
            <h4>Success</h4>
            <table style="width: 100%" class="m-3">
              <tr
                v-for="row in formSubmit.dataSubmitted.success"
                :key="row.customer_name"
              >
                <td>
                  {{ row.error_description }}
                  <span v-if="row.error_description === 'MPAN'">
                    {{ row.mpan }}
                  </span>
                  <span v-else>
                    {{ row.mprn }}
                  </span>
                </td>
                <td>{{ row.customer_forename }} {{ row.customer_name }}</td>
              </tr>
            </table>

            <br />
          </div>
          <div v-if="formSubmit.dataSubmitted.duplicate.length > 0">
            <h4>Record already exists</h4>
            <table style="width: 100%" class="m-3">
              <tr
                v-for="row in formSubmit.dataSubmitted.duplicate"
                :key="row.customer_name"
              >
                <td>
                  {{ row.error_description }}
                  <span v-if="row.error_description === 'MPAN'">
                    {{ row.mpan }}
                  </span>
                  <span v-else>
                    {{ row.mprn }}
                  </span>
                </td>
                <td>{{ row.customer_forename }} {{ row.customer_name }}</td>
              </tr>
            </table>

            <br />
          </div>
          <div v-if="formSubmit.dataSubmitted.error">
            <h4>Error</h4>
            <table style="width: 100%" class="m-3">
              <tr
                v-for="row in formSubmit.dataSubmitted.error.length > 0"
                :key="row.customer_name"
              >
                <td>
                  {{ row.error_description }}
                  <span v-if="row.error_description === 'MPAN'">
                    {{ row.mpan }}
                  </span>
                  <span v-else>
                    {{ row.mprn }}
                  </span>
                </td>
                <td>{{ row.customer_forename }} {{ row.customer_name }}</td>
              </tr>
            </table>

            <br />
          </div>
          <b-button
            size="sm"
            variant="outline-success"
            @click="formSubmit.status = null"
          >
            Clear
          </b-button>
        </div>
        <div class="w-100"></div>
      </b-modal>
    </b-row>

    <b-row v-show="registrations.items.length > 0" class="ml-5 mb-2 mr-3">
      <b-card
        bg-variant="light"
        class="mr-2 mt-2 d-flex align-items-stretch"
        style="width: 33%"
        title="Awating"
      >
        <b-card-text>
          <h2>{{ RejectedRegisAwaiting.length }}</h2>
        </b-card-text>
        <b-button
          v-show="RejectedRegisAwaiting.length >= 1"
          size="sm"
          variant="outline-success"
          @click="rejectedRegistrationSendAllEmail()"
        >
          Send all awaiting
        </b-button>
      </b-card>

      <b-card
        bg-variant="light"
        class="mt-2 d-flex align-items-stretch"
        style="width: 33%"
        title="Completed"
      >
        <b-card-text>
          <h2>{{ RejectedRegisCompleted.length }}</h2>
        </b-card-text>
        <b-button
          v-show="RejectedRegisCompleted.length >= 1"
          size="sm"
          variant="outline-danger"
          @click="rejectedRegistrationDeleteAll()"
        >
          Clear all comepleted
        </b-button>
      </b-card>
    </b-row>

    <div v-if="deliveryError.error" class="text-danger">
      <p>{{ deliveryError.message }}</p>
    </div>

    <b-row v-show="registrations.items.length > 0" class="ml-5">
      <b-card bg-variant="light">
        <b-table
          striped
          hover
          small
          responsive
          :items="registrations.items"
          :fields="registrations.fields"
        >
          <div
            v-if="progressBar.jobId === null"
            slot="table-busy"
            class="text-center text-danger my-2"
          >
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>

          <template v-slot:cell(last_updated_date)="row">
            {{ row.item.updated_at | formattedDate }}
          </template>

          <template v-slot:cell(show_details)="row">
            <b-button
              size="sm"
              class="mr-2"
              variant="outline-primary"
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? "Hide" : "Show" }}
            </b-button>
          </template>

          <template v-slot:cell(first_name)="row">
            <input
              :key="row.item.account_number"
              v-model="row.item.customer_forename"
              type="text"
              :readonly="!row.item.edited"
              style="width: 100px"
            />
          </template>

          <template slot="row-details" slot-scope="row">
            <b-row class="text-left">
              <b-col> Broker Code: {{ row.item.brokercode }} </b-col>
              <b-col>
                <p
                  class="border rounded"
                  style="
                    text-align: left;
                    background-color: white;
                    padding: 15px;
                  "
                  v-html="row.item.templateContent"
                ></p>
              </b-col>
            </b-row>
          </template>

          <template v-slot:cell(send_email)="row">
            <div
              v-if="
                row.item.completedFl === false &&
                row.item._rowVariant !== 'danger'
              "
            >
              <b-button
                size="sm"
                class="mr-2"
                variant="outline-primary"
                @click="send(row)"
              >
                Process
              </b-button>
            </div>
            <div v-else-if="row.item.completedFl === true">
              <img src="../../assets/icons/check.png" style="width: 32px" />
            </div>
          </template>

          <template v-slot:cell(edit)="row">
            <div v-if="row.item.completedFl === false">
              <b-button
                size="sm"
                class="mr-2"
                variant="outline-info"
                @click="row.item.edited = !row.item.edited"
              >
                {{ row.item.edited ? "Save" : "Edit" }}
              </b-button>
            </div>
          </template>

          <template v-slot:cell(delete)="row">
            <b-button
              size="sm"
              class="mr-2"
              variant="outline-danger"
              @click="deleteRow(row)"
            >
              Delete
            </b-button>
          </template>
        </b-table>
      </b-card>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import dayjs from "dayjs"
import VueCsvImport from "@/components/CsvImporter"
import Registrations from "@/api/Registrations.js"
import Jobs from "@/api/Jobs.js"
import headerBlock from "@/components/header"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

export default {
  data() {
    return {
      submittedUrl: null,
      formSubmit: {
        status: null,
        dataSubmitted: null,
      },
      progressBar: {
        jobId: null,
        stage: null,
        counter: null,
        max: null,
      },
    }
  },
  async created() {
    this.submittedUrl = process.env.VUE_APP_API_CSV_IMPORT

    if (this.registrations.items.length === 0) {
      this.$store.dispatch("juniferRejections/getAll")
    }

    var progress = this.progressBar
    if (progress.jobId === null) {
      Jobs.getDelayedJobQueue("enrolment_rejections")
        .then((response) => {
          if (response.data.length !== 0) {
            progress.jobId = response.data[0].id
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
    }
  },
  computed: {
    ...mapGetters("juniferRejections", [
      "registrations",
      "registrationsState",
      "deliveryError",
    ]),
    RejectedRegisCompleted() {
      return this.registrations.items.filter(
        (review) => review.completedFl === true
      )
    },
    RejectedRegisAwaiting() {
      return this.registrations.items.filter(
        (review) => review.completedFl === false
      )
    },
  },
  methods: {
    ...mapActions("juniferRejections", ["send", "deleteRow", "deleteAll"]),
    rejectedRegistrationSendAllEmail() {
      Registrations.rejectedRegistrationSendAllEmail(this.RejectedRegisAwaiting)
        .then((response) => {
          this.progressBar.jobId = response.data.id
          this.progressBar.counter = response.data.progress_current
          this.progressBar.max = this.RejectedRegisAwaiting.length
          this.progressBar.stage = response.data.progress_stage
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
    },
    onCallback(response) {
      this.formSubmit.status = response.statusText
      this.formSubmit.dataSubmitted = response.data
    },
  },
  filters: {
    formattedDate: function (date) {
      return dayjs(date).format("Do MMM YY")
    },
  },
  components: { VueCsvImport, headerBlock },
  watch: {
    "progressBar.jobId": function (newValue) {
      if (newValue !== null) {
        var progress = this.progressBar
        var interval = setInterval(function () {
          Jobs.getDelayedJob(newValue)
            .then((job) => {
              if (job.data.last_error != null) {
                clearInterval(interval)
              }
              progress.stage = job.data.progress_stage
              progress.counter = job.data.progress_current
              progress.max = job.data.progress_max
            })
            .catch(() => {
              progress.jobId = null
              progress.stage = null
              progress.counter = null
              progress.max = null
              clearInterval(interval)
              this.elecReadings()
            })
        }, 1000)
      }
    },
  },
}
</script>

<style lang="scss">
.table-condensed {
  font-size: 10px;
}
</style>
