<template>
  <div>
    <b-table
      hover
      small
      responsive
      :busy="isBusy"
      :sort-by.sync="dataTable.sortBy"
      :sort-desc.sync="dataTable.sortDesc"
      :items="dataTable.items"
      :fields="dataTable.fields"
      style="font-size: 14px"
    >
      <template v-slot:cell(tickets)="row">
        <span v-show="row.item.tags.length > 0">
          <div
            v-for="(tag, index) in row.item.tags"
            :key="index"
            style="display: inline-block"
          >
            <b-badge
              style="white-space: normal; max-width: 150px; margin: 1px"
              :variant="tag.class"
            >
              {{ tag.text }}
            </b-badge>
          </div>
        </span>
      </template>

      <template v-slot:cell(check_box)="row">
        <div v-show="!row.item.sevendayreview">
          <b-form-checkbox
            v-model="row.item.clicked"
            :disabled="row.item.disabled || row.item.process_disabled"
          />
        </div>
      </template>

      <template v-slot:cell(dont_send)="row">
        <div v-show="!row.item.sevendayreview">
          <b-form-checkbox
            v-model="row.item.notOkToSend"
            :disabled="row.item.disabled || row.item.process_disabled"
            switch
          />
        </div>
      </template>

      <template v-slot:cell(payment_adequacy_id)="row">
        <span v-if="row.item.junifer_payment_adequacy_id === null">
          {{ row.item.id }}
        </span>
        <span v-else>
          {{ row.item.junifer_payment_adequacy_id }}
        </span>
      </template>

      <template v-slot:cell(origin)="row">
        <span v-if="row.item.junifer_payment_adequacy_id === null">
          <b-icon icon="arrow-down" />
        </span>
        <span v-else>
          <b-icon icon="arrow-repeat" />
        </span>
      </template>

      <template v-slot:cell(seasonal)="row">
        <div v-if="row.item.current_payment_seasonal_fl">
          <b-icon icon="shuffle" />
        </div>
        <div v-else>
          <b-icon icon="arrow-right" />
        </div>
      </template>

      <template v-slot:cell(suggested_payment_amount_on_follow_up)="row">
        <span v-if="row.item.edited">
          <input
            v-model="row.item.suggested_payment_amount_on_follow_up"
            type="number"
            class="payment"
          />
        </span>
        <span v-else>
          £{{ Math.round(row.item.suggested_payment_amount_on_follow_up, 0) }}
        </span>
        <span
          v-show="
            Math.round(row.item.suggested_payment_amount) !==
              Math.round(row.item.suggested_payment_amount_on_follow_up) &&
            row.item.follow_up_reads_check
          "
        >
          (£{{ Math.round(row.item.suggested_payment_amount, 0) }})
        </span>
      </template>

      <template v-slot:cell(current_payment_amount)="row">
        £{{ Math.round(row.item.current_payment_amount, 0) }}
      </template>

      <template v-slot:cell(perc_change)="row">
        <div
          :class="{ 'text-danger': row.item.suggested_payment_amount === null }"
          style="display: inline"
        >
          <span v-if="row.item.paymentChange">
            <span v-if="row.item.perc_change > 0">
              {{ row.item.perc_change }}%
              <b-icon icon="arrow-up" variant="danger" />
            </span>
            <span v-else-if="row.item.perc_change < 0">
              {{ row.item.perc_change }}%
              <b-icon icon="arrow-down" variant="success" />
            </span>
          </span>
        </div>
      </template>

      <template v-slot:cell(refund)="row">
        <span
          v-show="
            Math.round(row.item.suggested_refund) > 0 &&
            row.item.follow_up_reads_check
          "
        >
          £{{ Math.round(row.item.suggested_refund, 2) }}
        </span>
      </template>

      <template v-slot:cell(account_balance)="row">
        <span v-if="row.item.account_balance > 0" class="text-danger">
          £{{ Math.round(row.item.account_balance, 2) }} (DR)
        </span>
        <span v-else>
          £{{ -Math.round(row.item.account_balance, 2) }} (CR)
        </span>
      </template>

      <template v-slot:cell(account_number)="row">
        <span v-if="'account' in row.item">{{
          row.item.account.junifer_account_number
        }}</span>
      </template>

      <template v-slot:cell(review_date)="row">
        {{ row.item.email_dayone_sent | formattedDate }}
      </template>

      <template v-slot:cell(seven_day_follow_up_date)="row">
        <span v-if="row.item.edited">
          <input
            v-model="row.item.seven_day_follow_up_date"
            type="date"
            class="date"
          />
        </span>
        <span v-else>
          {{ row.item.seven_day_follow_up_date | formattedDate }}
        </span>
      </template>

      <template v-slot:cell(updated_at)="row">
        <span :class="{ 'light-red': !isToday(row.item.updated_at) }">
          {{ row.item.updated_at | formattedDate }}
        </span>
        <input v-model="row.item.follow_up_fl" type="checkbox" disabled />
      </template>

      <template v-slot:cell(show_details)="row">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="fetchCompleteRecord(row.item)"
        >
          {{ row.detailsShowing ? "Hide" : "Show" }}
        </b-button>
      </template>

      <template v-slot:cell(process)="row">
        <div v-if="row.item.sevendayreview">
          <b-icon icon="check-circle" variant="success" />
        </div>
        <div v-else>
          <b-button
            size="sm"
            :disabled="row.item.disabled || row.item.process_disabled"
            variant="outline-success"
            @click="paymentscript7dayaction(row.item)"
          >
            <div v-if="row.item.process_disabled">
              <b-spinner small />
            </div>
            <div v-else>Process</div>
          </b-button>
        </div>
      </template>

      <template v-slot:cell(edit)="row">
        <b-button
          size="sm"
          :disabled="row.item.disabled || row.item.process_disabled"
          variant="outline-secondary"
          @click="editable(row.item)"
        >
          {{ row.item.edited ? "Save" : "Edit" }}
        </b-button>
      </template>

      <template v-slot:cell(delete)="row">
        <div v-if="!row.item.deleted_fl">
          <b-button
            size="sm"
            :disabled="row.item.disabled || row.item.process_disabled"
            variant="outline-danger"
            @click="softdelete(row.item)"
          >
            Delete
          </b-button>
        </div>
      </template>

      <template v-slot:cell(cust_check)="row">
        <b-button
          v-if="!row.item.sevendayreview"
          size="sm"
          variant="outline-secondary"
          :disabled="row.item.disabled"
          @click="checkForResponse(row.item)"
        >
          <div v-if="row.item.disabled">
            <b-spinner small />
          </div>
          <div v-else>Refresh</div>
        </b-button>
        <!-- </div> -->
      </template>

      <template v-slot:cell(mr_check)="row">
        <span v-if="row.item.has_meter_readings" class="badge badge-success">
          Meter Read
        </span>
        <span v-else class="badge badge-danger"> Need Read </span>
        <span
          v-if="Math.round(row.item.perc_change) > 50"
          class="badge badge-warning"
        >
          Large Change
        </span>
      </template>

      <template v-slot:cell(cust_contact)="row">
        <span v-if="row.item.follow_up_reads_check" class="badge badge-success">
          Meter Read
        </span>
        <span v-else class="badge badge-danger">No Read </span>
        <div v-if="row.item.edited">
          Customer Contact Check:
          <input v-model="row.item.customer_contact_fl" type="checkbox" />
        </div>
        <div v-else-if="row.item.customer_contact_fl">
          <b-icon incon="chat-dots" />
        </div>
      </template>
    </b-table>
    <PaymentAdequacyPreviewModal
      :review="selectedReview"
      :show="showModal"
      @close="closeModal"
    />
  </div>
</template>

<script>
import PaymentAdequacyPreviewModal from "@/components/paymentAdequacy/paymentAdequacyPreviewModal"
import { mapActions, mapGetters, mapMutations } from "vuex"
import dayjs from "dayjs"
import { paymentAdequacyMixin } from "@/mixins/paymentAdequacyMixin"
import { isTodayCheck } from "@/helpers/dates"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

export default {
  mixins: [paymentAdequacyMixin],
  props: {
    data: {
      type: Array,
      default: () => [],
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      selectedReview: {},
      showModal: false,
      day7lookup: null,
      groupAction: null,
      selected: null,
    }
  },
  computed: {
    dataTable() {
      var obj = {
        sortBy: "Status",
        sortDesc: false,
        fields: [
          { key: "check_box", label: "" },
          { key: "origin", label: "", sortable: true },
          { key: "payment_adequacy_id", label: "ID" },
          {
            key: "seven_day_follow_up_date",
            label: "Follow Up Due",
            sortable: true,
          },
          { key: "dont_send", label: "" },
          { key: "tickets", label: "" },
          { key: "account_number", label: "Account" },
          { key: "seasonal", label: "" },
          { key: "current_payment_amount", label: "Current", sortable: true },
          {
            key: "suggested_payment_amount_on_follow_up",
            label: "Suggested (Initial Suggested)",
            sortable: true,
          },
          { key: "perc_change", label: "%", sortable: true },
          { key: "refund", label: "Refund" },
          { key: "account_balance", label: "A/B" },
          { key: "review_date", label: "Initial Review Date" },
          { key: "mr_check", label: "Read in 90 days B4 1st Review" },
          { key: "updated_at", label: "Last Update" },
          { key: "cust_contact", label: "Read since 1st Review?" },
          { key: "show_details", label: "" },
          { key: "cust_check", label: "", sortable: false },
          { key: "edit", label: "", sortable: false },
          { key: "process", label: "", sortable: false },
          { key: "delete", label: "", sortable: false },
        ],
        items: this.amendedData,
      }
      return obj
    },
    amendedData() {
      let newData = JSON.parse(JSON.stringify(this.data))
      for (let i = 0; i < newData.length; i++) {
        newData[i].origin = newData[i].junifer_payment_adequacy_id === null
        const currentPayment = parseInt(newData[i].current_payment_amount, 10)
        if (isNaN(currentPayment)) {
          currentPayment = 0
        }
        newData[i].nominal_change =
          newData[i].suggested_payment_amount - currentPayment
        newData[i].perc_change = parseInt(newData[i].perc_change, 10)
      }
      return newData
    },
  },
  channels: {
    PaymentAdequacyChannel: {
      connected() {},
      received(data) {
        if (data.deleted_fl) {
          this.DELETE_ROW_ID(data.id)
        } else {
          this.SET_RECORD(data)
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.showModal = false
    },
    setupStream() {
      this.$cable.subscribe({
        channel: "PaymentAdequacyChannel",
      })
    },
    ...mapActions("sixMonthAdFollowUp", [
      "deleteAll",
      "softdelete",
      "restore",
      "paymentscript7dayaction",
      "checkForResponse",
      "fetchRowInfo",
    ]),
    ...mapMutations({
      SET_RECORD: "sixMonthAdFollowUp/SET_RECORD",
      SET_DAYSEVENDATA_STATE: "sixMonthAdFollowUp/SET_DAYSEVENDATA_STATE",
      DELETE_ROW_ID: "sixMonthAdFollowUp/DELETE_ROW",
      SET_DAYSEVENDATA: "sixMonthAdFollowUp/SET_DAYSEVENDATA",
      SET_LOOKUP_RESULT: "sixMonthAdFollowUp/SET_LOOKUP_RESULT",
    }),
    async fetchCompleteRecord(row) {
      await this.fetchRowInfo(row)
      this.selectedReview = row
      this.showModal = true
    },
    isToday(date) {
      return isTodayCheck(date)
    },
    editable(row) {
      row.edited = !row.edited
      this.SET_RECORD(row)
    },
  },
  filters: {
    formattedDate: function (date) {
      return dayjs(date).format("Do MMM YY")
    },
  },
  components: {
    PaymentAdequacyPreviewModal,
  },
}
</script>

<style lang="scss">
.email-template-content {
  table {
    background-color: transparent;
    width: auto;
    td {
      padding: 0;
      border: 0;
    }
    td[width] {
      display: inline-block;
    }
  }
  table[width="100%"] {
    width: 100%;
  }
  tbody tr:hover {
    color: unset !important;
    background-color: transparent !important;
  }
}
</style>
