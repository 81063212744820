<template>
  <div class="upload-core">
    <button class="upload-core__header" @click="showToggle()">
      <i
        :class="{
          'upload-core__arrow-down': show,
          'upload-core__arrow-up': !show,
        }"
        class="fas fa-chevron-right"
      ></i>
      <h2 class="upload-core__heading">Upload ‘Core’ Customers</h2>
    </button>
    <transition name="fade">
      <div v-if="show" class="upload-core__body">
        <p>
          This will add ‘core group’ to the customer’s vulnerabilities in
          Junifer.
        </p>
        <UploadCoreCustomersCSV
          @response="
            (o) => {
              $emit('handle-core-accounts-upload', o)
            }
          "
          @error="
            (o) => {
              $emit('handle-core-accounts-upload-error', o)
            }
          "
        ></UploadCoreCustomersCSV>
      </div>
    </transition>
  </div>
</template>

<script>
import UploadCoreCustomersCSV from "./UploadCoreCustomersCSV.vue"

export default {
  components: {
    UploadCoreCustomersCSV,
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    showToggle() {
      this.show = !this.show
    },
  },
}
</script>

<style lang="scss">
.upload-core {
  text-align: left;
  max-width: 800px;
  margin-bottom: 3.5rem;
  border-radius: 4px;

  &__header {
    padding: $spacing-3 $spacing-4;
    font-size: $size-7;
    width: 100%;
    text-align: left;
    border: none;
    background: $water;
    color: $day;

    .fas {
      font-size: 14px;
    }
  }

  &__arrow-down {
    transition: transform 0.4s;
    transform: rotate(90deg);
  }

  &__arrow-up {
    transition: transform 0.4s;
  }

  &__heading {
    display: inline-block;
    font-weight: $weight-medium;
    font-size: $size-6;
    line-height: 1.1;
    margin: 0 0 0 $spacing-3;
  }

  &__body {
    padding: $spacing-5 $spacing-6;
    color: $night-light;

    p {
      margin-bottom: $spacing-5;
      font-size: $size-7;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
