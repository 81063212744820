<template>
  <section class="choose-job-type">
    <template>
      <p ref="AES_Desc" class="choose-job-type__description">
        This customer’s meters are serviced by AES, who allow you to add one job
        per meter each time.
      </p>

      <fieldset>
        <RadioGroup v-model="selectedMeterTypeAES">
          <RadioButton
            v-for="(meterType, index) in jobTypes.meterTypesAES"
            :key="index"
            :input-id="meterType.id"
            :value="meterType.value"
            :label="meterType.label"
          />
        </RadioGroup>
      </fieldset>

      <div class="choose-job-type__selectors">
        <div class="choose-job-type__selector">
          <Select
            v-model="selectedInstallationType"
            input-id="selectedInstallationType"
            label="Installation type (AES)"
            :options="jobTypes.installationTypesAES"
            disabled-option-label="Select installation type"
            @change="validate"
          />
        </div>
        <div class="choose-job-type__selector">
          <Select
            v-model="selectedAppointmentType"
            input-id="selectedAppointmentType"
            label="Appointment type (AES)"
            :options="jobTypes.appointmentTypesAES"
            disabled-option-label="Select appointment type"
            @change="validate"
          />
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import RadioGroup from "@soenergy/frontend-library/src/components/RadioButton/RadioGroup"
import RadioButton from "@soenergy/frontend-library/src/components/RadioButton/RadioButton"
import Select from "@soenergy/frontend-library/src/components/Select"
import jobTypes from "@/constants/imbp_jobs"

import { validationMixin } from "vuelidate"

export default {
  components: {
    RadioGroup,
    RadioButton,
    Select,
  },
  mixins: [validationMixin],
  props: {
    installer: {
      type: String,
      default: "",
    },
    accountDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      jobTypes: jobTypes,
    }
  },
  validations() {
    return {
      selectedMeterTypeAES: {
        requiredForAES: (value) => {
          return !!value
        },
      },
      selectedInstallationType: {
        requiredForAES: (value) => {
          return !!value
        },
      },
      selectedAppointmentType: {
        requiredForAES: (value) => {
          return !!value
        },
      },
    }
  },
  computed: {
    meters() {
      let meterData = this.accountDetails.electric_meterpoint_data.concat(
        this.accountDetails.gas_meterpoint_data
      )
      return meterData.map((meter) => {
        return {
          label: meter.type + ": " + meter.identifier,
          value: meter.identifier,
          type: meter.type,
        }
      })
    },
    selectedMeterTypeAES: {
      get() {
        return this.$store.state.imbp.selectedJobs.selectedMeterTypeAES
      },
      set(value) {
        this.$store.commit("imbp/SET_METER_TYPE_AES", value)
        this.validate()
      },
    },
    selectedInstallationType: {
      get() {
        return this.$store.state.imbp.selectedJobs.selectedInstallationType
      },
      set(value) {
        this.$store.commit("imbp/SET_INSTALLATION_TYPE", value)
      },
    },
    selectedAppointmentType: {
      get() {
        return this.$store.state.imbp.selectedJobs.selectedAppointmentType
      },
      set(value) {
        this.$store.commit("imbp/SET_APPOINTMENT_TYPE", value)
      },
    },
  },
  watch: {
    accountDetails() {
      this.$emit("mark-page-invalid")
    },
  },
  created() {
    this.$emit("mark-page-invalid")
  },
  methods: {
    validate() {
      this.$v.$touch()
      if (this.$v.$error) {
        this.$emit("mark-page-invalid")
      } else {
        this.$emit("mark-page-valid")
      }
    },
  },
}
</script>

<style scoped lang="scss">
.choose-job-type {
  &__loading {
    display: inline-block;
    margin-bottom: $space-8;
  }
  &__description {
    font-size: $font-size-5;
    line-height: $line-height-4;
    margin-bottom: $space-8;
  }
  &__selector-block {
    margin: $space-10 0;

    h4 {
      margin-bottom: $space-4;
      font-weight: $weight-bold;
    }
  }
  &__selectors-wide {
    width: 100%;
    display: flex;
  }

  &__selector {
    max-width: 370px;
    margin-bottom: $space-10;
    width: 50%;
    margin-right: $space-5;
  }
  ::v-deep .radio-group__form {
    display: flex;
    justify-content: space-between;
    max-width: 500px;
    margin-bottom: $space-5;
  }
}
</style>
