import Vue from "vue"
import App from "./App.vue"

import store from "./store"
import router from "./router"

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import VueGoogleCharts from "vue-google-charts"
import Vuelidate from "vuelidate"
import initErrorReporting from "./helpers/initErrorReporting"
import Datadog from "./plugins/datadog"

require("titleize")
import "@soenergy/frontend-library/src/stylesheets/vendors/bulma.scss"
import "@soenergy/frontend-library/src/stylesheets/base.scss"
import "@soenergy/frontend-library/src/stylesheets/components/_form.scss"
import "@soenergy/frontend-library/src/stylesheets/components/_list.scss"
import "./assets/stylesheets/main.scss"

import ActionCableVue from "actioncable-vue"

initErrorReporting()

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  connectionUrl: process.env.VUE_APP_CABLE + "feed",
  connectImmediately: true,
})
Vue.use(Vuelidate)
Vue.use(Datadog)
Vue.use(VueGoogleCharts)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false
import authentication from "./authentication"

authentication.initialize().then((_) => {
  new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount("#app")
})
