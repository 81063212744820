<template>
  <div class="update-status">
    <div
      v-if="!updateSucceeded && !filteredStatusChanges.length"
      class="update-status__empty"
    >
      <span> Selected status changes will be shown here. </span>
      <p class="update-status__empty--text">
        Once you have selected status changes from the tables on the left, they
        will be summarised here for you to review before updating.
      </p>
    </div>
    <table
      v-if="!updateSucceeded && filteredStatusChanges.length"
      class="update-status__table"
    >
      <tr>
        <th v-for="(title, index) in tableHeads" :key="index">
          {{ title }}
        </th>
      </tr>
      <tr
        v-for="(statusObject, index) in filteredStatusChanges"
        :key="index"
        class="update-status__table-rows"
      >
        <td>
          {{ statusObject.items.length }}
        </td>
        <td>
          <i
            class="fa fa-circle"
            :class="'update-status--cirle-' + statusObject.status.color"
          ></i>
          {{ statusObject.status.text }}
        </td>
        <td>
          <i
            class="fa fa-circle"
            :class="'update-status--cirle-' + statusObject.newStatus.color"
          ></i>
          {{ statusObject.newStatus.text }}
        </td>
      </tr>
    </table>

    <div v-if="updateSucceeded" class="update-status__succeeded">
      <h6>
        <i class="fa fa-check-circle"></i>
        Success
      </h6>
      <p>Booking status have been successfully updated.</p>
    </div>
    <div class="update-status__cta">
      <button
        v-if="!updateSucceeded && filteredStatusChanges.length"
        class="button button--blue"
        @click="updateStatus"
      >
        Update
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statusChanges: {
      type: Array,
      default: () => [],
    },
    updateSucceeded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableHeads: ["#", "Current", "Update to"],
    }
  },
  computed: {
    filteredStatusChanges() {
      return this.statusChanges.filter((item) => item.items.length)
    },
  },
  methods: {
    updateStatus() {
      this.$emit("updateStatus", this.filteredStatusChanges)
    },
  },
}
</script>

<style lang="scss" scoped>
.update-status {
  .fa-circle {
    font-size: 10px;
    position: relative;
    bottom: 1px;
    margin-right: $spacing-1;
  }

  &--cirle-blue {
    color: $water;
  }
  &--cirle-orange {
    color: $yellow-500;
  }
  &--cirle-red {
    color: $fire;
  }

  &__empty {
    padding: 0 0 $spacing-2 $spacing-5;
    position: relative;

    &:before {
      font-family: "Font Awesome 5 Free";
      font-weight: $weight-bold;
      content: "\f05a";
      position: absolute;
      left: -2px;
      top: 2px;
    }

    span {
      font-size: $size-7;
      font-weight: $weight-bold;
    }

    &--text {
      margin-top: $spacing-1;
      line-height: 1.7;
    }
  }

  &__table {
    margin: 0 0 $spacing-5;
    width: calc(100% - 40px);
    font-size: 14px;
    color: $night-light;

    th {
      padding: $spacing-3 $spacing-5 $spacing-3 0;

      &:last-child {
        padding: $spacing-3 0 $spacing-3 0;
      }
    }
  }

  &__table-rows {
    border-bottom: $night-light-2;

    td {
      padding: $spacing-3 $spacing-5 $spacing-3 0;
      max-width: 120px;

      &:last-child {
        padding: $spacing-3 0 $spacing-3 0;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__succeeded {
    padding: $spacing-2;

    h6 {
      font-weight: $weight-bold;
      font-size: $size-7;
      margin-bottom: $spacing-2;
    }
    p {
      font-size: 14px;
      color: $night-light;
      margin-left: $spacing-5;
    }
  }

  &__succeeded {
    background-color: $green-100;
    color: $earth;
  }

  &__cta {
    padding: 0 $spacing-5 $spacing-2;
    display: flex;
    justify-content: flex-end;

    &--double {
      justify-content: space-between;
    }

    .button {
      min-width: 120px;
    }
  }
}
</style>
