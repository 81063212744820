<template>
  <b-container fluid class="has-text-left">
    <b-row class="ml-5">
      <header-block title="Standalone refund results"></header-block>
    </b-row>
    <page-section title="Upload accounts to be considered for a refund">
      <template v-slot:content>
        <p>Upload a csv for accounts to be considered for a refund.</p>
        <div class="upload-csv">
          <UploadCsv
            :parser="parser"
            :api-call="PaymentAdequacy.refundUploader"
            v-on="$listeners"
          ></UploadCsv>
        </div>
      </template>
    </page-section>

    <page-section class="mt-6" title="Download the most recent results">
      <template v-slot:content>
        <p>
          Download the most recent results from standalone refunds.<br />
          Results that have not been downloaded before are available here.<br />
          <strong>
            It may take several minutes before the results can be downloaded.
          </strong>
        </p>

        <DownloadButton
          :api-call="PaymentAdequacy.refundLatestReportCsv"
          btn-label="Download csv"
          @error="onDownloadError"
          @download="onDownload"
        ></DownloadButton>

        <p v-if="errorMessage" class="error-msg mt-2">
          {{ errorMessage }}
        </p>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import UploadCsv from "@/components/csv/UploadCsv.vue"
import { yetAnotherCSVParseMethod } from "@/helpers/csv/parsers"
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import PaymentAdequacy from "@/api/PaymentAdequacy.js"
import DownloadButton from "@soenergy/frontend-library/src/components/DownloadButton"

export default {
  components: {
    pageSection,
    headerBlock,
    UploadCsv,
    DownloadButton,
  },
  data() {
    return {
      parser: yetAnotherCSVParseMethod,
      PaymentAdequacy: PaymentAdequacy,
      errorMessage: null,
    }
  },
  methods: {
    onDownloadError(error) {
      if (error.toString().includes("Empty response")) {
        this.errorMessage = "No results. Please try again in a moment"
      } else {
        this.errorMessage = error
      }
    },
    onDownload() {
      this.errorMessage = null
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-csv {
  background-color: $day-dark;
  max-width: 450px;
  padding: 1rem;
  border-radius: 0.25rem;
}
.error-msg {
  color: $fire;
  font-weight: $weight-medium;
}
</style>
