import Vue from "vue"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"
import authentication from "../authentication"

export default () =>
  ErrorReporter.init({
    Vue,
    apiKey: process.env.VUE_APP_BUGSNAG_TOKEN,
    onError: function (event) {
      if (authentication.isAuthenticated()) {
        const user = authentication.getUserProfile().upn
        if (user) {
          event.addMetadata("user", {
            user,
          })
        }
      }
    },
  })
