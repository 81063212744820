<template>
  <AppModal :show="show" style-modifiers="modal--error" @close="closeModal">
    <template slot="header">
      <h2 class="modal__header">
        {{ errorModal.heading }}
      </h2>
      <p v-if="errorMessage.length > 0" class="modal__subheader">
        {{ errorMessage }}
      </p>
      <p v-else class="modal__subheader">
        {{ errorModal.subheading }}
      </p>
    </template>

    <template slot="body">
      <div class="flex-container">
        <div
          v-if="duplicatedAccountNumbers.length"
          class="errors-displayed errors-displayed__orange"
        >
          <div
            class="errors-displayed__headers errors-displayed__orange--headers"
          >
            <h3
              class="
                errors-displayed__heading errors-displayed__orange--heading
              "
            >
              <i class="fas fa-clone errors-displayed__heading--icon"></i>
              {{ errorModal.orangeColumnHeading }}
              ({{ duplicatedAccountNumbers.length }})
            </h3>
          </div>
          <div class="errors-displayed__listed">
            <table>
              <tr
                v-for="(user, index) in duplicatedAccountNumbers"
                :key="index"
              >
                <td class="errors-displayed__listed--account">
                  {{ user }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          v-if="invalidAccountNumbers.length"
          class="errors-displayed errors-displayed__invalid"
        >
          <div class="errors-displayed__headers">
            <h3 class="errors-displayed__heading">
              <i
                class="
                  fas
                  fa-exclamation-triangle
                  errors-displayed__heading--icon
                "
              ></i>
              {{ errorModal.redColumnHeading }} ({{
                invalidAccountNumbers.length
              }})
            </h3>
          </div>
          <div class="errors-displayed__listed">
            <table>
              <tr v-for="(user, index) in invalidAccountNumbers" :key="index">
                <td class="errors-displayed__listed--account">
                  {{ user }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          v-if="blueAccounts.length"
          class="errors-displayed errors-displayed__blue"
        >
          <div
            class="errors-displayed__headers errors-displayed__blue--headers"
          >
            <h3
              class="errors-displayed__heading errors-displayed__blue--heading"
            >
              <i class="fas fa-info-circle errors-displayed__heading--icon"></i>
              {{ errorModal.blueColumnHeading }}
              ({{ blueAccounts.length }})
            </h3>
          </div>
          <div class="errors-displayed__listed">
            <table>
              <tr v-for="(user, index) in blueAccounts" :key="index">
                <td class="errors-displayed__listed--account">
                  {{ user }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          v-if="greyAccounts.length"
          class="errors-displayed errors-displayed__grey"
        >
          <div
            class="errors-displayed__headers errors-displayed__grey--headers"
          >
            <h3
              class="errors-displayed__heading errors-displayed__grey--heading"
            >
              <i class="fas fa-info-circle errors-displayed__heading--icon"></i>
              {{ errorModal.greyColumnHeading }}
              ({{ errorModal.greyColumn.length }})
            </h3>
          </div>
          <div class="errors-displayed__listed">
            <table>
              <tr v-for="(user, index) in greyAccounts" :key="index">
                <td class="errors-displayed__listed--account">
                  {{ user }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </template>

    <template slot="footer">
      <p v-if="successfulTotal.length" class="footer__success-msg">
        {{ successfulTotal.length }}
        <template v-if="successfulMessage">
          {{ successfulMessage }}
        </template>
        <template v-else> applications were processed successfully </template>
      </p>
      <div class="footer__buttons flex-container">
        <button
          class="footer__buttons--button button button--blue"
          @click="closeModal"
        >
          Close
        </button>
      </div>
    </template>
  </AppModal>
</template>

<script>
import AppModal from "./AppModal.vue"

export default {
  components: {
    AppModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    errorModal: {
      type: Object,
      default: () => {},
    },
    duplicatedAccountNumbers: {
      type: Array,
      default: () => [],
    },
    invalidAccountNumbers: {
      type: Array,
      default: () => [],
    },
    blueAccounts: {
      type: Array,
      default: () => [],
    },
    greyAccounts: {
      type: Array,
      default: () => [],
    },
    successfulMessage: {
      type: String,
      default: "",
    },
    successfulTotal: {
      type: Array,
      default: () => [],
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
  },
}
</script>

<style scoped lang="scss">
.modal-mask {
  overflow: hidden;
}

.modal__header,
.modal__subheader {
  font-weight: $weight-bold;
}

.modal__header {
  font-size: $size-5;
  color: $night-light;
  margin-bottom: $spacing-2;
}

.modal__subheader {
  font-size: $size-7;
  color: $night-light-2;
  margin-bottom: $spacing-6;
}

.flex-container {
  display: flex;
}

.errors-displayed {
  flex: 1;
  border-radius: 4px;
  padding-bottom: $spacing-7;
  border: 1px solid $red-100;
  max-height: 400px;
  overflow-y: scroll;
  margin: $spacing-2;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &__headers {
    padding: $spacing-2 $spacing-4;
    background: $red-100;
  }

  &__heading {
    font-weight: $weight-bold;
    font-size: $size-7;
    color: $fire;

    &--icon {
      padding-right: $spacing-1;
    }
  }

  &__orange {
    border: 1px solid $yellow-50;
    &--headers {
      background: $yellow-50;
    }

    &--heading {
      color: $yellow-500;
    }
  }

  &__blue {
    border: 1px solid $blue-100;

    &--headers {
      background: $blue-100;
    }

    &--heading {
      color: $water;
    }
  }

  &__grey {
    border: 1px solid $day-dark;

    &--headers {
      background: $day-dark;
    }

    &--heading {
      color: $night-light;
    }
  }

  &__listed {
    padding: $spacing-4 $spacing-4 $spacing-2 3.25rem;

    &--account {
      font-weight: $weight-medium;
      height: 30px;

      color: $night-light-2;
    }
  }
}

.footer {
  &__success-msg {
    font-weight: $weight-bold;
    color: $earth;
    text-align: right;
  }

  &__buttons {
    margin: $spacing-6 0px $spacing-7 0px;

    &--button {
      margin-left: auto;
    }
  }
}
</style>
