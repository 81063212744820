<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Missing loss confirmations"
        summary="Places accounts under review to prevent incorrect billing and payments being requested."
      >
        <template v-slot:description-body>
          <p>Task runs at 6am each day</p>
          <p><b>Account would be placed under review if:</b></p>
          <ul>
            <li>Past loss date without a loss completion flow received.</li>
            <li>
              Next payment is due to be taken within 7 days after loss date, and
              loss date is less than 3 days from the day the task is run.
            </li>
          </ul>
          <p><b>Account under review cancelled if:</b></p>
          <ul>
            <li>
              All loss completion flow(s) have been received so that a final
              bill can be produced and payment taken automatically.
            </li>
          </ul>
          <p>
            <b-button size="sm" variant="outline-primary"
              >Check Status</b-button
            >
            fetches supply status of each meterpoint. If all accounts are off
            supply the review status will be immediately cancelled.
          </p>
        </template>
      </header-block>
    </b-row>

    <!-- <page-section>
      <template v-if="!isBusy" v-slot:content>
        <commit-chart :chart-data="chart" :options="chartOptions" />
      </template>
    </page-section> -->

    <page-section title="Accounts">
      <template v-slot:content>
        <div class="float-right mb-3">
          <b-form inline>
            <input
              id="accountsearch"
              v-model="filter"
              type="text"
              placeholder="Account number...."
              style="width: 300px"
              class="form-control mb-2 mr-sm-2 mb-sm-0"
            />
            <p class="m-2">Total: {{ accounts.length }}</p>
          </b-form>
        </div>

        <div v-if="errorResults.length > 0">
          <h3>Errors</h3>
          {{ errorResults }}
        </div>

        <b-table
          id="accounts-table"
          class="table table-sm"
          striped
          hover
          :per-page="perPage"
          :current-page="currentPage"
          :items="dataTable.items"
          :fields="dataTable.fields"
        >
          <template v-slot:cell(check)="row">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="
                checkMeterpointStatus(row.item.account.junifer_account_number)
              "
            >
              Check Status
            </b-button>
          </template>

          <template v-slot:cell(under_review_from)="row">
            {{ row.item.under_review_from | formattedDate }}
          </template>

          <template v-slot:cell(account_number)="row">
            {{ row.item.account.junifer_account_number }}
          </template>
        </b-table>

        <div v-show="accounts.length > perPage">
          <b-pagination
            v-model="currentPage"
            :total-rows="accounts.length"
            :per-page="perPage"
            aria-controls="accounts-table"
          ></b-pagination>
          <p class="mt-3">
            Page: {{ currentPage }} / {{ Math.ceil(accounts.length / perPage) }}
          </p>
        </div>

        <b-modal v-model="modalShow" hide-footer title="Meterpoints">
          <div v-if="modalDescription.length === 0">
            <b-spinner small />
          </div>
          <div v-else>
            <table>
              <tr>
                <th>Identifier</th>
                <th>Event Type</th>
                <th>Supply Status</th>
              </tr>
              <tr
                v-for="meterpoint in modalDescription.status"
                :key="meterpoint.id"
              >
                <td>{{ meterpoint.identifier }}</td>
                <td>{{ meterpoint.eventType }}</td>
                <td>{{ meterpoint.supplyStatus }}</td>
              </tr>
            </table>

            <p v-show="modalDescription.loss_complete" class="text-success">
              Under review status has been cancelled &#10004;
            </p>
          </div>
        </b-modal>
      </template>
    </page-section>
  </b-container>
</template>

<script>
// import CommitChart from "@/components/charts/CommitChartBar"
import Accounts from "@/api/JuniferAccounts.js"
import dayjs from "dayjs"
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

export default {
  filters: {
    formattedDate: function (date) {
      return dayjs(date).format("Do MMM YY")
    },
  },
  components: {
    headerBlock,
    pageSection,
    // CommitChart
  },
  data() {
    return {
      isBusy: false,
      filter: null,
      accounts: [],
      perPage: 50,
      currentPage: 1,
      modalShow: false,
      modalDescription: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      chart: {
        labels: [],
        datasets: [
          {
            label: "Account under review",
            backgroundColor: "#f87979",
            data: [],
          },
        ],
      },
    }
  },
  computed: {
    dataTable() {
      var accounts = {
        fields: [
          { key: "account_number", label: "Account Number", sortable: false },
          {
            key: "under_review_from",
            label: "Under Review From",
            sortable: false,
          },
          { key: "reason", label: "Reason", sortable: false },
          { key: "check", label: "", sortable: false },
        ],
        items: this.filteredResults,
      }
      return accounts
    },
    filteredResults() {
      let filteredAccounts = this.accounts
      if (this.filter !== null) {
        filteredAccounts = this.accounts.filter((comms) => {
          return (
            comms.account &&
            comms.account.junifer_account_number !== null &&
            comms.account.junifer_account_number.includes(this.filter)
          )
        })
      }
      return filteredAccounts
    },
    errorResults() {
      return this.accounts.filter((comms) => {
        return comms.account.junifer_account_number === null
      })
    },
  },
  watch: {
    filteredResults: function (newValue) {
      var max, min
      var dates = []
      if (newValue.length <= 1) {
        max = min = newValue[0]
      } else {
        var results = newValue.map((x) =>
          dayjs(x.created_at).format("YYYY-MM-DD")
        )
        min = results.reduce(function (a, b) {
          return a < b ? a : b
        })
        max = results.reduce(function (a, b) {
          return a > b ? a : b
        })
      }
      dates = this.getDates(min, max)
      this.chart.labels = dates

      var data = []
      dates.forEach(function (date) {
        var x = newValue.filter((x) =>
          dayjs(x.created_at).isSame(date, "day")
        ).length
        data.push(x)
      })
      this.chart.datasets[0].data = data
    },
  },
  created() {
    this.isBusy = true
    Accounts.getAccountsWithoutLossConfirmation()
      .then((response) => {
        this.accounts = response.data
        this.isBusy = false
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
  },
  methods: {
    getDates(startDate, stopDate) {
      var dateArray = []
      var currentDate = dayjs(startDate)
      parsedStopDate = dayjs(stopDate)
      while (currentDate <= parsedStopDate) {
        dateArray.push(dayjs(currentDate).format("YYYY-MM-DD"))
        currentDate = dayjs(currentDate).add(1, "days")
      }
      return dateArray
    },
    checkMeterpointStatus(account_number) {
      this.modalDescription = []
      this.modalShow = true
      Accounts.getMeterpointSupplyStatus(account_number).then((response) => {
        if (response.data.loss_complete) {
          Accounts.closeLossConfirmationReview(account_number)
          var index = this.accounts.findIndex(
            (s) => s.account_number == account_number
          )
          this.accounts.splice(index, 1)
        }
        this.modalDescription = response.data
      })
    },
  },
}
</script>
