<template>
  <div class="manage-applications__flex-box">
    <div class="manage-applications__left-column">
      <div
        class="manage-applications__flex-box manage-applications__flex-right"
      >
        <p class="manage-applications__filter-text">Filter by:</p>
        <!-- Criteria One -->
        <DropdownSelection
          dropdown-type="filter"
          dropdown-name="criteria1"
          :options="dropdownOptions.criteria"
          placeholder="Criteria 1"
          @dropdownChange="updateFilters"
        ></DropdownSelection>

        <!-- Criteria Two -->
        <DropdownSelection
          dropdown-type="filter"
          dropdown-name="criteria2"
          :options="secondCriteria(criteria1Filter)"
          placeholder="Criteria 2"
          @dropdownChange="updateFilters"
        ></DropdownSelection>

        <!-- Assigned to -->
        <DropdownSelection
          dropdown-type="filter"
          dropdown-name="assignedTo"
          :options="dropdownOptions.assignedTo"
          placeholder="Assigned to"
          @dropdownChange="updateFilters"
        ></DropdownSelection>

        <!-- Status -->
        <DropdownSelection
          dropdown-type="filter"
          dropdown-name="status"
          :options="dropdownOptions.status"
          placeholder="Status"
          @dropdownChange="updateFilters"
        ></DropdownSelection>
      </div>

      <div class="manage-applications__tables">
        <GenericTable
          v-if="tableData(accounts).length"
          :table-header="AuditAccountsHeader"
          color-scheme="blue"
          :is-load-more="isLoadMoreShown"
          :column-ratio="[3, 6, 10, 30, 30, 11, 10]"
          :loading-status="loadMoreStatus.name"
          :loading-status-active="loadMoreStatus.type === 'review in progress'"
          @loadMoreTableRows="$emit('load-more', 'review in progress')"
        >
          <GenericTableRow
            v-for="(account, index) in tableData(accounts)"
            :key="index"
            :column-ratio="[3, 6, 10, 30, 30, 11, 10]"
            @dropdown-open="
              $emit('get-uploaded-images', {
                accountId: account.account_id,
                tableName: 'auditCandidates',
              })
            "
          >
            <template v-slot:column-1>
              <div class="generic-table__checkbox">
                <input
                  v-model="selectedAccounts"
                  :value="account.junifer_account_number"
                  type="checkbox"
                  @click.stop
                />
              </div>
            </template>
            <template v-slot:column-2>
              <span class="generic-table__account-id">
                {{ account.junifer_account_number }}
              </span>
            </template>
            <template v-slot:column-3>
              {{ account.primary_whd_criterion_text }}
            </template>
            <template v-slot:column-4>
              {{ account.secondary_whd_criterion_text }}
            </template>
            <template v-slot:column-5>
              {{ account.agent }}
            </template>

            <template v-slot:column-6>
              <span :class="getLabelClass(account.ark_status)">
                {{ getArkStatus(account.ark_status) }}
              </span>
            </template>

            <template v-slot:dropdown>
              <form
                class="generic-table__dropdown"
                @submit.prevent="submitAccountUpdate($event, account)"
              >
                <h3>
                  <strong>First name: </strong>
                  {{ account.first_name }}
                </h3>
                <h3>
                  <strong>Last name: </strong>
                  {{ account.family_name }}
                </h3>
                <h3>
                  <strong>Email: </strong>
                  {{ account.email }}
                </h3>
                <h3>
                  <strong>Contact number: </strong>
                  {{ account.phone_number }}
                </h3>
                <h3>
                  <strong>Supply address: </strong>
                  {{ account.customer_details.address }},
                  {{ account.customer_details.postcode }}
                </h3>
                <h3>
                  <strong>Communication preference: </strong>
                  {{ account.communication_preference }}
                </h3>

                <h3 v-if="account.images">
                  <strong>Eligibility proof: </strong>
                  <strong v-if="account.images.length === 0">
                    No documents uploaded
                  </strong>
                  <a
                    v-else-if="account.images.length === 1"
                    href="javascript:;"
                    @click="openProofModal(account.images[0])"
                  >
                    {{ account.images[0].name }}
                  </a>
                  <div v-else class="generic-list">
                    <ul>
                      <li
                        v-for="(image, imageIndex) in account.images"
                        :key="imageIndex"
                      >
                        <a href="javascript:;" @click="openProofModal(image)">
                          {{ account.images[imageIndex].name }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </h3>

                <div class="generic-table__customer-criteria">
                  <h3>
                    <strong>Update customer criterias</strong>
                  </h3>
                  <p>Criteria One</p>
                  <DropdownSelection
                    dropdown-name="criteria1"
                    :options="dropdownOptions.criteria"
                    :set-selected="account.primary_whd_criterion_id.toString()"
                    :has-placeholder="false"
                    @dropdownChange="registerChange($event, account)"
                  ></DropdownSelection>

                  <p>Criteria Two</p>
                  <DropdownSelection
                    dropdown-name="criteria2"
                    :options="
                      secondCriteria(
                        account.primary_whd_criterion_id.toString(),
                      )
                    "
                    :set-selected="
                      account.secondary_whd_criterion_id.toString()
                    "
                    :has-placeholder="false"
                    @dropdownChange="registerChange($event, account)"
                  ></DropdownSelection>
                </div>

                <div class="generic-table__flex-box">
                  <div class="generic-table__half-left-column">
                    <h3>
                      <strong>Update assigned agent</strong>
                      <DropdownSelection
                        dropdown-name="assignedTo"
                        :options="dropdownOptions.assignedTo"
                        :set-selected="account.agent"
                        :has-placeholder="false"
                        @dropdownChange="registerChange($event, account)"
                      ></DropdownSelection>
                    </h3>
                  </div>
                  <div class="generic-table__half-right-column">
                    <h3>
                      <strong>Update status of application</strong>
                      <DropdownSelection
                        dropdown-name="status"
                        :options="dropdownOptions.status"
                        :set-selected="account.ark_status"
                        :has-placeholder="false"
                        @dropdownChange="registerChange($event, account)"
                      ></DropdownSelection>
                    </h3>
                  </div>
                </div>

                <div
                  class="generic-table__flex-box generic-table__flex-right generic-table__flex-wrap"
                >
                  <button
                    class="button button--blue"
                    :disabled="!account.account_data_changed.all"
                    type="submit"
                  >
                    Save Changes
                  </button>
                  <div
                    v-if="checkAccountUpdate(account, 'fail')"
                    class="generic-table__submit-error"
                  >
                    <i class="fas fa-exclamation-triangle"></i>Update failed:
                    <span>Please try resubmitting the form</span>
                  </div>
                  <div
                    v-if="checkAccountUpdate(account, 'success')"
                    class="generic-table__submit-success"
                  >
                    <i class="fa fa-check-circle"></i>Successfully updated
                  </div>
                </div>
              </form>
            </template>
          </GenericTableRow>
        </GenericTable>

        <p v-else>We have no datas from the selected type.</p>
      </div>
    </div>

    <div class="manage-applications__right-column manage-applications__sidebar">
      <SidebarCard>
        <template v-slot:header>
          <i class="fas fa-info-circle manage-applications__info-icon"></i>
          Chosen for audit
        </template>
        <p>
          We will check if accounts are already receiving payment via the core
          group (PSR eligibility) and that the account is still active. These
          customers will be informed of the outcome.
        </p>
        <div
          class="manage-applications__select-all manage-applications__flex-box manage-applications__flex-right"
        >
          <button
            class="button button--blue select-button"
            :disabled="accountList.length === 0"
          >
            <input
              id="selectAllAcc"
              v-model="selectAll"
              type="checkbox"
              class="checkbox select-button__select-checkbox"
              :disabled="accountList.length === 0"
              @click="select"
            />
            <span>
              <template v-if="selectAll">
                All accounts selected
                <i class="fas fa-check manage-applications__check-icon"></i>
              </template>
              <template v-else> Select all accounts </template>
            </span>
          </button>
        </div>

        <hr />

        <div
          class="manage-applications__flex-box manage-applications__flex-right"
        >
          <a
            class="returned-audit__csv-button button button--round button--blue"
            @click.prevent="exportAuditCSVFile"
          >
            Download audit CSV
          </a>
        </div>
      </SidebarCard>

      <SidebarCard>
        <template v-slot:header> Audit Return </template>

        <div class="returned-audit">
          <AuditCandidatesAuditReturnCSV
            @response="
              (o) => {
                $emit('handle-accounts-upload', o)
              }
            "
            @error="
              (o) => {
                $emit('handle-accounts-upload-error', o)
              }
            "
          ></AuditCandidatesAuditReturnCSV>
        </div>
      </SidebarCard>

      <SidebarCard>
        <template v-slot:header> Process approved at audit </template>
        <p class="manage-applications__selected">
          <strong>Total approved</strong>
          <span class="manage-applications__selected-total">
            {{ successfulAudits.length }}
          </span>
        </p>
        <div
          class="manage-applications__flex-box manage-applications__flex-right process-approved"
        >
          <button
            :disabled="!successfulAudits.length"
            class="button button--blue"
            @click="$emit('pass-audit', successfulAudits)"
          >
            Process
          </button>
          <div
            v-if="passAuditSuccessState"
            class="manage-applications__process manage-applications__process-successful"
          >
            <i class="fa fa-check-circle"></i>
            <span>Successfully processed</span>
          </div>
        </div>
      </SidebarCard>

      <SidebarCard>
        <template v-slot:header>Update status of application</template>
        <p class="manage-applications__selected">
          <strong>Accounts selected</strong>
          <span class="manage-applications__selected-total">
            {{ selectedAccounts.length }}
          </span>
        </p>

        <form @submit.prevent="submitStatusUpdate">
          <DropdownSelection
            dropdown-name="processTo"
            :options="dropdownOptions.status"
            placeholder="Please select"
            @dropdownChange="processApplications"
          ></DropdownSelection>
          <div
            class="manage-applications__flex-box manage-applications__flex-right process-approved"
          >
            <button
              class="button button--blue"
              :disabled="
                selectedAccounts.length === 0 ||
                processType.length === 0 ||
                pendingProcessing
              "
              type="submit"
            >
              Process
            </button>
          </div>
        </form>

        <div class="manage-applications__process-container">
          <div
            v-if="failedToProcess"
            class="manage-applications__process manage-applications__process-failed"
          >
            <i class="fas fa-exclamation-triangle"></i>
            <span>Process failed, please retry</span>
          </div>

          <div
            v-if="pendingProcessing && !successfullyProcessed"
            class="manage-applications__loading"
          >
            <img src="@/assets/icons/loading-bar.gif" />
          </div>

          <div
            v-if="successfullyProcessed && !selectedAccounts.length"
            class="manage-applications__process manage-applications__process-successful"
          >
            <i class="fa fa-check-circle"></i>
            <span>Successfully processed</span>
          </div>
        </div>
      </SidebarCard>
    </div>

    <Modal
      :is-full-screen="true"
      :is-modal-open="isFilePreviewVisible"
      @closeModal="isFilePreviewVisible = false"
    >
      <template #body>
        <FilePreview :file-data="currentFile" />
        <p class="file-modal-text">{{ fileModalText }}</p>
      </template>
      <template #cancelButton>Close</template>
    </Modal>
  </div>
</template>

<script>
import { whdMixin } from "@/mixins/whdMixin"
import SidebarCard from "@/components/SidebarCard"
import GenericTable from "@/components/smartMeter/GenericTable"
import GenericTableRow from "@/components/smartMeter/GenericTableRow"
import DropdownSelection from "@/components/warmHomeDiscount/DropdownSelection"
import AuditCandidatesAuditReturnCSV from "./AuditCandidatesAuditReturnCSV.vue"
import FilePreview from "@/components/FilePreview"
import Modal from "@soenergy/frontend-library/src/components/Modal"
import WhdApi from "@/api/WarmHomeDiscount.js"

export default {
  name: "ApplicationReceived",
  components: {
    SidebarCard,
    GenericTable,
    GenericTableRow,
    DropdownSelection,
    AuditCandidatesAuditReturnCSV,
    FilePreview,
    Modal,
  },
  mixins: [whdMixin],
  props: {
    accountList: {
      type: Array,
      default: () => [],
    },
    dropdownOptions: {
      type: Object,
      default: () => {},
    },
    successfulAudits: {
      type: Array,
      default: () => [],
    },
    loadMoreStatus: {
      type: Object,
      default: () => {},
    },
    passAuditSuccess: {
      type: Boolean,
      default: false,
    },
    failedProcessing: {
      type: Boolean,
      default: false,
    },
    pendingProcessing: {
      type: Boolean,
      default: false,
    },
    successfullyProcessed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      AuditAccountsHeader: [
        "",
        "Select",
        "Account Id",
        "Criteria 1",
        "Criteria 2",
        "Assigned",
        "Status",
      ],
      accounts: [],
      passAuditSuccessState: this.passAuditSuccess,
      selectedAccounts: [],
      selectAll: false,
      processType: "",
      failedToProcess: this.failedProcessing,
      isFilePreviewVisible: false,
      currentFile: null,
    }
  },
  computed: {
    isLoadMoreShown() {
      return !this.searchSuccess
    },
    fileModalText() {
      const fileName = this.currentFile ? this.currentFile.name : ""
      return "Uploaded file: " + fileName
    },
  },
  watch: {
    passAuditSuccess(value) {
      this.passAuditSuccessState = value

      if (value) {
        setTimeout(() => (this.passAuditSuccessState = false), 3000)
      }
    },
    accountList(value) {
      this.accounts = this.accountList.map((account) => ({
        account_data_changed: {
          cr1: false,
          cr2: false,
          agent: false,
          status: false,
          all: false,
          originalPrimaryCriteria: account.primary_whd_criterion_id,
        },
        accountUpdateFail: false,
        accountUpdateSuccess: false,
        ...account,
      }))
    },
    successfullyProcessed(value) {
      this.resetSelection()
    },
    failedProcessing(value) {
      this.failedToProcess = value
    },
  },
  methods: {
    resetSelection() {
      this.selectedAccounts = []
      this.failedToProcess = false
    },
    select() {
      this.selectedAccounts = []
      if (!this.selectAll) {
        for (let i in this.accountList) {
          this.selectedAccounts.push(this.accountList[i].junifer_account_number)
        }
      }
    },
    processApplications(selection) {
      switch (selection.name) {
        case "processTo":
          this.processType = selection.value
          break
        default:
          return
      }
    },
    submitStatusUpdate() {
      let submissionData = {
        processingTab: "auditCandidate",
        data: {
          warm_home_discounts_interface: {
            account_numbers: this.selectedAccounts,
            new_attributes: {
              ark_status: this.processType,
            },
          },
        },
      }

      this.$emit("process-applications", submissionData)
    },
    getLabelClass(status) {
      let modifier = ""

      switch (status) {
        case "audit_candidate":
          modifier = "generic-table__status--sent"
          break
        case "sent_for_audit":
          modifier = "generic-table__status--sent"
          break
        case "approved_at_audit":
          modifier = "generic-table__status--approved"
          break
        case "document_verification":
          modifier = "generic-table__status--needs-verification"
          break
        default:
          modifier = ""
      }
      return "generic-table__status " + modifier
    },
    getArkStatus(status) {
      let statusUppercase = status.charAt(0).toUpperCase() + status.slice(1)
      return statusUppercase.replaceAll("_", " ")
    },
    openProofModal(item) {
      this.isFilePreviewVisible = true
      this.currentFile = item
    },
    downloadCSVFileFromUrl(url) {
      let link = document.createElement("a")
      link.href = url
      const fileName = `audit-candidates.csv`
      link.download = fileName
      link.click()
      link.remove()
    },
    exportAuditCSVFile() {
      WhdApi.downloadAuditCSV().then((response) => {
        if (response && response.data) {
          const url = response.data.exportAuditCandidates.signedUrl
          this.downloadCSVFileFromUrl(url)
        } else {
          throw new Error("Missing response data")
        }
      })
    },
  },
}
</script>

<style lang="scss">
.returned-audit {
  &__csv-button {
    margin-top: $spacing-2;
  }

  &__failure {
    font-size: $size-7;
    line-height: 1.2;
    text-align: left;
    position: relative;
    padding: 0 0 $spacing-4 $spacing-5;
    color: $fire;

    .fas {
      position: absolute;
      left: 0;
      top: 2px;
    }
    span {
      font-weight: $weight-bold;
      font-size: $size-7;
    }
    p {
      margin: $spacing-1 0 0 0;
    }
  }

  &__radio {
    margin: $spacing-4 0 $spacing-2;
  }

  &__radio-input {
    margin-right: $spacing-2;
  }

  &__radio-label {
    font-weight: $weight-bold;
    color: $night-light;
  }

  &__success {
    font-weight: $weight-bold;
    color: $earth;
    text-align: center;
    padding: $spacing-4 0 $spacing-2 0;

    span {
      padding-left: $spacing-2;
    }
  }

  &__loading {
    text-align: center;
  }
}
.process-approved {
  flex-wrap: wrap;

  .manage-applications__process-successful {
    width: 100%;
    margin: $spacing-5 0 0 0;
    text-align: center;
  }
}

.manage-applications__process-container {
  margin-top: $spacing-5;
  text-align: right;
}

.generic-list {
  color: $water-dark;
  margin-top: 18px;
  margin-bottom: -$space-2;
}
</style>
