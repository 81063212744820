<template>
  <UploadCsv
    :parser="parser"
    :api-call="PaymentAdequacy.uploadAccountsAdhoc"
    v-on="$listeners"
  ></UploadCsv>
</template>

<script>
import UploadCsv from "../csv/UploadCsv.vue"
import { csvToListOfCells } from "@/helpers/csv/parsers"
import PaymentAdequacy from "@/api/PaymentAdequacy"

export default {
  components: {
    UploadCsv,
  },
  data() {
    return {
      parser: csvToListOfCells,
      PaymentAdequacy: PaymentAdequacy,
    }
  },
}
</script>
