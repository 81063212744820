<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Email templates"
        summary="Summary of all communications sent by ARK"
      >
        <template v-slot:description-body> </template>
      </header-block>
    </b-row>

    <page-section>
      <template v-slot:content>
        <div v-if="isBusy">
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </div>
        <div v-else>
          <template-preview
            :results-table="dataTable"
            :is-busy="isBusy"
            :per-page="perPage"
            :current-page="currentPage"
          />
        </div>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import templatePreview from "@/components/templatePreview"

export default {
  components: {
    headerBlock,
    pageSection,
    templatePreview,
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters("comms", ["templates", "isBusy"]),
    dataTable() {
      var t = {
        sortBy: "Status",
        sortDesc: false,
        fields: [
          { key: "name", label: "Template", sortable: true },
          { key: "id", label: "ID", sortable: false },
          { key: "created_at", label: "", sortable: false },
        ],
        items: this.templates,
      }
      return t
    },
  },
  mounted() {
    if (this.templates.length === 0) {
      this.getTemplates()
    }
  },
  methods: {
    ...mapActions("comms", ["getTemplates"]),
  },
}
</script>
