import axios from "axios"

const server =
  process.env.VUE_APP_ROOT_API === "http://localhost:3000/ark/api/v1"
    ? "https://services.staging.soenergy.co/ark/api/v1"
    : process.env.VUE_APP_ROOT_API

const apiImportTariffs = axios.create({
  baseURL: server,
  timeout: 1000000,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Headers":
      "Content-Type, Accept, Authorization, X-Request-With",
  },
})

export default {
  importTariffs() {
    return apiImportTariffs.get("tariffs/update_available_tariffs")
  },
}
