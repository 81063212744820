<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Payment adequacy follow up"
        summary="We review our customers payment amount 6 months after their payment schedule has been created."
      >
        <template v-slot:description-body>
          <PaymentAdequacyFollowUpDescription />
        </template>
      </header-block>
    </b-row>

    <page-section title="Individual Account Lookup">
      <template v-slot:content>
        <b-form inline>
          <input
            id="accountsearch"
            v-model="day7lookup"
            type="text"
            :disabled="isBusy"
            placeholder="Account number...."
            style="width: 300px"
            class="form-control mb-2 mr-sm-2 mb-sm-0"
          />
          <span class="input-group-btn">
            <b-button
              id="search"
              :disabled="isBusy || !validLookup"
              variant="outline-primary"
              class="mb-2 mr-sm-2 mb-sm-0"
              @click="accountLookup(day7lookup)"
            >
              <span v-show="!isBusy">Search</span>
              <span v-show="isBusy"><b-spinner small /></span>
            </b-button>
            <b-button
              id="clear"
              :disabled="isBusy"
              class="mb-2 mr-sm-2 mb-sm-0"
              variant="outline-danger"
              @click="clearLookup()"
            >
              Clear
            </b-button>
          </span>
        </b-form>

        <b-row>
          <b-col class="text-left mt-2">
            <i
              >Only returns payment adequacy reviews that have already been
              initially reviewed.</i
            >
            <FollowUpReviewTable
              v-if="daySevenLookupResults.length > 0"
              ref="lookup-results"
              :data="daySevenLookupResults"
            />
          </b-col>
        </b-row>
      </template>
    </page-section>

    <page-section :title="'Bulk Follow Up (' + dataTotal + ')'">
      <template v-slot:content>
        <b-form inline>
          <b-form-select
            id="inline-form-custom-select-pref"
            v-model="importCount"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="importCountOptions"
          >
            <template v-slot:first>
              <option :value="null">Choose...</option>
            </template>
          </b-form-select>

          <b-form-select
            id="inline-form-custom-select-pref"
            v-model="importTypeFilter"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="importTypeFilterOptions"
          >
            <template v-slot:first>
              <option :value="null">Choose...</option>
            </template>
          </b-form-select>
          <b-form-select
            id="inline-form-custom-select-pref"
            v-model="importRefreshFilter"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="importRefreshFilterOptions"
          >
          </b-form-select>
          <b-button
            class="mb-2 mr-sm-2 mb-sm-0"
            :disabled="
              groupActionDisable || progressBar.jobId !== null || isBusyState
            "
            variant="outline-success"
            @click="
              importReviews(importCount, importTypeFilter, importRefreshFilter)
            "
          >
            Fetch
          </b-button>

          <b-button
            :disabled="groupActionDisable || progressBar.jobId !== null"
            variant="outline-danger"
            @click="clearReviews()"
          >
            Clear
          </b-button>
        </b-form>

        <b-spinner v-show="isBusy" />

        <div v-if="progressBar.jobId !== null" class="p-5">
          Job: {{ progressBar.jobId }} - {{ progressBar.stage }} ({{
            progressBar.counter
          }}/{{ progressBar.max }})
          <b-progress
            v-if="progressBar.jobId !== null"
            b-progress
            :value="progressBar.counter"
            :max="progressBar.max"
            show-progress
            animated
          />
        </div>

        <div v-else>
          <div v-if="data.length > 0">
            <b-row cols="1" cols-sm="2">
              <b-col>
                <b-card bg-variant="light" class="mt-3">
                  <b-form-group label-cols-sm="3" label="Bulk">
                    <b-input-group>
                      <b-form-select
                        v-model="groupAction"
                        :options="actionOptions"
                      >
                        <option slot="first" :value="null">-- none --</option>
                      </b-form-select>
                      <b-input-group-append>
                        <b-button
                          :disabled="!groupAction || groupActionDisable"
                          variant="success"
                          @click="groupActionClick(clickedReviews)"
                        >
                          <span v-if="!groupActionDisable">
                            ({{ clickedReviews.length }}) Go
                          </span>
                          <span v-else>
                            <b-spinner small></b-spinner>
                          </span>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group label-cols-sm="3" label="Select">
                    <b-form-radio-group
                      v-model="selected"
                      style="text-align: left"
                      :options="selectedOptions"
                      name="radios-stacked"
                      stacked
                    ></b-form-radio-group>
                  </b-form-group>

                  <b-button v-b-modal.guide class="m-2 float-right"
                    >Process guide</b-button
                  >
                  <b-modal
                    id="guide"
                    hide-footer
                    size="lg"
                    title="Process guide"
                  >
                    <img
                      src="../../assets/processMaps/PaymentAdeqaucy - 6m (day 7).png"
                      width="100%"
                    />
                  </b-modal>
                </b-card>
              </b-col>
            </b-row>

            <div v-show="groupActionDisable">
              <h3>Please wait while Ark performs this bulk process</h3>
            </div>

            <results-table
              :count-box-one="overdueAwaiting.length"
              :count-box-two="overdueInvestigate.length"
              :count-box-three="completeReviews.length"
            >
              <div slot="box-three-slot">
                <b-button
                  v-show="completeReviews.length !== 0"
                  size="sm"
                  variant="outline-light"
                  :disabled="isBusyState"
                  @click="deleteAll(completeReviews)"
                >
                  Clear all
                </b-button>
              </div>
            </results-table>
            <FollowUpReviewTable
              v-if="data.length > 0"
              ref="all-results"
              :is-busy="isBusy || groupActionDisable"
              :data="data"
            />
          </div>
        </div>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import FollowUpReviewTable from "@/components/paymentAdequacy/followUpReviewTable"
import PaymentAdequacyFollowUpDescription from "@/components/paymentAdequacy/paymentAdequacyFollowUpDescription"
import { mapActions, mapGetters, mapMutations } from "vuex"
import dayjs from "dayjs"
import PaymentAdequacy from "@/api/PaymentAdequacy"
import { paymentAdequacyMixin } from "@/mixins/paymentAdequacyMixin"
import headerBlock from "@/components/header"
import Jobs from "@/api/Jobs"
import resultsTable from "@/components/resultsTable"
import pageSection from "@/components/pageSection"
import { isTodayCheck } from "@/helpers/dates"
import { fetchFollowUpTemplates } from "@/helpers/paymentAdequacy/followTemplates"
import { followUpReview } from "@/helpers/paymentAdequacy/followUpReview"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

export default {
  mixins: [paymentAdequacyMixin],
  data() {
    return {
      importCount: 100,
      importCountOptions: [
        100, 500, 1000, 1500,
        // 10000
      ],
      importRefreshFilter:
        "follow_up_fl = 't' AND updated_at >= '" +
        dayjs().subtract(0, "days").format("YYYY-MM-DD") +
        "'",
      importRefreshFilterOptions: [
        {
          text: "Refreshed today",
          value:
            "follow_up_fl = 't' AND updated_at >= '" +
            dayjs().subtract(0, "days").format("YYYY-MM-DD") +
            "'",
        },
        {
          text: "Refreshed before today",
          value:
            "follow_up_fl = 't' AND updated_at < '" +
            dayjs().subtract(0, "days").format("YYYY-MM-DD") +
            "'",
        },
        { text: "All", value: "" },
      ],
      importTypeFilter: "",
      importTypeFilterOptions: [
        { text: "Need read", value: "has_meter_readings = 'f' " },
        { text: "Large change", value: "has_meter_readings = 't' " },
        { text: "All", value: "" },
      ],
      isBusy: false,
      day7lookup: "",
      groupAction: null,
      selected: null,
      selectedOptions: [
        { text: "Need a reading", value: "selectAllNeedRead" },
        { text: "Erroneous reviews", value: "selectErroneousReviews" },
        { text: "Large Payment", value: "selectAllLargeChange" },
        {
          text: "Awaiting & No Customer Contact",
          value: "selectAwaitingUpdatedNoContact",
        },
        { text: "Awaiting", value: "selectAwaiting" },
        { text: "All", value: "selectAll" },
        { text: "None", value: "selectClear" },
      ],
      actionOptions: [
        { text: "Delete", value: "softDelete" },
        { text: "Refresh", value: "refresh" },
        { text: "Process", value: "process" },
      ],
      progressBar: {
        jobId: null,
        stage: null,
        counter: null,
        max: null,
      },
      groupActionDisable: false,
    }
  },
  computed: {
    ...mapGetters("sixMonthAdFollowUp", [
      "isBusyState",
      "data",
      "dataTotal",
      "daySevenLookupResults",
    ]),
    editedArrays() {
      var arr = []
      this.data.forEach(function (x) {
        arr.push(x.edited)
      })
      return arr
    },
    lookupEditedArrays() {
      var arr = []
      this.daySevenLookupResults.forEach(function (x) {
        arr.push(x.edited)
      })
      return arr
    },
    overdueInvestigate() {
      return this.data.filter((x) => x.notOkToSend && !x.sevendayreview)
    },
    overdueAwaiting() {
      return this.data.filter((x) => !x.notOkToSend && !x.sevendayreview)
    },
    completeReviews() {
      return this.data.filter((x) => x.sevendayreview)
    },
    clickedReviews() {
      return this.data.filter((x) => x.clicked)
    },
    searchFilter() {
      var string
      if (
        this.importRefreshFilter.length > 0 &&
        this.importTypeFilter.length > 0
      ) {
        string = this.importTypeFilter + " AND " + this.importRefreshFilter
      } else if (this.importTypeFilter.length > 0) {
        string = this.importTypeFilter
      } else {
        string = this.importRefreshFilter
      }
      return { filter: string, count: this.importCount }
    },
    validLookup() {
      return this.day7lookup.length >= 6 && this.day7lookup.length <= 8
    },
  },
  channels: {
    PaymentAdequacyChannel: {
      connected() {},
      received(data) {
        if (data.deleted_fl) {
          this.DELETE_ROW_ID(data.id)
        } else {
          this.SET_RECORD(data)
        }
      },
    },
  },
  methods: {
    setupStream() {
      this.$cable.subscribe({
        channel: "PaymentAdequacyChannel",
      })
    },
    ...mapActions("sixMonthAdFollowUp", [
      "deleteAll",
      "softdelete",
      "restore",
      "paymentscript7dayaction",
      "checkForResponse",
      "fetchRowInfo",
    ]),
    ...mapMutations({
      SET_RECORD: "sixMonthAdFollowUp/SET_RECORD",
      SET_DAYSEVENDATA_STATE: "sixMonthAdFollowUp/SET_DAYSEVENDATA_STATE",
      DELETE_ROW_ID: "sixMonthAdFollowUp/DELETE_ROW",
      SET_DAYSEVENDATA: "sixMonthAdFollowUp/SET_DAYSEVENDATA",
      SET_LOOKUP_RESULT: "sixMonthAdFollowUp/SET_LOOKUP_RESULT",
    }),
    clearReviews() {
      this.SET_DAYSEVENDATA([])
    },
    clearLookup() {
      this.SET_LOOKUP_RESULT([])
      this.day7lookup = ""
    },
    importReviews() {
      this.$store.dispatch(
        "sixMonthAdFollowUp/getDaySevenReviews",
        this.searchFilter
      )
    },
    isToday(date) {
      return isTodayCheck(date)
    },
    groupActionClick(clickedReviews) {
      var arr = []
      var that = this
      this.groupActionDisable = true
      if (this.groupAction === "softDelete") {
        clickedReviews.forEach(function (x) {
          arr.push(x.id)
          that.DELETE_ROW_ID(x)
        })
        PaymentAdequacy.softDeleteGroup(arr)
        that.groupActionDisable = false
        that.groupAction = null
        that.selected = null
      } else if (this.groupAction === "process") {
        PaymentAdequacy.followUpProcessGroup(clickedReviews)
          .then((response) => {
            that.SET_DAYSEVENDATA_STATE(true)
            this.groupActionDisable = false
            this.groupAction = null
            this.selected = null
            this.progressBar.jobId = response.data.id
            this.progressBar.counter = response.data.progress_current
            this.progressBar.max = clickedReviews.length
            this.progressBar.stage = response.data.progress_stage
          })
          .catch(() => {
            that.SET_DAYSEVENDATA([])
            that.SET_DAYSEVENDATA_STATE(false)
          })
      } else if (this.groupAction === "refresh") {
        clickedReviews.forEach(function (x) {
          arr.push(x.id)
        })
        PaymentAdequacy.refreshFollowUpGroup(arr)
          .then((response) => {
            that.SET_DAYSEVENDATA_STATE(true)
            this.groupActionDisable = false
            this.groupAction = null
            this.selected = null
            this.progressBar.jobId = response.data.id
            this.progressBar.counter = response.data.progress_current
            this.progressBar.max = this.countAwaiting.length
            this.progressBar.stage = response.data.progress_stage
          })
          .catch(() => {
            that.SET_DAYSEVENDATA([])
            that.SET_DAYSEVENDATA_STATE(false)
          })
      }
    },
    accountLookup(account) {
      var that = this
      this.isBusy = true
      PaymentAdequacy.fetchAccountPaymentReviews(account)
        .then((response) => {
          this.isBusy = false
          var followUpReviews = response.data.filter(
            (x) => x.initial_review_completed && !x.deleted_fl
          )

          followUpReviews.forEach(function (e) {
            followUpReview(e)
            e._rowVariant = ""
          })

          that.SET_LOOKUP_RESULT(followUpReviews)
        })
        .catch(() => {})
    },
    editable(row) {
      row.edited = !row.edited
      this.SET_RECORD(row)
    },
    compareArrays(arr1, arr2) {
      var chgValueIndex
      arr1.forEach(function (x, index) {
        if (x !== arr2[index] && arr2[index] !== undefined) {
          chgValueIndex = index
        }
      })
      return chgValueIndex
    },
    selectErroneousReviews() {
      var that = this
      this.selectClear()
      var accountChecks = this.data.filter(
        (x) => x.tags !== null && x.account_checks !== null
      )
      accountChecks = accountChecks.filter(
        (x) => x.account_checks.length > 0 || x.tags.length > 0
      )
      accountChecks.forEach(function (x) {
        if (typeof x.account_checks[0] === "string") {
          x.account_checks.forEach(function (y) {
            if (typeof y == "string") {
              if (
                y.includes("New Schedule") ||
                y.includes("Account Closed") ||
                y.includes("Account Terminating") ||
                y.includes("No Active Mandate") ||
                y.includes("Under Review")
              ) {
                x.clicked = true
              }
              that.SET_RECORD(x)
            }
          })
        }
        x.tags.forEach(function (y) {
          if (y.text === "Account Terminating") {
            x.clicked = true
          }
          that.SET_RECORD(x)
        })
      })
    },
    selectAllLargeChange() {
      var that = this
      this.selectClear()
      var reads = this.data.filter(
        (x) => x.has_meter_readings && !x.notOkToSend
      )
      reads.forEach(function (x) {
        x.clicked = true
        that.SET_RECORD(x)
      })
    },
    selectAwaitingUpdatedNoContact() {
      var that = this
      this.selectClear()
      var reads = this.data.filter(
        (x) => !x.notOkToSend && x.follow_up_fl && !x.customer_contact_fl
      )
      reads.forEach(function (x) {
        x.clicked = true
        that.SET_RECORD(x)
      })
    },
    selectAwaiting() {
      var that = this
      this.selectClear()
      var reads = this.data.filter((x) => !x.notOkToSend)
      reads.forEach(function (x) {
        x.clicked = true
        that.SET_RECORD(x)
      })
    },
    selectAllNeedRead() {
      var that = this
      this.selectClear()
      var noReads = this.data.filter(
        (x) => !x.has_meter_readings && !x.notOkToSend
      )
      noReads.forEach(function (x) {
        x.clicked = true
        that.SET_RECORD(x)
      })
    },
    selectAll() {
      var that = this
      this.data.forEach(function (x) {
        x.clicked = true
        that.SET_RECORD(x)
      })
    },
    selectClear() {
      var that = this
      this.data.forEach(function (x) {
        x.clicked = false
        that.SET_RECORD(x)
      })
    },
    sum(data) {
      var total = 0
      for (var i = 0; i < data.length; i++) {
        if (isNaN(data[i].alp)) {
          continue
        }
        total += Number(data[i].alp)
      }
      return total
    },
  },
  watch: {
    "progressBar.jobId": function (newValue) {
      var that = this
      if (newValue !== null) {
        var progress = this.progressBar
        var interval = setInterval(function () {
          Jobs.getDelayedJobQueue("payment_adequacy_follow_up_review")
            .then((job) => {
              progress.stage = job.data[0].progress_stage
              progress.counter = job.data[0].progress_current
              progress.max = job.data[0].progress_max
            })
            .catch(() => {
              progress.jobId = null
              progress.stage = null
              progress.counter = null
              progress.max = null
              clearInterval(interval)
              that.SET_DAYSEVENDATA_STATE(false)
              that.SET_DAYSEVENDATA([])
              that.$store.dispatch("sixMonthAdFollowUp/getDaySevenReviewTotal")
            })
        }, 500)
      }
    },
    // Update in large data set
    editedArrays: {
      handler: function (newValue, oldValue) {
        var index = this.compareArrays(newValue, oldValue)
        if (index !== undefined && newValue[index] === false) {
          var updatedRecord = this.data[index]
          fetchFollowUpTemplates(updatedRecord)
          this.SET_RECORD(updatedRecord)
          PaymentAdequacy.updateRecord(updatedRecord.id, updatedRecord)
        }
      },
    },
    // Update in individual lookup
    lookupEditedArrays: {
      handler: function (newValue, oldValue) {
        var index = this.compareArrays(newValue, oldValue)
        if (index !== undefined && newValue[index] === false) {
          var updatedRecord = this.daySevenLookupResults[index]
          this.SET_RECORD(updatedRecord)
          PaymentAdequacy.updateFollowUpRecord(updatedRecord.id, updatedRecord)
        }
      },
    },
    selected: function (newValue) {
      if (newValue === "selectAllNeedRead") {
        this.selectAllNeedRead()
      } else if (newValue === "selectErroneousReviews") {
        this.selectErroneousReviews()
      } else if (newValue === "selectAwaiting") {
        this.selectAwaiting()
      } else if (newValue === "selectAwaitingUpdated") {
        this.selectAwaitingUpdated()
      } else if (newValue === "selectAwaitingUpdatedNoContact") {
        this.selectAwaitingUpdatedNoContact()
      } else if (newValue === "selectAllLargeChange") {
        this.selectAllLargeChange()
      } else if (newValue === "selectAll") {
        this.selectAll()
      } else if (newValue === "selectClear") {
        this.selectClear()
      }
    },
  },
  created() {
    this.$store.dispatch("sixMonthAdFollowUp/getDaySevenReviewTotal")
    var progress = this.progressBar
    if (progress.jobId === null) {
      Jobs.getDelayedJobQueue("payment_adequacy_follow_up_review")
        .then((response) => {
          if (response.data.length > 0) {
            progress.jobId = response.data[0].id
          }
        })
        .catch(() => {})
    }
  },
  components: {
    PaymentAdequacyFollowUpDescription,
    FollowUpReviewTable,
    headerBlock,
    resultsTable,
    pageSection,
  },
}
</script>
