var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload-core"},[_c('button',{staticClass:"upload-core__header",on:{"click":function($event){return _vm.showToggle()}}},[_c('i',{staticClass:"fas fa-chevron-right",class:{
        'upload-core__arrow-down': _vm.show,
        'upload-core__arrow-up': !_vm.show,
      }}),_c('h2',{staticClass:"upload-core__heading"},[_vm._v("Upload ‘Core’ Customers")])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.show)?_c('div',{staticClass:"upload-core__body"},[_c('p',[_vm._v(" This will add ‘core group’ to the customer’s vulnerabilities in Junifer. ")]),_c('UploadCoreCustomersCSV',{on:{"response":(o) => {
            _vm.$emit('handle-core-accounts-upload', o)
          },"error":(o) => {
            _vm.$emit('handle-core-accounts-upload-error', o)
          }}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }