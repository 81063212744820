<template>
  <div class="import-tariffs">
    <div class="import-tariffs__inner">
      <section class="import-tariffs__header">
        <h2>Import New Fixed Tariffs</h2>
        <p>
          Imports new fixed tariffs to Ark and Nova when tariff updates are made by
          Trading. This will ignore So Flex and So Flex Green tariffs.
        </p>
      </section>
      <section class="import-tariffs__body">
        <div class="import-tariffs__box">
          <p>
            Please ensure new fixed tariffs are set available to the public and old
            tariffs have been ended (set unavailable to the public) before
            importing.
          </p>
          <AppButton
            ref="submitButton"
            variant="secondary"
            type="submit"
            :disabled="pendingImport"
            @click="requestImport()"
          >
            Import New Tariffs
          </AppButton>
          <div v-if="failedImport" class="import-tariffs__failure">
            <i class="fas fa-exclamation-triangle"></i>
            <span> Import failed</span>
            <p ref="errorMessage">{{ errorMessage }}</p>
          </div>
          <div v-if="pendingImport" class="import-tariffs__loading">
            <img src="@/assets/icons/loading-bar.gif" />
          </div>

          <div v-if="successfulImport" class="import-tariffs__success">
            <i class="fas fa-check-circle"></i>
            <span> Import successful!</span>
            <p ref="successMessage">{{ successMessage }}</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ImportTariffsApi from "@/api/ImportTariffs.js"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"

export default {
  name: "ImportTariffs",
  components: {
    AppButton,
  },
  data() {
    return {
      failedImport: false,
      pendingImport: false,
      successfulImport: false,
      errorMessage: "",
      successMessage: "",
    }
  },
  methods: {
    requestImport() {
      this.pendingImport = true
      this.failedImport = false
      this.successfulImport = false

      ImportTariffsApi.importTariffs()
        .then((response) => {
          if (response.data.data.tariff_availability_updated) {
            this.successfulImport = true
            this.successMessage = response.data.data.information
          } else {
            this.failedImport = true
            this.errorMessage = response.data.data.information
          }
        })
        .catch((error) => {
          this.failedImport = true
          if (
            error.response &&
            error.response.data &&
            error.response.data.data[0].message
          ) {
            this.errorMessage = error.response.data.data[0].message
          } else {
            this.errorMessage = "Something went wrong. Try again."
          }
        })
        .finally(() => {
          this.pendingImport = false
          setTimeout(() => {
            this.failedImport = false
            this.successfulImport = false
          }, 5000)
        })
    },
  },
}
</script>

<style lang="scss">
.import-tariffs {
  background-color: $day;
  padding: 0 0 $spacing-8 0;
  min-height: 100vh;

  &__inner {
    min-width: 1024px;
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
  }

  &__header {
    text-align: left;
    padding: $spacing-8 0 $spacing-8;
    position: relative;

    h2 {
      font-size: 50px;
      font-weight: $weight-bold;
      margin-bottom: $spacing-4;
    }

    p {
      font-size: $size-6;
      max-width: 900px;
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
  }

  &__box {
    background-color: $day-dark;
    width: calc(50% - #{$space-4});
    padding: $spacing-7;
    min-height: 100px;
    margin-right: $spacing-4;
    margin-bottom: $spacing-6;

    p {
      text-align: left;
      padding-bottom: $spacing-3;
    }
  }

  &__failure {
    font-size: $size-7;
    line-height: 1.2;
    position: relative;
    margin-top: $spacing-6;

    .fas {
      color: $fire;
    }

    span {
      font-weight: $weight-bold;
      color: $fire;
    }

    p {
      margin: $spacing-2 0 0 0;
      text-align: center;
      padding-bottom: 0;
    }
  }

  &__loading {
    margin: $spacing-6 0 0;
  }

  &__success {
    font-size: $size-7;
    line-height: 1.2;
    position: relative;
    margin-top: $spacing-6;
    background-color: $green-100;
    padding: $spacing-3;

    .fas {
      color: $earth;
    }

    span {
      font-weight: $weight-bold;
      color: $earth;
    }

    p {
      margin: $spacing-2 0 0 0;
      text-align: center;
      padding-bottom: 0;
    }
  }
}
</style>
