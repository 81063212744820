<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Rejected opening electricity readings"
        summary="Sends an email to customers letting them know that their electricity reading has failed validation."
        description="ABC"
      ></header-block>
    </b-row>

    <page-section title="Individual MPAN lookup">
      <template v-slot:content>
        <b-form inline>
          <input
            id="accountsearch"
            v-model="lookupValue"
            type="text"
            placeholder="MPAN ...."
            style="width: 200px"
            class="form-control"
          />
          <span class="input-group-btn">
            <input
              id="search"
              type="button"
              value="search"
              class="btn btn-light custom-button-width light-blue"
              @click="lookupMpan(lookupValue)"
            />
            <input
              id="clear"
              type="button"
              value="clear"
              class="btn btn-light custom-button-width red"
              @click="clear()"
            />
          </span>
        </b-form>

        <b-row v-if="lookupMpanResult !== null" class="ml-5 mb-5 mr-3">
          <b-card
            bg-variant="light"
            class="mr-2 mt-2 d-flex align-items-stretch"
            header-tag="header"
          >
            <template v-slot:header>
              <h6 class="mb-0">
                ID: {{ lookupMpanResult.id }} - Account Number:
                {{ lookupMpanResult.account_number }}
              </h6>
            </template>
            <b-card-text>
              <pre>{{ lookupMpanResult }}</pre>
            </b-card-text>
          </b-card>
        </b-row>
      </template>
    </page-section>

    <page-section title="Bulk action">
      <template v-slot:content>
        <b-button
          v-if="progressBar.jobId === null"
          :disabled="elecResultsIsBusy"
          class="mr-2 mb-2 float-left"
          variant="outline-success"
          @click="importElecReads()"
        >
          <div v-if="elecResultsIsBusy">Loading...</div>
          <div v-else>Import &#8250;</div>
        </b-button>

        <results-table
          :count-box-one="countAwaiting.length"
          :count-box-two="countInvestigate.length"
          :count-box-three="countCompleted.length"
        >
          <div slot="box-one-slot">
            <b-button
              v-show="countAwaiting.length !== 0"
              size="sm"
              variant="outline-success"
              :disabled="elecResultsIsBusy || progressBar.jobId !== null"
              @click="sendAllElecReadEmails()"
            >
              Send all
            </b-button>
          </div>

          <div slot="box-three-slot">
            <b-button
              v-show="countCompleted.length !== 0"
              size="sm"
              variant="outline-danger"
              :disabled="elecResultsIsBusy || progressBar.jobId !== null"
              @click="clearElecReads()"
            >
              Clear all
            </b-button>
          </div>
        </results-table>

        <span v-show="progressBar.jobId !== null">
          Job: {{ progressBar.jobId }} - {{ progressBar.stage }} ({{
            progressBar.counter
          }}/{{ progressBar.max }})
          <b-progress
            v-if="progressBar.jobId !== null"
            b-progress
            :value="progressBar.counter"
            :max="progressBar.max"
            show-progress
            animated
          />
          <br /><br />
        </span>

        <b-table
          v-show="electricityResults.items.length > 0"
          hover
          small
          responsive
          :busy="elecResultsIsBusy || progressBar.jobId !== null"
          :sort-by.sync="electricityResults.sortBy"
          :sort-desc.sync="electricityResults.sortDesc"
          :items="electricityResults.items"
          :fields="electricityResults.fields"
        >
          <div
            v-if="progressBar.jobId === null"
            slot="table-busy"
            class="text-center text-danger my-2"
          >
            <br />
            <br />
            <b-spinner class="align-middle"></b-spinner>
          </div>

          <template v-slot:cell(check_box)="row">
            <div v-show="!row.item.completed">
              <b-form-checkbox v-model="row.item.clicked" switch />
            </div>
          </template>

          <template v-slot:cell(tag)="row">
            <div v-if="row.item.tags !== 0" style="display: inline-block">
              <b-badge
                v-for="tag in row.item.tags"
                :key="tag.text"
                :class="tag.class"
                style="
                  word-wrap: break-word;
                  max-width: 150px;
                  white-space: normal;
                "
                >{{ tag.text }} </b-badge
              >&nbsp;
            </div>
          </template>

          <template v-slot:cell(read_date)="row">
            {{ row.item.read_received_date | formattedDate }}
          </template>

          <template v-slot:cell(first_name)="row">
            {{ row.item.customer_forename }}
          </template>

          <template v-slot:cell(dispute_type)="row">
            <b-badge
              style="
                word-wrap: break-word;
                max-width: 150px;
                white-space: normal;
              "
              >{{ row.item.template }}</b-badge
            >
          </template>

          <template v-slot:cell(show_details)="row">
            <b-button
              size="sm"
              class="mr-2"
              variant="outline-primary"
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? "Hide" : "Show" }}
            </b-button>
          </template>

          <template slot="row-details" slot-scope="row">
            <b-row class="text-left">
              <b-col>
                <b-table striped hover :items="recordData(row)"></b-table>
              </b-col>
              <b-col>
                <b-form-group
                  label-cols-sm="3"
                  label="Template"
                  label-align-sm="left"
                  label-for="template"
                >
                  <b-form-select
                    v-model="row.item.template"
                    :options="templateOptions"
                    :disabled="row.item.completed"
                    @change="updateTemplate(row.item)"
                  />
                </b-form-group>
                <br />
                <p
                  class="border rounded"
                  style="
                    text-align: left;
                    background-color: white;
                    padding: 15px;
                  "
                  v-html="row.item.templateContent"
                ></p>
              </b-col>
            </b-row>
          </template>

          <template v-slot:cell(send_email)="row">
            <div v-if="!row.item.completed">
              <b-button
                size="sm"
                class="mr-2"
                variant="outline-success"
                @click="sendElecReadEmail(row)"
              >
                Process
              </b-button>
            </div>
            <div v-else-if="row.item.completed">
              <b-icon icon="check-circle" variant="success" />
            </div>
          </template>

          <template v-slot:cell(delete)="row">
            <b-button
              size="sm"
              class="mr-2"
              variant="outline-danger"
              @click="softElecDelete(row)"
            >
              Delete
            </b-button>
          </template>
        </b-table>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import dayjs from "dayjs"
import OpeningReadings from "@/api/OpeningReadings"
import Jobs from "@/api/Jobs"
import email from "@/constants/email"
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import resultsTable from "@/components/resultsTable"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

export default {
  data() {
    return {
      lookupValue: null,
      progressBar: {
        jobId: null,
        stage: null,
        counter: null,
        max: null,
      },
      templateOptions: [
        { value: "WithinTolerance", text: "Within Tolerance" },
        { value: "OutofTolerance", text: "Out of Tolerance" },
        { value: "Transposed", text: "Transposed Readings" },
      ],
    }
  },
  created() {
    if (this.electricityResults.items.length === 0) {
      this.$store.dispatch("openingReadings/elecReadings")
    }

    var progress = this.progressBar
    if (progress.jobId === null) {
      Jobs.getDelayedJobQueue("estimated_opening_elec_read")
        .then((response) => {
          if (response.data.length !== 0) {
            progress.jobId = response.data[0].id
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  computed: {
    ...mapGetters("openingReadings", [
      "electricityResults",
      "elecResultsIsBusy",
      "lookupMpanResult",
    ]),
    countAwaiting() {
      return this.electricityResults.items.filter(
        (review) => review.clicked === false && review.completed === false
      )
    },
    countInvestigate() {
      return this.electricityResults.items.filter(
        (review) => review.clicked === true && review.completed === false
      )
    },
    countCompleted() {
      return this.electricityResults.items.filter(
        (review) => review.completed === true
      )
    },
  },
  methods: {
    ...mapActions({
      importElecReads: "openingReadings/importElecReads",
      sendElecReadEmail: "openingReadings/sendElecReadEmail",
      softElecDelete: "openingReadings/softElecDelete",
      clearElecReads: "openingReadings/clearElecReads",
      lookupMpan: "openingReadings/mpanLookup",
    }),
    ...mapMutations("openingReadings", [
      "DELETE_ELEC_ROW",
      "SET_CLICKED_ELEC_REVIEW",
      "SET_LOOKUP_MPAN",
    ]),
    clear() {
      this.lookupValue = null
      this.SET_LOOKUP_MPAN([])
    },
    recordData(row) {
      var records = [
        { type: "Notes", count: row.item.note_count },
        { type: "D71", count: row.item.d71_count },
        { type: "D10", count: row.item.d10_count },
        { type: "D300", count: row.item.d300_count },
        { type: "D301", count: row.item.d301_count },
        { type: "D86", count: row.item.d86_count + 1 },
        { type: "Under Review", count: row.item.under_review },
        { type: "ET", count: row.item.erroneous_transfer_ticket_open },
        { type: "Error", count: row.item.error_reason },
      ]
      return records
    },
    sendAllElecReadEmails() {
      var that = this
      OpeningReadings.sendAllElecReadEmails(this.countAwaiting)
        .then((response) => {
          this.progressBar.jobId = response.data.id
          this.progressBar.counter = response.data.progress_current
          this.progressBar.max = this.countAwaiting.length
          this.progressBar.stage = response.data.progress_stage
        })
        .catch((error) => {
          that.$store.dispatch("openingReadings/elecReadings")
          console.log(error)
        })
    },
    updateTemplate(newValue) {
      var footer = email.footer
      var customer_firstname = newValue.customer_forename
      var customer_read = newValue.customer_read
      var industry_read = newValue.industry_read
      var mpxn = newValue.mpxn
      var account_number = newValue.account_number
      var plural, has_have, plural_an

      if (newValue.industry_read.split(".").length >= 2) {
        plural = "s"
        has_have = "have"
        plural_an = ""
      } else {
        plural = ""
        has_have = "has"
        plural_an = "an "
      }

      if (newValue.template === "OutofTolerance") {
        newValue.templateContent = `<html>
                                    <body>
                                      <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                        Hello ${customer_firstname},
                                        <br>
                                        <br>Account Number: ${account_number}
                                        <br>MPAN: ${mpxn}
                                        <br>
                                        <br>We're contacting you today because your opening electricity reading${plural} ${has_have} failed
                                        <a href='https://www.so.energy/so/help-and-advice/faq/switching-to-us/Opening-Read-Validation' target='_blank'><b>validation</b></a>.
                                        <br>
                                        <br>You provided the following reading${plural}: ${customer_read}
                                        <br>
                                        <br>We have been provided with ${plural_an}estimate${plural} to use instead.
                                        Estimates are given to us by industry based on your meter reading history and estimated energy usage.
                                        <br>
                                        <br>We will be opening your account with ${plural_an}estimated reading${plural}: ${industry_read}.
                                        <br>
                                        <br>
                                        If you are happy with the estimated reading${plural} no action is necessary.
                                        The same estimate${plural} ${has_have} been given to your previous supplier as
                                        your final reading${plural} to be used on your final bill, so you will not be charged twice for any energy.
                                        Their final bill should be with you in the next couple of weeks. Please contact your old supplier
                                        first if you are concerned that this is taking a long time.
                                        <br>
                                        <br><i>Is the opening reading you gave us correct?</i>
                                        <br>
                                        <br>If you think your opening read is correct and this validation process isn't working properly
                                        then we'd love to help out. We can raise a 'dispute', and we can get the opening reading amended for you.
                                        To do so, we will need clear photographs of your electricity meter, showing the full front of the meter
                                        including serial number (a series of letters and/or numbers printed on the meter).
                                        You can just reply to this email with the photographs attached.
                                        <br>
                                        <br>You can also read our handy guide for we've made which explains this process: <a href='https://www.so.energy/so/help-and-advice/faq/switching-to-us/disputing-reads' target='_blank'><b>My opening or closing reading is wrong</b></a>.
                                        <br>
                                        <br>If you have any queries, please do get in touch.
                                      </div>
                                      ${footer}
                                    </body>
                                  </html>`
      } else if (newValue.template === "WithinTolerance") {
        newValue.templateContent = `<html>
                                    <body>
                                        <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                          Hello ${customer_firstname},
                                          <br>
                                          <br>Account Number: ${account_number}
                                          <br>MPAN: ${mpxn}
                                          <br>
                                          <br>We're contacting you today because your opening electricity reading${plural} ${has_have} failed <a href='https://www.so.energy/so/help-and-advice/faq/switching-to-us/Opening-Read-Validation' target='_blank'><b>validation</b></a>.
                                          <br>
                                          <br>You provided the following reading${plural}: ${customer_read}
                                          <br>
                                          <br>We have been provided with ${plural_an}estimate${plural} to use instead.
                                          Estimates are given to us by industry based on your meter reading history and estimated energy usage.
                                          <br>
                                          <br>We will be opening your account with ${plural_an}estimated reading${plural}: ${industry_read}.
                                          <br>
                                          <br>
                                          The same estimate${plural} ${has_have} been given to your previous supplier as
                                          your final reading${plural} to be used on your final bill, so you will not be charged twice for any energy.
                                          Their final bill should be with you in the next couple of weeks. Please contact your old supplier
                                          first if you are concerned that this is taking a long time.
                                          <br>
                                          <br>The estimate${plural} we have been given is very similar to, or 'within tolerance' of the reading you gave us.
                                          This means, as much as we would prefer to use your reading (as we're sure you would too!) the energy industry will not allow
                                          us to dispute the estimate. At this point So Energy and your previous supplier must use this reading for your switch.
                                          The estimate is sensible and could reasonably have been taken around the time of your switch,
                                          and the important thing to remember is that you will not be charged twice for the same energy.
                                          <br>
                                          <br>If you have any queries, please do get in touch.
                                        </div>
                                        ${footer}
                                    </body>
                                  </html>
                                  `
      } else {
        newValue.templateContent = `<html>
                                    <body>
                                      <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                          Hello ${customer_firstname},
                                          <br>
                                          <br>Account Number: ${account_number}
                                          <br>MPAN: ${mpxn}
                                          <br>
                                          <br>We're contacting you today because the opening electricity readings you provided ${customer_read} have failed validation as central industry have deemed the reads to be the wrong way around for Day and Night.
                                          <br>
                                          <br>We will be opening your account with the reads you provided the following way around: <b>${industry_read}</b>.
                                          <br>
                                          <br>Your previous supplier will also generate your final bill to the above reads, so please rest assured, you will not be charged twice for any electricity consumed.
                                          <br>
                                          <br>If you are happy for us to use your opening reads this way around, no action is required.
                                          <br>
                                          <br>If you think your opening read is correct and this validation process isn't working properly then we'd love to help out.
                                          In order to dispute your opening readings you will be required to complete a
                                          <a href='https://www.so.energy/so/help-and-advice/faq/your-meter/how-do-i-know-which-register-is-day-night' target='_blank'><b>Time Test</b></a>.
                                          To complete the Time Test, please take readings from your electricity meter in the middle of the day, and then take more readings a few hours later.
                                          Only one of the readings should have changed and this will be (or should be) the Day reading.
                                          Please take photographs of all meter readings if possible and send them in a reply to this email.
                                          <br>
                                          <br>If you have any queries, please do get in touch.
                                        </div>
                                        ${footer}
                                    </body>
                                  </html>`
      }
      this.SET_CLICKED_ELEC_REVIEW(newValue)
    },
  },
  watch: {
    "progressBar.jobId": function (newValue) {
      if (newValue !== null) {
        var that = this
        var progress = this.progressBar
        var interval = setInterval(function () {
          Jobs.getDelayedJob(newValue)
            .then((job) => {
              if (job.data.last_error != null) {
                clearInterval(interval)
              }
              progress.stage = job.data.progress_stage
              progress.counter = job.data.progress_current
              progress.max = job.data.progress_max
            })
            .catch(() => {
              progress.jobId = null
              progress.stage = null
              progress.counter = null
              progress.max = null
              clearInterval(interval)
              that.$store.dispatch("openingReadings/elecReadings")
            })
        }, 1000)
      }
    },
  },
  filters: {
    formattedDate: function (date) {
      return dayjs(date).format("Do MMM YY")
    },
  },
  components: { headerBlock, pageSection, resultsTable },
}
</script>
