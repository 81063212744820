<template>
  <div v-if="customer" class="rejected-dropdown">
    <div class="rejected-dropdown__flex-box">
      <div class="rejected-dropdown__left-column">
        <section class="rejected-dropdown__information">
          <h3>Customer Information</h3>
          <ul>
            <li>
              Account Number:
              <span>
                {{ customer.account_number }}
              </span>
            </li>
            <li>
              Email Address:
              <span>
                {{ customer.email }}
              </span>
            </li>
          </ul>
        </section>

        <section class="rejected-dropdown__flags">
          <h3>Flags on Account</h3>
          <ul class="rejected-dropdown__flags-list">
            <li>
              Notes
              <strong>{{ customer.note_count }}</strong>
            </li>
            <li v-if="customer.d10_count">
              D10
              <strong>{{ customer.d10_count }}</strong>
            </li>
            <li v-if="customer.d71_count">
              D71
              <strong>{{ customer.d71_count }}</strong>
            </li>
            <li v-if="customer.d86_count">
              D86
              <strong>{{ customer.d86_count }}</strong>
            </li>
            <li v-if="customer.d300_count">
              D300
              <strong>{{ customer.d300_count }}</strong>
            </li>
            <li v-if="customer.d301_count">
              D301
              <strong>{{ customer.d301_count }}</strong>
            </li>
            <li v-if="customer.bill_disp_read_tickets_open">
              Disputed Read Ticket Open
              <strong>{{ customer.bill_disp_read_tickets_open }}</strong>
            </li>
            <li v-if="customer.sar_count">
              SAR
              <strong>{{ customer.sar_count }}</strong>
            </li>
            <li v-if="customer.next_step === 'Investigate'">
              Next Steps
              <strong>{{ customer.next_step }}</strong>
            </li>
            <li v-if="customer.under_review">
              Under Review
              <strong>{{ customer.under_review }}</strong>
            </li>
            <li v-if="customer.erroneous_transfer_ticket_open">
              ET
              <strong>{{ customer.erroneous_transfer_ticket_open }}</strong>
            </li>
            <li v-if="customer.under_review">
              Under Review
              <strong>{{ customer.under_review }}</strong>
            </li>
            <li v-if="customer.error_reason">
              Error
              <strong>{{ customer.error_reason }}</strong>
            </li>
          </ul>
        </section>

        <section class="rejected-dropdown__flags">
          <h3>Other Account Information</h3>
          <ul>
            <template v-if="customer.units">
              <li v-if="customer.units === 'M'">
                Units
                <strong>m<sup>3</sup></strong>
              </li>
              <li v-else>
                Units
                <strong>100'sft<sup>3</sup></strong>
              </li>
            </template>
            <li v-if="customer.meter_serial_number">
              Meter Serial Number
              <strong>{{ customer.meter_serial_number }}</strong>
            </li>
          </ul>
        </section>
        <section class="rejected-dropdown__information">
          <h3>Reading Difference</h3>
          <ul>
            <li>
              Customer:
              <span v-if="customer.customer_read">
                {{ customer.customer_read }}
              </span>
              <span v-else>
                {{ customer.urs_read }}
              </span>
            </li>
            <li>
              Industry:
              <span v-if="customer.industry_read">
                {{ customer.industry_read }}
              </span>
              <span v-else>
                {{ customer.mbr_read }}
              </span>
            </li>
            <li>
              Difference:
              <span v-if="customer.read_difference">
                {{ customer.read_difference }}
              </span>
              <span v-else>
                {{ getGasDifference }}
              </span>
            </li>
          </ul>
        </section>
      </div>

      <div class="rejected-dropdown__right-column">
        <DropdownSelection
          dropdown-type="filter"
          dropdown-name="criteria1"
          :options="type === 'gas' ? gasTemplates : elecTemplates"
          :set-selected="customer.template"
          :has-placeholder="false"
          @dropdownChange="updateTemplate($event, customer)"
        ></DropdownSelection>

        <section class="rejected-dropdown__email-preview">
          <div v-html="templateContent"></div>
        </section>
      </div>
    </div>
    <div class="rejected-dropdown__button-footer">
      <button class="button button--red-outlined" @click="deleteCustomerModal">
        <i class="fas fa-trash button--icon"></i>
        Delete Customer
      </button>
      <DeleteCustomerModal
        :show="showDeleteCustomerModal"
        :customer="customer"
        @deleteCustomer="deleteCustomer"
        @close="closeDeleteCustomerModal"
      ></DeleteCustomerModal>
    </div>
  </div>
</template>

<script>
import DeleteCustomerModal from "@/components/Operations/DeleteCustomerModal"
import DropdownSelection from "@/components/warmHomeDiscount/DropdownSelection"
import emailTemplates from "@/helpers/rejectedReadings/rejectedReadingsTemplate"

export default {
  components: {
    DeleteCustomerModal,
    DropdownSelection,
  },
  props: {
    customer: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showDeleteCustomerModal: false,
      templateContent: "",
      elecTemplates: [
        { value: "WithinTolerance", text: "Within Tolerance" },
        { value: "OutofTolerance", text: "Out of Tolerance" },
        { value: "Transposed", text: "Transposed Readings" },
      ],
      gasTemplates: [
        { value: "WithinTolerance", text: "Within Tolerance" },
        { value: "OutofTolerance", text: "Out of Tolerance" },
      ],
    }
  },
  computed: {
    getGasDifference() {
      const difference = this.customer.mbr_read - this.customer.urs_read
      return difference
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.updateTemplate({ value: this.customer.template }, this.customer)
    })
  },
  methods: {
    deleteCustomerModal() {
      this.showDeleteCustomerModal = true
    },
    closeDeleteCustomerModal() {
      this.showDeleteCustomerModal = false
    },
    deleteCustomer(customerId) {
      this.$emit("deleteCustomer", customerId)
      this.showDeleteCustomerModal = false
    },
    updateTemplate(event, customer) {
      let isPlural = customer.industry_read
        ? customer.industry_read.split(".").length >= 2
        : false

      let data = {
        customer_firstname: customer.customer_forename,
        customer_read:
          this.type === "gas" ? customer.urs_read : customer.customer_read,
        industry_read:
          this.type === "gas" ? customer.mbr_read : customer.industry_read,
        mpxn: customer.mpxn,
        mprn: customer.mprn,
        account_number: customer.account_number,
        plural: isPlural ? "s" : "",
        has_have: isPlural ? "have" : "has",
        plural_an: isPlural ? "" : "an ",
        unit:
          customer.units === "M" ? "(m<sup>3</sup>)" : "(100'sft<sup>3</sup>)",
      }
      let htmlTemplate = ""

      if (event.value === "WithinTolerance" && this.type === "gas") {
        htmlTemplate = emailTemplates.getGasWithinTolerance(data)
      } else if (event.value === "OutofTolerance" && this.type === "gas") {
        htmlTemplate = emailTemplates.getGasOutofTolerance(data)
      } else if (event.value === "WithinTolerance") {
        htmlTemplate = emailTemplates.getElecWithinTolerance(data)
      } else if (event.value === "OutofTolerance") {
        htmlTemplate = emailTemplates.getElecOutofTolerance(data)
      } else {
        htmlTemplate = emailTemplates.getElecTransposed(data)
      }
      // save data locally for visible update
      this.templateContent = htmlTemplate
      // save data to store
      customer.templateContent = htmlTemplate
      this.$store.commit("openingRejectedReadings/UPDATE_ACCOUNT", {
        customer,
        type: this.type,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.rejected-dropdown {
  border-bottom: 1px solid $night-light-2;

  &__flex-box {
    display: flex;
  }

  section {
    padding-bottom: $spacing-5;

    h3 {
      font-weight: $weight-bold;
      font-size: $size-7;
      margin-bottom: $spacing-2;
      color: $night-light;
    }

    ul {
      margin-bottom: 0;
      list-style: none;

      li {
        line-height: 2;

        span {
          font-weight: $weight-medium;
          text-align: center;
          display: block;
          border-radius: 4px;
          margin-top: $spacing-1;
          background: $day;
          color: $night-light;
        }
      }
    }
  }

  &__left-column {
    flex: 0 0 50%;
  }

  &__right-column {
    flex: 0 0 50%;
    padding: 0 0 0 $spacing-6;

    .dropdown-selection__wrapper--filter {
      margin-left: 0;
    }
  }

  &__button-footer {
    .button {
      float: right;

      &--icon {
        font-size: 12px;
        margin-right: $spacing-1;
      }
    }
  }

  &__information {
    ul li {
      font-weight: $weight-bold;
      color: $night-light;
    }
  }

  &__reading-difference {
    ul {
      display: flex;

      li {
        flex: 0 0 31%;
        margin-right: 0px;
        font-weight: $weight-bold;
      }
    }
  }

  &__flags {
    ul li {
      padding: $spacing-1 $spacing-5;
      font-weight: $weight-medium;

      strong {
        float: right;
      }

      &:nth-child(odd) {
        border-radius: 4px;
        background: $day;
      }
    }
  }

  &__email-preview {
    border: 1px solid $night-light-2;
    max-height: 600px;
    margin-bottom: $spacing-5;
    overflow-y: scroll;
  }
}
</style>
