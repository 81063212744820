import AuthenticationContext from "adal-angular/lib/adal.js"

const redirectUrl = () => {
  const env = process.env.VUE_APP_VERCEL_ENV
  const url = process.env.VUE_APP_VERCEL_URL
  const branch = process.env.VUE_APP_VERCEL_GIT_COMMIT_REF
  const branches = ["sandbox", "staging", "develop"] // TODO: remove develop once deprecated

  if (!url || env === "production" || branches.includes(branch)) {
    return process.env.VUE_APP_BASE
  } else {
    return "https://" + url
  }
}

const config = {
  tenant: "2fd125f7-d450-4fdb-986d-a48296918cbb",
  clientId: "919921f4-8746-41d3-8224-3b99a7ddff90",
  redirectUri: redirectUrl(),
  cacheLocation: "localStorage",
}
export default {
  authenticationContext: null,
  /**
   * @return {Promise}
   */
  initialize() {
    this.authenticationContext = new AuthenticationContext(config)

    return new Promise((resolve, reject) => {
      if (
        this.authenticationContext.isCallback(window.location.hash) ||
        window.self !== window.top
      ) {
        this.authenticationContext.handleWindowCallback()
      } else {
        let user = this.authenticationContext.getCachedUser()
        if (user) {
          resolve()
        } else {
          this.signIn()
        }
      }
    })
  },
  /**
   * @return {Promise.<String>}
   */
  acquireToken() {
    return new Promise((resolve, reject) => {
      this.authenticationContext.acquireToken(
        config.clientId,
        (error, token) => {
          if (error || !token) {
            return reject(error)
          } else {
            return resolve(token)
          }
        }
      )
    })
  },
  acquireTokenRedirect() {
    this.authenticationContext.acquireTokenRedirect(config.clientId)
  },
  /**
   * @return {Boolean}
   */
  isAuthenticated() {
    if (this.authenticationContext.getCachedToken(config.clientId)) {
      return true
    }
    return false
  },
  /**
   * @return
   */
  getUserProfile() {
    return this.authenticationContext.getCachedUser().profile
  },
  signIn() {
    this.authenticationContext.login()
  },
  signOut() {
    this.authenticationContext.logOut()
  },
}
