<template>
  <div class="sidebar-card" :class="colorClass">
    <div class="sidebar-card__header">
      <slot name="header"></slot>
    </div>
    <div class="sidebar-card__body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colorSelect: {
      type: String,
      default: "",
    },
  },
  computed: {
    colorClass() {
      return this.colorSelect ? "sidebar-card--" + this.colorSelect : ""
    },
  },
}
</script>

<style lang="scss">
.sidebar-card {
  text-align: left;
  box-shadow: $box-shadow;
  margin-bottom: $spacing-6;

  &__header {
    padding: $spacing-3 $spacing-4;
    font-weight: $weight-bold;
    font-size: $size-7;
    background: $blue-50;

    &--icon {
      font-size: 11px;
      border-radius: 50%;
      margin-right: $spacing-1;
      padding: $spacing-1;
    }
  }

  &__body {
    padding: $spacing-5;
    background-color: $day;
  }
}

.sidebar-card--orange {
  .sidebar-card__header {
    background: $yellow-50;

    &--icon {
      color: $yellow-50;
      background: $yellow-500;
    }
  }
}

.sidebar-card--blue {
  .sidebar-card__header {
    background: $blue-100;

    &--icon {
      color: $blue-100;
      background: $water;
    }
  }
}
</style>
