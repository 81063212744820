import OpeningReadings from "@/api/OpeningReadings"
import { validatedReadingCompile } from "@/helpers/readings/validatedReadings"

const state = {
  accountLookup: [],
  validatedReadingAccount: [],
  emailTemplateName: "All opening readings validated",
  isBusy: true,
  noteCountCheck: 5,
}

const mutations = {
  SET_LOOKUP_ACCOUNT(state, data) {
    state.accountLookup = data
  },
  SET_ALL_ACCOUNTS(state, data) {
    state.validatedReadingAccount = data
  },
  SET_BUSY_STATE(state, data) {
    state.isBusy = data
  },
  SET_ROW(state, data) {
    var index = state.validatedReadingAccount.findIndex((s) => s.id == data.id)
    state.validatedReadingAccount[index] = data
  },
  SET_OPENING_READS(state, data) {
    var index = state.validatedReadingAccount.findIndex((s) => s.id == data.id)
    state.validatedReadingAccount[index].opening_readings =
      data.opening_readings
  },
  DELETE_ROW(state, data) {
    var index = state.validatedReadingAccount.findIndex((s) => s.id == data.id)
    state.validatedReadingAccount.splice(index, 1)
  },
}
const getters = {
  taskScheduler: (state) => {
    return state.taskSchedule
  },
  lookupResults: (state) => {
    return state.accountLookup
  },
  noteCountCheck: (state) => {
    return state.noteCountCheck
  },
  validatedAccountsTable: (state) => {
    return {
      sortBy: "Status",
      sortDesc: false,
      fields: [
        { key: "check_box", label: "", sortable: false },
        { key: "tags", label: "", sortable: false },
        { key: "account_number", label: "Account Number", sortable: false },
        {
          key: "account_start_date",
          label: "Account Start Date",
          sortable: true,
        },
        { key: "mprn", label: "MPRN", sortable: false },
        { key: "mpan", label: "MPAN", sortable: false },
        { key: "email", label: "Email", sortable: false },
        { key: "customer_forename", label: "Name", sortable: false },
        { key: "refresh", label: "", sortable: false },
        { key: "show_details", label: "", sortable: false },
        { key: "send_email", label: "", sortable: false },
        { key: "delete", label: "", sortable: false },
      ],
      items: state.validatedReadingAccount,
    }
  },
  validatedAccounts: (state) => {
    return state.validatedReadingAccount
  },
  isBusy: (state) => {
    return state.isBusy
  },
}
const setters = {}
const actions = {
  refresh: function (context, data) {
    OpeningReadings.refreshValidatedReading(data.id)
      .then((response) => {
        var x = response.data
        if (x.note_count > state.noteCountCheck) {
          var z = { text: x.note_count + " notes", class: "danger" }
          x.tags.push(z)
          x.clicked = true
        }
        x.opening_readings.forEach(function (z) {
          if (z.opening_reading.length === 0) {
            var y = {
              text: "No opening reading for " + z.identifier,
              class: "danger",
            }
            x.tags.push(y)
            x.clicked = true
          }
        })
        context.commit("SET_ROW", x)
      })
      .catch((error) => {
        console.log(error.response)
      })
  },
  deleteRow: function (context, data) {
    OpeningReadings.deleteValidatedReading(data.id)
      .then((response) => {
        context.commit("DELETE_ROW", data)
      })
      .catch((error) => {
        console.log(error.response)
      })
  },
  deleteAll: function (context) {
    var completedReviews = state.validatedReadingAccount.filter(
      (x) => x.completed_fl === true
    )
    var completedIds = []
    completedReviews.forEach(function (x) {
      completedIds.push(x.id)
      context.commit("DELETE_ROW", x)
    })
    OpeningReadings.deleteValidatedReadingAll(completedIds)
  },
  sendEmail: function (context, data) {
    OpeningReadings.sendValidatedReadsEmail(data.item.id, data.item)
      .then((response) => {
        data.item.completed_fl = true
        context.commit("SET_LOOKUP_ACCOUNT", data)
      })
      .catch((error) => {
        console.log(error.response)
      })
  },
  getAccounts(context) {
    OpeningReadings.getValidatedReadings()
      .then((response) => {
        response.data.forEach(function (x) {
          x.email_template = state.emailTemplateName
          x.clicked = false
          x.tags = []
          x = validatedReadingCompile(x)

          if (x.note_count > state.noteCountCheck) {
            var z = { text: x.note_count + " notes", class: "danger" }
            x.tags.push(z)
            x.clicked = true
          }

          x.opening_readings.forEach(function (z) {
            if (z.opening_reading.length === 0) {
              var y = {
                text: "No opening reading for " + z.identifier,
                class: "danger",
              }
              x.tags.push(y)
              x.clicked = true
            }
          })
        })
        console.log(response.data)

        context.commit("SET_ALL_ACCOUNTS", response.data)
        context.commit("SET_BUSY_STATE", false)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  lookupAccount: function (context, searchProperty) {
    OpeningReadings.lookupValidatedReadEmail(searchProperty)
      .then((response) => {
        response.data.forEach(function (z) {
          z = validatedReadingCompile(z)
        })
        console.log(response.data)
        context.commit("SET_LOOKUP_ACCOUNT", response.data)
      })
      .catch((error) => {
        console.log(error.response)
      })
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  setters,
  state,
}
