<template>
  <UploadCsv :parser="parser" :api-call="apiCall()" v-on="$listeners">
    <div v-for="checkbox in checkboxes" :key="checkbox.text" class="checkbox">
      <label class="checkbox-label" :for="checkbox.text">
        {{ checkbox.text }}
      </label>
      <input
        :id="checkbox.text"
        v-model="checkbox.selected"
        type="checkbox"
        :name="checkbox.text"
        class="checkbox-input"
        @change="selectCheckbox"
      />
    </div>
  </UploadCsv>
</template>

<script>
import SmartMeterApi from "@/api/SmartMeter.js"
import { headlessCsvToArrayOfStrings } from "@/helpers/csv/parsers"
import UploadCsv from "../csv/UploadCsv.vue"

export default {
  components: {
    UploadCsv,
  },
  data() {
    return {
      parser: headlessCsvToArrayOfStrings,
      checkboxes: [
        {
          text: "Add new ineligible customer",
          selected: false,
          endpoint: "addAccountsIneligible",
        },
        {
          text: "Restart customer campaign",
          selected: false,
          endpoint: "restart",
        },
        {
          text: "Mark existing customer ineligible",
          selected: false,
          endpoint: "markIneligible",
        },
        {
          text: "Mark existing customer eligible",
          selected: false,
          endpoint: "markEligible",
        },
      ],
    }
  },
  methods: {
    apiCall() {
      const endpointName = this.getEndpoint() // Retrieved here to avoid scoping change.
      return (parsedValuesFromCsv) => {
        return SmartMeterApi[endpointName]({
          junifer_account_numbers: parsedValuesFromCsv,
        })
      }
    },
    getEndpoint() {
      const selectedCheckbox = this.checkboxes.find((o) => o.selected)
      if (selectedCheckbox) {
        return selectedCheckbox.endpoint
      }
      return "addAccounts"
    },
    selectCheckbox(e) {
      const targetCheckbox = this.checkboxes.find(
        (o) => o.text === e.target.name
      )
      const targetCheckboxShouldBe = targetCheckbox.selected
      this.checkboxes.forEach((o) => (o.selected = false))
      targetCheckbox.selected = targetCheckboxShouldBe
    },
  },
}
</script>

<style lang="scss" scoped>
.checkbox {
  margin-bottom: $spacing-5;
}

.checkbox-label {
  font-size: 14px;
  line-height: 1.2;
  font-weight: $weight-bold;
  color: $night-light;
  margin: 0 $spacing-2 0 0;

  &:hover {
    cursor: pointer;
  }
}

.checkbox-input {
  width: 15px;
  height: 15px;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
}
</style>
