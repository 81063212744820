<template>
  <div class="account-details">
    <div
      v-if="warning === 'failed'"
      class="account-details__message account-details__message--orange"
    >
      <h6>
        <i class="fas fa-exclamation-triangle"></i>
        <span>Installation Failed:</span>
        {{ warningMessage }}
      </h6>
    </div>
    <div
      v-if="warning === 'ineligible'"
      class="account-details__message account-details__message--red"
    >
      <h6>
        <i class="fas fa-exclamation-triangle"></i>
        <span>Ineligibility:</span>
        {{ warningMessage }}
      </h6>
    </div>
    <div class="account-details__row">
      <div class="account-details__row-item">
        <span>Job Number: </span>
        {{ account.booking_status.job_number }}
      </div>
      <div class="account-details__row-item">
        <span>Timeslot: </span>
        {{ timeSlot }}
      </div>
      <div class="account-details__row-item">
        <span>Date: </span>
        {{ installDate }}
      </div>
    </div>
    <div class="account-details__separator">
      <span> Person at Home </span>
      <hr />
    </div>
    <div class="account-details__row">
      <div class="account-details__row-item">
        <span>Name: </span>
        {{ account.customer_details.name }}
      </div>
      <div class="account-details__row-item">
        <span>Number: </span>
        {{ account.customer_details.phone }}
      </div>
      <div class="account-details__row-item">
        <span>Email: </span>
        {{ account.customer_details.email }}
      </div>
    </div>
    <div class="account-details__separator">
      <span> Meter Information </span>
      <hr />
    </div>
    <div class="account-details__row">
      <div class="account-details__row-item">
        <span>10 meters apart: </span>
        {{ account.meter_info.ten_meters_apart }}
      </div>
      <div class="account-details__row-item">
        <span>High off ground: </span>
        {{ account.meter_info.high_off_ground }}
      </div>
      <div class="account-details__row-item">
        <span>Obstructions: </span>
        {{ account.meter_info.obstruction }}
      </div>
      <div class="account-details__row-item">
        <span>Limited space: </span>
        {{ account.meter_info.limited_space }}
      </div>
      <div class="account-details__row-item">
        <span>Sloped lid meter box: </span>
        {{ account.meter_info.outside }}
      </div>
    </div>
    <p
      v-if="account.meter_info.meter_access_info"
      class="account-details__text"
    >
      <span>Meter access info: </span>
      {{ account.meter_info.meter_access_info }}
    </p>
    <p
      v-if="account.meter_info.additional_meter_info"
      class="account-details__text"
    >
      <span>Additional meter info: </span>
      {{ account.meter_info.additional_meter_info }}
    </p>
    <div class="account-details__separator">
      <span> Additional Information </span>
      <hr />
    </div>
    <p class="account-details__text">
      <span>Needs medical equipment: </span>
      {{ account.additional_info.needs_medical_equipment }}
    </p>
    <p
      v-if="account.additional_info.medical_equipment_information"
      class="account-details__text"
    >
      <span>Equipment information: </span>
      {{ account.additional_info.medical_equipment_information }}
    </p>
    <p
      v-if="account.additional_info.additional_needs"
      class="account-details__text"
    >
      <span>Additional needs: </span>
      {{ account.additional_info.additional_needs }}
    </p>

    <div class="account-details__button-container">
      <AppButton
        class="account-details__button"
        @click="$emit('make-new-booking', account.junifer_account_number)"
      >
        Make a new booking
      </AppButton>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"

export default {
  components: { AppButton },
  props: {
    account: {
      type: Object,
      default: () => {},
    },
    warning: {
      type: String,
      default: "",
    },
  },
  computed: {
    warningMessage() {
      let elecCode =
        this.account.booking_status.work_completion.electric_reason_code
      let gasCode = this.account.booking_status.work_completion.gas_reason_code
      let isKnown = elecCode || gasCode
      let elecText = elecCode ? "Elec code: " + elecCode : ""
      let gasText = gasCode ? "Gas code: " + gasCode : ""
      let message = isKnown
        ? elecText + (elecCode && gasCode ? ", " : "") + gasText
        : "Unkown reason."
      return message
    },
    timeSlot() {
      let times = ""
      let slot = this.account.booking_status.slot
      if (slot) {
        times = slot.start_time.slice(0, 5) + "-" + slot.end_time.slice(0, 5)
      }
      return times
    },
    installDate() {
      let date = ""
      let slot = this.account.booking_status.slot
      if (slot) {
        date = dayjs(slot.date).format("DD-MM-YYYY")
      }
      return date
    },
  },
}
</script>

<style lang="scss" scoped>
.account-details {
  &__row {
    display: flex;
    padding: 0 $spacing-4 $spacing-2;
    flex-wrap: wrap;
  }
  &__row-item {
    flex: 0 0 33.33%;
    margin-bottom: $spacing-4;

    span {
      font-weight: $weight-bold;
    }
  }

  &__separator {
    position: relative;
    margin-bottom: $spacing-5;

    span {
      position: absolute;
      top: calc(50% - 12px);
      left: 0;
      font-size: $size-7;
      color: $night-light-2;
      font-weight: $weight-bold;
      display: inline-block;
      background-color: $day;
      padding: 0 $spacing-2 0 0;
    }

    hr {
      margin: $spacing-4;
      border: 1px solid $night-light-2;
    }
  }

  &__text {
    padding: 0 $spacing-4 $spacing-5;
    margin: 0;

    span {
      font-weight: $weight-bold;
    }
  }

  &__message {
    padding: $spacing-5;
    margin: 0 $spacing-4 $spacing-5 0;
    border-radius: 4px;

    &--red {
      background-color: $red-50;

      .fas {
        color: $fire;
      }
    }

    &--orange {
      background-color: $yellow-50;

      .fas {
        color: $yellow-500;
      }
    }

    .fas {
      margin-right: $spacing-2;
      font-size: $size-7;
    }

    span {
      font-weight: $weight-bold;
    }

    p {
      padding-left: $spacing-6;
      margin: 0;
    }
  }

  &__button-container {
    text-align: right;
    padding-right: $spacing-4;
    margin-bottom: $spacing-4;
  }
}
</style>
