import apiArk from "./Ark"

export default {
  getTickets(account_id) {
    return apiArk.get("accounts/" + account_id + "/tickets")
  },
  getTransactions(account_number) {
    return apiArk.get("accounts/" + account_number + "/account_transactions")
  },
  getAccountBalance(account_number) {
    return apiArk.get("accounts/" + account_number + "/balance")
  },
  getPayments(account_number) {
    return apiArk.get("accounts/" + account_number + "/payments")
  },
  getAccountDetails(account_number) {
    return apiArk.get("accounts/" + account_number + "/details")
  },
  renewAgreements(account_number, payload) {
    return apiArk.post("accounts/" + account_number + "/renew", { payload })
  },
  updatePayment(account_number, payload) {
    return apiArk.post("accounts/" + account_number + "/payments", { payload })
  },
  getMeterpoints(account_number) {
    return apiArk.get("accounts/" + account_number + "/meterpoint_info")
  },
  getAgreements(account_number) {
    return apiArk.get("accounts/" + account_number + "/agreements")
  },
  getCustomer(account_number) {
    return apiArk.get("accounts/" + account_number + "/customer_details")
  },
  getNotes(account_id) {
    return apiArk.get("accounts/" + account_id + "/notes")
  },
  syncFreshdesk(account_number) {
    return apiArk.post("accounts/" + account_number + "/freshdesk_sync", {
      account_number,
    })
  },
  getAccountsWithoutLossConfirmation() {
    return apiArk.get("accounts/without_loss_confirmation")
  },
  closeLossConfirmationReview(account_number) {
    return apiArk.post(
      "accounts/without_loss_confirmation/" + account_number + "/close"
    )
  },
  getMeterpointSupplyStatus(accountNumber) {
    return apiArk.get("accounts/" + accountNumber + "/meterpoint_status")
  },
  getUploadedImages(account_id) {
    return apiArk.get(
      "accounts/" + account_id + "/warm_home_discount/psr/images"
    )
  },
}
