export function gasChecks(e) {
  try {
    //Add tags
    e.tags = []
    var tag

    if (e.bill_disp_read_tickets_open > 2) {
      tag = new Object()
      tag.text = e.bill_disp_read_tickets_open + " Bill Disputed Read Tickets"
      tag.class = "badge-warning"
      e.tags.push(tag)
    }
    if (e.sar_count >= 1) {
      tag = new Object()
      tag.text = e.sar_count + " SAR"
      tag.class = "badge-warning"
      e.tags.push(tag)
    }
    if (e.ret_count > 2) {
      tag = new Object()
      tag.text = e.ret_count + " RET"
      tag.class = "badge-warning"
      e.tags.push(tag)
    }
    if (e.next_step === "Investigate") {
      tag = new Object()
      tag.text = e.next_step + " - Next Step"
      tag.class = "badge-warning"
      e.tags.push(tag)
    }
    if (e.note_count > 2) {
      tag = new Object()
      tag.text = e.note_count + " Notes"
      tag.class = "badge-warning"
      e.tags.push(tag)
    }
    if (e.erroneous_transfer_ticket_open === true) {
      tag = new Object()
      tag.text = "ET Open"
      tag.class = "badge-danger"
      e.tags.push(tag)
    }
    if (e.under_review != false) {
      tag = new Object()
      tag.text = "Under Review"
      tag.class = "badge-danger"
      e.tags.push(tag)
    }
    if (e.error_reason != null) {
      tag = new Object()
      tag.text = "Error! " + e.error_reason
      tag.class = "badge-danger"
      e.tags.push(tag)
    }
    if (e.tags.length === 0) {
      e.clicked = false
    } else {
      e.clicked = true
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
