<template>
  <b-container fluid>
    <b-row class="ml-5 mr-3">
      <header-block
        title="Validated opening readings"
        summary="Sends an email to customers letting them know that their previous supplier now has their opening readings"
      >
        <template v-slot:description-body>
          <h5>Import settings</h5>
          <ul>
            <li>
              Imports all accounts where all meterpoints have had validated
              readings on the account
            </li>
            <li>
              Only accounts that have been created and their SSD is within the
              last 3 months
            </li>
            <li>MBR / D86 received date is less thanb 5 days ago</li>
            <li>No accounts under review</li>
            <li>
              Only 1 record per each unique combination of opening readings and
              account number
            </li>
            <li>SQL Stored Procedure: 'sp_openingReadvalidation'</li>
          </ul>
          <h5>Bulk tool features</h5>
          <ul>
            <li>
              If the Junifer account has more than {{ noteCountCheck }} notes on
              Junifer it will be flagged up for manual review
            </li>
            <li>
              Refresh button - Refreshes the opening readings due to be sent are
              the same as in Junifer
            </li>
            <li>
              Process button - sends an email will to the primary customer via
              Mandrill and adds a note to Junifer
            </li>
            <li>
              Once the record has been either reviewed and decided it is no
              longer appropriate or email has been sent the record can be
              deleted
            </li>
          </ul>
          <h5>Individual lookup</h5>
          <ul>
            <li>Fetches any record, either completed, deleted or awaiting</li>
          </ul>
          <i
            >For more information see the user guide:
            <a
              href="https://soenergygroup.sharepoint.com/:w:/g/Marketing_Tech/EZnCh2OU_ylJq1kbtbKJk5cBADnyukB7P7B05biOPmZkFA?e=RhxwLY"
              target="_blank"
              >ARK Guide: Validated Opening Readings</a
            ></i
          >
        </template>
      </header-block>
    </b-row>

    <page-section title="Individual account lookup">
      <template v-slot:content>
        <b-form inline>
          <input
            id="accountsearch"
            v-model="lookupValue"
            type="text"
            placeholder="Account or email ...."
            style="width: 200px"
            class="form-control"
          />
          <span class="input-group-btn">
            <input
              id="search"
              type="button"
              value="search"
              class="btn btn-light custom-button-width light-blue"
              @click="lookupAccount(lookupValue)"
            />
            <input
              id="clear"
              type="button"
              value="clear"
              class="btn btn-light custom-button-width red"
              @click="clear()"
            />
          </span>
        </b-form>

        <b-row v-show="lookupResults.length > 0" class="ml-5 mb-5">
          <div v-for="result in lookupResults" :key="result.id">
            <b-card
              bg-variant="light"
              class="mr-2 mt-2 d-flex align-items-stretch"
              header-tag="header"
            >
              <template v-slot:header>
                <h6 class="mb-0">
                  ID: {{ result.id }} - Account Number:
                  {{ result.account_number }} - Email: {{ result.email }}
                  {{ result.completed_fl }} {{ result.deleted_fl }}
                </h6>
              </template>

              <b-card-text>
                <table>
                  <tr>
                    <td>Email:</td>
                    <td>First Name: {{ result.customer_forename }}</td>
                    <td>Note Count: {{ result.note_count }}</td>
                  </tr>
                  <tr>
                    <td colspan="3">Readings: {{ result.reading }}</td>
                  </tr>
                  <tr>
                    <td>Error: {{ result.error_reason }}</td>
                    <td>Updated: {{ result.updated_at }}</td>
                    <td>Created: {{ result.created_at }}</td>
                  </tr>
                </table>
              </b-card-text>
            </b-card>
          </div>
        </b-row>
      </template>
    </page-section>

    <page-section title="Bulk action">
      <template v-slot:content>
        <b-button
          v-if="progressBar.jobId === null"
          :disabled="isBusy"
          class="mt-2 mb-2 float-left"
          variant="outline-success"
          @click="importAccounts()"
        >
          <div v-if="isBusy">Loading...</div>
          <div v-else>Import &#8250;</div>
        </b-button>

        <results-table
          :count-box-one="countAwaiting.length"
          :count-box-two="countInvestigate.length"
          :count-box-three="countCompleted.length"
        >
          <div slot="box-one-slot">
            <b-button
              v-show="countAwaiting.length !== 0"
              size="sm"
              variant="outline-light"
              :disabled="isBusy || progressBar.jobId !== null"
              @click="sendAllEmails()"
            >
              Send all
            </b-button>
          </div>

          <div slot="box-two-slot">
            Check if more than {{ noteCountCheck }} notes on Junifer account
          </div>

          <div slot="box-three-slot">
            <b-button
              v-show="countCompleted.length !== 0"
              size="sm"
              variant="outline-light"
              :disabled="isBusy || progressBar.jobId !== null"
              @click="clearCompleted()"
            >
              Clear all
            </b-button>
          </div>
        </results-table>

        <span v-show="progressBar.jobId !== null">
          Job: {{ progressBar.jobId }} - {{ progressBar.stage }} ({{
            progressBar.counter
          }}/{{ progressBar.max }})
          <b-progress
            v-if="progressBar.jobId !== null"
            b-progress
            :value="progressBar.counter"
            :max="progressBar.max"
            show-progress
            animated
          />
          <br /><br />
        </span>

        <b-table
          v-if="
            progressBar.jobId === null &&
            validatedAccountsTable.items.length > 0
          "
          hover
          small
          responsive
          class="mt-2"
          :busy="isBusy || progressBar.jobId !== null"
          :sort-by.sync="validatedAccountsTable.sortBy"
          :sort-desc.sync="validatedAccountsTable.sortDesc"
          :items="validatedAccountsTable.items"
          :fields="validatedAccountsTable.fields"
        >
          <template slot="show" slot-scope="row">
            <b-button
              variant="outline-primary"
              @click="fetchCompleteRecord(row.item)"
            >
              {{ row.detailsShowing ? "Hide" : "Show" }}
            </b-button>
          </template>

          <template slot="row-details" slot-scope="row">
            <b-row class="text-left">
              <b-col>
                <b-card>
                  <h6 slot="header" class="mb-0">
                    Subject: {{ row.item.subject }}
                  </h6>
                  <b-card-text>
                    <p style="text-align: left" v-html="row.item.html_body"></p>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </template>

          <template v-slot:cell(check_box)="row">
            <div v-show="!row.item.completed_fl">
              <b-form-checkbox v-model="row.item.clicked" switch />
            </div>
          </template>

          <template v-slot:cell(tags)="row">
            <div v-show="row.item.tags.length > 0">
              <div
                v-for="(tag, index) in row.item.tags"
                :key="index"
                style="display: inline-block"
              >
                <b-badge
                  style="white-space: normal; max-width: 150px; margin: 1px"
                  :variant="tag.class"
                >
                  {{ tag.text }}
                </b-badge>
              </div>
            </div>
          </template>

          <template v-slot:cell(send_email)="row">
            <div v-if="!row.item.completed">
              <div v-if="row.item.completed_fl === true">
                <img src="../../assets/icons/check.png" style="width: 32px" />
              </div>
              <div v-else>
                <b-button
                  size="sm"
                  class="mr-2"
                  variant="outline-success"
                  @click="sendEmail(row)"
                >
                  <div v-if="row.item.process_disabled">
                    <b-spinner small />
                  </div>
                  <div v-else>Send</div>
                </b-button>
              </div>
            </div>
            <div v-else-if="row.item.completed">
              <img src="../../assets/icons/check.png" style="width: 32px" />
            </div>
          </template>

          <template v-slot:cell(refresh)="row">
            <div v-show="!row.item.completed_fl">
              <b-button
                size="sm"
                class="mr-2"
                variant="outline-primary"
                @click="refresh(row.item)"
              >
                Refresh
              </b-button>
            </div>
          </template>

          <template v-slot:cell(delete)="row">
            <b-button
              size="sm"
              class="mr-2"
              variant="outline-danger"
              @click="deleteRow(row.item)"
            >
              Delete
            </b-button>
          </template>
        </b-table>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import OpeningReadings from "@/api/OpeningReadings.js"
import Jobs from "@/api/Jobs.js"
import headerBlock from "@/components/header"
import resultsTable from "@/components/resultsTable"
import pageSection from "@/components/pageSection"

export default {
  components: { headerBlock, resultsTable, pageSection },
  data() {
    return {
      lookupValue: null,
      progressBar: {
        jobId: null,
        stage: null,
        counter: null,
        max: null,
      },
    }
  },
  computed: {
    ...mapGetters("validatedOpeningReadings", [
      "validatedAccountsTable",
      "validatedAccounts",
      "isBusy",
      "lookupResults",
      "taskScheduler",
      "noteCountCheck",
    ]),
    countAwaiting() {
      return this.validatedAccounts.filter(
        (x) => x.clicked === false && x.completed_fl === false
      )
    },
    countInvestigate() {
      return this.validatedAccounts.filter(
        (x) => x.clicked === true && x.completed_fl === false
      )
    },
    countCompleted() {
      return this.validatedAccounts.filter((x) => x.completed_fl === true)
    },
  },
  watch: {
    "progressBar.jobId": function (newValue) {
      var that = this
      if (newValue !== null) {
        that.SetBusyState(true)
        var progress = this.progressBar
        var interval = setInterval(function () {
          Jobs.getDelayedJob(newValue)
            .then((job) => {
              if (job.data.last_error != null) {
                clearInterval(interval)
              }
              progress.stage = job.data.progress_stage
              progress.counter = job.data.progress_current
              progress.max = job.data.progress_max
            })
            .catch(() => {
              progress.jobId = null
              progress.stage = null
              progress.counter = null
              progress.max = null
              clearInterval(interval)
              that.$store.dispatch("validatedOpeningReadings/getAccounts")
              that.SetBusyState(false)
            })
        }, 1000)
      }
    },
  },
  mounted: function () {
    if (this.validatedAccounts.length === 0) {
      this.$store.dispatch("validatedOpeningReadings/getAccounts")
    }

    var progress = this.progressBar
    if (progress.jobId === null) {
      Jobs.getDelayedJobQueue("validated_readings")
        .then((response) => {
          if (response.data.length !== 0) {
            progress.jobId = response.data[0].id
          }
        })
        .catch(() => {})
    }
  },
  methods: {
    ...mapActions({
      deleteRow: "validatedOpeningReadings/deleteRow",
      sendEmail: "validatedOpeningReadings/sendEmail",
      lookupAccount: "validatedOpeningReadings/lookupAccount",
      clearCompleted: "validatedOpeningReadings/deleteAll",
      refresh: "validatedOpeningReadings/refresh",
    }),
    ...mapMutations({
      SetLookupAccount: "validatedOpeningReadings/SET_LOOKUP_ACCOUNT",
      SetBusyState: "validatedOpeningReadings/SET_BUSY_STATE",
    }),
    importAccounts() {
      OpeningReadings.importValidatedReadings()
        .then((response) => {
          this.progressBar.jobId = response.data.id
          this.progressBar.counter = response.data.progress_current
          this.progressBar.max = response.data.progress_max
          this.progressBar.stage = response.data.progress_stage
        })
        .catch(() => {})
    },
    clear() {
      var that = this
      this.lookupValue = null
      that.SetLookupAccount([])
    },
    sendAllEmails() {
      OpeningReadings.sendAllValidatedReadsEmail(this.countAwaiting)
        .then((response) => {
          this.progressBar.jobId = response.data.id
          this.progressBar.counter = response.data.progress_current
          this.progressBar.max = response.data.progress_max
          this.progressBar.stage = response.data.progress_stage
        })
        .catch(() => {})
    },
  },
}
</script>
