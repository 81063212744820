import dayjs from "dayjs"

export function unbilledChecks(e) {
  try {
    var billedReadings = []
    var unBilledReadings = []
    var readDates = []

    if (
      e.account.latest_bill !== undefined &&
      e.account.latest_bill !== null &&
      e.unbilled_readings !== null
    ) {
      var latestBillDate = dayjs(
        e.account.latest_bill.bill_created_date
      ).format("YYYY-MM-DD") //Convert to date
      e.unbilled_readings.forEach(function (z) {
        readDates.push(dayjs(z.readings.readingDttm).format("YYYY-MM-DD"))
      })

      if (readDates.length > 0) {
        readDates.forEach(function (t) {
          if (t < latestBillDate) {
            billedReadings.push(t)
          } else if (t >= latestBillDate) {
            unBilledReadings.push(t)
          }
        })
      }

      if (billedReadings.length > 0) {
        e.billedMeterReading = true
      }

      if (unBilledReadings.length > 0) {
        e.unbilled_readings_fl = true
      } else {
        e.unbilled_readings_fl = false
      }
    }
  } catch (error) {
    console.log(error)
  }
}
