<template>
  <b-container>
    <b-row class="text-left account-summary mb-3">
      <p
        v-if="
          paymentReview.error_reason == null ||
          paymentReview.error_reason.includes(
            'Failed - No such file or directory'
          )
        "
        style="color: red"
      ></p>
      <p v-else style="color: red">
        <i>Error: {{ paymentReview.error_reason }}</i>
      </p>
      <b-col>
        <b-card bg-variant="light">
          <table style="width: 100%; table-layout: fixed">
            <tr>
              <td>Account</td>
              <td>{{ paymentReview.account.junifer_account_number }}</td>
            </tr>
            <tr>
              <td>Full Name</td>
              <td>{{ primaryContact.full_name }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{ primaryContact.email }}</td>
            </tr>
          </table>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card bg-variant="light">
          <table style="width: 100%; table-layout: fixed">
            <tr
              v-if="paymentReview.projection_cost_type === 'Annualised Tariff'"
            >
              <td>Annual Cost On Current Tariff</td>
              <td>
                £{{ Math.round(paymentReview.current_tariff_annual_cost) }}
              </td>
            </tr>
            <tr
              v-if="
                paymentReview.projection_cost_type === 'Personal Projection'
              "
            >
              <td>Personal Projection (JPP)</td>
              <td>
                £{{ Math.round(paymentReview.junifer_personal_projection) }}
                (taken at
                {{
                  paymentReview.junifer_personal_projection_timestamp
                    | formattedDate
                }})
              </td>
            </tr>
            <tr>
              <td>Account Balance</td>
              <td>
                {{ accountBalanceFormat(paymentReview.account_balance) }}
              </td>
            </tr>
            <tr
              v-if="
                paymentReview.projection_cost_type === 'Personal Projection'
              "
            >
              <td>JPP + Account Balance</td>
              <td>
                £{{
                  Math.round(
                    parseFloat(paymentReview.junifer_personal_projection) +
                      parseFloat(paymentReview.account_balance)
                  )
                }}
              </td>
            </tr>
            <tr
              v-if="paymentReview.projection_cost_type === 'Annualised Tariff'"
            >
              <td>Annual Cost + Account Balance</td>
              <td>
                £{{
                  Math.round(
                    parseFloat(
                      paymentReview.current_tariff_annual_cost || "0.0"
                    ) + parseFloat(paymentReview.account_balance || "0.0")
                  )
                }}
              </td>
            </tr>
            <tr v-show="paymentReview.unbilled_readings_fl">
              <td>Unbilled Readings</td>
              <td>
                {{ accountBalanceFormat(paymentReview.unbilled_readings_cost) }}
              </td>
            </tr>
            <tr>
              <td>Current Payment</td>
              <td>
                <span v-if="paymentReview.current_payment_seasonal_fl">
                  £{{ paymentReview.current_payment_amount * 0.75 }} / £{{
                    paymentReview.current_payment_amount * 1.25
                  }}
                </span>
                <span v-else>
                  £{{ Math.round(paymentReview.current_payment_amount) }}
                </span>
                (next
                {{ paymentReview.current_payment_next_dt | formattedDate }})
              </td>
            </tr>
            <tr>
              <td>Suggested Payment</td>
              <td>
                <span v-if="paymentReview.current_payment_seasonal_fl">
                  <span v-if="paymentReview.follow_up_fl">
                    £{{
                      Math.round(
                        paymentReview.suggested_payment_amount_on_follow_up
                      ) * 0.75
                    }}
                    / £{{
                      Math.round(
                        paymentReview.suggested_payment_amount_on_follow_up
                      ) * 1.25
                    }}
                    (£{{
                      Math.round(
                        paymentReview.suggested_payment_amount_on_follow_up *
                          12,
                        0
                      )
                    }}/year)
                  </span>
                  <span v-else>
                    £{{
                      Math.round(paymentReview.suggested_payment_amount) * 0.75
                    }}
                    / £{{
                      Math.round(paymentReview.suggested_payment_amount) * 1.25
                    }}
                    (£{{
                      Math.round(
                        paymentReview.suggested_payment_amount * 12,
                        0
                      )
                    }}/year)
                  </span>
                </span>
                <span v-else>
                  <span v-if="paymentReview.follow_up_fl">
                    £{{
                      Math.round(
                        paymentReview.suggested_payment_amount_on_follow_up
                      )
                    }}
                    (£{{
                      Math.round(
                        paymentReview.suggested_payment_amount_on_follow_up *
                          12,
                        0
                      )
                    }}/year)
                  </span>
                  <span v-else>
                    £{{
                      Math.round(paymentReview.suggested_payment_amount)
                    }}
                    (£{{
                      Math.round(
                        paymentReview.suggested_payment_amount * 12,
                        0
                      )
                    }}/year)
                  </span>
                </span>
              </td>
            </tr>
            <tr>
              <td>Suggested Payment Date</td>
              <td>
                {{ paymentReview.suggested_payment_next_dt | formattedDate }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-card bg-variant="light">
          <b>Meterpoints</b><br />
          <div
            v-for="meterpoint in paymentReview.account.meterpoints"
            :key="meterpoint.id"
          >
            <table
              v-if="meterpoint.active_agreement"
              style="width: 100%; table-layout: fixed"
            >
              <tr style="color: blue">
                <td>{{ meterpoint.meterpoint_type }}</td>
                <td>
                  {{ meterpoint.identifier }} ({{ meterpoint.operation_type }})
                </td>
              </tr>
              <tr>
                <td>Agreement</td>
                <td>
                  {{ meterpoint.active_agreement.display_name }}
                </td>
              </tr>
              <tr>
                <td>Details</td>
                <td>
                  {{ meterpoint.active_agreement.from_date }}
                  <span
                    v-if="meterpoint.active_agreement.to_date !== '9999-12-31'"
                  >
                    - {{ meterpoint.active_agreement.to_date }}
                  </span>
                  <span v-else> onwards </span>
                </td>
              </tr>
              <tr>
                <td>Standing Charge</td>
                <td>
                  {{
                    meterpoint.active_agreement.tariff.product_details
                      .standingCharge
                  }}
                  p/day
                </td>
              </tr>
              <tr>
                <td>Unit Rates</td>
                <td>
                  <span v-if="meterpoint.identifier.length === 13">
                    <span
                      v-for="(value, key) in meterpoint.active_agreement.tariff
                        .product_details.unitRates"
                      :key="key"
                    >
                      {{ key }}: {{ value }} p/kWh
                    </span>
                  </span>
                  <span v-else>
                    {{
                      meterpoint.active_agreement.tariff.product_details
                        .unitRate
                    }}p/kWh
                  </span>
                </td>
              </tr>

              <tr>
                <td>Usage</td>
                <td>
                  <div v-for="meter in meterpoint.meters" :key="meter.id">
                    <div v-for="register in meter.registers" :key="register.id">
                      <span v-if="register.rate_name !== null"
                        >{{ register.rate_name }}:</span
                      >
                      {{ register.usage }} kWh / year
                    </div>
                  </div>
                </td>
              </tr>
            </table>

            <p v-else>No Active Agreement present</p>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="paymentReview.unbilled_readings_fl" class="mt-3">
      <b-col>
        <b-card bg-variant="light">
          <b>Unbilled Readings</b><br />
          <div
            v-for="(register, index) in paymentReview.unbilled_readings"
            :key="index"
          >
            <table style="width: 100%; table-layout: fixed">
              <tr>
                <td>
                  {{ register.meterpoint_identifier }}
                  <span v-if="register.readings.register">
                    ({{ register.readings.register }})
                  </span>
                </td>
                <td>
                  {{ register.readings.cumulative }}
                  {{ register.readings.unit }} (on
                  {{ register.readings.readingDttm | formattedDate }})
                </td>
              </tr>
            </table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

export default {
  filters: {
    formattedDate: function (date) {
      return dayjs(date).format("Do MMM YY")
    },
  },
  props: {
    paymentReview: {
      type: Object,
      required: true,
      default: () => null,
    },
  },
  computed: {
    primaryContact() {
      return this.paymentReview.account.contacts.filter(
        (contact) => contact.junifer_primary_contact
      )[0]
    },
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    },
    accountBalanceFormat(balance) {
      var accBal = null
      if (balance < 0) {
        accBal = "£" + Number(-balance).toFixed(2) + " CR"
      } else if (balance > 0) {
        accBal = "£" + Number(balance).toFixed(2) + " DR"
      } else {
        accBal = "£" + Number(balance).toFixed(2)
      }
      return accBal
    },
  },
}
</script>
