import apiArk from "./Ark"

export default {
  billValueChecks() {
    return apiArk.get("bills/monthly/bill_value_tickets/all")
  },
  importBillValueTickets() {
    return apiArk.get("bills/monthly/bill_value_tickets/import")
  },
  sendBillValueEmail(id, row) {
    return apiArk.post("bill_values/" + id, { row })
  },
  acceptBill(bill_id) {
    return apiArk.post("bills/monthly/" + bill_id + "/accept_bill")
  },

  finalBills() {
    return apiArk.get("final_bills/finalbills")
  },
  importFinalBillValueTickets() {
    return apiArk.post("final_bills/importsp", { record: "" })
  },
}
