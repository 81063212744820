<template>
  <div>
    <div class="upload-csv__attachment">
      <input
        :id="id"
        ref="fileInput"
        class="upload-csv__attachment-input"
        :name="id"
        type="file"
        accept=".csv"
        @change="file = $event.target.files[0]"
      />
      <label class="upload-csv__attachment-label" :for="id"> Browse </label>
    </div>

    <div v-if="file" class="upload-csv__attachment-file">
      <i class="far fa-file"></i>
      {{ file.name }}
      <div class="upload-csv__attachment-remove" @click="resetFile">
        <i class="fas fa-times buttons__close-icon"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: File,
      default: null,
    },
  },
  data() {
    return {
      file: null,
    }
  },
  computed: {
    id() {
      return `input-csv-${this._uid}`
    },
  },
  watch: {
    file() {
      this.$emit("input", this.file)
    },
  },
  methods: {
    resetFile() {
      this.file = null
      this.$refs.fileInput.value = null
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-csv {
  padding: 0 0 $spacing-5;
  text-align: right;

  &__attachment {
    position: relative;
  }

  &__attachment-label {
    position: relative;
    display: block;
    z-index: 2;
    border-radius: 4px;
    border: 1px solid $night-light-2;
    color: $night-light-2;
    padding: $space-1;
    text-align: center;

    &:hover {
      border: 1px solid $night-light;
      color: $night-light;
      background-color: $day-dark;
      cursor: pointer;
    }
  }

  &__attachment-file {
    background-color: $day-dark;
    position: relative;
    padding: $spacing-2 $spacing-6;
    text-align: left;
    margin: $spacing-2 0;
    font-size: 14px;
    font-weight: $weight-bold;
    color: $night-light;

    .fa-file {
      top: 13px;
      left: 10px;
      position: absolute;
    }
  }

  &__attachment-remove {
    position: absolute;
    right: 0;
    top: 0;
    padding: $spacing-2;
    cursor: pointer;

    &:hover {
      color: $night-light;
    }
  }

  &__attachment-input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    opacity: 0;
    top: 0;
    z-index: 1;

    &:focus {
      + .upload-csv__attachment-label {
        border: 1px solid $water;
      }
    }
  }
}
</style>
