<template>
  <b-row class="mt-5">
    <b-col>
      <b-table
        v-show="resultsTable.items.length > 0"
        hover
        small
        responsive
        fixed
        class="border rounded"
        :busy="isBusy"
        :sort-by.sync="resultsTable.sortBy"
        :sort-desc.sync="resultsTable.sortDesc"
        :items="resultsTable.items"
        :fields="resultsTable.fields"
        :per-page="perPage"
        :current-page="currentPage"
        @row-clicked="(item) => (communicationShow = item[showItem])"
      >
        <template slot="created_at" slot-scope="row">
          {{ row.item.created_at | formattedDate }}
        </template>
      </b-table>

      <div v-if="resultsTable.items.length > perPage">
        <b-pagination
          v-model="currentPage"
          :total-rows="resultsTable.items.length"
          :per-page="perPage"
          aria-controls="my-table"
        >
        </b-pagination>
        <p class="mt-3">
          Page: {{ currentPage }} /
          {{ Math.ceil(resultsTable.items.length / perPage) }}
        </p>
      </div>
    </b-col>

    <b-col>
      <div
        v-show="resultsTable.items.length > 0"
        class="border rounded bg-light"
        style="min-height: 300px"
      >
        <p
          v-show="communicationShow !== null"
          style="text-align: left; background-color: white"
          v-html="communicationShow"
        ></p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

export default {
  filters: {
    formattedDate: function (date) {
      return dayjs(date).format("Do MMM YY")
    },
  },
  props: ["resultsTable", "isBusy", "perPage", "currentPage", "showItem"],
  data: function () {
    return {
      communicationShow: null,
    }
  },
}
</script>
