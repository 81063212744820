import PaymentAdequacy from "@/api/PaymentAdequacy"
import { fetchFollowUpTemplates } from "@/helpers/paymentAdequacy/followTemplates"
import { followUpReview } from "@/helpers/paymentAdequacy/followUpReview"
import Vue from "vue"

const state = {
  daySevenLookupResults: [],
  daySevenReviewTotal: 0,
  daySevenReview: [],
  isBusy: false,
}

const mutations = {
  SET_DAYSEVENDATA(state, data) {
    state.daySevenReview = data
  },
  SET_DAYSEVEN_TOTAL(state, data) {
    state.daySevenReviewTotal = data
  },
  DELETE_ROW(state, data) {
    var index = state.daySevenReview.findIndex((s) => s.id == data.id)
    state.daySevenReview.splice(index, 1)
  },
  SET_DAYSEVENDATA_STATE(state, data) {
    state.isBusy = data
  },
  SET_RECORD(state, data) {
    // Set large dataset
    var index = state.daySevenReview.findIndex((s) => s.id == data.id)
    if (index !== undefined) {
      Vue.set(state.daySevenReview, index, data)
    }
    // Set account lookup
    var lookupIndex = state.daySevenLookupResults.findIndex(
      (s) => s.id == data.id
    )
    if (lookupIndex !== undefined) {
      Vue.set(state.daySevenLookupResults, lookupIndex, data)
    }
  },
  SET_LOOKUP_RESULT(state, data) {
    state.daySevenLookupResults = data
  },
}
const getters = {
  isBusyState: (state) => {
    return state.isBusy
  },
  data: (state) => {
    return state.daySevenReview
  },
  dataTotal: (state) => {
    return state.daySevenReviewTotal
  },
  daySevenLookupResults: (state) => {
    return state.daySevenLookupResults
  },
}

const actions = {
  async getDaySevenReviewTotal({ commit }) {
    await this.dispatch("sixMonthAdDayOne/getRefundWeight")
    PaymentAdequacy.getDaySevenReviewsTotal()
      .then((response) => {
        commit("SET_DAYSEVEN_TOTAL", response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  getDaySevenReviews: function (context, data) {
    context.commit("SET_DAYSEVENDATA_STATE", true)
    PaymentAdequacy.getDaySevenReviews(data)
      .then((response) => {
        response.data.forEach(function (e) {
          e.process_disabled = false
          e._showDetails = false
          followUpReview(e)
        })
        context.commit("SET_DAYSEVENDATA", response.data)
        context.commit("SET_DAYSEVENDATA_STATE", false)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  fetchRowInfo(context, data) {
    PaymentAdequacy.getFollowUp(data.id)
      .then((response) => {
        if (response.data.html_content) {
          data.templateContent = response.data.html_content
          data.emailSubject = response.data.subject
        } else {
          _.merge(response.data, data)
          fetchFollowUpTemplates(response.data)
        }
      })
      .catch((error) => {
        console.log("fetchRowInfo failed", error)
      })
  },
  deleteAll: function (context, data) {
    var reviewIds = []
    data.forEach(function (x) {
      reviewIds.push(x.id)
      context.commit("DELETE_ROW", x)
    })
    PaymentAdequacy.softDeleteGroup(reviewIds)
  },
  softdelete: function (context, data) {
    PaymentAdequacy.softDelete(data.id)
      .then(() => {
        context.commit("DELETE_ROW", data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  restore: function (context, data) {
    PaymentAdequacy.restore(data.id)
      .then(() => {
        data.deleted_fl = false
        context.commit("SET_RECORD", data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  checkForResponse: function (context, data) {
    data.disabled = true
    context.commit("SET_RECORD", data)
    PaymentAdequacy.refreshFollowUp(data.id)
      .then((response) => {
        response.data.disabled = false
        followUpReview(response.data)
        context.commit("SET_RECORD", response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  paymentscript7dayaction: function (context, data) {
    data.process_disabled = true
    context.commit("SET_RECORD", data)
    fetchFollowUpTemplates(data)
    PaymentAdequacy.followUpProcess(data.id, data)
      .then(() => {
        data.sevendayreview = true
        data.process_disabled = false
        context.commit("SET_RECORD", data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}
