import axios from "axios"
import apiArk from "./Ark"

const appURL = process.env.VUE_APP_JOBS_API
const apiJobs = axios.create({
  baseURL: appURL,
  timeout: 1000000,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Headers":
      "Content-Type, Accept, Authorization, X-Request-With",
  },
})

export default {
  getDelayedJobQueue(queue_name) {
    return apiArk.get("jobs/" + queue_name)
  },
  getDelayedJob(job_id) {
    return apiJobs.get("progress-job/" + job_id)
  },
}
