<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Email lookup"
        summary=""
        description=""
      ></header-block>
    </b-row>

    <b-row class="ml-5 mb-2 mr-3">
      <b-form inline>
        <b-input-group>
          <input
            id="email"
            v-model="email"
            type="text"
            :disabled="isBusy"
            placeholder="email address"
            style="width: 300px"
            class="form-control mb-2 mr-sm-2 mb-sm-0"
          />
          <b-form-input
            v-model="searchFromDate"
            type="date"
            :disabled="isBusy"
            class="mb-2 mr-sm-2 mb-sm-0"
          />
          <b-form-input
            v-model="searchToDate"
            type="date"
            :disabled="isBusy"
            class="mb-2 mr-sm-2 mb-sm-0"
          />
          <b-input-group-append>
            <b-button
              id="search"
              :disabled="isBusy || (email === null && searchFromDate === null)"
              variant="outline-primary"
              class="mb-2 mr-sm-2 mb-sm-0"
              @click="lookupEmail()"
            >
              <span v-show="!isBusy">Search</span>
              <span v-show="isBusy"><b-spinner small /></span>
            </b-button>
            <b-button
              id="clear"
              :disabled="isBusy"
              class="mb-2 mr-sm-2 mb-sm-0"
              variant="outline-danger"
              @click="clear()"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
    </b-row>

    <page-section title="Mandrill emails">
      <template v-slot:content>
        <b-table
          hover
          small
          responsive
          :items="results.results"
          :fields="fields"
        >
          <template v-slot:cell(created_at)="row">
            {{ row.item.created_at | formattedDate }}
          </template>
        </b-table>

        <b-pagination
          v-if="results.total > 100"
          v-model="results.page"
          :total-rows="results.total"
          :per-page="100"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          @change="handlePageChange"
        ></b-pagination>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import headerBlock from "@/components/header"
import Messages from "@/api/Messages"
import pageSection from "@/components/pageSection"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

export default {
  components: {
    headerBlock,
    pageSection,
  },
  filters: {
    formattedDate: function (date) {
      return dayjs(date).format("Do MMM YY")
    },
  },
  data() {
    return {
      isBusy: false,
      searchFromDate: null,
      searchToDate: null,
      email: null,
      results: [],
      fields: [
        "_id",
        "event",
        "msg.email",
        "msg.state",
        "msg.subject",
        "msg.tags",
        "msg.opens",
        "msg.clicks",
        "msg.sender",
        "created_at",
      ],
    }
  },
  computed: {
    searchParams() {
      return {
        email: this.email,
        from_dt: this.searchFromDate,
        to_dt: this.searchToDate,
      }
    },
  },
  methods: {
    handlePageChange(value) {
      var paginatedParams = this.searchParams
      paginatedParams.page = value
      var params = this.formatUrlParams(this.searchParams)
      this.searchMandrill(params)
    },
    formatUrlParams(obj) {
      var par = []
      if (obj.from_dt) {
        par.push("from_dt=" + obj.from_dt)
      }
      if (obj.email) {
        par.push("email=" + obj.email)
      }
      if (obj.to_dt) {
        par.push("to_dt=" + obj.to_dt)
      }
      if (obj.page) {
        par.push("page=" + obj.page)
      }
      return par.join("&")
    },
    lookupEmail() {
      var params = this.formatUrlParams(this.searchParams)
      this.isBusy = true
      this.searchMandrill(params)
    },
    searchMandrill(params) {
      this.isBusy = true
      Messages.fetchEmailResponses(params)
        .then((response) => {
          this.results = response.data
          this.isBusy = false
        })
        .catch(() => {
          this.isBusy = false
        })
    },
    clear() {
      this.searchFromDate = null
      this.searchToDate = null
      this.email = null
      this.results = []
    },
  },
}
</script>
