<template>
  <div>
    <section class="appointment-summary">
      <div class="appointment-summary__grid">
        <div class="appointment-summary-card">
          <h3 class="appointment-summary-card__title">Customer Details</h3>
          <div
            class="
              appointment-summary-card__body
              appointment-summary-card__body--columns
            "
          >
            <ul class="appointment-summary-card__list">
              <li>
                {{ accountDetails.name.title }}
                {{ accountDetails.name.forename }}
                {{ accountDetails.name.surname }}
              </li>
              <li>{{ accountDetails.contact_info.email }}</li>
              <li>{{ accountDetails.contact_info.number }}</li>
            </ul>
            <div>{{ addressFormatted }}</div>
          </div>
        </div>

        <div class="appointment-summary-card">
          <h3 class="appointment-summary-card__title">Date & Time</h3>
          <div
            class="
              appointment-summary-card__body
              appointment-summary-card__body--columns
            "
          >
            <div>{{ appointmentDateFormatted }}</div>
            <div>{{ appointmentTimeFormatted }}</div>
          </div>
          <a
            href="javascript:;"
            class="appointment-summary-card__link"
            @click="goTo('ChooseAppointment')"
          >
            Change
          </a>
        </div>

        <div class="appointment-summary-card">
          <h3 class="appointment-summary-card__title">Job Type</h3>
          <div class="appointment-summary-card__body">
            <ul class="appointment-summary-card__list">
              <li>
                {{
                  selectionDescriptionAES(
                    selectedJobs.selectedMeterTypeAES,
                    "meterType"
                  )
                }}
              </li>
              <li>
                {{
                  selectionDescriptionAES(
                    selectedJobs.selectedInstallationType,
                    "installationType"
                  )
                }}
              </li>
              <li>
                {{
                  selectionDescriptionAES(
                    selectedJobs.selectedAppointmentType,
                    "appointmentType"
                  )
                }}
              </li>
            </ul>
          </div>
          <a
            href="javascript:;"
            class="appointment-summary-card__link"
            @click="goTo('ChooseJobType')"
          >
            Change
          </a>
        </div>

        <div class="appointment-summary-card">
          <h3 class="appointment-summary-card__title">Medical Equipment</h3>
          <div class="appointment-summary-card__body">
            <p>{{ hasMedicalEquipment ? "Yes" : "No" }}</p>
          </div>
          <a
            href="javascript:;"
            class="appointment-summary-card__link"
            @click="goTo('ExtraInformation')"
          >
            Change
          </a>
        </div>
      </div>

      <FeedbackDialog
        class="appointment-summary__feedback-dialog"
        variant="warning"
      >
        <p>Make sure the customer is aware of the following:</p>
        <ul>
          <li>
            If an engineer attends the property between the times specified and
            they are unable to access the property, meters or main fuse, then
            the job won't be able to be completed and will need to be
            rescheduled.
          </li>
          <li>
            Please ensure that there is someone over the age of 18 in the
            property throughout the installation/appointment, even if the meters
            are outside.
          </li>
          <li>
            Ensure that there is a clear working area around the meters and the
            main fuse.
          </li>
        </ul>
      </FeedbackDialog>

      <AppButton
        class="appointment-summary__confirm-button"
        :loading="bookingApiStatus === 'PENDING'"
        :disabled="bookingApiStatus === 'PENDING'"
        @click="bookAppointment"
      >
        Confirm Booking
      </AppButton>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import dayjs from "dayjs"

import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog"
import jobTypes from "@/constants/imbp_jobs"

export default {
  components: {
    AppButton,
    FeedbackDialog,
  },
  props: {
    accountDetails: {
      type: Object,
      default: () => ({}),
    },
    bookingApiStatus: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      jobTypes: jobTypes,
    }
  },
  computed: {
    ...mapState({
      steps: (state) => state.imbp.steps,
      time: (state) => state.imbp.activeTime,
      selectedDate: (state) => state.imbp.selectedDate,
      hasMedicalEquipment: (state) => state.imbp.hasMedicalEquipment,
      selectedJobs: (state) => state.imbp.selectedJobs,
      installer: (state) => state.imbp.installer,
    }),
    addressFormatted() {
      const postcode = this.accountDetails.address.postcode
      return `${this.accountDetails.address.address1} ${this.accountDetails.address.address2}, ${this.accountDetails.address.address3},  ${postcode}`
    },
    appointmentDateFormatted() {
      return dayjs(this.selectedDate).format("D MMM YYYY")
    },
    appointmentTimeFormatted() {
      if (this.time && this.time.startTime && this.time.endTime) {
        const startTime = this.time.startTime.split(":")
        const endTime = this.time.endTime.split(":")
        return `${startTime[0]}:${startTime[1]} - ${endTime[0]}:${endTime[1]}`
      } else {
        return ""
      }
    },
  },
  methods: {
    ...mapMutations({
      setCurrentStepNumber: "imbp/SET_CURRENT_STEP_NUMBER",
    }),
    goTo(stepName) {
      const stepNumber = this.steps.findIndex((step) => step.name === stepName)
      this.setCurrentStepNumber(stepNumber)
      window.scrollTo(0, 0)
    },
    bookAppointment() {
      this.$emit("book-appointment")
    },
    selectionDescriptionAES(selectionId, selectionType) {
      switch (selectionType) {
        case "meterType":
          const meterType = this.jobTypes.meterTypesAES.find(
            (item) => item.value === selectionId
          )
          return meterType ? meterType.label : ""
          break
        case "installationType":
          const installationType = this.jobTypes.installationTypesAES.find(
            (item) => item.value === selectionId
          )
          return installationType ? installationType.label : ""
          break
        case "appointmentType":
          const appointmentType = this.jobTypes.appointmentTypesAES.find(
            (item) => item.value === selectionId
          )
          return appointmentType ? appointmentType.label : ""
          break
        default:
          return ""
      }
    },
  },
}
</script>

<style scoped lang="scss">
.appointment-summary-card {
  &__title {
    font-size: $font-size-6;
    font-weight: $weight-bold;
    margin-bottom: $space-4;
  }

  &__body {
    font-size: $font-size-4;
    margin-bottom: $space-4;

    &:last-child {
      margin-bottom: 0;
    }

    &--columns {
      display: flex;
      gap: $space-12;
    }
  }

  &__list {
    list-style: none;
    margin-bottom: 0;

    & li {
      margin: 0;
    }
  }

  &__ol-list {
    margin-bottom: 0;
    padding-inline-start: 20px;

    & li {
      margin: 0 0 $space-4;
    }
    span {
      font-weight: $weight-bold;
    }
  }

  &__link {
    color: $water-dark;
  }
}
.appointment-summary {
  &__heading {
    font-weight: $weight-bold;
    font-size: $font-size-7;
    margin-top: $space-4;
    margin-bottom: $space-11;
  }

  &__grid {
    display: grid;
    gap: $space-11;
    margin-bottom: $space-10;
    grid-template-columns: 1fr 1fr;
  }

  &__feedback-dialog {
    width: unset;
    margin-top: $space-10;

    ul {
      margin-bottom: 0;
    }
  }

  &__confirm-button {
    margin-top: $space-10;
  }
}
</style>
