<template>
  <b-row class="mt-5">
    <b-col>
      <b-table
        v-show="resultsTable.items.length > 0"
        hover
        small
        responsive
        fixed
        class="border rounded"
        :busy="isBusy"
        :sort-by.sync="resultsTable.sortBy"
        :sort-desc.sync="resultsTable.sortDesc"
        :items="resultsTable.items"
        :fields="resultsTable.fields"
        :per-page="perPage"
        :current-page="currentPage"
        @row-clicked="(item) => (communicationShow = item)"
      >
        <template slot="created_at" slot-scope="row">
          {{ row.item.created_at | formattedDate }}
        </template>
      </b-table>

      <div v-if="resultsTable.items.length > perPage">
        <b-pagination
          v-model="currentPage"
          :total-rows="resultsTable.items.length"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
        <p class="mt-3">
          Page: {{ currentPage }} /
          {{ Math.ceil(resultsTable.items.length / perPage) }}
        </p>
      </div>
    </b-col>

    <b-col>
      <div v-show="resultsTable.items.length > 0" class="mb-3">
        <h4 class="text-left">Template</h4>
        <div class="border rounded bg-light" style="min-height: 300px">
          <p
            v-if="communicationShow !== null"
            style="text-align: left; background-color: white"
            v-html="complileTemplate"
          ></p>
        </div>
      </div>

      <div v-if="communicationShow !== null" class="mb-3">
        <h4 v-if="communicationShow.tags !== null" class="text-left">Tags</h4>
        <table class="border rounded bg-light">
          <tr
            v-for="(value, key) in communicationShow.tags"
            :key="key"
            class="border rounded bg-light"
          >
            <td>{{ key }}</td>
            <td>
              <p
                style="text-align: left; background-color: white"
                v-html="value"
              ></p>
            </td>
          </tr>
        </table>
      </div>

      <div v-if="communicationShow !== null" class="mb-3">
        <h4 class="text-left">Comments</h4>
        <table class="border rounded bg-light">
          <tr
            v-for="value in communicationShow.comments"
            :key="value.id"
            class="border rounded bg-light"
          >
            <td>
              <p
                style="text-align: left; background-color: white"
                v-html="value.body"
              ></p>
            </td>
          </tr>
        </table>

        <b-form-textarea
          v-model="commentText"
          class="mt-2"
          placeholder="Enter comment..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <b-button
          class="float-right p-2 mt-2"
          size="sm"
          variant="outline-success"
          @click="addComment()"
        >
          Submit
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Communication from "@/api/Communication"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

export default {
  filters: {
    formattedDate: function (date) {
      return dayjs(date).format("Do MMM YY")
    },
  },
  props: ["resultsTable", "isBusy", "perPage", "currentPage", "showItem"],
  data: function () {
    return {
      communicationShow: null,
      commentText: null,
    }
  },
  computed: {
    complileTemplate() {
      var body = this.communicationShow.body_html
      var bodyHtml = this.communicationShow.template_html.replace(
        "#{body}",
        body
      )
      return bodyHtml
    },
  },
  method: {
    addComment() {
      console.log("test")
      Communication.addComment(this.communicationShow.id, this.commentText)
        .then(() => {
          var x = {
            body: this.commentText,
            communication_content_id: this.communicationShow.id,
            created_at: Date.now(),
            updated_at: Date.now(),
          }
          this.communicationShow.comments.push(x)
          this.commentText = null
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
