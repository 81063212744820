<template>
  <UploadCsv
    :parser="parser"
    :api-call="WhdApi.updateVulnerabilityJunifer"
    v-on="$listeners"
  ></UploadCsv>
</template>

<script>
import UploadCsv from "@/components/csv/UploadCsv.vue"
import { csvToListOfCells } from "@/helpers/csv/parsers"
import WhdApi from "@/api/WarmHomeDiscount.js"

export default {
  components: {
    UploadCsv,
  },
  data() {
    return {
      parser: csvToListOfCells,
      WhdApi: WhdApi,
    }
  },
}
</script>
