export function createFormatter(e) {
  console.log(e)
  try {
    var d = {}
    d.account.junifer_account_number = e.account.junifer_account_number
    d.account_checks = e.account_checks
    d.account.latest_bill = e.account.latest_bill
    d.account.bills = e.account.bills
    d.account.contacts = e.account.contacts
    d.account.unbilled_readings = e.account.unbilled_readings
    d.email_dayone_sent = e.email_dayone_sent
    d.tickets = e.tickets
    d.account_balance = e.account_balance
    d.current_payment_amount = e.current_payment_amount
    d.current_payment_next_dt = e.current_payment_next_dt
    d.current_payment_seasonal_fl = e.current_payment_seasonal_fl
    d.customer_contact_fl = e.customer_contact_fl
    d.customer_contact = e.customer_contact
    d.suggested_refund = e.suggested_refund
    d.follow_up_reads_check = e.follow_up_reads_check
    d.follow_on_tariff_annual_cost = e.follow_on_tariff_annual_cost
    d.follow_up_fl = e.follow_up_fl
    d.seven_day_follow_up = e.seven_day_follow_up
    d.seven_day_follow_up_date = e.seven_day_follow_up_date
    d.suggested_payment_amount_on_follow_up =
      e.suggested_payment_amount_on_follow_up
    d.payment_schedule_id = e.payment_schedule_id
    d.perc_change = e.perc_change
    d.current_tariff_annual_cost = e.current_tariff_annual_cost
    d.unbilled_readings_cost = e.unbilled_readings_cost
    d.unbilled_readings_fl = e.unbilled_readings_fl
    d.has_meter_readings = e.has_meter_readings
    d.junifer_personal_projection = e.junifer_personal_projection
    d.junifer_personal_projection_timestamp =
      e.junifer_personal_projection_timestamp
    return d
  } catch (error) {
    console.log(error)
  }
}
