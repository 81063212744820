import apiArk from "./Ark"
import apiArk2 from "./ApiV2"

export default {
  // Pre reviews
  importPreReview() {
    return apiArk.get("payment_adequacy_pre_reviews")
  },
  skipPreReview(id) {
    return apiArk.post("payment_adequacy_pre_reviews/" + id + "/skip_review")
  },
  emailPreReview(id, send_boolean) {
    return apiArk.post("payment_adequacy_pre_reviews/" + id + "/send_email")
  },
  getPreReviewEmail(id) {
    return apiArk.get("payment_adequacy_pre_reviews/" + id)
  },

  // 6 Month Adequacy - Initial Reviews
  getDayOneReviews(payload) {
    return apiArk.post("payment_adequacy_initial_reviews", { payload })
  },
  getReview(id) {
    return apiArk.get("payment_adequacy_initial_reviews/" + id)
  },
  initialRefresh(id, payload) {
    return apiArk.post(
      "payment_adequacy_initial_reviews/" + id + "/refresh",
      payload
    )
  },
  refreshGroup(payload) {
    return apiArk.post(
      "payment_adequacy_initial_reviews/refresh_batch",
      payload
    )
  },
  import6mPaymentAdequacy(import_count) {
    return apiArk.post("payment_adequacy_initial_reviews/import", {
      import_count,
    })
  },
  process(id, payload) {
    return apiArk.post(
      "payment_adequacy_initial_reviews/" + id + "/send_email",
      payload
    )
  },
  processGroup(payload) {
    return apiArk.post("payment_adequacy_initial_reviews/process_batch", {
      payload,
    })
  },
  getDayOneReviewsTotal() {
    return apiArk.get("payment_adequacy_initial_reviews/total_reviews")
  },

  //  7 follow up reviews
  getFollowUp(id) {
    return apiArk.get("payment_adequacy_follow_up_reviews/" + id)
  },
  getDaySevenReviews(payload) {
    return apiArk.post("payment_adequacy_follow_up_reviews", { payload })
  },
  refreshFollowUpGroup(payload) {
    return apiArk.post("payment_adequacy_follow_up_reviews/refresh_batch", {
      payload,
    })
  },
  followUpProcessGroup(payload) {
    return apiArk.post("payment_adequacy_follow_up_reviews/process_batch", {
      payload,
    })
  },
  getDaySevenReviewsTotal() {
    return apiArk.get("payment_adequacy_follow_up_reviews/total_reviews")
  },
  followUpProcess(id, payload) {
    return apiArk.post(
      "payment_adequacy_follow_up_reviews/" + id + "/send_email",
      { payload }
    )
  },
  refreshFollowUp(id) {
    return apiArk.get("payment_adequacy_follow_up_reviews/" + id + "/refresh")
  },

  // Adhoc review
  create(payload) {
    return apiArk.post("payment_adequacy_adhoc_reviews/create", {
      account_number: payload,
    })
  },
  livePaymentReview(account_number) {
    return apiArk.post(
      "payment_adequacy_adhoc_reviews/" + account_number + "/review"
    )
  },
  getCompletedReviews() {
    return apiArk.get("payment_adequacy_adhoc_reviews/completed")
  },

  // shared actions
  softDelete(id) {
    return apiArk.delete("payment_adequacy/" + id)
  },
  softDeleteGroup(payload) {
    return apiArk.post("payment_adequacy/delete_all", payload)
  },
  updateRecord(id, payload) {
    return apiArk.put("payment_adequacy/" + id + "/update", { payload })
  },
  restore(id) {
    return apiArk.post("payment_adequacy/" + id + "/restore")
  },
  fetchAccountPaymentReviews(accountNumber) {
    return apiArk.get("payment_adequacy/account/" + accountNumber)
  },

  // Refund Weight
  refundWeight() {
    return apiArk.get("payment_adequacy/refund_weight")
  },

  // Freshdesk - are we even using this really???
  // reply(payload) {
  //   return apiArk.post("main/faq_reply", payload )
  // },

  uploadAccountsAdhoc(data) {
    return apiArk.post(
      "payment_adequacy_adhoc_reviews/live_review_bulk_process",
      {
        account_numbers: data,
      }
    )
  },
  bulkUploader(reviewId, formData) {
    return apiArk2.post(`/payment-reviews/${reviewId}`, formData)
  },
  bulkUploaderStatus(formData) {
    return apiArk2.get(
      `/payment-review/${formData.reviewId}/status?from=${formData.from}&to=${formData.to}`
    )
  },
  paymentReviewResultsCsv(reviewId) {
    return apiArk2.get(`/payment-reviews/${reviewId}/download`)
  },
  paymentExclusionsListCsv(reviewId) {
    return apiArk2.get(`/exclusions/${reviewId}/download`)
  },
  refundUploader(data) {
    return apiArk.post("excess-credit-refunds", {
      account_numbers: data,
    })
  },
  refundLatestReportCsv() {
    return apiArk.get("excess-credit-refunds/latest-report")
  },
  addExclusionsForReviewId(reviewId, formData) {
    return apiArk2.post(`exclusions/${reviewId}`, formData)
  },
  removeExclusionsForReviewId(reviewId, formData) {
    return apiArk2.delete(`exclusions/${reviewId}`, { data: formData })
  },
  retriesUploader(data) {
    return apiArk2.post("payment-reviews/retry", data)
  },
}
