<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Adhoc payment adequacy"
        summary="We review our customers payment amount 6 months after their payment schedule has been created."
        description='<b-button v-b-modal.guide  class="m-2 float-right">
              Process guide
            </b-button>

            <b-modal id="guide" hide-footer size="lg" title="Process guide">
              <img src="../../assets/processMaps/PaymentAdeqaucy - 6m (day 1).png" width="100%">
            </b-modal>'
      >
      </header-block>
    </b-row>

    <page-section title="Individual account lookup">
      <template v-slot:content>
        <b-form inline>
          <input
            id="accountsearch"
            v-model="accountNumber"
            type="text"
            :disabled="isBusy"
            placeholder="Account number...."
            style="width: 300px"
            class="form-control mb-2 mr-sm-2 mb-sm-0"
          />
          <span class="input-group-btn">
            <b-button
              id="search"
              :disabled="isBusy"
              variant="outline-primary"
              class="mb-2 mr-sm-2 mb-sm-0"
              @click="accountLookup(accountNumber)"
            >
              <span v-show="!isBusy">Search</span>
              <span v-show="isBusy"><b-spinner small /></span>
            </b-button>
            <b-button
              id="clear"
              :disabled="isBusy"
              class="mb-2 mr-sm-2 mb-sm-0"
              variant="outline-danger"
              @click="clear()"
            >
              Clear
            </b-button>
          </span>
        </b-form>

        <b-row>
          <b-col class="text-left mt-2">
            <i
              >Returns all historic as well as a live payment adequacy review
              per account.</i
            >
          </b-col>
        </b-row>

        <b-row v-if="accountDetails !== null" class="m-5">
          <div
            v-for="paymentReview in accountDetails"
            :key="paymentReview.id"
            class="w-100"
          >
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                v-b-toggle="'collapse-' + paymentReview.id"
                block
                style="text-align: left"
                variant="outline-primary"
                size="lg"
              >
                Payment Schedule ID:
                {{ paymentReview.payment_schedule_id }} &nbsp;&nbsp;&nbsp;&nbsp;
                | &nbsp;&nbsp;&nbsp;&nbsp; Created:
                {{ paymentReview.created_at | formattedDate }}
                &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp; Deleted:
                {{ paymentReview.deleted_fl }} &nbsp;&nbsp;&nbsp;&nbsp; |
                &nbsp;&nbsp;&nbsp;&nbsp; Initial Review Completed:
                {{ paymentReview.initial_review_completed }}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span
                  v-if="
                    paymentReview.initial_review_completed &&
                    paymentReview.seven_day_follow_up
                  "
                >
                  | &nbsp;&nbsp;&nbsp;&nbsp; Follow Up Review:
                  {{ paymentReview.sevendayreview }} &nbsp;&nbsp;&nbsp;&nbsp; |
                  &nbsp;&nbsp;&nbsp;&nbsp; Follow Up Date:
                  {{ paymentReview.seven_day_follow_up_date }}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </b-button>
            </b-card-header>

            <b-collapse
              :id="'collapse-' + paymentReview.id"
              :visible="false"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <p>
                    Suggested Payment: £{{
                      paymentReview.suggested_payment_amount
                    }}
                  </p>
                  <p>Refund: {{ paymentReview.refund }}</p>
                  <payment-review-template :account-details="paymentReview" />
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </div>
          <div class="w-100">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                v-b-toggle="'collapse-new-review'"
                block
                style="text-align: left"
                variant="outline-success"
                size="lg"
                @click="livePaymentReveiw(accountNumber)"
              >
                Live Review >
              </b-button>
            </b-card-header>

            <b-collapse
              :id="'collapse-new-review'"
              :visible="false"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <span v-if="newReview === null">
                    <b-spinner class="m-5 align-middle"></b-spinner>
                  </span>
                  <span v-else>
                    <div>
                      <b-button
                        v-b-modal="'modal'"
                        size="lg float-right"
                        variant="success"
                        @click="createAdequacy(newReview)"
                      >
                        Create Adequacy
                      </b-button>

                      <b-modal :id="'modal'" hide-footer hide-header>
                        <b-card-body>
                          <span
                            v-if="
                              adequacyResponse !== null &&
                              adequacyResponse.status === 200
                            "
                          >
                            <h5>
                              Success
                              <b-icon icon="check-circle" variant="success" />
                            </h5>
                          </span>
                          <span
                            v-else-if="
                              adequacyResponse !== null &&
                              adequacyResponse.status === 400
                            "
                          >
                            <h5>
                              Error
                              <b-icon icon="x-circle-fill" variant="danger" />
                            </h5>
                          </span>
                          <div v-if="adequacyResponse === null">
                            <b-spinner class="m-5 align-middle"></b-spinner>
                          </div>
                          <div v-else>
                            {{ adequacyResponse.message }}
                          </div>
                        </b-card-body>
                      </b-modal>

                      Tickets: {{ newReview.tickets }} Account Checks:
                      {{ newReview.account_checks }} Current:
                      {{ newReview.current_payment_amount }} Suggested:
                      {{ newReview.suggested_payment_amount }} Refund:
                      {{ newReview.refund }}
                    </div>

                    <payment-review-template :payment-review="newReview" />
                  </span>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </div>
        </b-row>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import PaymentReviewTemplate from "@/components/paymentAdequacy/paymentReviewTemplate"
import PaymentAdequacy from "@/api/PaymentAdequacy.js"
import { paymentAdequacyMixin } from "@/mixins/paymentAdequacyMixin"
import headerBlock from "@/components/header"
import { mapGetters } from "vuex"
import pageSection from "@/components/pageSection"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

export default {
  components: {
    PaymentReviewTemplate,
    headerBlock,
    pageSection,
  },
  filters: {
    formattedDate(date) {
      return dayjs(date).format("Do MMM YY")
    },
  },
  mixins: [paymentAdequacyMixin],
  data() {
    return {
      newReview: null,
      adequacyResponse: null,
      accountDetails: null,
      accountNumber: null,
      isBusy: false,
      completedReviews: {
        xAxis: [],
        yAxis: [],
      },
    }
  },
  computed: {
    ...mapGetters("sixMonthAdDayOne", ["lookupResults"]),
  },
  created() {
    this.$store.dispatch("sixMonthAdDayOne/getRefundWeight")
    PaymentAdequacy.getCompletedReviews()
      .then((response) => {
        var orderedDates = {}
        Object.keys(response.data)
          .sort(function (a, b) {
            return (
              dayjs(b, "DD/MM/YYYY").toDate() - dayjs(a, "DD/MM/YYYY").toDate()
            )
          })
          .forEach(function (key) {
            orderedDates[key] = response.data[key]
          })
        this.completedReviews.xAxis = Object.keys(orderedDates)
        this.completedReviews.yAxis = Object.values(orderedDates)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
  },

  methods: {
    clear() {
      this.newReview = null
      this.accountDetails = null
      this.accountNumber = null
    },
    createAdequacy(newReview) {
      this.adequacyResponse = null
      PaymentAdequacy.create(newReview.account.junifer_account_number).then(
        (response) => {
          this.adequacyResponse = response.data
        }
      )
    },
    accountLookup(data) {
      var that = this
      this.accountDetails = null
      this.isBusy = true
      PaymentAdequacy.fetchAccountPaymentReviews(data)
        .then((response) => {
          response.data.forEach(function (x) {
            if (!x.initial_review_completed) {
              that.initialReview(x)
            }
          })
          this.accountDetails = response.data
          this.isBusy = false
        })
        .catch((error) => {
          this.isBusy = false
        })
    },
    livePaymentReveiw(accountNumber) {
      this.newReview = null
      // var that = this
      PaymentAdequacy.livePaymentReview(accountNumber)
        .then((response) => {
          this.newReview = response.data
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error), (this.isBusy = false)
        })
    },
    dataLabels: function (data) {
      var dates = [],
        sortedDates = []
      for (
        var i = 0;
        i < data.meterpoints[0].registers[0].register_alp.length;
        i++
      ) {
        dates.push(data.meterpoints[0].registers[0].register_alp[i].date)
      }
      sortedDates = dates.sort((a, b) => a - b)
      return sortedDates
    },
    expectedDailyAccountBalance(data) {
      var allRegDailyCosts = []
      var totalDailyCosts = []

      data.meterpoints.forEach(function (x) {
        x.registers.forEach(function (y) {
          var regDailyCosts = []
          var tariff_days_left =
            new Date(x.tariff_to) - new Date().setHours(0, 0, 0, 0)
          var annualCost =
            parseFloat(y.sooc_tariff_remainder_cost) +
            (((365 - tariff_days_left) * x.sooc_standing_charge) / 100 +
              (tariff_days_left * x.standing_charge) / 100) /
              x.registers.length
          for (var i = 0; i < y.register_alp.length; i++) {
            regDailyCosts.push(y.register_alp[i].alp * annualCost)
          }
          allRegDailyCosts.push(regDailyCosts)
        })
      }),
        allRegDailyCosts.forEach(function (num) {
          for (var i = 0; i < num.length; i++) {
            var number = 0
            if (Number.isFinite(totalDailyCosts[i])) {
              number = totalDailyCosts[i]
            }
            totalDailyCosts[i] = -parseFloat(num[i]) + number
          }
        })
      var number = data.personal_projection / 365
      var new_array = totalDailyCosts.concat()
      for (var i = 1; i < totalDailyCosts.length; i++) {
        new_array[i] = new_array[i - 1] + totalDailyCosts[i] + number
      }
      return new_array
    },
    chartData(dates, data1, totalDailyCosts) {
      var graph = {
        labels: dates,
        datasets: [
          {
            label: "Live Balance",
            data: data1,
            backgroundColor: "rgba(255, 99, 132, 0.2)",
          },
          {
            label: "Expected Balance",
            data: totalDailyCosts,
            backgroundColor: "rgba(255, 159, 64, 0.2)",
          },
        ],
      }
      return graph
    },
    daysDiff(startDate, endDate) {
      var now = dayjs(startDate) //todays date
      var end = dayjs(endDate) // another date
      var dayDifference = now.diff(end, "days")
      return -dayDifference
    },
  },
}
</script>

<style lang="scss">
.modal-header .close {
  display: none;
}
</style>
