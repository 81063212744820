import WhdApi from "@/api/WarmHomeDiscount.js"

export const whdMixin = {
  data() {
    return {
      criteria1Filter: "",
      criteria2Filter: "",
      assignedToFilter: "",
      statusFilter: "",
    }
  },
  methods: {
    secondCriteria(selection) {
      let secondCriteriaList = []

      if (selection && this.dropdownOptions.criteria.length) {
        let firstCriteria = this.dropdownOptions.criteria.find(
          (criteria) => criteria.id == selection
        )
        if (firstCriteria) {
          let secondCriteria = firstCriteria?.secondary_whd_criteria?.map(
            (value) => ({
              value: value.id.toString(),
              text: value.text,
            })
          )
          secondCriteriaList = secondCriteria
        }
      }
      return secondCriteriaList
    },
    tableData(accountList) {
      let accounts = Object.assign([], accountList)

      if (this.statusFilter) {
        accounts = accounts.filter(
          (account) => this.statusFilter === account.ark_status
        )
      }

      if (this.assignedToFilter) {
        accounts = accounts.filter(
          (account) => this.assignedToFilter === account.agent
        )
      }

      if (this.criteria1Filter) {
        accounts = accounts.filter(
          (account) => this.criteria1Filter == account.primary_whd_criterion_id
        )

        if (this.criteria2Filter) {
          accounts = accounts.filter(
            (account) =>
              this.criteria2Filter == account.secondary_whd_criterion_id
          )
        }
      }
      return accounts
    },
    updateFilters(selection) {
      switch (selection.name) {
        case "criteria1":
          this.criteria1Filter = selection.value
          break
        case "criteria2":
          this.criteria2Filter = selection.value
          break
        case "assignedTo":
          this.assignedToFilter = selection.value
          break
        case "status":
          this.statusFilter = selection.value
          break
        default:
          return
      }
    },
    registerChange(event, account) {
      let saveChanges = {}
      let getAccount = this.accounts.find(
        (storedAccount) =>
          storedAccount.junifer_account_number ===
          account.junifer_account_number
      )
      if (getAccount) {
        saveChanges = getAccount.account_data_changed
        getAccount.accountUpdateSuccess = false
        getAccount.accountUpdateFail = false
      }

      switch (event.name) {
        case "criteria1":
          saveChanges.cr1 =
            account.account_data_changed.originalPrimaryCriteria != event.value
          // save change so second criteria updates
          getAccount.primary_whd_criterion_id = parseInt(event.value, 10)
          break
        case "criteria2":
          saveChanges.cr2 = account.secondary_whd_criterion_id != event.value
          break
        case "assignedTo":
          saveChanges.agent = account.agent != event.value
          break
        case "status":
          saveChanges.status = account.ark_status != event.value
          break
        default:
          return
      }

      saveChanges.all =
        saveChanges.cr1 ||
        saveChanges.cr2 ||
        saveChanges.agent ||
        saveChanges.status
    },
    submitAccountUpdate(event, account) {
      let data = {
        warm_home_discounts_interface: {
          account_numbers: [account.junifer_account_number],
          new_attributes: {},
        },
      }
      let endpoint = "updateManyApplications"

      for (let i = 0; i < event.target.length; i++) {
        let formEl = event.target[i]
        switch (formEl.name) {
          case "criteria1":
            data.warm_home_discounts_interface.new_attributes.first_criteria_selection =
              formEl.value
            break
          case "criteria2":
            data.warm_home_discounts_interface.new_attributes.second_criteria_selection =
              formEl.value
            break
          case "assignedTo":
            data.warm_home_discounts_interface.new_attributes.agent =
              formEl.value
            break
          case "status":
            data.warm_home_discounts_interface.new_attributes.ark_status =
              formEl.value
            if (formEl.value === "no_evidence_provided")
              endpoint = "closeNoEvidence"
            break
          default:
            break
        }
      }

      let updatedAccount = this.accounts.find(
        (storedAccount) =>
          storedAccount.junifer_account_number ===
          account.junifer_account_number
      )
      updatedAccount.accountUpdateSuccess = false
      updatedAccount.accountUpdateFail = false

      WhdApi[endpoint](data)
        .then((response) => {
          if (
            response.data &&
            response.data?.[endpoint]?.errors?.length === 0 &&
            response.data?.[endpoint]?.successful_account_numbers.length > 0
          ) {
            updatedAccount.accountUpdateSuccess = true
            setTimeout(() => this.$emit("update-tables"), 800)
          } else {
            updatedAccount.accountUpdateFail = true
          }
        })
        .catch((error) => {
          console.log(error)
          updatedAccount.accountUpdateFail = true
        })
    },
    checkAccountUpdate(account, type) {
      let updatedAccount = this.accounts.find(
        (storedAccount) =>
          storedAccount.junifer_account_number ===
          account.junifer_account_number
      )

      if (type === "fail") {
        return updatedAccount.accountUpdateFail
      } else if (type === "success") {
        return updatedAccount.accountUpdateSuccess
      } else {
        return false
      }
    },
  },
}
