<template>
  <Modal
    v-if="show"
    class="cancellation-modal"
    :is-modal-open="show"
    :show-close-icon="true"
    :is-full-screen="true"
    :has-input-content="false"
    :is-desktop-width-expanded="true"
    @closeModal="closeModal"
  >
    <template slot="header">
      <h4 class="cancellation-modal__header">Appointment Details</h4>
    </template>
    <template slot="body">
      <div class="cancellation-modal__body">
        <section class="cancellation-modal__section">
          <p class="cancellation-modal__text">
            <span class="cancellation-modal__title">Reference Number:</span>
            {{ booking["aes-job-number"] }}
          </p>
          <p class="cancellation-modal__text">
            <span class="cancellation-modal__title">Appointment Date:</span>
            {{ appointmentDate }}
          </p>
          <p class="cancellation-modal__text">
            <span class="cancellation-modal__title">Time:</span>
            {{ appointmentTime }}
          </p>
        </section>
        <section v-if="bookingState" class="cancellation-modal__section">
          <p class="cancellation-modal__text">
            <span class="cancellation-modal__title">Appointment Status:</span>
            {{ bookingState }}
          </p>
        </section>
        <section class="cancellation-modal__section">
          <p class="cancellation-modal__text">
            <span class="cancellation-modal__title">Customer Details</span>
          </p>
          <div class="cancellation-modal__customer">
            <div class="cancellation-modal__customer-contact">
              <p>{{ booking["customer-name"] }}</p>
              <p>{{ booking["customer-email"] }}</p>
              <p>{{ booking["customer-phone"] }}</p>
            </div>
            <div class="cancellation-modal__customer-address">
              <p>{{ booking.address }}</p>
              <p>{{ booking.postcode }}</p>
            </div>
          </div>
        </section>
        <section class="cancellation-modal__section">
          <p class="cancellation-modal__text">
            <span class="cancellation-modal__title">Job Type</span>
          </p>
          <ol class="cancellation-modal__list">
            <li v-if="booking['electricity-meter-serial-number']">
              <b>Meter {{ booking["electricity-meter-serial-number"] }}</b>
              {{ booking.jobDetails }}
            </li>
            <li v-if="booking['gas-meter-serial-number']">
              <b>Meter {{ booking["gas-meter-serial-number"] }}</b>
              {{ booking.jobDetails }}
            </li>
          </ol>
        </section>
        <section
          v-if="booking['medical-equipment']"
          class="cancellation-modal__section"
        >
          <div class="cancellation-modal__text">
            <span class="cancellation-modal__title">Medical Equipment</span>
            <p>
              {{ booking["medical-equipment"] ? "Yes" : "No" }}
            </p>
          </div>
        </section>
      </div>
    </template>
    <template slot="footer">
      <div v-if="eligibleToCancel" class="cancellation-modal__footer">
        <AppButton
          v-if="!cancelError && !cancelSuccess"
          variant="primary"
          :loading="cancelPending"
          @click="cancelAppointment"
        >
          Cancel Appointment
        </AppButton>
        <FeedbackDialog
          v-if="!cancelPending && cancelSuccess"
          variant="positive"
        >
          {{ cancelSuccess }}
        </FeedbackDialog>
        <FeedbackDialog v-if="!cancelPending && cancelError" variant="negative">
          {{ cancelError }}
        </FeedbackDialog>
      </div>
      <div v-else class="cancellation-modal__footer--info">
        <FeedbackDialog variant="info">
          This appointment is not eligible to be changed.
        </FeedbackDialog>
      </div>
    </template>
  </Modal>
</template>

<script>
import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import Modal from "@soenergy/frontend-library/src/components/Modal"
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog"
import jobTypes from "@/constants/imbp_jobs"
import { dateFormatMixin } from "../../mixins/dateFormatMixin"

export default {
  components: {
    AppButton,
    FeedbackDialog,
    Modal,
  },
  mixins: [dateFormatMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    booking: {
      type: Object,
      default: () => ({}),
    },
    bookingState: {
      type: String,
      default: "",
    },
    eligibleToCancel: {
      type: Boolean,
      default: true,
    },
    cancelPending: {
      type: Boolean,
      default: false,
    },
    cancelSuccess: {
      type: String,
      default: "",
    },
    cancelError: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      jobTypes: jobTypes,
    }
  },
  computed: {
    appointmentDate() {
      const slotStartDateTime = this.booking?.["slot-start-datetime"]
      return this.appointmentDateFormatted(slotStartDateTime)
    },
    appointmentTime() {
      const slotStartDateTime = this.booking?.["slot-start-datetime"]
      const slotEndDateTime = this.booking?.["slot-end-datetime"]
      return this.appointmentTimeFormatted(slotStartDateTime, slotEndDateTime)
    },
  },
  methods: {
    closeModal() {
      this.$emit("close-cancellation-modal")
    },
    cancelAppointment() {
      this.$emit("cancel-appointment", this.booking["aes-job-number"])
    },
  },
}
</script>

<style lang="scss" scoped>
.cancellation-modal {
  background-color: rgba(0, 0, 0, 0.63);
  opacity: 1;

  &__header {
    margin: $space-4 0 $space-9;
  }
  &__body {
    padding-bottom: $space-9;
  }
  &__footer {
    padding: $space-8;

    &--info {
      padding: $space-3;
    }
  }

  &__section {
    margin-bottom: 52px;
  }
  &__text {
    font-size: $font-size-4;
    margin-bottom: $space-3;
    line-height: 22px;

    p {
      margin: $space-5 0 0 0;
    }
  }
  &__title {
    font-size: $font-size-5;
    font-weight: $weight-medium;
  }
  &__customer {
    display: flex;
    gap: $space-4;
  }
  &__customer-contact,
  &__customer-address {
    font-size: $font-size-4;

    p {
      margin-bottom: 0;
    }
  }

  &__list {
    font-size: $font-size-4;
    padding-top: 5px;

    li {
      margin-bottom: $space-4;

      b {
        display: block;
      }
    }
  }
}
</style>
