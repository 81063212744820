const textStyle = `max-width:700px; padding: 10px; margin: 0 auto;`
const footer = `<div style='${textStyle}'>
        Thanks,
        <br>Charlie
        <br>
        <br>
        <img src='https://s3-eu-west-1.amazonaws.com/soenergy-production/assets/freshdesk/So+Energy+Logo+v2.png' style='middle;' valign='middle' width='140'>
    </div>`

export default { footer, textStyle }
