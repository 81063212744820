<template>
  <div class="manage-bookings">
    <div class="manage-bookings__inner">
      <section class="manage-bookings__header">
        <h2>Smart Meter Booking Portal</h2>
        <p>
          Upload accounts to start offering them a smart meter. See and edit the
          status of every account that has been offered a smart meter.
        </p>
        <a href="/admin/user_guides" class="button button--blue-outlined">
          <i class="far fa-question-circle" />
          User Guide
        </a>
      </section>
      <section class="manage-bookings__body">
        <div class="manage-bookings__left-column">
          <div class="manage-bookings__filters">
            <FilterButton
              :title="
                'Pre Booking (' +
                (arkTotal.preBookAccounts + arkTotal.campaignEndedAccounts) +
                ')'
              "
              name="pre-booking"
              :active="activeFilter === 'pre-booking'"
              @filterClick="filterClick"
            />
            <FilterButton
              :title="'Booked (' + arkTotal.bookedAccounts + ')'"
              name="booked"
              :active="activeFilter === 'booked'"
              @filterClick="filterClick"
            />
            <FilterButton
              :title="'Failed (' + arkTotal.failedAccounts + ')'"
              name="failed"
              :active="activeFilter === 'failed'"
              @filterClick="filterClick"
            />
            <FilterButton
              :title="
                'Ineligible (' +
                (arkTotal.ineligibleAccounts + arkTotal.installedAccounts) +
                ')'
              "
              name="ineligible"
              :active="activeFilter === 'ineligible'"
              @filterClick="filterClick"
            />
          </div>
          <div class="manage-bookings__tables">
            <GenericTable
              v-if="arkTotal.preBookAccounts"
              v-show="activeFilter === 'pre-booking'"
              :table-header="preBookingHeader"
              color-scheme="blue"
              :is-load-more="isLoadMoreShown"
              :loading-status="loadMoreStatus.name"
              :loading-status-active="loadMoreStatus.type === 'prebooking'"
              :separator-text="
                'Campaign in Progress (' + arkTotal.preBookAccounts + ')'
              "
              :column-ratio="[3, 52, 15, 15, 15]"
              @loadMoreTableRows="loadMoreTableRows('prebooking')"
            >
              <GenericTableRow
                v-for="(account, index) in preBookingAccounts"
                :key="index"
                :column-ratio="[3, 52, 15, 15, 15]"
              >
                <template v-slot:column-1>
                  <span class="generic-table__account-id">
                    {{ account.junifer_account_number }}
                  </span>
                </template>
                <template v-slot:column-2><label>In Progress</label></template>
                <template v-slot:column-3>
                  <div class="generic-table__checkbox">
                    <input
                      type="checkbox"
                      :checked="
                        isChecked(
                          account.junifer_account_number,
                          'pre-booking',
                          'ineligible'
                        )
                      "
                      @change.stop.prevent="
                        changeAccountStatus(
                          'pre-booking',
                          'ineligible',
                          account
                        )
                      "
                      @click.stop
                    />
                  </div>
                </template>
                <template v-slot:column-4></template>
              </GenericTableRow>
            </GenericTable>

            <GenericTable
              v-if="arkTotal.campaignEndedAccounts"
              v-show="activeFilter === 'pre-booking'"
              color-scheme="blue"
              :is-load-more="isLoadMoreShown"
              :loading-status="loadMoreStatus.name"
              :loading-status-active="loadMoreStatus.type === 'campaign_ended'"
              :is-attached="true"
              :separator-text="
                'Campaign Ended (' + arkTotal.campaignEndedAccounts + ')'
              "
              @loadMoreTableRows="loadMoreTableRows('campaign_ended')"
            >
              <GenericTableRow
                v-for="(account, index) in campaignEndedAccounts"
                :key="index"
                :column-ratio="[3, 52, 15, 15, 15]"
              >
                <template v-slot:column-1>
                  <span class="generic-table__account-id">
                    {{ account.junifer_account_number }}
                  </span>
                </template>
                <template v-slot:column-2><label>Ended</label></template>
                <template v-slot:column-3>
                  <div class="generic-table__checkbox">
                    <input
                      type="checkbox"
                      :checked="
                        isChecked(
                          account.junifer_account_number,
                          'ended',
                          'ineligible'
                        )
                      "
                      @change.stop.prevent="
                        changeAccountStatus('ended', 'ineligible', account)
                      "
                      @click.stop
                    />
                  </div>
                </template>
                <template v-slot:column-4>
                  <div class="generic-table__checkbox">
                    <input
                      type="checkbox"
                      :checked="
                        isChecked(
                          account.junifer_account_number,
                          'ended',
                          'pre-booking'
                        )
                      "
                      @change.stop.prevent="
                        changeAccountStatus('ended', 'pre-booking', account)
                      "
                      @click.stop
                    />
                  </div>
                </template>
              </GenericTableRow>
            </GenericTable>

            <GenericTable
              v-if="arkTotal.bookedAccounts"
              v-show="activeFilter === 'booked'"
              :table-header="bookedHeader"
              color-scheme="green"
              :is-load-more="isLoadMoreShown"
              :loading-status="loadMoreStatus.name"
              :loading-status-active="loadMoreStatus.type === 'booked'"
              :column-ratio="[3, 20, 32, 15, 15, 15]"
              @loadMoreTableRows="loadMoreTableRows('booked')"
            >
              <GenericTableRow
                v-for="(account, index) in bookedAccounts"
                :key="index"
                :column-ratio="[3, 20, 32, 15, 15, 15]"
              >
                <template v-slot:column-1>
                  <span class="generic-table__account-id">
                    {{ account.junifer_account_number }}
                  </span>
                </template>
                <template v-slot:column-2>
                  {{ account.customer_details.name }}
                </template>
                <template v-slot:column-3>
                  {{ account.customer_details.postcode }}
                </template>
                <template v-slot:column-4><label>Booked</label></template>
                <template v-slot:column-5>
                  {{ installDate(account) }}
                </template>
                <template v-slot:dropdown>
                  <AccountDetails
                    :account="account"
                    class="generic-table__dropdown"
                    @make-new-booking="openReactivateBookingModal"
                  />
                </template>
              </GenericTableRow>
            </GenericTable>

            <GenericTable
              v-if="arkTotal.failedAccounts"
              v-show="activeFilter === 'failed'"
              :table-header="failedHeader"
              color-scheme="orange"
              :is-load-more="isLoadMoreShown"
              :loading-status="loadMoreStatus.name"
              :loading-status-active="loadMoreStatus.type === 'failed'"
              :column-ratio="[3, 20, 32, 15, 15, 15]"
              @loadMoreTableRows="loadMoreTableRows('failed')"
            >
              <GenericTableRow
                v-for="(account, index) in failedAccounts"
                :key="index"
                :column-ratio="[3, 20, 32, 15, 15, 15]"
              >
                <template v-slot:column-1>
                  <span class="generic-table__account-id">
                    {{ account.junifer_account_number }}
                  </span>
                </template>
                <template v-slot:column-2>
                  {{ account.customer_details.name }}
                </template>
                <template v-slot:column-3>
                  {{ account.customer_details.postcode }}
                </template>
                <template v-slot:column-4>
                  <div class="generic-table__checkbox">
                    <input
                      type="checkbox"
                      :checked="
                        isChecked(
                          account.junifer_account_number,
                          'failed',
                          'ineligible'
                        )
                      "
                      @change.stop.prevent="
                        changeAccountStatus('failed', 'ineligible', account)
                      "
                      @click.stop
                    />
                  </div>
                </template>
                <template v-slot:column-5>
                  <div class="generic-table__checkbox">
                    <input
                      type="checkbox"
                      :checked="
                        isChecked(
                          account.junifer_account_number,
                          'failed',
                          'pre-booking'
                        )
                      "
                      @change.stop.prevent="
                        changeAccountStatus('failed', 'pre-booking', account)
                      "
                      @click.stop
                    />
                  </div>
                </template>
                <template v-slot:dropdown>
                  <AccountDetails
                    :account="account"
                    warning="failed"
                    class="generic-table__dropdown"
                  />
                </template>
              </GenericTableRow>
            </GenericTable>

            <GenericTable
              v-if="arkTotal.ineligibleAccounts"
              v-show="activeFilter === 'ineligible'"
              :table-header="ineligibleHeader"
              color-scheme="red"
              :is-load-more="isLoadMoreShown"
              :loading-status="loadMoreStatus.name"
              :loading-status-active="loadMoreStatus.type === 'ineligible'"
              :separator-text="
                'Campaign suspended (' + arkTotal.ineligibleAccounts + ')'
              "
              :column-ratio="[3, 20, 32, 15, 15, 15]"
              @loadMoreTableRows="loadMoreTableRows('ineligible')"
            >
              <GenericTableRow
                v-for="(account, index) in ineligibleAccounts"
                :key="index"
                :column-ratio="[3, 20, 32, 15, 15, 15]"
              >
                <template v-slot:column-1>
                  <span class="generic-table__account-id">
                    {{ account.junifer_account_number }}
                  </span>
                </template>
                <template v-slot:column-2>
                  {{ account.customer_details.name || "-" }}
                </template>
                <template v-slot:column-3>
                  {{ account.customer_details.postcode || "-" }}
                </template>
                <template v-slot:column-4><label>Ineligible</label></template>
                <template v-slot:column-5>
                  <div class="generic-table__checkbox">
                    <input
                      type="checkbox"
                      :checked="
                        isChecked(
                          account.junifer_account_number,
                          'ineligible',
                          'pre-booking'
                        )
                      "
                      @change.stop.prevent="
                        changeAccountStatus(
                          'ineligible',
                          'pre-booking',
                          account
                        )
                      "
                      @click.stop
                    />
                  </div>
                </template>
                <template
                  v-if="account.customer_details.postcode"
                  v-slot:dropdown
                >
                  <AccountDetails
                    :account="account"
                    warning="ineligible"
                    class="generic-table__dropdown"
                  />
                </template>
              </GenericTableRow>
            </GenericTable>

            <GenericTable
              v-if="arkTotal.installedAccounts"
              v-show="activeFilter === 'ineligible'"
              color-scheme="red"
              :is-load-more="isLoadMoreShown"
              :loading-status="loadMoreStatus.name"
              :loading-status-active="loadMoreStatus.type === 'installed'"
              :is-attached="true"
              :separator-text="'Installed (' + arkTotal.installedAccounts + ')'"
              @loadMoreTableRows="loadMoreTableRows('installed')"
            >
              <GenericTableRow
                v-for="(account, index) in installedAccounts"
                :key="index"
                :column-ratio="[3, 20, 32, 15, 15, 15]"
              >
                <template v-slot:column-1>
                  <span class="generic-table__account-id">
                    {{ account.junifer_account_number }}
                  </span>
                </template>
                <template v-slot:column-2>
                  {{ account.customer_details.name }}
                </template>
                <template v-slot:column-3>
                  {{ account.customer_details.postcode }}
                </template>
                <template v-slot:column-4><label>Installed</label></template>
                <template v-slot:column-5></template>
                <template v-slot:dropdown>
                  <AccountDetails
                    :account="account"
                    class="generic-table__dropdown"
                  />
                </template>
              </GenericTableRow>
            </GenericTable>

            <p v-if="isFilterBlank">We have no datas from the selected type.</p>
          </div>
        </div>
        <div class="manage-bookings__right-column">
          <SidebarCard color-select="blue">
            <template v-slot:header>No availability data </template>
            <NoAvailabilityData />
          </SidebarCard>
          <SidebarCard color-select="blue">
            <template v-slot:header> Bulk upload customers </template>
            <UploadAccounts
              @response="handleAccountsUpload"
              @error="
                (error) => {
                  modalError = error
                }
              "
            />
          </SidebarCard>
          <SidebarCard color-select="blue">
            <template v-slot:header> Search by account </template>
            <AccountSearch
              :search-error="searchError"
              @accountSearch="handleAccountSearch"
              @clearAccountSearch="clearAccountSearch"
            />
          </SidebarCard>
          <SidebarCard color-select="blue">
            <template v-slot:header> Status updates </template>
            <UpdateStatus
              :status-changes="statusChanges"
              :update-succeeded="updateSucceeded"
              @updateStatus="handleStatusUpdate"
            />
          </SidebarCard>
        </div>
        <AppModal
          :show="showReactivateBookingModal"
          @close="showReactivateBookingModal = false"
        >
          <template slot="header">
            <h2 class="modal__header">Are you sure you wish to continue?</h2>
          </template>
          <template slot="body">
            <p class="modal__text">
              This action will delete any booking data for this customer's
              booking
            </p>
            <p class="modal__text">
              Account number: {{ reActivateBookingAccount }}
            </p>
          </template>
          <template slot="footer">
            <AppButton
              variant="secondary"
              @click="showReactivateBookingModal = false"
            >
              Cancel
            </AppButton>
            <AppButton
              class="modal__confirmation-button"
              :loading="loadingReActivateBookingAccount"
              @click="makeNewBooking(reActivateBookingAccount)"
            >
              Confirm
            </AppButton>
            <p v-if="errorReActivateBookingAccount" class="modal__error-text">
              Sorry, there was an error please try again
            </p>
          </template>
        </AppModal>
        <AppModal
          :show="showJobQueuedModal"
          @close="showJobQueuedModal = false"
        >
          <template slot="header">
            <h2 class="modal__header">Job queued for 1am</h2>
          </template>
          <template slot="body">
            <p class="modal__text">
              The accounts will not be uploaded until 1am, once the job runs it
              will send emails out to the relevant inboxes
            </p>
          </template>
          <template slot="footer">
            <button
              class="button button--blue"
              @click="showJobQueuedModal = false"
            >
              Close
            </button>
          </template>
        </AppModal>
        <AccountsUpdateErrorModal
          :show="showUpdateModal"
          :account-numbers="updateFailedAccounts"
          @close="closeStatusUpdateModal"
        ></AccountsUpdateErrorModal>
        <AppModal
          :show="showGenericModal"
          style-modifiers="modal--error"
          @close="showGenericModal = false"
        >
          <template slot="header">
            <h2 class="modal__header">Error updating accounts</h2>
          </template>
          <template slot="body">
            <p class="modal__text">
              Sorry, something went wrong. Please reload this page and try
              again. If the problem persists, please report this as a bug.
            </p>
          </template>
        </AppModal>
        <AppModal
          :show="Boolean(modalError)"
          style-modifiers="modal--error"
          @close="modalError = null"
        >
          <template slot="header">
            <h2 class="modal__header">Error:</h2>
          </template>
          <template v-if="modalError" slot="body">
            <div class="modal__text">
              {{ modalError.message }}
            </div>
            <p class="modal__text">
              Sorry, something went wrong. Please reload this page and try
              again. If the problem persists, please report this as a bug.
            </p>
          </template>
        </AppModal>
      </section>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs"
import { mapGetters } from "vuex"
import SmartMeterApi from "@/api/SmartMeter.js"
import UploadAccounts from "@/components/smartMeter/UploadAccounts"
import UpdateStatus from "@/components/smartMeter/UpdateStatus"
import AccountSearch from "@/components/smartMeter/AccountSearch"
import FilterButton from "@/components/smartMeter/FilterButton"
import GenericTable from "@/components/smartMeter/GenericTable"
import GenericTableRow from "@/components/smartMeter/GenericTableRow"
import AccountDetails from "@/components/smartMeter/AccountDetails"
import NoAvailabilityData from "@/components/smartMeter/NoAvailabilityData"
import AppModal from "@/components/smartMeter/AppModal.vue"
import AccountsUpdateErrorModal from "@/components/smartMeter/AccountsUpdateErrorModal.vue"
import SidebarCard from "@/components/SidebarCard.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"

export default {
  name: "ManageBookings",
  components: {
    NoAvailabilityData,
    UploadAccounts,
    UpdateStatus,
    FilterButton,
    GenericTable,
    GenericTableRow,
    AccountDetails,
    AppModal,
    AccountsUpdateErrorModal,
    SidebarCard,
    AccountSearch,
    AppButton,
  },
  data() {
    return {
      activeFilter: "pre-booking",
      preBookingHeader: [
        "",
        "Account Number",
        "Status",
        "Ineligible",
        "Restart",
      ],
      bookedHeader: [
        "",
        "Account Number",
        "Name",
        "Postcode",
        "Status",
        "Install Date",
      ],
      failedHeader: [
        "",
        "Account Number",
        "Name",
        "Postcode",
        "Ineligible",
        "Rebook",
      ],
      ineligibleHeader: [
        "",
        "Account Number",
        "Name",
        "Postcode",
        "Status",
        "Eligible",
      ],
      statusChanges: [
        {
          status: {
            name: "pre-booking",
            text: "In Progress",
            color: "blue",
          },
          newStatus: {
            name: "ineligible",
            text: "Ineligible",
            color: "red",
          },
          items: [],
        },
        {
          status: {
            name: "ended",
            text: "Ended",
            color: "blue",
          },
          newStatus: {
            name: "ineligible",
            text: "Ineligible",
            color: "red",
          },
          items: [],
        },
        {
          status: {
            name: "ended",
            text: "Ended",
            color: "blue",
          },
          newStatus: {
            name: "pre-booking",
            text: "Restart",
            color: "blue",
          },
          items: [],
        },
        {
          status: {
            name: "failed",
            text: "Failed",
            color: "orange",
          },
          newStatus: {
            name: "pre-booking",
            text: "Rebook",
            color: "blue",
          },
          items: [],
        },
        {
          status: {
            name: "failed",
            text: "Failed",
            color: "orange",
          },
          newStatus: {
            name: "ineligible",
            text: "Ineligible",
            color: "red",
          },
          items: [],
        },
        {
          status: {
            name: "ineligible",
            text: "Ineligible",
            color: "red",
          },
          newStatus: {
            name: "pre-booking",
            text: "Eligible",
            color: "blue",
          },
          items: [],
        },
      ],
      updateSucceeded: false,
      updateFailedAccounts: [],
      reActivateBookingAccount: null,
      showReactivateBookingModal: false,
      loadingReActivateBookingAccount: false,
      errorReActivateBookingAccount: false,
      showJobQueuedModal: false,
      showUpdateModal: false,
      showGenericModal: false,
      searchError: "",
      searchSuccess: false,
      loadMoreStatus: {},
      modalError: null,
      arkTotal: {
        preBookAccounts: 0,
        campaignEndedAccounts: 0,
        bookedAccounts: 0,
        failedAccounts: 0,
        ineligibleAccounts: 0,
        installedAccounts: 0,
      },
    }
  },
  computed: {
    ...mapGetters({
      preBookingAccounts: "smartMeter/preBooking",
      campaignEndedAccounts: "smartMeter/campaignEnded",
      bookedAccounts: "smartMeter/booked",
      failedAccounts: "smartMeter/failed",
      ineligibleAccounts: "smartMeter/ineligible",
      installedAccounts: "smartMeter/installed",
      existingAccountList: "smartMeter/existingAccountList",
      searchResultAccountList: "smartMeter/searchResultAccountList",
    }),
    isFilterBlank() {
      let preBook =
        !this.preBookingAccounts.length &&
        !this.campaignEndedAccounts.length &&
        this.activeFilter === "pre-booking"
      let ineligible =
        !this.ineligibleAccounts.length &&
        !this.installedAccounts.length &&
        this.activeFilter === "ineligible"
      let filterStatus =
        preBook ||
        (!this.bookedAccounts.length && this.activeFilter === "booked") ||
        (!this.failedAccounts.length && this.activeFilter === "failed") ||
        ineligible
      return filterStatus
    },
    isLoadMoreShown() {
      return !this.searchSuccess
    },
  },
  watch: {
    "$store.state.smartMeter.loadMoreStatus.name"(value) {
      this.loadMoreStatus = this.$store.state.smartMeter.loadMoreStatus
    },
    searchResultAccountList(value) {
      if (this.preBookingAccounts.length || this.campaignEndedAccounts.length)
        this.activeFilter = "pre-booking"
      if (this.bookedAccounts.length) this.activeFilter = "booked"
      if (this.failedAccounts.length) this.activeFilter = "failed"
      if (this.ineligibleAccounts.length || this.installedAccounts.length)
        this.activeFilter = "ineligible"
      if (!value.length) this.activeFilter = "pre-booking"
    },
  },
  mounted() {
    this.getAccounts()
  },
  methods: {
    getAccounts() {
      this.arkStatusTotal()
      // clear any previous
      this.$store.dispatch("smartMeter/clearAccounts")
      // get new datas
      this.$store.dispatch("smartMeter/triggerAccountAPI", {
        filter: "prebooking",
        downloadedAccounts: [],
      })
      this.$store.dispatch("smartMeter/triggerAccountAPI", {
        filter: "booked",
        downloadedAccounts: [],
      })
      this.$store.dispatch("smartMeter/triggerAccountAPI", {
        filter: "failed",
        downloadedAccounts: [],
      })
      this.$store.dispatch("smartMeter/triggerAccountAPI", {
        filter: "ineligible",
        downloadedAccounts: [],
      })
      this.$store.dispatch("smartMeter/triggerAccountAPI", {
        filter: "campaign_ended",
        downloadedAccounts: [],
      })
      this.$store.dispatch("smartMeter/triggerAccountAPI", {
        filter: "installed",
        downloadedAccounts: [],
      })
    },
    arkStatusTotal() {
      SmartMeterApi.getArkStatusTotals()
        .then((response) => {
          if (response && response.data) {
            let arkStatus = response.data
            this.arkTotal.preBookAccounts =
              arkStatus.prebooking.campaigns_in_progress

            this.arkTotal.campaignEndedAccounts =
              arkStatus.prebooking.campaigns_ended

            this.arkTotal.bookedAccounts = arkStatus.booked

            this.arkTotal.failedAccounts =
              arkStatus.failed.critical_failures +
              arkStatus.failed.non_critical_failures

            this.arkTotal.ineligibleAccounts =
              arkStatus.ineligible.campaigns_suspended

            this.arkTotal.installedAccounts = arkStatus.ineligible.installed
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    loadMoreTableRows(type) {
      this.$store.commit("smartMeter/LOAD_MORE_STATUS", {
        name: "loading",
        type,
      })
      this.$store.dispatch("smartMeter/triggerAccountAPI", {
        filter: type,
        downloadedAccounts: this.existingAccountList,
        loadMoreUpdate: true,
      })
    },
    filterClick(name) {
      this.activeFilter = name
    },
    handleStatusUpdate(accounts) {
      this.updateSucceeded = false
      this.updateFailedAccounts = []
      let anyErrors = false

      this.updateStatuses(accounts)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            if (response[i].error) {
              // if we have generic errors, show error modal
              this.showUpdateModal = false
              this.showGenericModal = true
              anyErrors = true
              break
            } else if (response[i].length) {
              // collect specific error account numbers into an array
              this.updateFailedAccounts = this.updateFailedAccounts.concat(
                response[i]
              )
            }
          }

          if (this.updateFailedAccounts.length && !anyErrors) {
            // if we have update errors, show update error modal
            this.showUpdateModal = true
          } else if (!anyErrors) {
            // if we dont have errors clear on move on
            this.showUpdateModal = false
            this.showGenericModal = false
            this.updateSucceeded = true
            this.clearAllStatusChanges()
          }
        })
        .catch((error) => {
          this.showUpdateModal = false
          this.showGenericModal = true
        })
    },
    async updateStatuses(accounts) {
      let statusAPIs = []
      let eligible = []
      let ineligible = []
      let restart = []
      let reActivate = []

      for (let i = 0; i < accounts.length; i++) {
        let account = accounts[i]
        if (account.status.name === "ineligible") {
          eligible = eligible.concat(account.items)
        } else if (account.newStatus.name === "ineligible") {
          ineligible = ineligible.concat(account.items)
        } else if (
          account.status.name === "ended" &&
          account.newStatus.name === "pre-booking"
        ) {
          restart = restart.concat(account.items)
        } else if (
          account.status.name === "failed" &&
          account.newStatus.name === "pre-booking"
        ) {
          reActivate = reActivate.concat(account.items)
        }
      }

      if (eligible.length) {
        let markEligibleResponse = this.updateStatusAPI(
          "markEligible",
          eligible
        )
        statusAPIs.push(markEligibleResponse)
      }
      if (ineligible.length) {
        let markIneligibleResponse = this.updateStatusAPI(
          "markIneligible",
          ineligible
        )
        statusAPIs.push(markIneligibleResponse)
      }
      if (restart.length) {
        let restartResponse = this.updateStatusAPI("restart", restart)
        statusAPIs.push(restartResponse)
      }
      if (reActivate.length) {
        let reActivateResponse = this.updateStatusAPI("reActivate", reActivate)
        statusAPIs.push(reActivateResponse)
      }

      return await Promise.all(statusAPIs)
    },
    updateStatusAPI(name, accounts) {
      let accountNumbers = {
        junifer_account_numbers: accounts.map(
          (item) => item.junifer_account_number
        ),
      }
      return SmartMeterApi[name](accountNumbers)
        .then((response) => {
          if (
            response.data &&
            response.data.errors &&
            response.data.errors.length
          ) {
            return response.data.errors.reduce(
              (accounts, error) =>
                (accounts = accounts.concat(error.junifer_account_numbers)),
              []
            )
          } else {
            return true
          }
        })
        .catch((error) => {
          return {
            error: true,
            name,
            message: `There was an error: ${error}`,
          }
        })
    },
    closeStatusUpdateModal() {
      this.showUpdateModal = false
      this.clearAllStatusChanges()
    },
    changeAccountStatus(currentValue, newValue, account) {
      // find which kind of status change we need to perform
      let statusObject = this.statusChanges.find(
        (item) =>
          item.status.name === currentValue && item.newStatus.name === newValue
      )

      if (statusObject) {
        // clear accounts previous status
        this.clearAccountStatusChange(account)

        if (event.target.checked) {
          // add item when checked
          statusObject.items.push(account)
          this.updateSucceeded = false
        }
      }
    },
    clearAccountStatusChange(account) {
      let found = false
      for (let i = 0; i < this.statusChanges.length; i++) {
        for (let j = 0; j < this.statusChanges[i].items.length; j++) {
          found =
            this.statusChanges[i].items[j].junifer_account_number ===
            account.junifer_account_number
          if (found) {
            let indexOfFound = this.statusChanges[i].items.indexOf(
              this.statusChanges[i].items[j]
            )
            this.statusChanges[i].items.splice(indexOfFound, 1)
            break
          }
        }
        if (found) break
      }
    },
    clearAllStatusChanges() {
      for (let i = 0; i < this.statusChanges.length; i++) {
        this.statusChanges[i].items = []
      }
      // Reset tables on status changes
      this.getAccounts()
    },
    isChecked(accoundId, currentValue, newValue) {
      let found = false
      for (let i = 0; i < this.statusChanges.length; i++) {
        for (let j = 0; j < this.statusChanges[i].items.length; j++) {
          found =
            this.statusChanges[i].items[j].junifer_account_number ===
              accoundId &&
            this.statusChanges[i].status.name === currentValue &&
            this.statusChanges[i].newStatus.name === newValue
          if (found) break
        }
        if (found) break
      }
      return found
    },
    //TODO: SO-7996 - this needs refactoring, ideally move error handling to UploadPostcodes.
    handleAccountsUpload(response) {
      if (response && response.data && response.data.status === 200) {
        this.showJobQueuedModal = true
      }
    },
    handleAccountSearch(accountId) {
      this.clearAccountSearch()

      SmartMeterApi.findAccounts(accountId)
        .then((response) => {
          this.searchSuccess = true
          this.$store.dispatch("smartMeter/addSearchResults", {
            results: [response.data],
          })
        })
        .catch((error) => {
          this.searchError = error.message
        })
    },
    clearAccountSearch() {
      this.searchError = ""
      this.searchSuccess = false
      if (this.searchResultAccountList.length) {
        this.$store.dispatch("smartMeter/clearSearchResults")
      }
    },
    installDate(account) {
      let date = ""
      let slot = account ? account.booking_status.slot : null
      if (slot) {
        date = dayjs(slot.date).format("DD-MM-YYYY")
      }
      return date
    },
    openReactivateBookingModal(account) {
      this.showReactivateBookingModal = true
      this.reActivateBookingAccount = account
    },
    makeNewBooking(account) {
      this.errorReActivateBookingAccount = false
      this.loadingReActivateBookingAccount = true
      let reActivateAccount = []
      reActivateAccount.push(account)
      let data = {
        junifer_account_numbers: reActivateAccount,
      }
      if (reActivateAccount.length > 0) {
        SmartMeterApi.reActivate(data)
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.successful_junifer_account_numbers &&
              response.data.successful_junifer_account_numbers.length
            ) {
              this.showReactivateBookingModal = false
              this.loadingReActivateBookingAccount = false
              this.getAccounts()
            }
          })
          .catch((error) => {
            console.log(error)
            this.loadingReActivateBookingAccount = false
            this.errorReActivateBookingAccount = true
          })
      }
    },
  },
}
</script>

<style lang="scss">
.manage-bookings {
  background-color: $day;
  padding: 0 0 $spacing-8 0;
  min-height: 100vh;

  &__inner {
    min-width: 1024px;
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
  }

  &__header {
    text-align: left;
    padding: $spacing-8 0 $spacing-8;
    position: relative;

    h2 {
      font-size: 50px;
      font-weight: $weight-bold;
      margin-bottom: $spacing-4;
    }

    p {
      font-size: $size-6;
      max-width: 900px;
    }

    .button {
      position: absolute;
      top: 75px;
      right: 0;
      font-weight: $weight-bold;

      .far {
        font-size: $size-7;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__body {
    display: flex;
  }

  &__left-column {
    flex: 0 0 75%;
  }

  &__right-column {
    flex: 0 0 25%;
    padding: 0 0 0 $spacing-6;
  }

  &__filters {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding: 0 0 50px;
  }

  &__tables {
    width: 100%;

    .generic-table__checkbox {
      margin-left: $spacing-6;

      input {
        width: 19px;
        height: 19px;
        vertical-align: text-top;
        cursor: pointer;
      }
    }
  }
}
.modal__header {
  font-weight: $weight-bold;
  margin-bottom: $spacing-6;
}
.modal__text {
  margin-bottom: $spacing-7;
}
.modal__confirmation-button {
  float: right;
}
.modal__error-text {
  color: $error-text;
  margin-top: $spacing-6;
  text-align: right;
}
</style>
