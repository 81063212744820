<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block title="Communications by account" summary="">
        <template v-slot:description-body> </template>
      </header-block>
    </b-row>

    <page-section title="">
      <template v-slot:content>
        <b-form inline>
          <input
            id="accountsearch"
            v-model="accountNumber"
            type="text"
            :disabled="isBusy"
            placeholder="Account number...."
            style="width: 300px"
            class="form-control mb-2 mr-sm-2 mb-sm-0"
          />
          <span class="input-group-btn">
            <b-button
              id="search"
              :disabled="isBusy"
              variant="outline-primary"
              class="mb-2 mr-sm-2 mb-sm-0"
              @click="accountLookup(accountNumber)"
            >
              <span v-show="!isBusy">Search</span>
              <span v-show="isBusy"><b-spinner small /></span>
            </b-button>
            <b-button
              id="clear"
              :disabled="isBusy"
              class="mb-2 mr-sm-2 mb-sm-0"
              variant="outline-danger"
              @click="clear()"
            >
              Clear
            </b-button>
          </span>
        </b-form>

        <table-preview
          :results-table="dataTable"
          :is-busy="isBusy"
          :per-page="perPage"
          :current-page="currentPage"
          show-item="body_raw"
        />
      </template>
    </page-section>
  </b-container>
</template>

<script>
import Communication from "@/api/Communication"
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import tablePreview from "@/components/tablePreview"

export default {
  components: {
    headerBlock,
    pageSection,
    tablePreview,
  },
  data() {
    return {
      communicationShow: null,
      accountNumber: null,
      isBusy: false,
      accountComms: [],
      perPage: 20,
      currentPage: 1,
    }
  },
  computed: {
    dataTable() {
      var t = {
        sortBy: "Status",
        sortDesc: false,
        fields: [
          { key: "id", label: "ID", sortable: false },
          { key: "account_number", label: "Account", sortable: false },
          {
            key: "communication_content_id",
            label: "Content ID",
            sortable: true,
          },
          {
            key: "communication_provider_id",
            label: "Carrier",
            sortable: false,
          },
          { key: "contact", label: "Contact", sortable: true },
          { key: "created_at", label: "Sent", sortable: false },
          { key: "external_id", label: "3rd Party Id", sortable: false },
        ],
        items: this.accountComms,
      }
      return t
    },
  },
  methods: {
    accountLookup(data) {
      var that = this
      this.accountComms = []
      this.isBusy = true
      Communication.lookupAccount(data)
        .then((response) => {
          this.accountComms = response.data
          this.isBusy = false
        })
        .catch((error) => {
          console.log(error), (this.isBusy = false)
        })
    },
    clear() {
      this.accountNumber = null
      this.accountComms = []
      this.communicationShow = null
    },
  },
}
</script>
