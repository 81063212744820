<template>
  <form class="upload-csv" @submit.prevent="upload">
    <div v-if="state === states.FAILED" class="upload-csv__failure">
      <i class="fas fa-exclamation-triangle"></i><span>Import failed</span>
      <p ref="uploadErrorMessage">{{ errorMessage }}</p>
    </div>
    <div
      v-if="state === states.MISSING_FILE || csvErrorMessage"
      class="upload-csv__failure"
    >
      <i class="fas fa-exclamation-triangle"></i>
      <span>{{ csvErrorMsg }}</span>
    </div>

    <p v-else class="upload-csv__info">(CSV file format)</p>
    <UploadCsvInput v-model="csvFile"></UploadCsvInput>

    <InputField
      v-if="inputField"
      v-model="inputValue"
      :label="inputLabel"
      :name="inputName"
      :error-message="hasInputError ? inputErrorMessage : null"
      :has-error="hasInputError"
      class="upload-csv__input"
      @input="state = states.IDLE"
    />
    <slot></slot>

    <button
      v-if="state === states.IDLE"
      class="button button--blue upload-csv__submit"
      type="submit"
      :disabled="csvErrorMessage !== ''"
    >
      Upload
    </button>

    <div v-if="state === states.LOADING" class="upload-csv__loading">
      <img src="@/assets/icons/loading-bar.gif" />
    </div>
    <div v-if="state === states.SUCCESS" class="upload-csv__success">
      <i class="fa fa-check-circle"></i><span>Successfully imported</span>
    </div>
  </form>
</template>

<script>
import { csvToArrayOfObjects } from "@/helpers/csv/parsers"
import UploadCsvInput from "./UploadCsvInput.vue"
import InputField from "@soenergy/frontend-library/src/components/InputField"

const STATES = Object.freeze({
  IDLE: 1,
  SUCCESS: 2,
  LOADING: 3,
  FAILED: 4,
  MISSING_FILE: 5,
  MISSING_INPUT: 6,
})

export default {
  components: {
    UploadCsvInput,
    InputField,
  },
  props: {
    parser: {
      /** Function to convert: File {type: 'text/csv'}  ->  to variable passed to apiCall.
       *  This approach was taken, as parsing of files is typically asynchrounous. */
      type: Function,
      default: csvToArrayOfObjects,
    },
    apiCall: {
      /** The axios call. */
      type: Function,
      default: () => {},
    },
    inputField: {
      type: Boolean,
      default: false,
    },
    inputName: {
      type: String,
      default: "upload_file_param",
    },
    inputLabel: {
      type: String,
      default: "",
    },
    inputErrorMessage: {
      type: String,
      default: "",
    },
    validators: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      csvFile: null,
      parsedValues: [],
      states: STATES,
      state: STATES.IDLE,
      errorMessage: null,
      csvErrorMessage: "",
      inputValue: null,
    }
  },
  computed: {
    hasInputError() {
      return this.state === this.states.MISSING_INPUT
    },
    csvErrorMsg() {
      return this.csvErrorMessage
        ? this.csvErrorMessage
        : "Please select a file to be uploaded"
    },
  },
  watch: {
    csvFile(newVal) {
      this.state = this.states.IDLE
      if (!newVal) {
        this.csvErrorMessage = ""
        return
      }
      this.parser(newVal).then((result) => {
        const errors = this.validateData(result)
        if (errors) {
          this.csvErrorMessage = errors
        } else {
          this.csvErrorMessage = ""
          this.parsedValues = result
        }
      })
    },
  },
  methods: {
    async upload() {
      if (this.inputField && !this.inputValue) {
        this.state = this.states.MISSING_INPUT
      } else if (this.parsedValues && this.csvFile) {
        this.state = this.states.LOADING
        this.apiCall(this.parsedValues, this.inputValue)
          .then((data) => {
            this.$emit("response", data)
            this.state = this.states.SUCCESS
          })
          .catch((error) => {
            this.state = this.states.FAILED
            this.errorMessage = error?.response?.data?.message || error.message
            this.$emit("error", error)
          })
      } else {
        this.state = this.states.MISSING_FILE
      }
    },
    validateData(data) {
      return this.validators
        .reduce((errors, validator) => {
          const error = validator(data)
          if (error) errors.push(error)
          return errors
        }, [])
        .join(". ")
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-csv {
  padding: 0 0 $spacing-5;
  text-align: right;

  &__info {
    font-size: $size-7;
    line-height: 1.2;
    font-weight: $weight-bold;
    color: $night-light;
    text-align: left;
  }

  &__failure {
    font-size: 14px;
    line-height: 1.2;
    text-align: left;
    position: relative;
    padding: 0 0 $spacing-4 $spacing-5;

    .fas {
      position: absolute;
      left: 0;
      top: 2px;
      color: $fire;
    }
    span {
      font-weight: $weight-bold;
      font-size: $size-7;
      color: $fire;
    }
    p {
      margin: $spacing-1 0 0 0;
    }
  }

  &__success {
    font-weight: $weight-bold;
    color: $earth;
    background-color: $green-100;
    text-align: center;
    padding: $spacing-1 0;

    span {
      margin-left: $spacing-1;
    }
  }

  &__input {
    margin-top: $space-5;
    text-align: left;
  }
}
</style>
