<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Contact Center Emergency Status"
        summary="Update Twilio number redirect depending on the status of the contact center"
      >
        <template v-slot:description-body>
          <p>
            We send/recieve SMS/Whatsapps using a Twilio registered phone number
            (01465915050). On dialing this number one of two events can happend
            depending on the status set.
          </p>
          <ul>
            <li>
              <b>Emergency</b> - Call will be played 'Technical Difficulties'
              RAD
            </li>
            <li><b>Ok</b> - Calls will be redirected to Talkdesk</li>
          </ul>
          <p>
            <a
              href="https://app.lucidchart.com/documents/edit/1f52e67e-07e7-4a5a-996e-56eeef31db21/0_0"
              target="_blank"
              >Call Routing Flowchart</a
            >
          </p>
        </template>
      </header-block>
    </b-row>

    <page-section title="">
      <template v-slot:content>
        <b-row>
          <b-form class="w-100" inline @submit="onSubmit">
            <b-form-group
              id="input-group-1"
              label="Emergency Status"
              class="m-1"
              label-for="input-1"
            >
              <b-form-select
                id="input-1"
                v-model="selected"
                class="m-1"
                :options="options"
              ></b-form-select>
            </b-form-group>
            <b-button
              v-show="currentStatus != selected"
              class="m-1"
              type="submit"
              variant="success"
              >Set</b-button
            >
          </b-form>
        </b-row>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import Emergencies from "@/api/Emergencies.js"

export default {
  components: {
    headerBlock,
    pageSection,
  },
  data() {
    return {
      selected: null,
      currentStatus: null,
      options: [],
      error: null,
    }
  },
  created() {
    var that = this
    Emergencies.fetchStatuses().then((response) => {
      response.data.forEach(function (option) {
        var obj = {}
        obj["text"] = option.status
        obj["value"] = option.id
        that.options.push(obj)
      })
    })
    Emergencies.fetchCurrentStatus().then((response) => {
      this.currentStatus = response.data.contact_center_status_id
      this.selected = this.currentStatus
    })
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      this.send(this.selected)
    },
    send() {
      Emergencies.updateStatuses({
        contact_center_status_id: this.selected,
      }).then((response) => {
        if (response.status === 200) {
          this.currentStatus = this.selected
        } else {
          this.error = response
        }
      })
    },
  },
}
</script>
