<template>
  <div class="imbp">
    <section v-if="currentStepNumber < steps.length - 1" class="imbp__body">
      <div class="imbp__page-content">
        <h2 class="imbp__page-title">
          {{ currentStep.pageTitle }}
        </h2>
        <keep-alive>
          <component
            :is="currentStepName"
            :ref="currentStepName"
            v-bind="stepProperties"
            @mark-page-valid="markPageValid"
            @mark-page-invalid="markPageInValid"
            @get-appointments="getAppointments"
            @book-appointment="bookAppointment"
            @cancel-appointment="cancelAppointment"
            @get-account-details="getAccountDetails"
          />
        </keep-alive>
      </div>
      <div v-if="currentStepNumber < steps.length - 2" class="imbp__navigation">
        <AppButton
          v-if="currentStepNumber !== 0"
          ref="backButton"
          class="is-secondary imbp__back-button"
          @click="previousStep"
        >
          <span class="fas fa-chevron-left"></span>
          <span>Back</span>
        </AppButton>
        <AppButton
          ref="nextButton"
          class="imbp__next-button"
          :disabled="isNextButtonDisabled"
          v-on="{
            click:
              currentStepName === 'ChooseAppointment'
                ? submitChooseYourAppointment
                : nextStep,
          }"
        >
          <span>Next</span>
          <span class="fas fa-chevron-right"></span>
        </AppButton>
      </div>
    </section>

    <section v-else class="imbp__completed">
      <component
        :is="currentStepName"
        :ref="currentStepName"
        v-bind="stepProperties"
        @get-customer="getCustomerDetails"
      />
    </section>

    <WarningModal
      :show="modal.isModalVisible"
      :variation="modal.variation"
      @close="modal.isModalVisible = false"
    />
  </div>
</template>

<script>
import dayjs from "dayjs"
import { mapState, mapGetters } from "vuex"
import services from "@/api/Imbp.js"
import authentication from "@/authentication/index.js"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import Introduction from "@/components/imbp/Introduction.vue"
import AccountSearch from "@/components/imbp/AccountSearch.vue"
import ChooseJobType from "@/components/imbp/ChooseJobType.vue"
import ChooseAppointment from "@/components/imbp/ChooseAppointment.vue"
import ExtraInformation from "@/components/imbp/ExtraInformation.vue"
import AppointmentSummary from "@/components/imbp/AppointmentSummary.vue"
import BookingConfirmation from "@/components/imbp/BookingConfirmation.vue"
import WarningModal from "@/components/imbp/WarningModal.vue"

export default {
  name: "ManageBookingsIMBP",
  components: {
    Introduction,
    AppButton,
    AccountSearch,
    ChooseAppointment,
    ChooseJobType,
    ExtraInformation,
    AppointmentSummary,
    BookingConfirmation,
    WarningModal,
  },
  data() {
    return {
      slotsPending: false,
      customerPending: false,
      bookingApiStatus: "",
      isNextButtonDisabled: false,
      days: [],
      calendarSettings: [
        {
          key: "today",
          highlight: true,
          dates: [],
        },
      ],
      showDates: false,
      modal: {
        isModalVisible: false,
        variation: "generic",
      },
      timeOut: undefined,
      cancellation: {
        cancelPending: false,
        cancelSuccess: "",
        cancelError: "",
        closeModal: false,
      },
      cachedAccountNumberSearch: "",
      cancellationModalMessageTime: 2000,
    }
  },
  computed: {
    ...mapState({
      currentStepNumber: (state) => state.imbp.currentStepNumber,
      steps: (state) => state.imbp.steps,
      bookingData: (state) => state.imbp.bookingData,
      accountDetails: (state) => state.imbp.accountDetails,
      hasMedicalEquipment: (state) => state.imbp.hasMedicalEquipment,
      accountSearchError: (state) => state.imbp.accountSearchError,
      selectedDate: (state) => state.imbp.selectedDate,
      activeTime: (state) => state.imbp.activeTime,
      fuelType: (state) => state.imbp.fuelType,
      installer: (state) => state.imbp.installer,
      selectedJobs: (state) => state.imbp.selectedJobs,
      reservationId: (state) => state.imbp.reservationId,
    }),
    ...mapGetters({
      timeRange: "imbp/timeRange",
      customerAddress: "imbp/customerAddress",
      gasMeterSerialNumber: "imbp/gasMeterSerialNumber",
      electricityMeterSerialNumber: "imbp/electricityMeterSerialNumber",
      mpan: "imbp/mpan",
      mprn: "imbp/mprn",
      formattedPostcode: "imbp/formattedPostcode",
    }),
    currentStepName() {
      return this.steps.length ? this.steps[this.currentStepNumber].name : ""
    },
    currentStep() {
      return this.steps.length ? this.steps[this.currentStepNumber] : {}
    },
    maxSteps() {
      return this.steps.length - 1
    },
    stepProperties() {
      let props = {}

      switch (this.currentStepName) {
        case "AccountSearch":
          props = {
            accountDetails: this.accountDetails,
            searchError: this.accountSearchError,
            cancellation: this.cancellation,
            bookingData: this.bookingData,
            customerPending: this.customerPending,
          }
          break
        case "ChooseJobType":
          props = {
            installer: this.installer,
            accountDetails: this.accountDetails,
          }
          break
        case "ChooseAppointment":
          props = {
            days: this.days,
            calendarSettings: this.calendarSettings,
            accountDetails: this.accountDetails,
            showDates: this.showDates,
            slotsPending: this.slotsPending,
            fuelType: this.fuelType,
          }
          break
        case "AppointmentSummary":
          props = {
            accountDetails: this.accountDetails,
            bookingApiStatus: this.bookingApiStatus,
          }
          break
        case "BookingConfirmation":
          props = {
            customerDetails: this.bookingData,
            customerPending: this.customerPending,
          }
          break
      }

      return props
    },
    getAgentEmail() {
      return authentication.getUserProfile()?.upn
    },
  },
  created() {
    this.$store.dispatch("imbp/resetForm")
  },
  methods: {
    previousStep() {
      this.$store.commit(
        "imbp/SET_CURRENT_STEP_NUMBER",
        this.currentStepNumber - 1
      )
      window.scrollTo(0, 0)
      this.basicValidation()
    },
    nextStep() {
      this.$store.commit(
        "imbp/SET_CURRENT_STEP_NUMBER",
        this.currentStepNumber + 1
      )
      window.scrollTo(0, 0)
      this.basicValidation()
    },
    basicValidation() {
      this.isNextButtonDisabled = !this.currentStep.isPageValid
    },
    markPageValid() {
      this.isNextButtonDisabled = false

      this.$store.commit("imbp/SET_PAGE_VALIDITY", {
        isPageValid: true,
        pageIndex: this.currentStepNumber,
      })
    },
    markPageInValid() {
      this.isNextButtonDisabled = true

      this.$store.commit("imbp/SET_PAGE_VALIDITY", {
        isPageValid: false,
        pageIndex: this.currentStepNumber,
      })
    },
    getAppointments() {
      this.slotsPending = true
      // reset the calendar
      this.$store.commit("imbp/SET_TIME", "")
      this.$store.commit("imbp/SET_DATE", "")

      services
        .getAvailableSlots(this.formattedPostcode)
        .then((response) => {
          const availableDays = response.data.days.filter(
            (day) => day.slots.length
          )
          if (availableDays.length) {
            this.days = availableDays
            this.calendarSettings[0].dates = availableDays.map(
              (day) => new Date(day.date)
            )
          } else {
            this.modal.isModalVisible = true
            this.modal.variation = "noSlotError"
          }
        })
        .catch((error) => {
          this.modal.isModalVisible = true
          this.modal.variation = "generic"
          ErrorReporter.notify(error)
        })
        .finally(() => {
          this.showDates = this.calendarSettings[0]?.dates?.length > 0
          this.slotsPending = false
        })
    },
    submitChooseYourAppointment() {
      if (dayjs(this.selectedDate).isBefore(dayjs())) {
        this.modal.isModalVisible = true
        this.modal.variation = "dateError"
        return
      }

      const appointmentData = {
        postcode: this.formattedPostcode,
        timeSlot: this.timeRange,
        day: dayjs(this.selectedDate).format("YYYY-MM-DD"),
        meterType: this.selectedJobs.selectedMeterTypeAES,
        appointmentType: this.selectedJobs.selectedAppointmentType,
        installType: this.selectedJobs.selectedInstallationType,
      }

      services
        .reserveSlots(appointmentData)
        .then((response) => {
          this.$store.commit(
            "imbp/SET_RESERVATION_ID",
            response.data.reservationId
          )
          this.nextStep()
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            // show network error modal
            this.modal.isModalVisible = true
            this.modal.variation = "networkError"
          } else {
            // show generic error modal
            this.modal.isModalVisible = true
            this.modal.variation = "generic"
          }
          ErrorReporter.notify(error)
        })
    },
    getAccountDetails(data) {
      clearTimeout(this.timeOut)
      this.$store.dispatch("imbp/resetForm")
      this.showDates = false
      this.calendarSettings = [
        {
          key: "today",
          highlight: true,
          dates: [],
        },
      ]
      this.$store.commit("imbp/SET_ACCOUNT_SEARCH_ERROR", "")
      if (data) {
        this.cachedAccountNumberSearch = data.accountNumberSearch
      }

      services
        .getAccountDetails(this.cachedAccountNumberSearch)
        .then((response) => {
          if (response && response.data) {
            this.$store.commit(
              "imbp/SET_ACCOUNT_DETAILS",
              response.data.account
            )
            this.getCustomerDetails()
          } else {
            throw Error
          }
        })
        .catch((error) => {
          this.timeOut = setTimeout(() => {
            this.$store.commit("imbp/SET_ACCOUNT_SEARCH_ERROR", "")
          }, 5000)
          let errorMessage = ""

          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length
          ) {
            errorMessage = error.response.data.errors[0].message
          } else {
            errorMessage = "An unexpected error happened. Please try again!"
          }
          this.$store.commit("imbp/SET_ACCOUNT_SEARCH_ERROR", errorMessage)
          ErrorReporter.notify(error)
        })
    },
    getCustomerDetails() {
      this.customerPending = true
      services
        .getBookings(this.accountDetails.billing_account_id)
        .then((response) => {
          this.$store.commit("imbp/SET_BOOKING_DATA", response.data)
        })
        .catch((error) => {
          this.modal.isModalVisible = true
          this.modal.variation = "getBookingError"
          ErrorReporter.notify(error)
        })
        .finally(() => {
          this.customerPending = false
        })
    },
    bookAppointment() {
      this.bookingApiStatus = "PENDING"

      const newBookingData = {
        "account-id": this.accountDetails.billing_account_id,
        "customer-first-name": this.accountDetails.name.forename,
        "customer-last-name": this.accountDetails.name.surname,
        "customer-email": this.accountDetails.contact_info.email,
        "customer-phone": this.accountDetails.contact_info.number.replace(
          /\s/g,
          ""
        ),
        "customer-address": this.customerAddress,
        "customer-city": this.accountDetails.address.address3 || "Unknown",
        "customer-postcode": this.formattedPostcode,
        mpan: this.mpan,
        mprn: this.mprn,
        "gas-meter-serial-number": this.gasMeterSerialNumber,
        "electric-meter-serial-number": this.electricityMeterSerialNumber,
        "reservation-id": this.reservationId,
        "medical-equipment": this.hasMedicalEquipment,
        "agent-email": this.getAgentEmail,
      }

      services
        .createAppointment(newBookingData)
        .then(() => {
          this.bookingApiStatus = "SUCCESS"
          this.nextStep()
        })
        .catch((error) => {
          this.bookingApiStatus = "ERROR"
          const errorMessage = error.response?.data?.message || ""

          if (errorMessage.includes("timeout has expired")) {
            this.modal.isModalVisible = true
            this.modal.variation = "timeoutError"
          } else if (
            errorMessage.includes(
              "Customer is not going to be appointed at the selected date"
            ) ||
            errorMessage.includes("Selected date is no longer available")
          ) {
            this.modal.isModalVisible = true
            this.modal.variation = "slotError"
          } else {
            this.modal.isModalVisible = true
            this.modal.variation = "bookingError"
          }
          ErrorReporter.notify(error)
        })
    },
    cancelAppointment(jobNumber) {
      this.cancellation.cancelPending = true
      this.cancellation.cancelSuccess = ""
      this.cancellation.cancelError = ""
      this.cancellation.closeModal = false

      services
        .cancelBooking({ jobNumber }, this.accountDetails.billing_account_id)
        .then(() => {
          this.cancellation.cancelSuccess =
            "Appointment Cancelled! Customer will receive cancellation confirmation via email."
          setTimeout(() => {
            this.cancellation.cancelSuccess = ""
            this.cancellation.closeModal = true
            this.getCustomerDetails()
          }, this.cancellationModalMessageTime)
        })
        .catch((error) => {
          this.cancellation.cancelError =
            error.response?.data?.message ??
            "There was an error when cancelling this appointment."
          setTimeout(() => {
            this.cancellation.cancelError = ""
          }, this.cancellationModalMessageTime)
          ErrorReporter.notify(error)
        })
        .finally(() => {
          this.cancellation.cancelPending = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.imbp {
  background-color: $white;
  min-height: calc(100vh - 62px);

  &__completed {
    background-color: $day-dark;
    min-height: calc(100vh - 62px);
  }

  &__body {
    text-align: left;
    background-color: $water;
  }
  &__page-content {
    min-height: calc(100vh - 142px);
    background-color: $white;
    padding: $space-12 $space-15 $space-12 $space-15;
    max-width: 1148px;
    margin: 0 auto;
    position: relative;
  }
  &__page-title {
    font-size: $font-size-7;
    margin-bottom: $space-13;
    line-height: $line-height-4;
    font-weight: $weight-medium;
  }
  &__navigation {
    height: 80px;
    background: $day-dark;
    padding: $space-4 $space-10;
    max-width: 1148px;
    margin: 0 auto;
  }
  &__next-button {
    .fas {
      margin-left: $space-2;
    }
  }
  &__back-button {
    margin-right: $space-4;
    .fas {
      margin-right: $space-2;
    }
  }
}
</style>
