import dayjs from "dayjs"
import { listArray } from "@/helpers/arrayList"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

export function electricityOpeningReadings(elec_readings) {
  var registerReadings = []
  var meterReadings = []
  var reading, meterReading, all_electricity_readings

  elec_readings.forEach(function (z) {
    z.opening_reading.forEach(function (y) {
      if (y.rateName === "Standard") {
        reading = y.cumulative + y.unit
      } else {
        reading = y.cumulative + y.unit + " (" + y.rateName + ")"
      }
      registerReadings.push(reading)
    })
    meterReading =
      registerReadings.join(", ") +
      " (MPAN:" +
      z.identifier +
      ") on " +
      dayjs(z.fromDt).format("Do MMMM")
    meterReadings.push(meterReading)
  })

  all_electricity_readings = listArray(meterReadings)

  return all_electricity_readings
}

export function gasOpeningReadings(gas_readings) {
  var registerReadings = []
  var meterReadings = []
  var reading, meterReading, all_gas_readings

  gas_readings.forEach(function (z) {
    z.opening_reading.forEach(function (y) {
      if (y.unit === "hcf") {
        y.unit = " 100ft<sup>3</sup>"
      } else {
        y.unit = "m<sup>3</sup>"
      }
      reading = y.cumulative + y.unit
      registerReadings.push(reading)
    })
    meterReading =
      registerReadings.join(", ") +
      " (MPRN:" +
      z.identifier +
      ") on " +
      dayjs(z.fromDt).format("Do MMMM")
    meterReadings.push(meterReading)
  })
  all_gas_readings = listArray(meterReadings)

  return all_gas_readings
}

export function validatedReadingCompile(x) {
  var elec_readings = x.opening_readings.filter(
    (x) => x.type === "Electricity Supply (Res)"
  )
  var gas_readings = x.opening_readings.filter(
    (x) => x.type === "Gas Supply (Res)"
  )
  var elecReadings, gasReadings, z

  if (elec_readings.length + gas_readings.length > 1) {
    x.s = "s"
    x.isare = "are"
  } else {
    x.s = ""
    x.isare = "is"
  }

  if (elec_readings.length === 0) {
    x.reading = gasOpeningReadings(gas_readings) + " for gas"
  } else if (gas_readings.length === 0) {
    x.reading = electricityOpeningReadings(elec_readings) + " for electricity"
  } else {
    elecReadings = electricityOpeningReadings(elec_readings)
    gasReadings = gasOpeningReadings(gas_readings)
    x.reading =
      elecReadings + " for electricity and " + gasReadings + " for gas"
  }

  if (x.account_under_review) {
    z = { text: "Under Review", class: "danger" }
    x.tags.push(z)
    x.clicked = true
  }

  if (x.d300) {
    z = { text: "D300", class: "warning" }
    x.tags.push(z)
    x.clicked = true
  }

  if (x.d301) {
    z = { text: "D301", class: "warning" }
    x.tags.push(z)
    x.clicked = true
  }

  if (x.sar) {
    z = { text: "SAR", class: "warning" }
    x.tags.push(z)
    x.clicked = true
  }

  if (x.ret) {
    z = { text: "RET", class: "warning" }
    x.tags.push(z)
    x.clicked = true
  }

  return x
}
