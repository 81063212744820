import Vue from "vue"
import Router from "vue-router"
import authentication from "../authentication"

import Home from "@/views/Home"
import SmartMeterBookings from "@/views/smartMeter/manageBookings"
import InternalMeterBookings from "@/views/smartMeter/manageBookingsIMBP"
import SmartMeterUncommissionedTickets from "@/views/smartMeter/createUncommissionedTickets"
import WarmHomeDiscountApplications from "@/views/warmHomeDiscount/manageApplications"
import CommsSummary from "@/views/communications/summary"
import CommsAccount from "@/views/communications/accountLookup"
import CommsBlacklist from "@/views/communications/blacklist"
import CommsTemplates from "@/views/communications/templates"
import Payment6Months from "@/views/payments/paymentAdequacy6Months"
import Payment6MonthsPreReview from "@/views/payments/paymentAdequacy6MonthsPreReview"
import sendEmail from "@/views/emailTool/sendEmail"
// import Refund from '@/views/payments/refund'
import payment6monthsFollowUp from "@/views/payments/paymentAdequacy6MonthsFollowUp"
import PaymentAdhoc from "@/views/payments/paymentAdequacyAdhoc"
import PaymentAdequacyBulkUploader from "@/views/payments/paymentAdequacyBulkUploader"
import StandaloneRefunds from "@/views/payments/standaloneRefunds"
import ImportTariffs from "@/views/importTariffs/importTariffs"
// import AgentGroup from '@/views/freshdesk/agentGroup'
// import ContactLookup from '@/views/freshdesk/freshdeskContact'
// import NeedReadTickets from '@/views/customerServicePages/needReadTickets'
// import Survey from '@/views/freshdesk/survey'
// import freshdeskCustomerEmails from '@/views/freshdesk/freshdeskCustomerEmails'
// import BillValueTickets from '@/views/billing/billValue'
import RejectedOpeningReadings from "@/views/operations/rejectedOpeningReadings"
import IndustryRejections from "@/views/operations/industryRejections"
import RejectedOpeningElecReads from "@/views/operations/rejectedOpeningElecReads"
import RejectedOpeningGasReads from "@/views/operations/rejectedOpeningGasReads"
import RegistrationRejections from "@/views/operations/rejectedRegistrations"
import ValidatedReadsAccount from "@/views/operations/validatedOpeningReadings"
import missingLossConfirmedDataflows from "@/views/operations/missingLossConfirmedDataflows"
import DisputedReads from "@/views/operations/disputedReads"
import RejectionResubmission from "@/views/operations/rejectionResubmission"
// import ALPs from '@/views/trading/alps'
// import EcoesLookup from '@/views/ecoes/ecoesLookup'
import MandrillLookup from "@/views/mandrill/emailLookup"
import outboundSms from "@/views/soMessages/outboundSms"
import emergencyStatus from "@/views/soMessages/emergencyStatus"
import emergencyContacts from "@/views/soMessages/emergencyContacts"
import updateTicketStepBulk from "@/views/operations/updateTicketStepBulk"
import SmartMeterReadings from "@/views/smart_meters/readings"
import accountFinder from "@/views/accounts/accountFinder"

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        requiresAuthentication: true,
      },
    },
    // Smart meter
    {
      path: "/smart_meter/manage_bookings",
      name: "manage-bookings",
      component: SmartMeterBookings,
      meta: {
        requiresAuthentication: true,
      },
    },
    // Internal meter
    {
      path: "/meter_bookings",
      name: "manage-bookings-imbp",
      component: InternalMeterBookings,
      meta: {
        requiresAuthentication: true,
      },
    },
    // Smart Meter Tickets Task
    {
      path: "/smart_meter/uncommissioned_tickets",
      name: "smart-meter-uncommissioned-tickets",
      component: SmartMeterUncommissionedTickets,
      meta: {
        requiresAuthentication: true,
      },
    },
    // Smart Meter - Readings
     {
       path: "/smart_meter/meter-readings",
       name: "smart-meter-readings",
       component: SmartMeterReadings,
       meta: {
         requiresAuthentication: true,
       },
     },
    // Warm Home Discount
    {
      path: "/warm_home_discount/applications",
      name: "manage-applications",
      component: WarmHomeDiscountApplications,
    },
    // Communication
    {
      path: "/communications/summary",
      name: "summary",
      component: CommsSummary,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/communications/account_lookup",
      name: "acount-lookup",
      component: CommsAccount,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/communications/blacklist",
      name: "blacklist",
      component: CommsBlacklist,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/communications/templates",
      name: "templates",
      component: CommsTemplates,
      meta: {
        requiresAuthentication: true,
      },
    },
    // Ad hoc email
    {
      path: "/email_tool/send_email",
      name: "send-email",
      component: sendEmail,
      meta: {
        requiresAuthentication: true,
      },
    },
    // Settlements
    /*
    {
      path: '/accounts/need_reads',
      name: 'need-reads',
      component: NeedReadTickets
    },
    */
    // Meterpoints
    /*
    {
      path: '/meterpoint/ecoes',
      name: 'ecoes',
      component: EcoesLookup
    },
    */
    // Customer Service
    /*
    {
      path: '/freshdesk/contact',
      name: 'contacts',
      component: ContactLookup
    },
    {
      path: '/freshdesk/tickets',
      name: 'tickets',
      component: freshdeskCustomerEmails
    },
    {
      path: '/freshdesk/agents',
      name: 'users',
      component: AgentGroup
    },
    {
      path: '/freshdesk/csat',
      name: 'csat',
      component: Survey
    },
    */
    // Payment adequacy
    {
      path: "/payments/adequacy/pre_review",
      name: "pre-review",
      component: Payment6MonthsPreReview,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/payments/adequacy/initial",
      name: "initial-review",
      component: Payment6Months,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/payments/adequacy/follow_up",
      name: "follow-up-review",
      component: payment6monthsFollowUp,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/payments/adequacy/adhoc",
      name: "adhoc",
      component: PaymentAdhoc,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/payments/adequacy/bulk-uploader",
      name: "bulk-uploader",
      component: PaymentAdequacyBulkUploader,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/payments/refund/standalone",
      name: "refund-uploader",
      component: StandaloneRefunds,
      meta: {
        requiresAuthentication: true,
      },
    },
    /*
    {
      path: '/payments/adequacy/refund',
      name: 'refund',
      component: Refund
    },
    */
    {
      path: "/import_new_tariffs",
      name: "import-tariffs",
      component: ImportTariffs,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/rejections/industry",
      name: "rejection-industry",
      component: IndustryRejections,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/rejections/junifer",
      name: "rejection-junifer",
      component: RegistrationRejections,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/rejections/junifer/resubmit",
      name: "rejection-resubmit",
      component: RejectionResubmission,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/new_opening_reads/rejections",
      name: "rejected-opening-reads",
      component: RejectedOpeningReadings,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/opening_reads/rejections/gas",
      name: "rejection-gas",
      component: RejectedOpeningGasReads,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/opening_reads/rejections/electricity",
      name: "rejection-electricity",
      component: RejectedOpeningElecReads,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/opening_reads/rejections/dispute",
      name: "rejection-dispute",
      component: DisputedReads,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/opening_reads/validated/account",
      name: "validate-reads",
      component: ValidatedReadsAccount,
      meta: {
        requiresAuthentication: true,
      },
    },
    // Billing
    /*
    {
      path: '/billing/bill_value_tickets',
      name: 'bill-value-tickets',
      component: BillValueTickets
    },
    */
    // Energy demand
    /*
    {
      path: '/trading/alps',
      name: 'trading',
      component: ALPs
    },
    */
    // Routes not linked (hidden)
    {
      path: "/outbound_sms",
      name: "outbound-sms",
      component: outboundSms,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/emergencies/status",
      name: "emergency-status",
      component: emergencyStatus,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/emergencies/contacts",
      name: "emergency-contacts",
      component: emergencyContacts,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/mandrill/lookup",
      name: "mandrill",
      component: MandrillLookup,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/accounts/missing_loss_confirmation",
      name: "missing-loss-confirmation",
      component: missingLossConfirmedDataflows,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/tools/update_ticket_step_bulk",
      name: "update-ticket-step-bulk",
      component: updateTicketStepBulk,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      path: "/account_finder",
      name: "account-finder",
      component: accountFinder,
      meta: {
          requiresAuthentication: true,
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuthentication)) {
    // this route requires auth, check if logged in
    if (authentication.isAuthenticated()) {
      // only proceed if authenticated.
      next()
    } else {
      authentication.signIn()
    }
  } else {
    next()
  }
})

export default router
