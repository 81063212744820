import apiArk from "./Ark"

export default {
  // Rejected readings
  searchElecAccounts(meterpoint) {
    return apiArk.post("rejected_electric_opening_reads/fetch_by_mpan", {
      meterpoint,
    })
  },
  searchGasAccounts(meterpoint) {
    return apiArk.post("rejected_gas_opening_reads/fetch_by_mprn", {
      meterpoint,
    })
  },

  // Electric
  elecReadings() {
    return apiArk.get("rejected_electric_opening_reads")
  },
  importElecReadings() {
    return apiArk.get("rejected_electric_opening_reads/import")
  },
  sendElecReadingsEmail(id, row) {
    return apiArk.post(
      "rejected_electric_opening_reads/" + id + "/send_email",
      row
    )
  },
  sendAllElecReadEmails(meterpoints) {
    return apiArk.post("rejected_electric_opening_reads/send_all_emails", {
      meterpoints,
    })
  },
  deleteElectricity(id) {
    return apiArk.delete("rejected_electric_opening_reads/" + id)
  },

  // Gas
  gasReadings() {
    return apiArk.get("rejected_gas_opening_reads")
  },
  importGasReadings() {
    return apiArk.get("rejected_gas_opening_reads/import")
  },
  sendGasReadingsEmail(id, row) {
    return apiArk.post("rejected_gas_opening_reads/" + id + "/send_email", row)
  },
  sendAllGasReadEmails(meterpoints) {
    return apiArk.post("rejected_gas_opening_reads/send_all_emails", {
      meterpoints,
    })
  },
  deleteGas(id) {
    return apiArk.delete("rejected_gas_opening_reads/" + id)
  },

  //Disputing read
  mpxn_lookup(meterpoint) {
    return apiArk.post("opening_readings/mpxn_lookup", { meterpoint })
  },
  disputeReadForm(payload) {
    return apiArk.post("opening_readings/dispute", { payload })
  },

  // Validated Opening Reads
  getValidatedReadings() {
    return apiArk.get("validated_opening_reads/index")
  },
  importValidatedReadings() {
    return apiArk.get("validated_opening_reads/import")
  },
  sendValidatedReadsEmail(id, payload) {
    return apiArk.post("validated_opening_reads/" + id + "/send_email", {
      payload,
    })
  },
  sendAllValidatedReadsEmail(payload) {
    return apiArk.post("validated_opening_reads/send_emails", { payload })
  },
  deleteValidatedReading(id) {
    return apiArk.delete("validated_opening_reads/" + id)
  },
  lookupValidatedReadEmail(payload) {
    return apiArk.post("validated_opening_reads/show", { payload })
  },
  deleteValidatedReadingAll(payload) {
    return apiArk.post("validated_opening_reads/delete", { payload })
  },
  refreshValidatedReading(id) {
    return apiArk.post("validated_opening_reads/" + id + "/refresh")
  },
}
