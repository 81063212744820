<template>
  <div class="account-search">
    <form class="account-search__form" @submit.prevent="submitForm">
      <div class="form-group account-search__input-wrapper">
        <input
          v-model="searchTerm"
          type="text"
          name="searchTerm"
          class="form-group__input account-search__input"
          :placeholder="placeholderText"
        />
        <i
          v-if="searched && !searchError"
          class="fa fa-times form-group__icon account-search__icon"
          @click="clearSearch"
        ></i>
        <i
          v-else
          class="fas fa-search form-group__icon account-search__icon"
          @click="submitForm"
        ></i>
      </div>
      <p
        v-if="searchError"
        class="form-group__invalid-feedback account-search__feedback"
      >
        {{ searchError }}
      </p>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    searchError: {
      type: String,
      default: "",
    },
    placeholderText: {
      type: String,
      default: "Account number",
    },
  },
  data() {
    return {
      searchTerm: "",
      searched: false,
    }
  },
  watch: {
    searchTerm(value) {
      this.searched = false
    },
  },
  methods: {
    submitForm() {
      if (this.searchTerm) {
        this.searched = true
        this.$emit("accountSearch", this.searchTerm.replace(/\s/g, ""))
      } else {
        this.clearSearch()
      }
    },
    clearSearch() {
      this.searchTerm = ""
      this.searched = false
      this.$emit("clearAccountSearch")
    },
  },
}
</script>

<style lang="scss">
.form-group__input {
  &.account-search__input {
    color: $night-light;
    font-weight: $weight-bold;
    font-size: $size-7;
  }
}
.account-search {
  &__icon {
    color: $night-light-2;

    &:hover {
      color: $night-light;
    }
  }

  &__feedback {
    margin-bottom: 0;
  }
}
</style>
