export function roundNum(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals)
}

export function extend(obj, src) {
  for (var key in src) {
    if (src.hasOwnProperty(key)) obj[key] = src[key]
  }
  return obj
}
