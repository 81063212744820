import OpeningReadings from "@/api/OpeningReadings"
import email from "@/constants/email"
import { gasChecks } from "@/helpers/rejectedReadings/gasOpeningReadings"
import { gasDisputeTolChecks } from "@/helpers/rejectedReadings/gasDisputeTolerance"
import { gasOpeningReadTemplate } from "@/helpers/rejectedReadings/gasOpeningReadingsTemplate"

const state = {
  lookupMprn: null,
  lookupMpan: null,
  mpxn: null,
  rejectedElecReadings: [],
  rejectedElecReadingsLoading: true,
  rejectedGasReadings: [],
  rejectedGasReadingsLoading: true,
  message: [],
  selectedElecReading: [],
  chartOptions: {
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  },
}
const mutations = {
  SET_ELECDATA(state, data) {
    state.rejectedElecReadings = data
  },
  SET_ELECDISPUTE(state, data) {
    state.selectedElecReading = data
  },
  SET_CLICKED_ELEC_REVIEW(state, data) {
    var index = state.rejectedElecReadings.indexOf(data.item)
    state.rejectedElecReadings[index] = data.item
  },
  SET_ELEC_ROW(state, data) {
    var index = state.rejectedElecReadings.findIndex(
      (s) => s.account_number == data.account_number
    )
    state.rejectedElecReadings[index].completed = data.completed
    state.rejectedElecReadings[index].error_reason = data.error_reason
  },

  SET_GASDATA(state, data) {
    state.rejectedGasReadings = data
  },
  SET_CLICKED_GAS_REVIEW(state, data) {
    var index = state.rejectedGasReadings.indexOf(data.item)
    state.rejectedGasReadings[index] = data.item
  },
  SET_LOOKUP_MPRN(state, data) {
    state.lookupMprn = data
  },
  SET_LOOKUP_MPAN(state, data) {
    state.lookupMpan = data
  },
  SET_MPXN(state, data) {
    state.mpxn = data
  },
  GAS_LOAD_STATE(state, data) {
    state.rejectedGasReadingsLoading = data
  },
  SET_GAS_EMAIL_TEMPLATE(state, data) {
    state.rejectedGasReadings.templateContent = data
  },
  UPDATE_GAS_ROw(state, data) {
    var index = state.rejectedGasReadings.findIndex(
      (s) => s.account_number == data.account_number
    )
    state.rejectedGasReadings[index].completed = data.completed
    state.rejectedGasReadings[index].error_reason = data.error_reason
  },
  DELETE_GAS_ROW(state, data) {
    var index = state.rejectedGasReadings.findIndex(
      (s) => s.account_number == data.item.account_number
    )
    state.rejectedGasReadings.splice(index, 1)
  },
  DELETE_GAS_COMPLETED(state, data) {
    var index = state.rejectedGasReadings.findIndex(
      (s) => s.account_number == data.account_number
    )
    state.rejectedGasReadings.splice(index, 1)
  },

  SET_ADD_INFO(state, data) {
    state.selectedElecReading.disputed_read.additionalInformation = data
  },
  SET_EMAIL(state, data) {
    state.selectedElecReading.emailContent = data
  },
  SET_EMAIL_PREF(state, data) {
    state.selectedElecReading.emailPref = data
  },
  SET_EMAIL_STATUS(state, data) {
    state.selectedElecReading.emailStatus = data
  },
  SET_SUPPLIER_CODE(state, data) {
    state.selectedElecReading.disputed_read.associatedSupplierCode = data
  },
  SET_MESSAGE(state, data) {
    state.message = data
  },
  DELETE_ELEC_ROW(state, data) {
    var index = state.rejectedElecReadings.findIndex(
      (s) => s.account_number == data.item.account_number
    )
    state.rejectedElecReadings.splice(index, 1)
  },
  DELETE_ELEC_COMPLETED(state, data) {
    var index = state.rejectedElecReadings.findIndex(
      (s) => s.account_number == data.account_number
    )
    state.rejectedElecReadings.splice(index, 1)
  },
  ELEC_LOAD_STATE(state, data) {
    state.rejectedElecReadingsLoading = data
  },
  SET_ELEC_EMAIL_TEMPLATE(state, data) {
    state.rejectedElecReadings.templateContent = data
  },
}
const getters = {
  gasResults: (state) => {
    return {
      sortBy: "Status",
      sortDesc: false,
      fields: [
        { key: "check_box", label: "", sortable: false },
        { key: "tag", label: "", sortable: false },
        "read_date",
        { key: "mprn", label: "MPRN", sortable: false },
        { key: "meter_serial_number", label: "MSN", sortable: false },
        { key: "mbr_read", label: "Industry Read", sortable: false },
        { key: "urs_read", label: "Customer Read", sortable: false },
        { key: "units", label: "", sortable: false },
        "difference",
        { key: "dispute_type", label: "", sortable: false },
        { key: "account_number", label: "Account Number", sortable: false },
        { key: "email", label: "Email", sortable: false },
        { key: "customer_name", label: "Name", sortable: false },
        "first_name",
        { key: "show_details", label: "", sortable: false },
        { key: "send_email", label: "", sortable: false },
        { key: "delete", label: "", sortable: false },
      ],
      items: state.rejectedGasReadings,
    }
  },
  electricityResults: (state) => {
    return {
      sortBy: "Status",
      sortDesc: false,
      fields: [
        { key: "check_box", label: "", sortable: false },
        { key: "tag", label: "", sortable: false },
        "read_date",
        { key: "mpxn", label: "MPAN", sortable: false },
        { key: "customer_read", label: "Customer Read", sortable: false },
        { key: "industry_read", label: "Industry Read", sortable: true },
        { key: "read_difference", label: "Difference", sortable: false },
        { key: "dispute_type", label: "", sortable: false },
        { key: "email", label: "Email", sortable: false },
        { key: "account_number", label: "Account Number", sortable: false },
        "first_name",
        { key: "show_details", label: "", sortable: false },
        { key: "send_email", label: "", sortable: false },
        { key: "delete", label: "", sortable: false },
      ],
      items: state.rejectedElecReadings,
    }
  },
  elecResultsIsBusy: (state) => {
    return state.rejectedElecReadingsLoading
  },
  gasBusy: (state) => {
    return state.rejectedGasReadingsLoading
  },
  lookupMprnResult: (state) => {
    return state.lookupMprn
  },
  lookupMpanResult: (state) => {
    return state.lookupMpan
  },
  lookupMpxn: (state) => {
    return state.mpxn
  },
  DISPUTEDELECREAD: (state) => {
    return state.selectedElecReading
  },
  DISPUTERESPONSE: (state) => {
    return state.message
  },
}
const setters = {}
const actions = {
  lookupMprn: function (context, mprn) {
    OpeningReadings.searchGasAccounts(mprn)
      .then((response) => {
        var mprnRecord = response.data
        gasChecks(mprnRecord)
        gasDisputeTolChecks(mprnRecord)
        gasOpeningReadTemplate(mprnRecord)
        context.commit("SET_LOOKUP_MPRN", mprnRecord)
      })
      .catch((error) => {
        console.log(error.response)
      })
  },
  softElecDelete: function (context, data) {
    OpeningReadings.deleteElectricity(data.item.id)
      .then(() => {
        context.commit("DELETE_ELEC_ROW", data)
      })
      .catch((error) => {
        console.log(error.response)
      })
  },
  clearElecReads: function (context) {
    var completedReviews = state.rejectedElecReadings.filter(
      (e) => e.completed === true
    )
    completedReviews.forEach(function (x) {
      OpeningReadings.deleteElectricity(x.id)
      context.commit("DELETE_ELEC_COMPLETED", x)
    })
  },
  elecReadings(context) {
    OpeningReadings.elecReadings()
      .then((response) => {
        var tag
        var footer = email.footer
        var textBodyStyle = email.textStyle
        response.data.forEach(function (e) {
          e.edited = false
          e.disabled = false
          e.tags = []
          if (e.d86_count != 0) {
            tag = new Object()
            tag.text = e.d86_count + 1 + " D86s"
            tag.class = "badge-warning"
            e.tags.push(tag)
          }
          if (e.d300_count >= 1) {
            tag = new Object()
            tag.text = e.d300_count + " D300s"
            tag.class = "badge-warning"
            e.tags.push(tag)
          }
          if (e.d71_count >= 2) {
            tag = new Object()
            tag.text = e.d71_count + 1 + " D71s"
            tag.class = "badge-warning"
            e.tags.push(tag)
          }
          if (e.d10_count >= 2) {
            tag = new Object()
            tag.text = e.d10_count + 1 + " D10s"
            tag.class = "badge-warning"
            e.tags.push(tag)
          }
          if (e.note_count > 2) {
            tag = new Object()
            tag.text = e.note_count + " Notes"
            tag.class = "badge-warning"
            e.tags.push(tag)
          }
          if (e.erroneous_transfer_ticket_open != false) {
            tag = new Object()
            tag.text = "ET Open"
            tag.class = "badge-danger"
            e.tags.push(tag)
          }
          if (e.under_review != false) {
            tag = new Object()
            tag.text = "Under Review"
            tag.class = "badge-danger"
            e.tags.push(tag)
          }
          if (e.error_reason != null) {
            tag = new Object()
            tag.text = "Error! " + e.error_reason
            tag.class = "badge-danger"
            e.tags.push(tag)
          }
          if (e.tags.length === 0) {
            e.clicked = false
          } else {
            e.clicked = true
          }

          var arr = e.read_difference.split(",")
          if (arr.length === 1) {
            if (arr[0].split(".")[0].match(/\d+/g).map(Number) >= 250) {
              e.template = "OutofTolerance"
            } else {
              e.template = "WithinTolerance"
            }
          } else {
            if (
              arr[0].split(".")[0].match(/\d+/g).map(Number)[0] ==
              arr[1].split(".")[0].match(/\d+/g).map(Number)[0]
            ) {
              e.template = "Transposed"
            } else {
              if (
                arr[0].split(".")[0].match(/\d+/g).map(Number) >= 250 ||
                arr[1].split(".")[0].match(/\d+/g).map(Number) >= 250
              ) {
                e.template = "OutofTolerance"
              } else {
                e.template = "WithinTolerance"
              }
            }
          }

          var customer_firstname = e.customer_forename
          var customer_read = e.customer_read
          var industry_read = e.industry_read
          var mpxn = e.mpxn
          var account_number = e.account_number
          var plural, has_have, plural_an

          if (e.industry_read.split(".").length >= 2) {
            plural = "s"
            has_have = "have"
            plural_an = ""
          } else {
            plural = ""
            has_have = "has"
            plural_an = "an "
          }

          if (e.template === "OutofTolerance") {
            e.templateContent = `<html>
                                        <body>
                                          <div style='${textBodyStyle}'>
                                            Hello ${customer_firstname},
                                            <br>
                                            <br>Account Number: ${account_number}
                                            <br>MPAN: ${mpxn}
                                            <br>
                                            <br>We're contacting you today because your opening electricity reading${plural} ${has_have} failed
                                            <a href='https://www.so.energy/so/help-and-advice/faq/switching-to-us/Opening-Read-Validation' target='_blank'><b>validation</b></a>.
                                            <br>
                                            <br>You provided the following reading${plural}: ${customer_read}
                                            <br>
                                            <br>We have been provided with ${plural_an}estimate${plural} to use instead.
                                            Estimates are given to us by industry based on your meter reading history and estimated energy usage.
                                            <br>
                                            <br>We will be opening your account with ${plural_an}estimated reading${plural}: ${industry_read}.
                                            <br>
                                            <br>
                                            If you are happy with the estimated reading${plural} no action is necessary.
                                            The same estimate${plural} ${has_have} been given to your previous supplier as
                                            your final reading${plural} to be used on your final bill, so you will not be charged twice for any energy.
                                            Their final bill should be with you in the next couple of weeks. Please contact your old supplier
                                            first if you are concerned that this is taking a long time.
                                            <br>
                                            <br><i>Is the opening reading you gave us correct?</i>
                                            <br>
                                            <br>If you think your opening read is correct and this validation process isn't working properly
                                            then we'd love to help out. We can raise a 'dispute', and we can get the opening reading amended for you.
                                            To do so, we will need clear photographs of your electricity meter, showing the full front of the meter
                                            including serial number (a series of letters and/or numbers printed on the meter).
                                            You can just reply to this email with the photographs attached.
                                            <br>
                                            <br>You can also read our handy guide which explains this process: <a href='https://www.so.energy/so/help-and-advice/faq/switching-to-us/disputing-reads' target='_blank'><b>My opening or closing reading is wrong</b></a>.
                                            <br>
                                            <br>If you have any queries, please do get in touch.
                                          </div>
                                          ${footer}
                                        </body>
                                      </html>`
          } else if (e.template === "WithinTolerance") {
            e.templateContent = `<html>
                                        <body>
                                            <div style='${textBodyStyle}'>
                                              Hello ${customer_firstname},
                                              <br>
                                              <br>Account Number: ${account_number}
                                              <br>MPAN: ${mpxn}
                                              <br>
                                              <br>We're contacting you today because your opening electricity reading${plural} ${has_have} failed <a href='https://www.so.energy/so/help-and-advice/faq/switching-to-us/Opening-Read-Validation' target='_blank'><b>validation</b></a>.
                                              <br>
                                              <br>You provided the following reading${plural}: ${customer_read}
                                              <br>
                                              <br>We have been provided with ${plural_an}estimate${plural} to use instead.
                                              Estimates are given to us by industry based on your meter reading history and estimated energy usage.
                                              <br>
                                              <br>We will be opening your account with ${plural_an}estimated reading${plural}: ${industry_read}.
                                              <br>
                                              <br>
                                              The same estimate${plural} ${has_have} been given to your previous supplier as
                                              your final reading${plural} to be used on your final bill, so you will not be charged twice for any energy.
                                              Their final bill should be with you in the next couple of weeks. Please contact your old supplier
                                              first if you are concerned that this is taking a long time.
                                              <br>
                                              <br>The estimate${plural} we have been given is very similar to, or 'within tolerance' of the reading you gave us.
                                              This means, as much as we would prefer to use your reading (as we're sure you would too!) the energy industry will not allow
                                              us to dispute the estimate. At this point So Energy and your previous supplier must use this reading for your switch.
                                              The estimate is sensible and could reasonably have been taken around the time of your switch,
                                              and the important thing to remember is that you will not be charged twice for the same energy.
                                              <br>
                                              <br>If you have any queries, please do get in touch.
                                            </div>
                                            ${footer}
                                        </body>
                                      </html>
                                      `
          } else {
            e.templateContent = `<html>
                                        <body>
                                          <div style='${textBodyStyle}'>
                                              Hello ${customer_firstname},
                                              <br>
                                              <br>Account Number: ${account_number}
                                              <br>MPAN: ${mpxn}
                                              <br>
                                              <br>We're contacting you today because the opening electricity readings you provided ${customer_read} have failed validation as central industry have deemed the reads to be the wrong way around for Day and Night.
                                              <br>
                                              <br>We will be opening your account with the reads you provided the following way around: <b>${industry_read}</b>.
                                              <br>
                                              <br>Your previous supplier will also generate your final bill to the above reads, so please rest assured, you will not be charged twice for any electricity consumed.
                                              <br>
                                              <br>If you are happy for us to use your opening reads this way around, no action is required.
                                              <br>
                                              <br>If you think your opening read is correct and this validation process isn't working properly then we'd love to help out.
                                              In order to dispute your opening readings you will be required to complete a
                                              <a href='https://www.so.energy/so/help-and-advice/faq/your-meter/how-do-i-know-which-register-is-day-night' target='_blank'><b>Time Test</b></a>.
                                              To complete the Time Test, please take readings from your electricity meter in the middle of the day, and then take more readings a few hours later.
                                              Only one of the readings should have changed and this will be (or should be) the Day reading.
                                              Please take photographs of all meter readings if possible and send them in a reply to this email.
                                              <br>
                                              <br>If you have any queries, please do get in touch.
                                            </div>
                                          ${footer}
                                        </body>
                                      </html>`
          }
        })
        context.commit("SET_ELECDATA", response.data)
        context.commit("ELEC_LOAD_STATE", false)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  clear(context) {
    context.commit("SET_ELECDISPUTE", [])
  },
  mpxnLookup(context, mpxn) {
    OpeningReadings.mpxn_lookup(mpxn)
      .then((response) => {
        var fuel
        var footer = email.footer
        var textBodyStyle = email.textStyle
        var firstName = response.data.rejection.customer_forename
        if (response.data.meter_info.identifier.length === 13) {
          fuel = "electricity"
        } else {
          fuel = "gas"
        }
        response.data.emailContent = ""
        response.data.emailContentStart = `<html>
                                      <body> 
                                        <div style='${textBodyStyle}'>
                                          Hello ${firstName},
                                          <br><br>
                                          We have now raised a dispute with your previous supplier
                                          to get your opening ${fuel} read amended.
                                          <br><br>
                                          The reading we have proposed is `
        response.data.emailContentEnd = `.
                                        <br><br>
                                        The disputed read process can take up to 6 weeks. Once we have resolved this, we will revise your statements
                                        accordingly and send out your latest one. The reading will be based on the information we have received from you and any readings your previous supplier has.
                                        <br><br>
                                        In the meantime, if you have any questions please do get in contact.
                                        </div>
                                        ${footer}
                                    </body>
                                  </html>`

        if (response.data.rejection.freshdesk_ticket !== null) {
          response.data.emailPref = "freshdesk"
        } else {
          response.data.emailPref = "email"
        }
        response.data.emailStatus = null
        context.commit("SET_ELECDISPUTE", response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  mpanLookup(context, mpan) {
    OpeningReadings.searchElecAccounts(mpan)
      .then((response) => {
        var fuel = "electricity"
        var footer = email.footer
        var textBodyStyle = email.textStyle
        var firstName = response.data.customer_forename
        response.data.emailContent = ""
        response.data.emailContentStart = `<html>
                                      <body> 
                                        <div style='${textBodyStyle}'>
                                          Hello ${firstName},
                                          <br><br>
                                          We have now raised a dispute with your previous supplier
                                          to get your opening ${fuel} read amended.
                                          <br><br>
                                          The reading we have proposed is `
        response.data.emailContentEnd = `.
                                        <br><br>
                                        The disputed read process can take up to 6 weeks. Once we have resolved this, we will revise your statements
                                        accordingly and send out your latest one. The reading will be based on the information we have received from you and any readings your previous supplier has.
                                        <br><br>
                                        In the meantime, if you have any questions please do get in contact.
                                        </div>
                                        ${footer}
                                    </body>
                                  </html>`

        if (response.data.freshdesk_ticket !== null) {
          response.data.emailPref = "freshdesk"
        } else {
          response.data.emailPref = "email"
        }
        response.data.emailStatus = null
        context.commit("SET_ELECDISPUTE", response.data)
        context.commit("SET_LOOKUP_MPAN", response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  disputeFormSubmit(context) {
    OpeningReadings.disputeReadForm(state.selectedElecReading)
      .then((response) => {
        console.log(response)
        context.commit("SET_MESSAGE", response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  importElecReads(context) {
    context.commit("ELEC_LOAD_STATE", true)
    OpeningReadings.importElecReadings()
      .then(() => {
        context.commit("ELEC_LOAD_STATE", false)
        context.dispatch("elecReadings")
      })
      .catch((error) => {
        context.commit("ELEC_LOAD_STATE", false)
        console.log(error)
      })
  },
  sendElecReadEmail(context, data) {
    OpeningReadings.sendElecReadingsEmail(data.item.id, data.item)
      .then((response) => {
        context.commit("SET_ELEC_ROW", response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  gasReadings(context) {
    OpeningReadings.gasReadings()
      .then((response) => {
        response.data.forEach(function (e) {
          e.edited = false
          e.disabled = false
          gasChecks(e)
          gasDisputeTolChecks(e)
          gasOpeningReadTemplate(e)
        })

        context.commit("SET_GASDATA", response.data)
        context.commit("GAS_LOAD_STATE", false)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  importGasReads(context) {
    context.commit("GAS_LOAD_STATE", true)
    OpeningReadings.importGasReadings()
      .then(() => {
        context.commit("GAS_LOAD_STATE", false)
        context.dispatch("gasReadings")
      })
      .catch(() => {
        context.commit("GAS_LOAD_STATE", false)
      })
  },
  softGasDelete: function (context, data) {
    OpeningReadings.deleteGas(data.item.id)
      .then(() => {
        context.commit("DELETE_GAS_ROW", data)
      })
      .catch((error) => {
        console.log(error.response)
      })
  },
  sendGasReadEmail(context, data) {
    OpeningReadings.sendGasReadingsEmail(data.item.id, data.item)
      .then((response) => {
        response.data.completed = true
        context.commit("UPDATE_GAS_ROw", response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  clearGasReads: function (context) {
    var completedReviews = state.rejectedGasReadings.filter(
      (e) => e.completed === true
    )
    completedReviews.forEach(function (x) {
      OpeningReadings.deleteGas(x.id)
      context.commit("DELETE_GAS_COMPLETED", x)
    })
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  setters,
  state,
}
