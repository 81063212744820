<template>
  <b-container fluid class="has-text-left">
    <b-row class="ml-5">
      <header-block
        title="Un-commissioned Smart Meter Tickets"
        description="By uploading a CSV containing account ids you will be
        creating an Un-commissioned Junifer ticket on these accounts. This
        will send an email to the customer to let them know their smart
        meter wont be sending remote reads just yet, but we’ll attempt to
        fix this remotely in the next few weeks."
      ></header-block>
    </b-row>
    <page-section
        title="Upload accounts id, meter identifier(s), and description csv"
    >
      <template slot="content">
        <p>
          Upload a csv containing three columns, the first: "ACCOUNT_ID" the
          second: "METER_IDENTIFIER", the third: "DESCRIPTION".
        </p>
        <div class="upload-csv">
          <UploadCsv
            :parser="parser"
            :api-call="processCsvApiCall"
            v-on="$listeners"
          ></UploadCsv>
        </div>
      </template>
    </page-section>
    <page-section title="Errors">
      <template slot="content">
        <div>
          <table>
            <tr>
              <th>Account ID</th>
              <th>Error Reason</th>
            </tr>
            <tr v-for="result in errors.list" :key="result">
              <template v-for="data in result.data">
                <td :key="data">{{ data }}</td>
              </template>
              <td>{{ result.error }}</td>
            </tr>
          </table>
        </div>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import UploadCsv from "../../components/csv/UploadCsv.vue"
import { csvToFormData } from "@/helpers/csv/parsers"
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import SmartMeter from "@/api/SmartMeter.js"

export default {
  components: {
    pageSection,
    headerBlock,
    UploadCsv,
  },
  data() {
    return {
      parser: csvToFormData,
      SmartMeter: SmartMeter,
      errors: {
        list: [],
        fields: [],
      },
    }
  },
  methods: {
    processCsvApiCall(csv) {
      return SmartMeter.createUncommissionedTicketsCsv(csv).then((response) => {
        this.errors = response.data
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-csv {
  background-color: $day-dark;
  max-width: 450px;
  padding: 1rem;
  border-radius: 0.25rem;
}
.error-msg {
  color: $fire;
  font-weight: $weight-medium;
}
</style>
