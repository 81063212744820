import dayjs from "dayjs"

export function billingChecks(e) {
  try {
    var tag = new Object()

    if (e.energised === false) {
      tag = { text: "Not Energised", class: "badge-danger" }
      e.tags.push(tag)
    }
    if (e.dirtyBill === true) {
      tag = { text: "Dirty Bill", class: "badge-danger" }
      e.tags.push(tag)
    }
    if (e.mandateStatus !== "Active") {
      tag = { text: "DD: " + e.mandateStatus, class: "badge-warning" }
      e.tags.push(tag)
    }

    var multi = (e.billValue - e.accountBalance) / e.paymentAmount

    if (e.paymentAmount !== null && Math.abs(multi) > 4) {
      if (multi > 4) {
        tag.text = "Large Debit x" + Math.round(multi)
        e.template = "readLarge"
      } else {
        tag.text = "Large Credit x" + Math.round(multi)
        e.template = "readLower"
      }
      tag.class = "badge-warning"
      e.tags.push(tag)
    }

    e.meter_registers.forEach(function (x) {
      tag = new Object()
      var billedRead = Number(x.billedRead)
      var firstRead = Number(x.firstBilledRead)

      if (billedRead < firstRead) {
        e.template = "readFirstRead"
        tag.text = "Register " + x.register + " reading lower than opening"
        tag.class = "badge-danger"
        e.tags.push(tag)
      }

      var lddatObj = new Date(e.billedReadDate)
      var fddateObj = new Date(e.firstBilledReadDate)
      var firstReadDate = dayjs(fddateObj)
      var billedReadDate = dayjs(lddatObj)
      var annualisedDiff = billedReadDate.diff(firstReadDate, "days") / 365

      var linearEac = (billedRead - firstRead) / annualisedDiff
      if (linearEac) {
        tag.text =
          "Register " +
          x.register +
          " EAC out of line. Industry (" +
          x.eac +
          ") Actual (" +
          linearEac +
          ")"
        tag.class = "badge-warning"
        e.tags.push(tag)
      }
    })
  } catch (error) {
    console.log(error)
  }
}
