<template>
  <div>
    <b-table
      hover
      small
      responsive
      :busy="isBusy"
      :sort-by.sync="dataTable.sortBy"
      :sort-desc.sync="dataTable.sortDesc"
      :items="dataTable.items"
      :fields="dataTable.fields"
      style="font-size: 14px"
    >
      <template v-slot:cell(show)="row">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="fetchCompleteRecord(row.item)"
        >
          {{ row.detailsShowing ? "Hide" : "Show" }}
        </b-button>
      </template>

      <template v-slot:cell(check_box)="row">
        <div v-show="!row.item.initial_review_completed">
          <b-form-checkbox
            v-if="row.item.initial_review_completed === false"
            v-model="row.item.clicked"
            :disabled="row.item.disabled || row.item.process_disabled"
            @click="check(row)"
          />
        </div>
      </template>
      <template v-slot:cell(switch_box)="row">
        <b-form-checkbox
          v-if="row.item.initial_review_completed === false"
          v-model="row.item.notOkToSend"
          switch
          :disabled="row.item.disabled || row.item.process_disabled"
          @click="switchToggle(row)"
        />
      </template>

      <template v-slot:cell(seasonal)="row">
        <b-icon
          :icon="
            row.item.current_payment_seasonal_fl ? 'shuffle' : 'arrow-right'
          "
        />
      </template>

      <template v-slot:cell(suggested_payment_amount)="row">
        <span v-if="row.item.edited">
          <input
            v-model="row.item.suggested_payment_amount"
            type="number"
            class="payment"
          />
        </span>
        <span v-else>
          £{{ Math.round(row.item.suggested_payment_amount, 0) }}
        </span>
      </template>

      <template v-slot:cell(acc_bal)="row">
        <div style="width: 100px">
          <span v-if="row.item.account_balance > 0">
            <span style="color: red">£{{ row.item.account_balance }} DR</span>
          </span>
          <span v-else-if="row.item.account_balance < 0">
            <span style="color: green">
              £{{ -row.item.account_balance }} CR
            </span>
          </span>
          <span v-else-if="row.item.account_balance === 0">
            £{{ row.item.account_balance }}
          </span>
        </div>
      </template>

      <template v-slot:cell(payment_adequacy_id)="row">
        <span v-if="row.item.junifer_payment_adequacy_id === null">
          {{ row.item.id }}
        </span>
        <span v-else>
          {{ row.item.junifer_payment_adequacy_id }}
        </span>
      </template>

      <template v-slot:cell(origin)="row">
        <span v-if="row.item.junifer_payment_adequacy_id === null">
          <b-icon icon="arrow-down" />
        </span>
        <span v-else>
          <b-icon icon="arrow-repeat" />
        </span>
      </template>

      <template v-slot:cell(current_payment_amount)="row">
        £{{ Math.round(row.item.current_payment_amount, 0) }}
      </template>

      <template v-slot:cell(account_number)="row">
        <span v-if="'account' in row.item">{{
          row.item.account.junifer_account_number
        }}</span>
      </template>
      <template v-slot:cell(updated_at)="row">
        <div style="width: 100px">
          {{ row.item.updated_at | formattedDate }}
        </div>
      </template>
      <template v-slot:cell(review_created_date)="row">
        <div style="width: 100px">
          <span v-if="row.item.edited">
            <input
              v-model="row.item.review_created_date"
              type="date"
              class="date"
            />
          </span>
          <span v-else>
            {{ row.item.review_created_date | formattedDate }}
          </span>
        </div>
      </template>

      <template v-slot:cell(perc_change)="row">
        <div
          :class="{ 'text-danger': row.item.suggested_payment_amount === null }"
        >
          <span v-if="row.item.paymentChange">
            <span v-if="row.item.perc_change > 0" style="display: inline-block">
              {{ row.item.perc_change }}%
              <b-icon icon="arrow-up" variant="danger" />
            </span>
            <span
              v-else-if="row.item.perc_change < 0"
              style="display: inline-block"
            >
              {{ row.item.perc_change }}%
              <b-icon icon="arrow-down" variant="success" />
            </span>
          </span>
        </div>
      </template>

      <template v-slot:cell(refund)="row">
        <span
          v-if="
            row.item.has_meter_readings &&
            parseFloat(row.item.suggested_refund) > 0
          "
        >
          £{{ Math.round(parseFloat(row.item.suggested_refund, "0.0")) }}
        </span>
      </template>

      <template v-slot:cell(tickets)="row">
        <div
          v-for="(tag, index) in row.item.tags"
          v-show="row.item.tags.length > 0"
          :key="index"
          style="display: inline-block"
        >
          <b-badge
            style="white-space: normal; max-width: 100px; margin: 1px"
            :variant="tag.class"
            >{{ tag.text }}</b-badge
          >
        </div>
      </template>

      <template v-slot:cell(mr_check)="row">
        <span
          v-show="!row.item.has_meter_readings"
          class="badge badge-danger ml-1"
          >Needs Read</span
        >
        <span
          v-show="row.item.unbilled_readings_fl"
          class="badge badge-warning ml-1"
          >Unbilled</span
        >
        <span v-if="row.item.has_meter_readings" class="badge badge-success"
          >Meter Read</span
        >
      </template>

      <template v-slot:cell(process)="row">
        <div v-if="row.item.initial_review_completed">
          <b-icon icon="check-circle" variant="success" />
        </div>
        <div v-else-if="!row.item.deleted_fl">
          <b-button
            size="sm"
            :disabled="row.item.disabled || row.item.process_disabled"
            variant="outline-success"
            @click="dayOneProcess(row.item)"
          >
            <div v-if="row.item.process_disabled">
              <b-spinner small />
            </div>
            <div v-else>Process</div>
          </b-button>
        </div>
      </template>

      <template v-slot:cell(edit)="row">
        <div v-if="!row.item.deleted_fl">
          <b-button
            v-if="row.item.initial_review_completed === false"
            size="sm"
            variant="outline-dark"
            :disabled="row.item.disabled || row.item.process_disabled"
            @click="editable(row.item)"
          >
            {{ row.item.edited ? "Save" : "Edit" }}
          </b-button>
        </div>
      </template>

      <template v-slot:cell(refresh_data)="row">
        <div v-if="!row.item.deleted_fl">
          <b-button
            v-if="!row.item.initial_review_completed"
            size="sm"
            variant="outline-secondary"
            :disabled="row.item.disabled || row.item.process_disabled"
            @click="refresh(row.item)"
          >
            <div v-if="row.item.disabled">
              <b-spinner small />
            </div>
            <div v-else>Refresh</div>
          </b-button>
        </div>
      </template>

      <template v-slot:cell(delete)="row">
        <div v-if="row.item.deleted_fl">
          <b-icon icon="x-circle-fill" variant="danger" />
        </div>
        <div v-else>
          <b-button
            size="sm"
            :disabled="row.item.disabled || row.item.process_disabled"
            variant="outline-danger"
            @click="softdelete(row)"
          >
            Delete
          </b-button>
        </div>
      </template>
    </b-table>
    <PaymentAdequacyPreviewModal
      :review="selectedReview"
      :show="showModal"
      @close="closeModal"
    />
  </div>
</template>

<script>
import PaymentAdequacyPreviewModal from "@/components/paymentAdequacy/paymentAdequacyPreviewModal"
import { mapActions, mapGetters, mapMutations } from "vuex"
import { paymentAdequacyMixin } from "@/mixins/paymentAdequacyMixin"
import PaymentAdequacy from "@/api/PaymentAdequacy"
import advancedFormat from "dayjs/plugin/advancedFormat"
import dayjs from "dayjs"
dayjs.extend(advancedFormat)

export default {
  components: {
    PaymentAdequacyPreviewModal,
  },
  filters: {
    formattedDate: function (date) {
      return dayjs(date).format("Do MMM YY")
    },
  },
  mixins: [paymentAdequacyMixin],
  props: {
    data: {
      type: Array,
      default: () => [],
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      selectedReview: {},
      selected: null,
      showModal: false,
      filter: null,
      oneDaylookup: null,
      tableColumns: [
        { key: "check_box", label: "", sortable: false },
        { key: "origin", label: "", sortable: true },
        { key: "payment_adequacy_id", label: "ID" },
        { key: "review_created_date", label: "Due", sortable: true },
        { key: "switch_box", label: "", sortable: false },
        { key: "tickets", label: "", sortable: false },
        { key: "account_number", label: "Account", sortable: false },
        { key: "seasonal", label: "" },
        { key: "current_payment_amount", label: "Current", sortable: true },
        { key: "suggested_payment_amount", label: "Suggested", sortable: true }, //suggested_payment_amount
        { key: "perc_change", label: "%", sortable: true },
        { key: "refund", label: "Refund" },
        { key: "mr_check", label: "" },
        { key: "acc_bal", label: "A/B" },
        { key: "updated_at", label: "Refresh", sortable: false },
        { key: "show", label: "", sortable: false },
        { key: "refresh_data", label: "", sortable: false },
        { key: "edit", label: "", sortable: false },
        { key: "process", label: "", sortable: false },
        { key: "delete", label: "", sortable: false },
      ],
    }
  },
  computed: {
    ...mapGetters("sixMonthAdDayOne", [
      "refundTable",
      "totalReviews",
      "lookupResults",
    ]),
    dataTable() {
      return {
        sortBy: "Status",
        sortDesc: false,
        fields: this.tableColumns,
        items: this.amendedData,
      }
    },
    amendedData() {
      let newData = JSON.parse(JSON.stringify(this.data))
      for (let i = 0; i < newData.length; i++) {
        newData[i].origin = newData[i].junifer_payment_adequacy_id === null
        const currentPayment = parseInt(newData[i].current_payment_amount, 10)
        if (isNaN(currentPayment)) {
          currentPayment = 0
        }
        newData[i].nominal_change =
          newData[i].suggested_payment_amount - currentPayment
        newData[i].perc_change = parseInt(newData[i].perc_change, 10)
      }
      return newData
    },
  },
  created() {
    // this.setupStream();
  },
  methods: {
    closeModal() {
      this.showModal = false
    },
    ...mapActions({
      softdelete: "sixMonthAdDayOne/softdelete",
      fetchRowInfo: "sixMonthAdDayOne/fetchRowInfo",
      findRowInfo: "sixMonthAdDayOne/findRowInfo",
    }),
    ...mapMutations("sixMonthAdDayOne", [
      "SET_REFRESH",
      "DELETE_ROW_ID",
      "SET_DAYONEDATA",
      "SET_STATE",
      "EDIT_ROW",
      "SET_LOOKUP_RESULTS",
    ]),
    editable(row) {
      row.edited = !row.edited
      this.EDIT_ROW(row)
      PaymentAdequacy.updateRecord(row.id, row)
    },
    refresh(data) {
      var that = this
      data.disabled = true
      this.SET_REFRESH(data)
      // eslint-disable-next-line no-console,no-debugger
      PaymentAdequacy.initialRefresh(data.id, data)
        .then((response) => {
          // eslint-disable-next-line no-console
          var e = response.data
          e.disabled = false
          // eslint-disable-next-line no-console
          this.initialReview(e)
          var d = that.reviewFormatter(e)
          // eslint-disable-next-line no-console

          that.SET_REFRESH(d)
        })
        .catch(() => {
          data.disabled = false
          that.SET_REFRESH(data)
        })
    },
    async fetchCompleteRecord(row) {
      await this.fetchRowInfo(row)
      this.selectedReview = await this.findRowInfo(row.id)
      this.showModal = true
    },
    formatObj(x) {
      this.fetchTemplate(x)
      delete x.meterpoints
      var row = {
        review: x,
        new_payment: x.suggested_payment_amount,
        refund: x.refund,
      }
      return row
    },
    dayOneProcess(data) {
      var that = this
      PaymentAdequacy.getReview(data.id).then((response) => {
        let newOb = Object.assign({}, response.data, data)
        var row = this.formatObj(newOb)
        this.$store.dispatch("sixMonthAdDayOne/paymentscript1dayaction", row)
        if (row.review.seven_day_follow_up) {
          that.DELETE_ROW_ID(row.review.id) // Slice from array if follow up
        }
      })
    },
    check(e) {
      e.item.clicked = !e.item.clicked
      this.SET_REFRESH(e)
    },
    switchToggle(e) {
      e.item.okToSend = !e.item.okToSend
      this.SET_REFRESH(e)
    },
  },
}
</script>

<style lang="scss">
.email-template-content {
  table {
    background-color: transparent;
    width: auto;
    td {
      padding: 0;
      border: 0;
    }
    td[width] {
      display: inline-block;
    }
  }
  table[width="100%"] {
    width: 100%;
  }
  tbody tr:hover {
    color: unset !important;
    background-color: transparent !important;
  }
}
</style>
