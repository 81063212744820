import Freshdesk from "@/api/Freshdesk"

const state = {
  busyStatus: false,
  surveys: [],
  surveyRatings: [],
  ratingValues: [
    { value: 103, desc: "Extremely Happy" },
    { value: 102, desc: "Very Happy" },
    { value: 101, desc: "Happy" },
    { value: 100, desc: "Neutral" },
    { value: -101, desc: "Unhappy" },
    { value: -102, desc: "Very Unhappy" },
    { value: -103, desc: "Extremely Unhappy" },
  ],
}

const mutations = {
  SET_SURVEY(state, data) {
    state.surveys = data
  },
  SET_SURVEY_RATING(state, data) {
    state.surveyRatings = data
  },
  SET_STATUS(state, data) {
    state.busyStatus = data
  },
}

const getters = {
  ratings: (state) => {
    return state.surveyRatings
  },
  surveys: (state) => {
    return state.surveys
  },
  status: (state) => {
    return state.busyStatus
  },
}

const setters = {}

const actions = {
  async getAllSurveys({ commit }) {
    commit("SET_STATUS", true)
    await Freshdesk.getAllSurveys()
      .then((response) => {
        commit("SET_SURVEY", response.data)
        commit("SET_STATUS", false)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getAllSurveyRatings({ commit }) {
    await Freshdesk.getSurveyRatings()
      .then((response) => {
        commit("SET_SURVEY_RATING", response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  setters,
  state,
}
