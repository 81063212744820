import axios from "axios"

const apiFreshdesk = axios.create({
  baseURL: process.env.VUE_APP_FRESDESK_API,
  auth: {
    username: process.env.VUE_APP_FRESDESK_USERNAME,
    password: process.env.VUE_APP_FRESDESK_SECRET,
  },
})

export default {
  getGroups() {
    return apiFreshdesk.get("groups?per_page=100")
  },
  getTicketFields() {
    return apiFreshdesk.get("ticket_fields")
  },
  searchAccountNumber(accountNumber) {
    return apiFreshdesk.get(
      'search/contacts?query="junifer_account:' + accountNumber + '"'
    )
  },
  getGroupTickets(id) {
    return apiFreshdesk.get(
      'search/tickets?query="group_id:' + id + '%20AND%20status:2"'
    )
  },
  getTicket(id) {
    return apiFreshdesk.get("tickets/" + id)
  },
  updateTicket(id, payload) {
    return apiFreshdesk.put("tickets/" + id, payload)
  },
  getTicketConversations(id) {
    return apiFreshdesk.get("tickets/" + id + "/conversations")
  },
  getGroupTicketsPages(id, page) {
    return apiFreshdesk.get(
      'search/tickets?query="group_id:' + id + '%20AND%20status:2"&page=' + page
    )
  },
  getAllAgents() {
    var promises = []
    var agents = []
    var i
    for (i = 1; i < 4; i++) {
      promises.push(
        apiFreshdesk.get("agents?per_page=100&page=" + i).then((x) => {
          agents = agents.concat(x.data)
        })
      )
    }
    Promise.all(promises).then(() => {
      console.log(agents)
      return agents
    })
  },
  getAgentDetails(agentId) {
    return apiFreshdesk.get("agents/" + agentId)
  },
  getAllRoles() {
    return apiFreshdesk.get("roles")
  },

  // Surveys
  getAllSurveys() {
    return apiFreshdesk.get("surveys")
  },
  getSurveyRatings() {
    return apiFreshdesk.get("surveys/satisfaction_ratings")
  },
}
