import Vue from "vue"
import Vuex from "vuex"

//Import modules
import communications from "./modules/Communications"
import freshdeskSurveys from "./modules/FreshdeskSurveys"
import freshdeskAgents from "./modules/FreshdeskAgents"
import freshdeskTickets from "./modules/FreshdeskTickets"
import sixMonthsAdequacyDayOne from "./modules/SixMonthPaymentAdequacy"
import paymentReviewFollowUp from "./modules/SixMonthPaymentAdequacyFollowUp"
import rejectedData from "./modules/JuniferRejectedRegistrations"
import openingReads from "./modules/RejectedOpeningReads"
import validatedOpeningReads from "./modules/ValidatedOpeningReads"
import billing from "./modules/Billing"
import industryRejections from "./modules/IndustryRejectedRegistrations"
import trading from "./modules/Trading"
import smartMeter from "./modules/SmartMeter"
import openingRejectedReadings from "./modules/OpeningRejectedReadings"
import warmHomeDiscount from "./modules/WarmHomeDiscount"
import disputedReadings from "./modules/DisputedReadings"
import imbp from "./modules/Imbp"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    comms: communications,
    fdSurveys: freshdeskSurveys,
    fdAgents: freshdeskAgents,
    fdTickets: freshdeskTickets,
    sixMonthAdDayOne: sixMonthsAdequacyDayOne,
    sixMonthAdFollowUp: paymentReviewFollowUp,
    juniferRejections: rejectedData,
    openingReadings: openingReads,
    validatedOpeningReadings: validatedOpeningReads,
    bills: billing,
    industryRejections: industryRejections,
    trading: trading,
    smartMeter: smartMeter,
    openingRejectedReadings: openingRejectedReadings,
    warmHomeDiscount: warmHomeDiscount,
    disputedReadings,
    imbp,
  },
})
