import Comms from "../../api/Communication"

const state = {
  communicationTemplates: [],
  isBusy: true,
}
const mutations = {
  SET_COMMS_TEMPLATE(state, data) {
    state.communicationTemplates = data
  },
  SET_BUSY_STATE(state, data) {
    state.isBusy = data
  },
}

const getters = {
  isBusy: (state) => {
    return state.isBusy
  },
  templates: (state) => {
    return state.communicationTemplates
  },
}

const setters = {}
const actions = {
  getTemplates(context) {
    Comms.getAllContentTemplates()
      .then((response) => {
        console.log(response.data)
        context.commit("SET_COMMS_TEMPLATE", response.data)
        context.commit("SET_BUSY_STATE", false)
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  setters,
  state,
}
