<template>
  <div class="send-email">
    <div class="send-email__inner">
      <section class="send-email__header">
        <h2>Ad Hoc Email Tool</h2>
        <p>
          This tool has a pre-defined email template. Do not use without
          consulting Product Team.
        </p>
      </section>
      <section class="send-email__body">
        <div class="send-email__box">
          <p class="send-email__text">
            Send emails to customers promoting fixed rate tariff
          </p>
          <UploadCsv
            :parser="csvToString"
            :api-call="AdHocApi.sendEmails"
          ></UploadCsv>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import AdHocApi from "@/api/AdHoc.js"
import UploadCsv from "@/components/csv/UploadCsv.vue"
import { csvToString } from "@/helpers/csv/parsers"

export default {
  name: "SendEmail",
  components: {
    UploadCsv,
  },
  data() {
    return {
      csvToString: csvToString,
      AdHocApi: AdHocApi,
    }
  },
}
</script>

<style lang="scss">
.send-email {
  background-color: $day;
  padding: 0 0 $spacing-8 0;
  min-height: 100vh;

  &__inner {
    min-width: 1024px;
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
  }

  &__header {
    text-align: left;
    padding: $spacing-8 0 $spacing-8;
    position: relative;

    h2 {
      font-size: 50px;
      font-weight: $weight-bold;
      margin-bottom: $spacing-4;
    }

    p {
      font-size: $size-6;
      max-width: 900px;
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
  }

  &__box {
    background-color: $day-dark;
    width: calc(50% - #{$space-4});
    padding: $spacing-6;
    min-height: 100px;
    margin-right: $spacing-4;
    margin-bottom: $spacing-6;

    &:nth-child(2n) {
      margin-right: 0;
      margin-left: $spacing-4;
    }
  }
}
</style>
