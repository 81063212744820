export function idColumnOnly(data) {
  if (!data.length) {
    return "This file has no data"
  }

  // more than one column of data
  if (data[0].includes(",")) {
    return "This file should only have one column of data"
  }

  return false
}
