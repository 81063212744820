<template>
  <section class="choose-appointment">
    <p
      v-if="Object.keys(accountDetails).length"
      class="choose-appointment__description"
    >
      This appointment will be booked for
      {{ formatAddress }}.
    </p>
    <p
      v-if="Object.keys(accountDetails).length"
      class="choose-appointment__description"
    >
      Please choose a date below and then select an available time slot.
    </p>
    <div v-if="slotsPending">
      <img src="@/assets/icons/loading-bar.gif" />
    </div>

    <div class="choose-appointment__body">
      <Calendar
        v-if="showDates"
        :show-dates="showDates"
        :available-dates="calendarSettings[0].dates"
        @selected-date="handleSelectedDate"
      />

      <div
        v-if="selectedDate && showDates"
        ref="availableTimes"
        class="choose-appointment__available-times"
      >
        <h4 class="choose-appointment__heading">{{ appointmentDate }}</h4>

        <fieldset>
          <RadioGroup v-model="activeTime">
            <RadioButton
              v-for="(slot, index) in timeSlots.slots"
              :key="index"
              :input-id="slot | formatSlot"
              :value="slot"
              :label="slot | formatSlot"
            />
          </RadioGroup>
        </fieldset>
      </div>
    </div>

    <div
      v-if="selectedDate && activeTime && showDates"
      class="choose-appointment__text"
    >
      <p v-if="fuelType === 'ELEC_ONLY'" ref="appointmentText">
        Remember, your chosen time slot is when your engineer will arrive. They
        will usually need 2 hours to install your new electricity smart meter.
      </p>
      <p v-else-if="fuelType === 'GAS_ONLY'" ref="appointmentText">
        Remember, your chosen time slot is when your engineer will arrive. They
        will usually need 2 hours to install your new gas smart meter.
      </p>
      <p v-else ref="appointmentText">
        Remember, your chosen time slot is when your engineer will arrive. They
        will usually need 4 hours to install your new electricity and gas smart
        meters.
      </p>
      <p>
        While dates and times are correct at the time of enquiry, we can't
        always guarantee they'll still be available by the time you complete
        your booking.
      </p>
    </div>
  </section>
</template>

<script>
import dayjs from "dayjs"
import Calendar from "@soenergy/frontend-library/src/components/Calendar"
import RadioGroup from "@soenergy/frontend-library/src/components/RadioButton/RadioGroup"
import RadioButton from "@soenergy/frontend-library/src/components/RadioButton/RadioButton"

export default {
  components: {
    Calendar,
    RadioGroup,
    RadioButton,
  },
  filters: {
    formatSlot(slot) {
      return `${slot.startTime.slice(0, 5)} - ${slot.endTime.slice(0, 5)}`
    },
  },
  props: {
    days: {
      type: Array,
      default: () => [],
    },
    calendarSettings: {
      type: Array,
      default: () => [],
    },
    accountDetails: {
      type: Object,
      default: () => ({}),
    },
    showDates: {
      type: Boolean,
      default: false,
    },
    slotsPending: {
      type: Boolean,
      default: false,
    },
    fuelType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {}
  },
  computed: {
    activeTime: {
      get() {
        return this.$store.state.imbp.activeTime
      },
      set(value) {
        this.$store.commit("imbp/SET_TIME", value)
        this.$emit("mark-page-valid")
      },
    },
    selectedDate: {
      get() {
        return this.$store.state.imbp.selectedDate
      },
      set(value) {
        this.$store.commit("imbp/SET_DATE", value)
        this.$emit("mark-page-invalid")
      },
    },
    timeSlots() {
      if (!this.selectedDate) return null
      return (
        this.days.find(
          (day) => day.date === dayjs(this.selectedDate).format("YYYY-MM-DD")
        ) || []
      )
    },
    appointmentDate() {
      return dayjs(this.selectedDate).format("D MMM YYYY")
    },
    formatAddress() {
      return `${this.accountDetails.address.address1} ${this.accountDetails.address.address2}, ${this.accountDetails.address.address3}, ${this.accountDetails.address.postcode}`
    },
  },
  watch: {
    accountDetails() {
      this.$emit("get-appointments")
      this.$emit("mark-page-invalid")
    },
  },
  created() {
    this.$emit("get-appointments")
    this.$emit("mark-page-invalid")
  },
  methods: {
    handleSelectedDate(data) {
      this.selectedDate = data
      this.$store.commit("imbp/SET_TIME", {})
    },
  },
}
</script>

<style scoped lang="scss">
.choose-appointment {
  &__body {
    margin-top: $space-6;

    @include md {
      max-width: unset;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: $space-8;
    }
  }

  &__available-times {
    margin-top: $space-4;
    margin-left: $space-16;
  }

  &__heading:not(:last-child) {
    margin: 0;
    font-size: $font-size-5;

    @include md {
      margin: $space-4 0 0 0;
    }
  }

  &__description {
    font-size: $font-size-5;
    line-height: $line-height-4;
    margin-bottom: $space-8;
  }

  &__text {
    text-align: left;
    font-size: $font-size-2;
  }
}
</style>
