<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Outbound SMS"
        summary="Send adhoc messages to customers"
      >
      </header-block>
    </b-row>

    <page-section title="">
      <template v-slot:content>
        <b-modal v-model="modalShow" size="lg" centered>
          <template v-slot:modal-header>
            <h5>Are you sure you want to send?</h5>
          </template>
          <b-card-body>
            <div v-if="!!error">Error Status: {{ error }}</div>
            <div v-else>
              Phone: {{ form.to_number }}<br />
              Content: {{ form.body }}
            </div>
          </b-card-body>
          <template v-slot:modal-footer>
            <b-button
              v-b-modal="'modal'"
              size="float-right"
              :disabled="sentFl"
              :variant="buttonClass"
              @click="send() && !sentFl"
            >
              <span v-if="isBusy">
                <b-spinner small label="Small Spinner"></b-spinner>
              </span>
              <span v-else>
                <span v-if="sentFl">
                  <span v-if="!!error"> &#10060; error!</span>
                  <span v-else>&#10004; sent!</span>
                </span>
                <span v-else>Yes, send</span>
              </span>
            </b-button>
          </template>
        </b-modal>

        <b-row>
          <b-form class="w-100" @submit="onSubmit" @reset="onReset">
            <b-form-group
              id="input-group-1"
              label="Phone Number"
              label-for="input-1"
              label-align="right"
              label-cols="4"
              label-cols-lg="2"
            >
              <b-form-input
                id="input-1"
                v-model="form.to_number"
                required
                placeholder="+447500123456"
                :state="phoneValidation"
                :disabled="disabledFl"
              ></b-form-input>
              <b-form-invalid-feedback :state="phoneValidation">
                Phone number must be 13 characters long and start with '+447'
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="phoneValidation">
                Looks Good.
              </b-form-valid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Message"
              label-for="input-2"
              label-align="right"
              label-cols="4"
              label-cols-lg="2"
            >
              <b-form-textarea
                id="input-2"
                v-model="form.body"
                required
                placeholder="Hi Joe, ......"
                rows="3"
                max-rows="6"
                :disabled="disabledFl"
              ></b-form-textarea>
            </b-form-group>
            <span class="float-right"
              ><i
                >Character count: {{ form.body.length }} (SMS:
                {{ Math.ceil(form.body.length / 153) }})</i
              ></span
            >
            <br /><br />
            <b-button
              type="reset"
              variant="outline-danger"
              class="float-right m-1"
              >Reset</b-button
            >
            <b-button
              type="submit"
              variant="success"
              :disabled="disabledFl"
              class="float-right m-1"
              >Submit</b-button
            >
          </b-form>
        </b-row>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import Messages from "@/api/Messages.js"

export default {
  components: {
    headerBlock,
    pageSection,
  },
  data() {
    return {
      form: {
        body: "",
        to_number: "",
      },
      sentFl: false,
      modalShow: false,
      disabledFl: false,
      error: null,
      isBusy: false,
    }
  },
  computed: {
    phoneValidation() {
      if (this.form.to_number.length === 0) {
        return null
      } else {
        return (
          this.form.to_number.length === 13 &&
          this.form.to_number.substring(0, 4) === "+447"
        )
      }
    },
    buttonClass() {
      if (this.error === null) {
        return "success"
      } else {
        return "danger"
      }
    },
  },
  created() {},
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      this.error = null
      this.modalShow = true
    },
    onReset(evt) {
      evt.preventDefault()
      this.form.to_number = ""
      this.form.body = ""
      this.disabledFl = false
      this.sentFl = false
      this.error = null
    },
    send() {
      this.isBusy = true
      Messages.outboundSms(this.form).then((response) => {
        if (response.status === 200) {
          this.disabledFl = true
          this.sentFl = true
        } else {
          this.error = response
        }
        this.isBusy = false
      })
    },
  },
}
</script>
