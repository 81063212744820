import { datadogRum } from "@datadog/browser-rum"

export default {
  install(app) {
    const branch = process.env.VERCEL_BRANCH
    const branches = ["sandbox", "staging", "master"]

    if (process.env.NODE_ENV === "production" && branches.includes(branch)) {
      datadogRum.init({
        applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
        clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
        site: "datadoghq.eu",
        service: "fe-ark",
        env: branch === "master" ? "production" : branch,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
        allowedTracingUrls: [
          { match: /https:\/\/.*\.staging.soenergy\.co\/.*/, propagatorTypes: ["tracecontext"] },
          { match: /https:\/\/.*\.pre-production\.soenergy\.co\/.*/, propagatorTypes: ["tracecontext"] },
          { match: /https:\/\/.*\.so\.energy\/.*/, propagatorTypes: ["tracecontext"] }
        ]
      })

      const originalErrorHandler = app.config.errorHandler
      app.config.errorHandler = function (error, vm, info) {
        if (typeof originalErrorHandler === "function") {
          originalErrorHandler.call(this, error, vm, info)
        }
        datadogRum.addError(error, {
          error: { kind: error.name, stack: error.stack },
          context: { info },
        })
      }
    }
  },
}
