<template>
  <div class="login-screen-mask">
    <div class="login-screen">
      <h2>Login to Warm Home Discount</h2>
      <label for="password">Please enter your password</label>
      <div class="login-screen__password-input">
        <input
          v-model="input.password"
          type="password"
          name="password"
          :class="{
            'input-error': incorrectPasswordError || blankPasswordError,
          }"
        />
        <span v-if="incorrectPasswordError">{{ incorrectPasswordError }}</span>
        <span v-if="blankPasswordError">{{ blankPasswordError }}</span>
      </div>
      <div class="login-screen__flex-box">
        <button class="button button--blue" @click="login()">Login</button>
      </div>
      <hr />
      <div class="login-screen__flex-box">
        <router-link to="/">Go back to homepage</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { trapFocus } from "../../mixins/trapFocus"

export default {
  name: "LoginScreen",
  mixins: [trapFocus],
  data() {
    return {
      blankPasswordError: "",
      incorrectPasswordError: "",
      input: {
        password: "",
      },
    }
  },
  methods: {
    login() {
      if (this.input.password != "") {
        if (this.input.password == this.$parent.warmHomePassword) {
          this.$emit("authenticated")
        } else {
          this.incorrectPasswordError = "The password you entered was incorrect"
          this.blankPasswordError = ""
        }
      } else {
        this.blankPasswordError = "Please enter password"
        this.incorrectPasswordError = ""
      }
    },
  },
}
</script>

<style lang="scss">
.login-screen-mask {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: $blue-100;
}
.login-screen {
  height: 360px;
  padding: $spacing-6;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #00000002;
  transition: all 0.3s ease;
  text-align: left;
  margin-top: 10%;
  background-color: $day;

  h2,
  label {
    font-weight: $weight-bold;
  }

  label {
    display: block;
    font-size: $size-7;
    color: $night-light-2;
  }

  input {
    width: 100%;
    height: 40px;
    border: 1px solid $night-light-2;
    border-radius: 4px;
    margin: $spacing-2 0 $spacing-2;
    padding: $spacing-2;

    &:focus {
      box-shadow: 0 0 0 4px $water-light;
      outline: none;
    }
  }

  &__password-input {
    position: relative;

    span {
      position: absolute;
      bottom: -16px;
      left: 8px;
      font-size: 14px;
      display: block;
      color: $fire;
    }
  }

  .input-error {
    border: 1px solid $error-text;

    &:focus {
      box-shadow: 0 0 0 4px $fire-dark;
    }
  }

  button {
    margin: $spacing-5 0 $spacing-4;

    &:focus {
      box-shadow: 0 0 0 4px $water-light;
    }
  }

  &__flex-box {
    display: flex;
    justify-content: flex-end;

    a {
      color: $water;

      &:focus {
        border-bottom: 2px solid $water;
        outline: none;
      }
    }
  }
}
</style>
