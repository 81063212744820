<template>
  <UploadCsv
    :parser="parser"
    :api-call="WhdApi.updateAccountsAuditReturn"
    v-on="$listeners"
  >
    <div class="radio-wrapper">
      <input
        id="approved"
        v-model="applicationStatus"
        type="radio"
        value="approved_at_audit"
      />
      <label for="approved"> Accounts approved at audit </label>
    </div>
    <div class="radio-wrapper">
      <input
        id="verification"
        v-model="applicationStatus"
        type="radio"
        value="document_verification"
      />
      <label for="verification"> Accounts require verification </label>
    </div>
  </UploadCsv>
</template>

<script>
import UploadCsv from "../csv/UploadCsv.vue"
import { csvToListOfCells } from "@/helpers/csv/parsers"
import PaymentAdequacy from "@/api/PaymentAdequacy"
import WhdApi from "@/api/WarmHomeDiscount.js"

export default {
  components: {
    UploadCsv,
  },
  data() {
    return {
      PaymentAdequacy: PaymentAdequacy,
      applicationStatus: "approved_at_audit",
      WhdApi: WhdApi,
    }
  },
  methods: {
    parser(csvFile) {
      return new Promise((resolve, reject = reject) => {
        csvToListOfCells(csvFile).then((listOfCells) => {
          resolve({
            warm_home_discounts_interface: {
              account_numbers: listOfCells,
              new_attributes: {
                ark_status: this.applicationStatus,
              },
            },
          })
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
.radio-wrapper {
  display: table;
}
label {
  font-weight: $weight-bold;
  color: $night-light;
  margin-left: $space-2;
}
</style>
