/**
 * Converts a csv file in formData.
 *
 * How to use:
 * Likely used in conjuction with an input html tag:
 * <input type="file" accept=".csv" @change="csvToFormData($event.target.files[0])" />
 *
 * @param {*File {type: "text/csv"}} csvFile
 * @returns {FormData} array of strings from the csv file.
 */
export function csvToFormData(csvFile) {
  return new Promise((resolve, reject) => {
    const fd = new FormData()
    fd.append("file", csvFile)
    resolve(fd)
    reader.onerror = reject
  })
}

/**
 * Converts a csv file into one long string (preserving new line characters).
 *
 * How to use:
 * Likely used in conjuction with an input html tag:
 * <input type="file" accept=".csv" @change="csvToFormData($event.target.files[0])" />
 *
 * @param {*File {type: "text/csv"}} csvFile
 * @returns {string} version of csv file
 */
export function csvToString(csvFile) {
  return new Promise((resolve, reject) => {
    if (!csvFile) resolve("")
    const reader = new FileReader()
    reader.onload = (e) => {
      resolve(e.target.result)
    }
    reader.onerror = reject
    reader.readAsText(csvFile)
  })
}

/**
 * Parses a headless csv into an array of strings.
 * Or in other words, a csv with one column and no headers, ...
 * ... is parsed into ["row_1", "row_2", etc...]
 *
 * How to use:
 * Likely used in conjuction with an input html tag:
 * <input type="file" accept=".csv" @change="headlessCsvToArrayOfStrings($event.target.files[0])" />
 *
 * @param {*File {type: "text/csv"}} csvFile
 * @returns {string[]} array of strings from the csv file.
 */
export function headlessCsvToArrayOfStrings(
  csvFile,
  _csvToString = csvToString
) {
  return new Promise((resolve, reject = reject) => {
    _csvToString(csvFile).then((str) => {
      const rows = str.split(/\r\n|\n/) // TODO: a 3rd party library would better handle edge cases.
      if (!rows.length) {
        resolve([])
        return
      }
      resolve(rows.filter((o) => o !== ""))
    })
  })
}

/**
 * Parses a csv into an array of objects.
 * Or in other words, a csv with 2 headers, ...
 * ... is parsed into [{h1: "a1", h2: "a2"}, {h1: "b1", h2: "b2"}, etc...]
 *
 * How to use:
 * Likely used in conjuction with an input html tag:
 * <input type="file" accept=".csv" @change="csvToArrayOfObjects($event.target.files[0])" />
 *
 * @param {*File {type: "text/csv"}} csvFile
 * @returns {Object[]} array of objects from the csv file.
 */
export function csvToArrayOfObjects(csvFile, _csvToString = csvToString) {
  return new Promise((resolve, reject = reject) => {
    _csvToString(csvFile).then((str) => {
      const rows = str.split(/\r\n|\n/) // TODO: a 3rd party library would better handle edge cases.
      if (!rows.length) {
        resolve([])
        return
      }
      const headers = rows[0].split(",")
      resolve(
        rows
          .slice(1)
          .filter((o) => o !== "")
          .map((o) =>
            o.split(",").reduce((accumulative, currentValue, i) => {
              accumulative[headers[i]] = currentValue
              return accumulative
            }, {})
          )
      )
    })
  })
}

/**
 * Parses a csv into an array of strings, without the header.
 * Or in other words, a csv with n column(s) ...
 * ... is parsed into ["row_2", "row_3", etc...]
 *
 * How to use:
 * Likely used in conjuction with an input html tag:
 * <input type="file" accept=".csv" @change="csvToListOfCells($event.target.files[0])" />
 *
 * @param {*File {type: "text/csv"}} csvFile
 * @returns {string[]} array of strings from the csv file.
 */
export function yetAnotherCSVParseMethod(csvFile, _csvToString = csvToString) {
  return new Promise((resolve, reject = reject) => {
    _csvToString(csvFile).then((str) => {
      const rows = str.split(/\r\n|\n/) // TODO: a 3rd party library would better handle edge cases.
      if (!rows.length) {
        resolve([])
        return
      }
      let result = rows.filter((o) => o !== "")
      result = result.slice(1, result.length)
      resolve(result)
    })
  })
}

/**
 * Parses a csv into an array of strings.
 * Or in other words, a csv with n column(s) ...
 * ... is parsed into ["header_1", "header_2", "row_1", "row_2", etc...]
 * This is a very weird parsing strategy, added to replicate previous behavior.
 *
 * How to use:
 * Likely used in conjuction with an input html tag:
 * <input type="file" accept=".csv" @change="csvToListOfCells($event.target.files[0])" />
 *
 * @param {*File {type: "text/csv"}} csvFile
 * @returns {string[]} array of strings from the csv file.
 */
export function csvToListOfCells(csvFile, _csvToString = csvToString) {
  return new Promise((resolve, reject = reject) => {
    _csvToString(csvFile).then((str) => {
      let rows = str.split(/\n|,/) // TODO: a 3rd party library would better handle edge cases.
      if (!rows.length) {
        resolve([])
        return
      }
      rows = rows.map((val) => val.replace(/\r/g, ""))
      resolve(rows.filter((o) => o !== ""))
    })
  })
}
