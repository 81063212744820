<template>
  <div class="rejected-readings">
    <div class="rejected-readings__inner">
      <section class="rejected-readings__header">
        <h2>Rejected opening readings</h2>
        <p>
          Email customers letting them know their reading has failed validation
        </p>
        <!-- <a href="/admin/user_guides" class="button button--blue-outlined">
          <i class="far fa-question-circle" />
          User Guide
        </a> -->
      </section>

      <!-- <section class="disputed-reads">
        <h3>Disputing meter readings</h3>
        <p>
          Raises a dispute within Junifer, puts the account under review and
          sends an email to the customer via Freshdesk (optional)
        </p>
        <AccountSearch
          class="disputed__search"
          :search-error="searchError"
          :placeholder-text="'Search MPAN/MPRN'"
          @accountSearch="handleAccountSearch"
          @clearAccountSearch="clearDisputedAccountSearch"
        />
        <img
          v-if="fetchingDisputedAccount === 'loading'"
          src="@/assets/icons/loading-bar.gif"
        />
        <p
          v-if="fetchingDisputedAccount === 'error'"
          class="disputed-reads__error-text"
        >
          There was an issue, please ensure the account number is correct and
          your VPN is connected
        </p>
        <DisputedReadings
          v-if="disputedSearchResult"
          :search-response="disputedSearchResult"
        />
      </section> -->

      <section v-if="!disputedSearchResult" class="rejected-readings__body">
        <div class="rejected-readings__filters">
          <FilterButton
            title="Electricity readings"
            name="electricity-readings"
            :energy-type="'electric'"
            :active="activeFilter === 'electricity-readings'"
            @filterClick="filterClick"
          >
            <i class="fas fa-bolt filter-button--rejected-icon"></i>
          </FilterButton>
          <FilterButton
            title="Gas readings"
            name="gas-readings"
            :active="activeFilter === 'gas-readings'"
            :energy-type="'gas'"
            @filterClick="filterClick"
          >
            <i class="fas fa-fire filter-button--rejected-icon"></i>
          </FilterButton>
        </div>

        <div
          v-show="activeFilter === 'electricity-readings'"
          class="rejected-readings__flex-box"
        >
          <div class="rejected-readings__left-column">
            <div class="rejected-readings__tables">
              <div v-if="!elecCustomers.length">
                <h3
                  class="
                    rejected-readings__import-heading
                    rejected-readings__import-heading--electric
                  "
                >
                  Please click 'Import' for more rejected readings
                  <i
                    class="
                      fas
                      fa-arrow-circle-right
                      rejected-readings__import-heading-icon
                    "
                  ></i>
                </h3>
              </div>
              <GenericTable
                v-if="elecCustomers.length"
                :table-header="electricHeader"
                color-scheme="orange"
                :column-ratio="[3, 20, 15, 15, 17, 23, 7]"
                @loadMoreTableRows="loadMoreTableRows('electricity-readings')"
              >
                <GenericTableRow
                  v-for="(customer, index) in elecCustomers"
                  :key="index"
                  :column-ratio="[3, 20, 15, 15, 17, 23, 7]"
                >
                  <template v-slot:column-1>
                    {{ customer.customer_name }}
                  </template>
                  <template v-slot:column-2>
                    {{ customer.read_received_date | formatDate }}
                  </template>
                  <template v-slot:column-3>
                    <span class="generic-table__energy-number">
                      {{ customer.mpxn }}
                    </span>
                  </template>
                  <template v-slot:column-4>
                    <label v-if="customer.template_type === 'outside_tol'">
                      Out of Tolerance
                    </label>
                    <label v-else-if="customer.template_type === 'within_tol'">
                      Within Tolerance
                    </label>
                    <label v-else>
                      {{ customer.template_type }}
                    </label>
                  </template>
                  <template v-slot:column-6>
                    <div class="generic-table__flags">
                      <span v-if="customer.note_count > 2">
                        {{ customer.note_count }} <br />
                        Notes
                      </span>
                      <span v-if="customer.erroneous_transfer_ticket_open">
                        ET <br />
                        Open
                      </span>
                      <span v-if="customer.under_review">
                        Under <br />
                        review
                      </span>
                      <span v-if="customer.error_reason"> Error </span>
                      <span v-if="customer.d86_count">
                        {{ customer.d86_count }} D86s
                      </span>
                      <span v-if="customer.d300_count">
                        {{ customer.d300_count }} <br />
                        D300s
                      </span>
                      <span v-if="customer.d71_count >= 2">
                        {{ customer.d71_count }} <br />
                        D71s
                      </span>
                      <span v-if="customer.d10_count >= 2">
                        {{ customer.d10_count }} <br />
                        D10s
                      </span>
                    </div>
                  </template>
                  <template v-slot:column-7>
                    <div class="generic-table__checkbox">
                      <input
                        v-model="elecAccountSelected"
                        type="checkbox"
                        :value="customer.id"
                        @click.stop
                        @change.stop.prevent="readingTaskApiElec = ''"
                      />
                    </div>
                  </template>
                  <template v-slot:dropdown>
                    <RejectedReadsDropdown
                      class="generic-table__dropdown"
                      :customer="customer"
                      type="elec"
                      @deleteCustomer="deleteCustomer"
                    />
                  </template>
                </GenericTableRow>
              </GenericTable>
            </div>
          </div>

          <div class="rejected-readings__right-column">
            <SidebarCard :color-select="'orange'">
              <template v-slot:header> Search by MPAN </template>
              <AccountSearch
                :search-error="searchError"
                :input-placeholder="mpanPlaceholder"
                :placeholder-text="'Search MPAN'"
                @accountSearch="handleMpanAccountSearch"
                @clearAccountSearch="clearAccountSearch"
              />
              <img
                v-if="searchAccountsPending"
                src="@/assets/icons/loading-bar.gif"
              />
            </SidebarCard>

            <SidebarCard v-if="elecCustomers.length" :color-select="'orange'">
              <template v-slot:header>
                Total Awaiting
                <span v-if="elecCustomers.length" class="reading-tasks__total">
                  {{ countElecAwaiting.length }} total
                </span>
              </template>
              <p>
                This will send all records which are not completed or flagged
                and therefore under investigation
              </p>
              <button
                class="button button--orange"
                :disabled="isElecBtnDisabled"
                @click="sendAwaitingEmails(countElecAwaiting)"
              >
                Send all
              </button>
              <div
                v-if="elecAwaitingPending === 'loading'"
                class="rejected-readings__loading"
              >
                <img src="@/assets/icons/loading-bar.gif" />
                <p>Please wait...</p>
              </div>
              <p
                v-else-if="elecAwaitingPending === 'error'"
                class="rejected-readings__error"
              >
                Failed to send awaiting accounts
              </p>
              <p
                v-else-if="elecAwaitingPending === 'success'"
                class="rejected-readings__success"
              >
                Successfully sent accounts, the request has been queued and will
                be sent as soon as possible
              </p>
            </SidebarCard>

            <SidebarCard v-if="elecCustomers.length" :color-select="'orange'">
              <template v-slot:header>
                Total Completed
                <span v-if="elecCustomers.length" class="reading-tasks__total">
                  {{ countElecCompleted.length }} total
                </span>
              </template>
              <p>This will delete all records which are marked as completed</p>
              <button
                class="button button--orange"
                :disabled="isElecBtnDisabled || countElecCompleted.length === 0"
                @click="clearReads(countElecCompleted)"
              >
                Clear all
              </button>
              <div
                v-if="
                  elecClearingCompleted === 'loading' &&
                  countElecCompleted.length
                "
                class="rejected-readings__loading"
              >
                <img src="@/assets/icons/loading-bar.gif" />
                <p>Please wait...</p>
              </div>
              <p
                v-else-if="elecClearingCompleted === 'error'"
                class="rejected-readings__error"
              >
                Failed to clear accounts
              </p>
            </SidebarCard>

            <SidebarCard :color-select="'orange'">
              <template v-slot:header>
                Import rejected electric readings
              </template>
              <button
                class="button button--orange"
                :disabled="isElecBtnDisabled"
                @click="importElecAccounts()"
              >
                Import customers
              </button>
              <div
                v-if="elecImportPending === 'loading'"
                class="rejected-readings__loading"
              >
                <img src="@/assets/icons/loading-bar.gif" />
                <p>Coming right up...</p>
              </div>
              <p
                v-else-if="elecImportPending === 'error'"
                class="rejected-readings__error"
              >
                Failed to import accounts
              </p>
              <p
                v-else-if="elecImportPending === 'success'"
                class="rejected-readings__success"
              >
                Successfully imported accounts
              </p>
            </SidebarCard>

            <SidebarCard :color-select="'orange'">
              <template v-slot:header>
                Investigate
                <span v-if="elecCustomers.length" class="reading-tasks__total">
                  {{ countElecInvestigate }} total
                </span>
              </template>
              <p>
                Sends an email to selected customers via Freshdesk and adds a
                note on Junifer account
              </p>
              <ReadingTasks
                :task-amount="elecAccountSelected.length"
                task-type="elec"
                color-scheme="orange"
                :reading-task-api="readingTaskApiElec"
                @sendReadingTasks="sendReadingTasks"
              />
            </SidebarCard>
          </div>
        </div>
        <div
          v-show="activeFilter === 'gas-readings'"
          class="rejected-readings__flex-box"
        >
          <div class="rejected-readings__left-column">
            <div class="rejected-readings__tables">
              <h3
                v-if="!gasCustomers.length"
                class="
                  rejected-readings__import-heading
                  rejected-readings__import-heading--gas
                "
              >
                Please click 'Import' for more rejected readings
                <i
                  class="
                    fas
                    fa-arrow-circle-right
                    rejected-readings__import-heading-icon
                  "
                ></i>
              </h3>
              <GenericTable
                v-if="gasCustomers.length"
                :table-header="gasHeader"
                color-scheme="blue"
                :column-ratio="[3, 20, 15, 15, 17, 23, 7]"
                @loadMoreTableRows="loadMoreTableRows('gas-readings')"
              >
                <GenericTableRow
                  v-for="(customer, index) in gasCustomers"
                  :key="index"
                  :column-ratio="[3, 20, 15, 15, 17, 23, 7]"
                >
                  <template v-slot:column-1>
                    {{ customer.customer_name }}
                  </template>
                  <template v-slot:column-2>
                    {{ customer.read_received_date | formatDate }}
                  </template>
                  <template v-slot:column-3>
                    <span class="generic-table__energy-number">
                      {{ customer.mprn }}
                    </span>
                  </template>
                  <template v-slot:column-4>
                    <label>{{ customer.template }}</label>
                  </template>
                  <template v-slot:column-6>
                    <div class="generic-table__flags generic-table__flags--gas">
                      <span>
                        {{ customer.note_count }} <br />
                        Notes
                      </span>
                      <span v-if="customer.erroneous_transfer_ticket_open">
                        ET <br />
                        Open
                      </span>
                      <span v-if="customer.under_review">
                        Under <br />
                        review
                      </span>
                      <span v-if="customer.next_step === 'Investigate'">
                        {{ customer.next_step }} <br />
                        - Next Step
                      </span>
                    </div>
                  </template>
                  <template v-slot:column-7>
                    <div class="generic-table__checkbox">
                      <input
                        v-model="gasAccountSelected"
                        type="checkbox"
                        :value="customer.id"
                        @click.stop
                        @change.stop.prevent="readingTaskApiGas = ''"
                      />
                    </div>
                  </template>
                  <template v-slot:dropdown>
                    <RejectedReadsDropdown
                      class="generic-table__dropdown"
                      :customer="customer"
                      type="gas"
                      @deleteCustomer="deleteCustomer"
                    />
                  </template>
                </GenericTableRow>
              </GenericTable>
            </div>
          </div>
          <div class="rejected-readings__right-column">
            <SidebarCard color-select="blue">
              <template v-slot:header> Search by MPRN </template>
              <AccountSearch
                :search-error="searchMprnError"
                :input-placeholder="mprnPlaceholder"
                :placeholder-text="'Search MPRN'"
                @accountSearch="handleMprnAccountSearch"
                @clearAccountSearch="clearMprnAccountSearch"
              />
              <img
                v-if="searchMprnAccountsPending"
                src="@/assets/icons/loading-bar.gif"
              />
            </SidebarCard>

            <SidebarCard v-if="gasCustomers.length" color-select="blue">
              <template v-slot:header>
                Total Awaiting
                <span v-if="gasCustomers.length" class="reading-tasks__total">
                  {{ countGasAwaiting.length }} total
                </span>
              </template>
              <p>
                This will send all records which are not completed or flagged
                and therefore under investigation
              </p>
              <button
                class="button button--blue"
                :disabled="isGasBtnDisabled"
                @click="sendAwaitingEmails(countGasAwaiting)"
              >
                Send all
              </button>
              <div
                v-if="gasAwaitingPending === 'loading'"
                class="rejected-readings__loading"
              >
                <img src="@/assets/icons/loading-bar.gif" />
                <p>Please wait...</p>
              </div>
              <p
                v-else-if="gasAwaitingPending === 'error'"
                class="rejected-readings__error"
              >
                Failed to send awaiting accounts
              </p>
              <p
                v-else-if="gasAwaitingPending === 'success'"
                class="rejected-readings__success"
              >
                Successfully sent accounts, the request has been queued and will
                be sent as soon as possible
              </p>
            </SidebarCard>

            <SidebarCard v-if="gasCustomers.length" color-select="blue">
              <template v-slot:header>
                Total Completed
                <span
                  v-if="gasCustomers.length && countGasCompleted.length !== 0"
                  class="reading-tasks__total"
                >
                  {{ countGasCompleted.length }} total
                </span>
              </template>
              <p>This will delete all records which are marked as completed</p>
              <button
                class="button button--blue"
                :disabled="isGasBtnDisabled || countGasCompleted.length === 0"
                @click="clearReads(countGasCompleted)"
              >
                Clear all
              </button>
              <div
                v-if="
                  gasClearingCompleted === 'loading' && countGasCompleted.length
                "
                class="rejected-readings__loading"
              >
                <img src="@/assets/icons/loading-bar.gif" />
                <p>Please wait...</p>
              </div>
              <p
                v-else-if="gasClearingCompleted === 'error'"
                class="rejected-readings__error"
              >
                Failed to clear accounts
              </p>
            </SidebarCard>
            <SidebarCard color-select="blue">
              <template v-slot:header> Import rejected gas readings </template>
              <button
                class="button button--blue"
                :disabled="isGasBtnDisabled"
                @click="importGasAccounts()"
              >
                Import customers
              </button>

              <div
                v-if="gasImportPending === 'loading'"
                class="rejected-readings__loading"
              >
                <img src="@/assets/icons/loading-bar.gif" />
                <p>Coming right up...</p>
              </div>
              <p
                v-else-if="gasImportPending === 'error'"
                class="rejected-readings__error"
              >
                Failed to import accounts
              </p>
              <p
                v-else-if="gasImportPending === 'success'"
                class="rejected-readings__success"
              >
                Successfully imported accounts
              </p>
            </SidebarCard>
            <SidebarCard color-select="blue">
              <template v-slot:header>
                Investigate
                <span v-if="gasCustomers.length" class="reading-tasks__total">
                  {{ countGasInvestigate }} total
                </span>
              </template>
              <p>
                Sends an email to selected customers via Freshdesk and adds a
                note on Junifer account
              </p>
              <ReadingTasks
                :task-amount="gasAccountSelected.length"
                task-type="gas"
                :reading-task-api="readingTaskApiGas"
                @sendReadingTasks="sendReadingTasks"
              />
            </SidebarCard>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import dayjs from "dayjs"

// import DisputedReadings from "@/components/Operations/DisputedReadings"
import FilterButton from "@/components/smartMeter/FilterButton"
import GenericTable from "@/components/smartMeter/GenericTable"
import GenericTableRow from "@/components/smartMeter/GenericTableRow"
import RejectedReadsDropdown from "@/components/Operations/RejectedReadsDropdown"
import SidebarCard from "@/components/SidebarCard"
import ReadingTasks from "@/components/ReadingTasks"
import AccountSearch from "@/components/smartMeter/AccountSearch"
import OpeningReadings from "@/api/OpeningReadings"

export default {
  name: "RejectedReadings",
  components: {
    AccountSearch,
    // DisputedReadings,
    FilterButton,
    GenericTable,
    GenericTableRow,
    RejectedReadsDropdown,
    SidebarCard,
    ReadingTasks,
    AccountSearch,
  },
  filters: {
    formatDate(date) {
      return dayjs(date).format("D MMM YYYY")
    },
  },
  data() {
    return {
      activeFilter: "electricity-readings",
      electricHeader: ["", "Name", "Date", "MPAN", "", "Flags", ""],
      gasHeader: ["", "Name", "Date", "MPRN", "", "Flags", ""],
      gasAccountSelected: [],
      elecAccountSelected: [],
      readingTaskApiElec: "",
      readingTaskApiGas: "",
      searchError: "",
      searchMprnError: "",
      mpanPlaceholder: "Search MPAN",
      mprnPlaceholder: "Search MPRN",
      searchAccountsPending: false,
      searchMprnAccountsPending: false,
    }
  },
  computed: {
    ...mapGetters({
      elecCustomers: "openingRejectedReadings/electricityCustomers",
      gasCustomers: "openingRejectedReadings/gasCustomers",
      searchMpanResultAccountList:
        "openingRejectedReadings/searchMpanResultAccountList",
      searchMprnResultAccountList:
        "openingRejectedReadings/searchMprnResultAccountList",
      disputedSearchResult: "disputedReadings/disputedSearchResult",
    }),
    ...mapState({
      elecImportPending: (state) =>
        state.openingRejectedReadings.isImportingElecAccounts,
      gasImportPending: (state) =>
        state.openingRejectedReadings.isImportingGasAccounts,
      elecAwaitingPending: (state) =>
        state.openingRejectedReadings.isSendingElecEmails,
      gasAwaitingPending: (state) =>
        state.openingRejectedReadings.isSendingGasEmails,
      elecClearingCompleted: (state) =>
        state.openingRejectedReadings.isClearingElecEmails,
      gasClearingCompleted: (state) =>
        state.openingRejectedReadings.isClearingGasEmails,
      fetchingDisputedAccount: (state) =>
        state.disputedReadings.isFetchingDisputedAccount,
    }),
    countElecAwaiting() {
      this.elecCustomers.forEach((account) => {
        if (
          account.d86_count != 0 ||
          account.d300_count >= 1 ||
          account.d71_count >= 2 ||
          account.d10_count >= 2 ||
          account.note_count > 2 ||
          account.erroneous_transfer_ticket_open ||
          account.under_review ||
          account.error_reason != null
        ) {
          account.clicked = false
        } else {
          account.clicked = true
        }
      })

      let clickedReviews = this.elecCustomers.filter(
        (account) => account.clicked === true
      )

      return clickedReviews
    },
    countGasAwaiting() {
      this.gasCustomers.forEach((account) => {
        if (
          account.bill_disp_read_tickets_open > 2 ||
          account.sar_count >= 1 ||
          account.ret_count > 2 ||
          account.next_step === "Investigate" ||
          account.note_count > 2 ||
          account.erroneous_transfer_ticket_open ||
          account.under_review ||
          account.error_reason != null
        ) {
          account.clicked = false
        } else {
          account.clicked = true
        }
      })

      let clickedReviews = this.gasCustomers.filter(
        (account) => account.clicked === true && account.completed === false
      )

      return clickedReviews
    },
    countElecCompleted() {
      let completedElecAccounts = this.elecCustomers.filter(
        (account) => account.completed === true
      )
      let completedElecIds = completedElecAccounts.map((account) => account.id)

      return completedElecIds
    },
    countGasCompleted() {
      let completedGasAccounts = this.gasCustomers.filter(
        (account) => account.completed === true
      )
      let completedGasIds = completedGasAccounts.map((account) => account.id)

      return completedGasIds
    },
    countElecInvestigate() {
      let totalAwaiting = this.countElecAwaiting.length
      let totalElecAccounts = this.elecCustomers.length

      return totalElecAccounts - totalAwaiting
    },
    countGasInvestigate() {
      let totalAwaiting = this.countGasAwaiting.length
      let totalGasAccounts = this.gasCustomers.length

      return totalGasAccounts - totalAwaiting
    },
    isGasBtnDisabled() {
      return (
        this.gasAwaitingPending === "loading" ||
        this.gasClearingCompleted === "loading" ||
        this.gasImportPending === "loading"
      )
    },
    isElecBtnDisabled() {
      return (
        this.elecAwaitingPending === "loading" ||
        this.elecClearingCompleted === "loading" ||
        this.elecImportPending === "loading"
      )
    },
  },
  mounted() {
    this.getElecAccounts()
    this.getGasAccounts()
    this.getElecDelayedQueue()
    this.getGasDelayedQueue()
  },
  methods: {
    filterClick(name) {
      this.activeFilter = name
    },
    deleteCustomer(customerId) {
      if (this.activeFilter === "electricity-readings") {
        this.$store.dispatch(
          "openingRejectedReadings/deleteElectricCustomer",
          customerId
        )
      }
      if (this.activeFilter === "gas-readings") {
        this.$store.dispatch(
          "openingRejectedReadings/deleteGasCustomer",
          customerId
        )
      }
    },
    loadMoreTableRows(type) {
      // TODO: add load more functionality
      console.log("load more ", type)
    },
    sendReadingTasks(name) {
      let accounts =
        name === "gas" ? this.gasAccountSelected : this.elecAccountSelected

      if (name === "gas") {
        let accountObjects = accounts.map((account) => {
          return this.gasCustomers.find((item) => item.id === account)
        })

        this.readingTaskApiGas = "loading"
        OpeningReadings.sendAllGasReadEmails(accountObjects)
          .then((response) => {
            if (response.status === 200) {
              this.readingTaskApiGas = "success"
              this.gasAccountSelected = []
              this.$store.commit("openingRejectedReadings/ADD_GAS_ACCOUNTS", [])
              this.getGasAccounts()
            } else {
              this.readingTaskApiGas = "error"
            }
          })
          .catch((error) => {
            this.readingTaskApiGas = "error"
            console.log(error)
          })
      } else {
        let accountObjects = accounts.map((account) => {
          return this.elecCustomers.find((item) => item.id === account)
        })

        this.readingTaskApiElec = "loading"
        OpeningReadings.sendAllElecReadEmails(accountObjects)
          .then((response) => {
            if (response.status === 200) {
              this.readingTaskApiElec = "success"
              this.elecAccountSelected = []
              this.$store.commit(
                "openingRejectedReadings/ADD_ELEC_ACCOUNTS",
                []
              )
              this.getElecAccounts()
            } else {
              this.readingTaskApiElec = "error"
            }
          })
          .catch((error) => {
            this.readingTaskApiElec = "error"
            console.log(error)
          })
      }
    },
    getElecAccounts() {
      this.$store.dispatch("openingRejectedReadings/triggerElecReadingsImport")
    },
    getGasAccounts() {
      this.$store.dispatch("openingRejectedReadings/triggerGasReadingsImport")
    },
    getElecDelayedQueue() {
      this.$store.dispatch("openingRejectedReadings/triggerGetElecDelayedQueue")
    },
    getGasDelayedQueue() {
      this.$store.dispatch("openingRejectedReadings/triggerGetGasDelayedQueue")
    },
    importElecAccounts() {
      this.$store.dispatch("openingRejectedReadings/triggerElecImportButton")
    },
    importGasAccounts() {
      this.$store.dispatch("openingRejectedReadings/triggerGasImportButton")
    },
    sendAwaitingEmails(data) {
      if (this.activeFilter === "electricity-readings") {
        this.$store.dispatch(
          "openingRejectedReadings/sendAwaitingElecEmails",
          data
        )
      }
      if (this.activeFilter === "gas-readings") {
        this.$store.dispatch(
          "openingRejectedReadings/sendAwaitingGasEmails",
          data
        )
      }
    },
    clearReads(accountIds) {
      if (this.activeFilter === "electricity-readings") {
        this.$store.dispatch(
          "openingRejectedReadings/clearElecReads",
          accountIds
        )
      }
      if (this.activeFilter === "gas-readings") {
        this.$store.dispatch(
          "openingRejectedReadings/clearGasReads",
          accountIds
        )
      }
    },
    handleAccountSearch(mpxn) {
      this.clearAccountSearch()
      this.$store.dispatch("disputedReadings/disputedSearch", mpxn)
    },
    handleMpanAccountSearch(accountId) {
      this.clearAccountSearch()
      this.searchAccountsPending = true

      OpeningReadings.searchElecAccounts(accountId)
        .then((response) => {
          if (response.data.status === 404) {
            this.searchError = response.data.message
          } else {
            this.$store.dispatch(
              "openingRejectedReadings/addMpanSearchResults",
              [response.data]
            )
          }
        })
        .catch((error) => {
          this.searchError = "Account could not be found"
          console.log(error)
        })
        .finally(() => {
          this.searchAccountsPending = false
        })
    },
    handleMprnAccountSearch(accountId) {
      this.clearMprnAccountSearch()
      this.searchMprnAccountsPending = true

      OpeningReadings.searchGasAccounts(accountId)
        .then((response) => {
          if (response.data.status === 404) {
            this.searchMprnError = response.data.message
          } else {
            this.$store.dispatch(
              "openingRejectedReadings/addMprnSearchResults",
              [response.data]
            )
          }
        })
        .catch((error) => {
          console.log(error)
          this.searchError = "Account could not be found"
        })
        .finally(() => {
          this.searchMprnAccountsPending = false
        })
    },
    clearAccountSearch() {
      this.searchError = ""
      this.searchSuccess = false
      if (this.searchMpanResultAccountList.length) {
        this.$store.dispatch("openingRejectedReadings/clearMpanSearchResults")
      }
    },
    clearMprnAccountSearch() {
      this.searchMprnError = ""
      this.searchSuccess = false
      if (this.searchMprnResultAccountList.length) {
        this.$store.dispatch("openingRejectedReadings/clearMprnSearchResults")
      }
    },
    clearDisputedAccountSearch() {
      if (this.disputedSearchResult) {
        this.$store.dispatch("disputedReadings/clearDisputedSearchResults")
      }
    },
  },
}
</script>

<style lang="scss">
.rejected-readings {
  padding: 0 0 $spacing-8 0;
  min-height: 100vh;
  background-color: $day;

  &__loading,
  &__error,
  &__success {
    margin-top: $spacing-5;
    font-weight: $weight-bold;
  }

  &__loading p {
    margin-top: $spacing-4;
  }

  &__error {
    color: $fire;
  }

  &__success {
    color: $earth;
  }

  &__inner {
    min-width: 1024px;
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
  }

  &__header {
    text-align: left;
    padding: $spacing-8 0 $spacing-5;
    position: relative;

    h2 {
      font-size: $size-3;
      font-weight: $weight-bold;
      margin-bottom: $spacing-4;
    }

    p {
      font-size: $size-6;
      max-width: 900px;
    }

    .button {
      position: absolute;
      top: 75px;
      right: 0;
      font-weight: $weight-bold;

      .far {
        font-size: $size-7;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__import-heading {
    display: inline-block;
    padding: $spacing-3 $spacing-5;
    border-radius: 4px;
    margin-top: $spacing-5;
    font-weight: $weight-bold;
    font-size: $size-6;

    &--electric {
      background: $yellow-50;
      color: $yellow-500;
    }
    &--gas {
      background: $blue-100;
      color: $water;
    }
  }

  &__import-heading-icon {
    margin-left: $spacing-4;
  }

  &__flex-box {
    display: flex;
  }

  &__left-column {
    flex: 0 0 75%;
  }

  &__right-column {
    flex: 0 0 25%;
    padding: 0 0 0 $spacing-6;
  }

  &__filters {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding: 0 0 3rem;
    max-width: 800px;
  }
}

.generic-table__flags {
  span {
    display: inline-flex;
    text-align: center;
    margin: 0 $spacing-1;
    padding: $spacing-1;
    background: $yellow-50;
    border-radius: 4px;
    line-height: 1.2;
    height: 44px;
    font-size: 14px;
  }

  &--gas {
    span {
      background: $blue-100;
    }
  }
}

.disputed-reads {
  text-align: left;
  margin-bottom: 3rem;
  margin-left: $spacing-2;

  h3 {
    font-weight: $weight-medium;
  }

  .account-search {
    max-width: 400px;
  }

  &__error-text {
    color: $fire;
    font-weight: $weight-bold;
  }
}

.generic-table__energy-number {
  letter-spacing: 0.05em;
}

.generic-table__checkbox {
  margin-left: $spacing-5;

  input {
    width: 19px;
    height: 19px;
    vertical-align: text-top;
    cursor: pointer;
  }
}

.reading-tasks__total {
  font-size: $size-7;
  margin-left: $spacing-2;
  color: $night-light;
}
</style>
