<template>
  <b-modal
    ref="my-modal"
    size="xl"
    hide-footer
    hide-header
    @hide="onModelClose"
  >
    <b-row>
      <b-col>
        <PaymentReviewTemplate :payment-review="review" />
      </b-col>
      <b-col>
        <div class="border">
          <h4 class="p-2">{{ review.emailSubject }}</h4>
          <p style="text-align: left" v-html="review.templateContent" />
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import PaymentReviewTemplate from "@/components/paymentAdequacy/paymentReviewTemplate"

export default {
  components: {
    PaymentReviewTemplate,
  },
  props: {
    review: {
      type: Object,
      default: () => {},
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.openModel()
      }
    },
  },
  methods: {
    onModelClose() {
      this.$emit("close")
    },
    openModel() {
      this.$refs["my-modal"].show()
    },
  },
}
</script>
