import email from "@/constants/email"

export function billingTemplates(e) {
  try {
    //Set template
    var fuel
    var footer = email.footer
    var bodyTextStyle = email.textStyle

    e.templateContent = null

    if (e.template === "readLarge") {
      e.templateContent = `<html>
                                    <body>
                                    <div style='${bodyTextStyle}'>
                                        Hi ${e.customerName},
                                        <br>
                                        <br>Account Number: ${e.accountNumber}
                                        <br>
                                        Thank you for submitting your recent ${fuel} meter read of ${x.lastActualRead}${x.unit}.
                                        <br>
                                        <br>However, the reading provided is slightly lower than we expected it to be. Just so we can confirm the reading is correct, we kindly request a photograph of your ${fuel} meter. You can reply to this email with any photographs attached..
                                        <br>
                                        <br>Please ensure your photographs clearly show both the current meter reading and the meter’s serial number (a series of letters and/or numbers printed on the meter).
                                        <br>
                                        <br>If you're ever having trouble reading your meter, try our guides - <a href="https://www.so.energy/so/help-and-advice/faq/your-meter/how-do-i-read-my-smart-meter" target="_blank">How to read your smart meter</a>, and <a href="https://www.so.energy/so/help-and-advice/how-to-read-your-meter" target="_blank">How to read your meter</a>.
                                        <br>
                                        <br>If you have any questions about this, please feel free to call us on 0330 111 5050. Our lines are open Monday to Friday, 9am to 5pm. 
                                      </div>
                                      ${footer}
                                    </body>
                                  </html>`
    } else if (e.template === "readLower") {
      e.templateContent = `<html>
                                  <body>
                                    <div style='${bodyTextStyle}'>
                                      Hi ${e.customerName},
                                      <br>
                                      <br>Account Number: ${e.accountNumber}
                                      <br>
                                      Thank you for submitting your recent ${fuel} meter read of ${x.lastActualRead}${x.unit}.
                                      <br>
                                      <br>However, the reading provided is slightly larger than we expected it to be. Just so we can confirm the reading is correct, we kindly request a photograph of your ${fuel} meter. You can reply to this email with any photographs attached..
                                      <br>
                                      <br>Please ensure your photographs clearly show both the current meter reading and the meter’s serial number (a series of letters and/or numbers printed on the meter).
                                      <br>
                                      <br>If you're ever having trouble reading your meter, try our guides - <a href="https://www.so.energy/so/help-and-advice/faq/your-meter/how-do-i-read-my-smart-meter" target="_blank">How to read your smart meter</a>, and <a href="https://www.so.energy/so/help-and-advice/how-to-read-your-meter" target="_blank">How to read your meter</a>.
                                      <br>
                                      <br>If you have any questions about this, please feel free to call us on 0330 111 5050. Our lines are open Monday to Friday, 9am to 5pm. 
                                    </div>
                                    ${footer}
                                  </body>
                                </html>`
    } else if (e.template === "readFirstRead") {
      e.meter_registers.forEach(function (x) {
        if (x.billedRead < x.firstBilledRead) {
          if (x.mpxn.length === 13) {
            fuel = "electricity"
          } else {
            fuel = "gas"
          }

          e.templateContent = `<html>
                                  <body>
                                    <div style='${bodyTextStyle}'>
                                      Hi ${e.customerName},
                                      <br>
                                      <br>Account Number: ${e.accountNumber}
                                      <br>
                                      Thank you for submitting your recent ${fuel} meter read of ${x.lastActualRead}${x.unit}.
                                      <br>
                                      <br>Unfortunately, the reading provided does not align with your opening read of ${x.firstBilledRead}${x.unit}.
                                      <br>
                                      <br>This indicates that we may need to raise a dispute with your previous supplier in order to amend your opening read.  So we can investigate this issue further, we kindly request a photograph of your ${fuel} meter. You can reply to this email with any photographs attached.
                                      <br>
                                      <br>Please ensure your photographs clearly show both the current meter reading and the meter’s serial number (a series of letters and/or numbers printed on the meter).
                                      <br>
                                      <br>If you're ever having trouble reading your meter, try our guides - <a href="https://www.so.energy/so/help-and-advice/faq/your-meter/how-do-i-read-my-smart-meter" target="_blank">How to read your smart meter</a>, and <a href="https://www.so.energy/so/help-and-advice/how-to-read-your-meter" target="_blank">How to read your meter</a>.
                                      <br>
                                      <br>If you have any questions about this, please feel free to call us on 0330 111 5050. Our lines are open Monday to Friday, 9am to 5pm. 
                                    </div>
                                    ${footer}
                                  </body>
                                </html>`
        }
      })
    }
  } catch (error) {
    console.log(error)
  }
}
