export function gasDisputeTolChecks(e) {
  try {
    var diff = Math.abs(Number(e.mbr_read) - Number(e.urs_read))
    if (e.units === "M") {
      e.unit = "(m<sup>3</sup>)"
      if (diff >= 125) {
        e.template = "OutofTolerance"
      } else {
        e.template = "WithinTolerance"
      }
    } else {
      e.unit = "(100'sft<sup>3</sup>)"
      if (diff >= 39) {
        e.template = "OutofTolerance"
      } else {
        e.template = "WithinTolerance"
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
