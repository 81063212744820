export function reviewFormatter(e) {
  try {
    var d = e
    d.process_disabled = false
    d.disabled = false
    d.edited = false
    var keys = ["seven_day_follow_up_date"]
    keys.forEach(function (key) {
      delete d[key]
    })
    return d
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
