import { checks } from "./checks"
import { unbilledChecks } from "./unbilledCheck"
import { fetchTemplate } from "./initialTemplates"

export function initialReview(e) {
  try {
    e.clicked = false
    e.edited = false
    e.disabled = false
    e.billedMeterReading = false
    e.unbilled = false
    unbilledChecks(e)
    checks(e)
    fetchTemplate(e)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
