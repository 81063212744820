export function listArray(array) {
  var reading = array.join(", ").replace(/,(?!.*,)/gim, " and")
  return reading
}

export function listOrArray(array) {
  if (array === undefined) {
    return false
  }
  var reading = array.join(", ").replace(/,(?!.*,)/gim, " or")
  return reading
}
