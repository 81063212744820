import axios from "axios"

const appURL = process.env.VUE_APP_SO_MESSAGES_API

const apiSoMessages = axios.create({
  baseURL: appURL,
  timeout: 1000000,
  withCredentials: false,
})

export default apiSoMessages
