<template>
  <div class="manage-applications">
    <LoginScreen
      v-show="needsAuthentication && !localHost"
      @authenticated="authenticatedUser"
    />
    <div
      v-show="!needsAuthentication || localHost"
      class="manage-applications__inner"
    >
      <section class="manage-applications__header">
        <h2>Warm Home Discount</h2>
        <p>
          Customers can apply for Warm Home Discount (WHD) as part of our
          Broader Group through their online account. All completed applications
          will show below. For more information about WHD including Core Group
          and Broader Group criteria, visit
          <a
            href="https://www.ofgem.gov.uk/environmental-programmes/warm-home-discount-whd-scheme"
            target="_blank"
          >
            Ofgem
          </a>
        </p>
        <!-- <a href="/admin/user_guides" class="button button--blue-outlined">
          <i class="far fa-question-circle" />
          User Guide
        </a> -->
      </section>

      <UploadCoreCustomers
        @handle-core-accounts-upload="handleCoreAccountsUpload"
        @handle-core-accounts-upload-error="handleCoreAccountsUploadError"
      />

      <section class="manage-applications__body">
        <div
          class="
            manage-applications__filter-container manage-applications__flex-box
          "
        >
          <div class="manage-applications__left-column">
            <div class="manage-applications__filters">
              <FilterButton
                :title="
                  'Application received (' + arkTotal.applicationReceived + ')'
                "
                name="application-received"
                :active="activeFilter === 'application-received'"
                @filterClick="filterClick"
              />
              <FilterButton
                :title="'Audit candidates (' + arkTotal.auditCandidates + ')'"
                name="audit-candidates"
                :active="activeFilter === 'audit-candidates'"
                @filterClick="filterClick"
              />
              <FilterButton
                :title="
                  'Closed applications (' + arkTotal.closedApplcations + ')'
                "
                name="closed-applications"
                :active="activeFilter === 'closed-applications'"
                @filterClick="filterClick"
              />
              <FilterButton
                :title="'Successful applicants (' + arkTotal.totalSuccess + ')'"
                name="successful-applicants"
                :active="activeFilter === 'successful-applicants'"
                @filterClick="filterClick"
              />
            </div>
          </div>

          <div
            class="
              manage-applications__right-column
              manage-applications__search-container
            "
          >
            <div class="manage-applications__export-wrapper">
              <p class="manage-applications__export-label">Download report:</p>
              <Select
                v-model="selectedExportYear"
                input-id="exportReports"
                :hidden-label="true"
                :options="exportOptions"
                @change="exportYearReportFile(selectedExportYear)"
              />
            </div>

            <div class="manage-applications__search-account">
              <div class="manage-applications__left-column">
                <AccountSearch
                  :search-error="searchError"
                  @accountSearch="handleAccountSearch"
                  @clearAccountSearch="clearAccountSearch"
                ></AccountSearch>
              </div>
            </div>
          </div>
        </div>

        <ApplicationReceived
          v-show="activeFilter === 'application-received'"
          :account-list="appReceived"
          :dropdown-options="dropdownOptions"
          :failed-processing="processReceivedAccounts.failed"
          :pending-processing="processReceivedAccounts.pending"
          :successfully-processed="processReceivedAccounts.successful"
          :cancel-failed-processing="cancelApplicationReceived.failed"
          :cancel-pending-processing="cancelApplicationReceived.pending"
          :cancel-successfully-processed="cancelApplicationReceived.successful"
          :load-more-status="loadMoreStatus"
          :application-received-total="arkTotal.applicationReceived > 0"
          @process-applications="handleProcessApplications"
          @load-more="loadMoreTableRows($event)"
          @update-tables="getAccounts()"
          @get-uploaded-images="getUploadedImages($event)"
        />
        <ProcessWarningModal
          :show="showProcessWarningModal"
          @close="showProcessWarningModal = false"
        >
          <button class="button button--blue" @click="processSelectedAccounts">
            Process accounts
          </button>
        </ProcessWarningModal>

        <AuditCandidates
          v-show="activeFilter === 'audit-candidates'"
          :account-list="auditApp"
          :dropdown-options="dropdownOptions"
          :audit-accounts="auditCandidates"
          :successful-audits="allSuccessfullyAuditedAccounts"
          :load-more-status="loadMoreStatus"
          :pass-audit-success="passAuditSuccess"
          :failed-processing="processAuditAccounts.failed"
          :pending-processing="processAuditAccounts.pending"
          :successfully-processed="processAuditAccounts.successful"
          @process-applications="handleProcessApplications"
          @handle-accounts-upload-error="handleAccountsUploadError"
          @handle-accounts-upload="handleAccountsUpload"
          @pass-audit="passAudit"
          @load-more="loadMoreTableRows($event)"
          @update-tables="getAccounts()"
          @get-uploaded-images="getUploadedImages($event)"
        />
        <AccountsErrorUploadModal
          :show="showUpdateModal"
          :variation="errorModal.variation"
          :error-modal="errorModal"
          :successful-total="errorModal.successfulTotal"
          @close="closeShowUpdateModal"
        />

        <ClosedApplications
          v-show="activeFilter === 'closed-applications'"
          :account-list="closedApp"
          :dropdown-options="dropdownOptions"
          :load-more-status="loadMoreStatus"
          :failed-processing="processClosedAccounts.failed"
          :pending-processing="processClosedAccounts.pending"
          :successfully-processed="processClosedAccounts.successful"
          @process-applications="handleProcessApplications"
          @load-more="loadMoreTableRows($event)"
          @update-tables="getAccounts()"
          @get-uploaded-images="getUploadedImages($event)"
        />

        <SuccessfulApplicants
          v-show="activeFilter === 'successful-applicants'"
          :successful-accounts="successfulApp"
          :credited-accounts="creditedApp"
          :dropdown-options="dropdownOptions"
          :load-more-status="loadMoreStatus"
          :apply-credit-success="applyCreditSuccess"
          :remove-credit-success="removeCreditSuccess"
          :succesful-applicants-total="arkTotal.totalSuccess > 0"
          :failed-processing="processSuccessfulAccounts.failed"
          :pending-processing="processSuccessfulAccounts.pending"
          :successfully-processed="processSuccessfulAccounts.successful"
          :reset-form="resetSuccessPageForm"
          @process-applications="handleProcessApplications"
          @open="openApplyCreditWarningModal($event)"
          @openCancelCreditModal="openCancelCreditModal($event)"
          @load-more="loadMoreTableRows($event)"
          @update-tables="getAccounts()"
          @get-uploaded-images="getUploadedImages($event)"
        />

        <ProcessWarningModal
          :show="showCreditWarningModal"
          @close="showCreditWarningModal = false"
        >
          <button class="button button--blue" @click="processApplyCredit">
            Apply credit
          </button>
        </ProcessWarningModal>

        <ProcessWarningModal
          :show="showCancelCreditWarningModal"
          :variation="warningModal.variation"
          @close="showCancelCreditWarningModal = false"
        >
          <button class="button button--blue" @click="processCancelCredit">
            Remove credit
          </button>
        </ProcessWarningModal>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import dayjs from "dayjs"
import WhdApi from "@/api/WarmHomeDiscount.js"
import Accounts from "@/api/JuniferAccounts.js"
import ApplicationReceived from "@/components/warmHomeDiscount/ApplicationReceived"
import AuditCandidates from "@/components/warmHomeDiscount/AuditCandidates"
import ClosedApplications from "@/components/warmHomeDiscount/ClosedApplications"
import SuccessfulApplicants from "@/components/warmHomeDiscount/SuccessfulApplicants"
import LoginScreen from "@/components/warmHomeDiscount/LoginScreen"
import FilterButton from "@/components/smartMeter/FilterButton"
import ProcessWarningModal from "@/components/warmHomeDiscount/ProcessWarningModal"
import AccountsErrorUploadModal from "@/components/AccountErrorModal"
import UploadCoreCustomers from "@/components/warmHomeDiscount/UploadCoreCustomers"
import AccountSearch from "@/components/smartMeter/AccountSearch"
import Select from "@soenergy/frontend-library/src/components/Select"

export default {
  name: "ManageApplications",
  components: {
    ApplicationReceived,
    AuditCandidates,
    ClosedApplications,
    SuccessfulApplicants,
    LoginScreen,
    FilterButton,
    ProcessWarningModal,
    AccountsErrorUploadModal,
    UploadCoreCustomers,
    AccountSearch,
    Select,
  },
  data() {
    return {
      activeFilter: "application-received",
      needsAuthentication: true,
      warmHomePassword: "warmhom3",
      localHost: location.hostname === "localhost",
      showProcessWarningModal: false,
      processingToSuccessfulData: {},
      warningModal: {
        variation: "cancelCredit",
      },
      showCreditWarningModal: false,
      showCancelCreditWarningModal: false,
      cancelApplicationReceived: {
        pending: false,
        successful: false,
        failed: false,
      },
      processReceivedAccounts: {
        pending: false,
        successful: false,
        failed: false,
      },
      processAuditAccounts: {
        pending: false,
        successful: false,
        failed: false,
      },
      processClosedAccounts: {
        pending: false,
        successful: false,
        failed: false,
      },
      processSuccessfulAccounts: {
        pending: false,
        successful: false,
        failed: false,
      },
      uploadAccounts: {
        pending: false,
        successful: false,
        failed: false,
      },
      showUpdateModal: false,
      passAuditSuccess: false,
      applyCreditAccounts: [],
      removeCreditAccounts: [],
      applyCreditSuccess: false,
      removeCreditSuccess: false,
      errorModal: {
        variation: "updateAccountErrorWHD",
        orangeColumn: [],
        redColumn: [],
        blueColumn: [],
        greyColumn: [],
        yellowColumn: [],
        tealColumn: [],
        whiteColumn: [],
        successfulMessage: null,
        successfulTotal: [],
      },
      arkTotal: {
        applicationReceived: 0,
        auditCandidates: 0,
        closedApplcations: 0,
        totalSuccess: 0,
      },
      searchError: "",
      selectedExportYear: null,
      resetSuccessPageForm: false,
    }
  },
  computed: {
    ...mapGetters({
      accountListLength: "warmHomeDiscount/accountListLength",
      dropdownOptions: "warmHomeDiscount/dropdownOptions",
      existingAccountList: "warmHomeDiscount/existingAccountList",
      appReceived: "warmHomeDiscount/appReceived",
      successfulApp: "warmHomeDiscount/successfulApp",
      auditApp: "warmHomeDiscount/auditApp",
      closedApp: "warmHomeDiscount/closedApp",
      creditedApp: "warmHomeDiscount/creditedApp",
      searchResultAccountList: "warmHomeDiscount/searchResultAccountList",
    }),
    ...mapState({
      auditCandidates: (state) => state.warmHomeDiscount.auditCandidates,
      loadMoreStatus: (state) => state.warmHomeDiscount.loadMoreStatus,
    }),
    allSuccessfullyAuditedAccounts() {
      return this.auditCandidates.filter((account) => {
        return account.ark_status === "approved_at_audit"
      })
    },
    exportOptions() {
      let options = []
      for (let i = 2020; i <= dayjs().year(); i++) {
        options.push({
          label: i.toString() + "-" + (i + 1).toString(),
          value: i.toString(),
        })
      }
      return options
    },
  },
  mounted() {
    this.getAccounts()
    this.getCriteria()
  },
  methods: {
    getUploadedImages(data) {
      WhdApi.getUploadedImages(data.accountId)
        .then((response) => {
          if (response?.data?.getUploadedFiles) {
            this.$store.commit("warmHomeDiscount/MODIFY_ACCOUNTS", {
              tableName: data.tableName,
              accountId: data.accountId,
              newData: response.data.getUploadedFiles,
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getCriteria() {
      this.$store.dispatch("warmHomeDiscount/triggerCriteriaAPI")
    },
    exportYearReportFile(selectedExportYear) {
      WhdApi.downloadYearReport(selectedExportYear).then((response) => {
        if (response && response.data) {
          let link = document.createElement("a")
          link.href = response.data.exportData.signedUrl
          const fileName = `whd-applications-${selectedExportYear}.csv`
          link.download = fileName
          link.click()
          link.remove()
        } else {
          throw Error
        }
      })
    },
    getAccounts() {
      this.arkStatusTotal()
      // clear any previous
      this.$store.dispatch("warmHomeDiscount/clearAccounts")
      // get data
      this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
        filter: "application_received",
        downloadedAccounts: [],
        queryAmount: 100,
      })
      this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
        filter: "audit_candidate",
        downloadedAccounts: [],
        queryAmount: 25,
      })
      this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
        filter: "sent_for_audit",
        downloadedAccounts: [],
        queryAmount: 25,
      })
      this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
        filter: "approved_at_audit",
        downloadedAccounts: [],
        queryAmount: 25,
      })
      this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
        filter: "document_verification",
        downloadedAccounts: [],
        queryAmount: 25,
      })
      this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
        filter: "core_group",
        downloadedAccounts: [],
        queryAmount: 20,
      })
      this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
        filter: "closed_account",
        downloadedAccounts: [],
        queryAmount: 20,
      })
      this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
        filter: "no_evidence_provided",
        downloadedAccounts: [],
        queryAmount: 20,
      })
      this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
        filter: "rejected_approved",
        downloadedAccounts: [],
        queryAmount: 20,
      })
      this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
        filter: "rejected_credited",
        downloadedAccounts: [],
        queryAmount: 20,
      })
      this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
        filter: "successful",
        downloadedAccounts: [],
        queryAmount: 100,
      })
      this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
        filter: "paid",
        downloadedAccounts: [],
        queryAmount: 100,
      })
    },
    loadMoreTableRows(type) {
      this.$store.commit("warmHomeDiscount/LOAD_MORE_STATUS", {
        name: "loading",
        type,
      })

      if (type === "application received") {
        this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
          filter: "application_received",
          downloadedAccounts: this.existingAccountList,
          loadMoreUpdate: true,
          queryAmount: 100,
        })
      } else if (type === "review in progress") {
        this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
          filter: "audit_candidate",
          downloadedAccounts: this.existingAccountList,
          loadMoreUpdate: true,
          queryAmount: 25,
        })
        this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
          filter: "sent_for_audit",
          downloadedAccounts: this.existingAccountList,
          loadMoreUpdate: true,
          queryAmount: 25,
        })
        this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
          filter: "approved_at_audit",
          downloadedAccounts: this.existingAccountList,
          loadMoreUpdate: true,
          queryAmount: 25,
        })
        this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
          filter: "document_verification",
          downloadedAccounts: this.existingAccountList,
          loadMoreUpdate: true,
          queryAmount: 25,
        })
      } else if (type === "rejected") {
        this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
          filter: "core_group",
          downloadedAccounts: this.existingAccountList,
          loadMoreUpdate: true,
          queryAmount: 20,
        })
        this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
          filter: "closed_account",
          downloadedAccounts: this.existingAccountList,
          loadMoreUpdate: true,
          queryAmount: 20,
        })
        this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
          filter: "no_evidence_provided",
          downloadedAccounts: this.existingAccountList,
          loadMoreUpdate: true,
          queryAmount: 20,
        })
        this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
          filter: "rejected_approved",
          downloadedAccounts: this.existingAccountList,
          loadMoreUpdate: true,
          queryAmount: 20,
        })
        this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
          filter: "rejected_credited",
          downloadedAccounts: this.existingAccountList,
          loadMoreUpdate: true,
          queryAmount: 20,
        })
      } else if (type === "approved") {
        this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
          filter: "successful",
          downloadedAccounts: this.existingAccountList,
          loadMoreUpdate: true,
          queryAmount: 100,
        })
      } else if (type === "paid") {
        this.$store.dispatch("warmHomeDiscount/triggerAccountAPI", {
          filter: "paid",
          downloadedAccounts: this.existingAccountList,
          loadMoreUpdate: true,
          queryAmount: 100,
        })
      }
    },
    filterClick(name) {
      this.activeFilter = name
    },
    authenticatedUser() {
      this.needsAuthentication = false
    },
    closeShowUpdateModal() {
      this.showUpdateModal = false
      this.errorModal.redColumn = []
      this.errorModal.orangeColumn = []
      this.errorModal.blueColumn = []
      this.errorModal.greyColumn = []
      this.errorModal.yellowColumn = []
      this.errorModal.tealColumn = []
      this.errorModal.whiteColumn = []
      this.errorModal.successfulMessage = null
      this.errorModal.successfulTotal = []
    },
    //TODO: SO-7996 - this needs refactoring, ideally move error handling to AuditCandidatesAuditReturnCsv.
    //      ... Was originally written here: https://github.com/soenergy/ark-frontend-new/commit/98ddca8
    handleAccountsUpload(response) {
      const res = response.data
      if (res && res.errors && res.errors.length) {
        this.showUpdateModal = true
        this.errorModal.variation = "updateApplicationErrorWHD"

        for (let i = 0; i < res.errors.length; i++) {
          let error = res.errors[i]
          if (error.name === "core_group") {
            this.errorModal.orangeColumn = error.account_numbers
          }
          if (error.name === "missing_applications") {
            this.errorModal.redColumn = error.account_numbers
          }
          if (error.name === "closed_accounts") {
            this.errorModal.blueColumn = error.account_numbers
          }
          if (error.name === "invalid_account_numbers") {
            this.errorModal.greyColumn = error.account_numbers
          }
        }
        if (res.successful_account_numbers.length) {
          this.errorModal.successfulTotal = res.successful_account_numbers
        }
      }
    },
    handleAccountsUploadError() {
      this.showUpdateModal = true
      this.errorModal.variation = "updateApplicationErrorWHD"
    },
    processSelectedAccounts() {
      this.showProcessWarningModal = false
      this.sendProcessApplications(this.processingToSuccessfulData, "updateManyApplications")
    },
    handleProcessApplications(submissionData) {
      if (
        submissionData.data.warm_home_discounts_interface.new_attributes
          .ark_status === "successful"
      ) {
        this.showProcessWarningModal = true
        this.processingToSuccessfulData = submissionData
      } else {
        this.sendProcessApplications(submissionData, "updateManyApplications")
      }
    },
    sendProcessApplications(submissionData, endpoint) {
      let data = submissionData.data
      if (submissionData.processingTab === "applicationReceived") {
        this.processReceivedAccounts.pending = true
        this.processReceivedAccounts.successful = false
        this.processReceivedAccounts.failed = false
      } else if (submissionData.processingTab === "cancelApplicationReceived") {
        this.cancelApplicationReceived.pending = true
        this.cancelApplicationReceived.successful = false
        this.cancelApplicationReceived.failed = false
      } else if (submissionData.processingTab === "auditCandidate") {
        this.processAuditAccounts.pending = true
        this.processAuditAccounts.successful = false
        this.processAuditAccounts.failed = false
      } else if (submissionData.processingTab === "closedApplication") {
        this.processClosedAccounts.pending = true
        this.processClosedAccounts.successful = false
        this.processClosedAccounts.failed = false
      } else if (submissionData.processingTab === "successfulApplication") {
        this.processSuccessfulAccounts.pending = true
        this.processSuccessfulAccounts.successful = false
        this.processSuccessfulAccounts.failed = false
      }
      this.showUpdateModal = false
      WhdApi[endpoint](data)
        .then((response) => {
          const responseData = response?.data?.updateManyApplications
          if (responseData && !responseData?.errors.length) {
            this.processReceivedAccounts.successful =
              submissionData.processingTab === "applicationReceived"
            this.cancelApplicationReceived.successful =
              submissionData.processingTab === "cancelApplicationReceived"
            this.processAuditAccounts.successful =
              submissionData.processingTab === "auditCandidate"
            this.processClosedAccounts.successful =
              submissionData.processingTab === "closedApplication"
            this.processSuccessfulAccounts.successful =
              submissionData.processingTab === "successfulApplication"
          } else if (response.data.errors) {
            this.showUpdateModal = true
            this.errorModal.variation = "updateAccountErrorWHD"

            for (let i = 0; i < responseData?.errors.length; i++) {
              this.errorModal.redColumn = this.errorModal.redColumn.concat(
                response.data.updateManyApplications.errors[i].account_numbers
              )
            }
          } else {
            this.processReceivedAccounts.failed =
              submissionData.processingTab === "applicationReceived"
            this.cancelApplicationReceived.failed =
              submissionData.processingTab === "cancelApplicationReceived"
            this.processAuditAccounts.failed =
              submissionData.processingTab === "auditCandidate"
            this.processClosedAccounts.failed =
              submissionData.processingTab === "closedApplication"
            this.processSuccessfulAccounts.failed =
              submissionData.processingTab === "successfulApplication"
          }
        })
        .catch((error) => {
          this.processReceivedAccounts.failed =
            submissionData.processingTab === "applicationReceived"
          this.cancelApplicationReceived.failed =
            submissionData.processingTab === "cancelApplicationReceived"
          this.processAuditAccounts.failed =
            submissionData.processingTab === "auditCandidate"
          this.processClosedAccounts.failed =
            submissionData.processingTab === "closedApplication"
          this.processSuccessfulAccounts.failed =
            submissionData.processingTab === "successfulApplication"
        })
        .finally((response) => {
          this.processReceivedAccounts.pending =
            !submissionData.processingTab === "applicationReceived"
          this.cancelApplicationReceived.pending =
            !submissionData.processingTab === "cancelApplicationReceived"
          this.processAuditAccounts.pending =
            !submissionData.processingTab === "auditCandidate"
          this.processClosedAccounts.pending =
            !submissionData.processingTab === "closedApplication"
          this.processSuccessfulAccounts.pending =
            !submissionData.processingTab === "successfulApplication"

          // update table
          this.getAccounts()
        })
    },
    //TODO: SO-7996 - this needs refactoring, ideally move error handling to UploadCoreCustomersCSV.
    //      ... Was originally written here: https://github.com/soenergy/ark-frontend-new/commit/9bc0b6a
    handleCoreAccountsUpload(response) {
      const res = response.data

      if (res && res.errors && res.errors.length) {
        this.showUpdateModal = true
        this.errorModal.variation = "coreAccountErrorWHD"

        for (let i = 0; i < res.errors.length; i++) {
          let error = res.errors[i]
          if (error.name === "invalid_account_numbers") {
            this.errorModal.redColumn = error.account_numbers
          }
          if (error.name === "no_junifer_account_numbers") {
            this.errorModal.orangeColumn = error.account_numbers
          }
          if (error.name === "already_core_group_account_numbers") {
            this.errorModal.blueColumn = error.account_numbers
          }
        }
      }
    },
    handleCoreAccountsUploadError() {
      this.showUpdateModal = true
      this.errorModal.variation = "coreAccountErrorWHD"
    },
    passAudit(accounts) {
      this.passAuditSuccess = false
      this.showUpdateModal = false

      let accountNumbers = accounts.map(
        (account) => account.junifer_account_number
      )
      let data = {
        account_numbers: accountNumbers,
      }
      WhdApi.passAudit(data)
        .then((response) => {
          if (response?.data?.accountsForAudit && !response?.data?.accountsForAudit?.errors.length) {
            this.passAuditSuccess = true
          } else if (response?.data?.accountsForAudit?.errors) {
            this.showUpdateModal = true
            this.errorModal.variation = "updateAccountErrorWHD"

            for (let i = 0; i < response.data.accountsForAudit.errors.length; i++) {
              this.errorModal.redColumn = this.errorModal.redColumn.concat(
                response.data.accountsForAudit.errors[i].account_numbers
              )
            }
          }
          // update table
          this.getAccounts()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    openApplyCreditWarningModal(data) {
      this.applyCreditAccounts = data
      this.showCreditWarningModal = true
    },
    openCancelCreditModal(data) {
      this.removeCreditAccounts = data
      this.showCancelCreditWarningModal = true
    },
    processApplyCredit() {
      this.showCreditWarningModal = false
      this.showUpdateModal = false
      this.applyCreditSuccess = false
      this.resetSuccessPageForm = false

      let data = {
        warm_home_discounts_interface: {
          account_numbers: this.applyCreditAccounts,
        },
      }

      WhdApi.applyCredit(data)
        .then((response) => {
          if (response?.data?.applyCredit && !response?.data?.applyCredit?.errors?.length) {
            this.applyCreditSuccess = true
          } else if (response?.data?.applyCredit?.errors) {
            this.showUpdateModal = true
            this.errorModal.variation = "updateAccountErrorWHD"
            this.resetSuccessPageForm = true

            for (let i = 0; i < response.data.applyCredit.errors.length; i++) {
              this.errorModal.redColumn = this.errorModal.redColumn.concat(
                response.data.applyCredit.errors[i].account_numbers
              )
            }
          }
          // update table
          this.getAccounts()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    processCancelCredit() {
      this.showCancelCreditWarningModal = false
      this.showUpdateModal = false
      this.removeCreditSuccess = false
      this.resetSuccessPageForm = false

      let data = {
        warm_home_discounts_interface: {
          account_numbers: this.removeCreditAccounts,
        },
      }

      WhdApi.cancelCredit(data)
        .then((response) => {
          let res = response?.data?.rejectApprovedApplications
          if (res && !res.errors?.length) {
            this.removeCreditSuccess = true
          } else if (res.errors) {
            this.showUpdateModal = true
            this.errorModal.variation = "cancelCreditWHD"
            this.resetSuccessPageForm = true

            for (let i = 0; i < res.errors.length; i++) {
              if (res.errors[i].name === "no_credit") {
                this.errorModal.redColumn = this.errorModal.redColumn.concat(
                  res.errors[i].account_numbers
                )
              } else if (res.errors[i].name === "no_application") {
                this.errorModal.orangeColumn =
                  this.errorModal.orangeColumn.concat(
                    res.errors[i].account_numbers
                  )
              } else if (res.errors[i].name === "junifer_error") {
                this.errorModal.blueColumn = this.errorModal.blueColumn.concat(
                  res.errors[i].account_numbers
                )
              } else if (res.errors[i].name === "could_not_cancel_credit") {
                this.errorModal.greyColumn = this.errorModal.greyColumn.concat(
                  res.errors[i].account_numbers
                )
              } else if (res.errors[i].name === "no_application") {
                this.errorModal.yellowColumn =
                  this.errorModal.yellowColumn.concat(
                    res.errors[i].account_numbers
                  )
              } else if (
                res.errors[i].name === "application_not_approved_or_paid"
              ) {
                this.errorModal.tealColumn = this.errorModal.tealColumn.concat(
                  res.errors[i].account_numbers
                )
              } else if (res.errors[i].name === "unknown_error") {
                this.errorModal.whiteColumn =
                  this.errorModal.whiteColumn.concat(
                    res.errors[i].account_numbers
                  )
              }
            }

            if (res.success) {
              this.errorModal.successfulTotal = res.success
            }
          }

          this.getAccounts()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    arkStatusTotal() {
      WhdApi.getArkStatusTotals()
        .then((response) => {
          if (response && response.data && response.status === 200) {
            let arkStatus = response.data

            this.arkTotal.applicationReceived =
              arkStatus.application_received.aggregate.count

            this.arkTotal.auditCandidates =
              arkStatus.audit_candidate.aggregate.count +
              arkStatus.sent_for_audit.aggregate.count +
              arkStatus.document_verification.aggregate.count +
              arkStatus.approved_at_audit.aggregate.count

            this.arkTotal.closedApplcations =
              arkStatus.closed_account.aggregate.count +
              arkStatus.core_group.aggregate.count +
              arkStatus.no_evidence_provided.aggregate.count +
              arkStatus.rejected_approved.aggregate.count +
              arkStatus.rejected_credited.aggregate.count

            this.arkTotal.totalSuccess =
              arkStatus.paid.aggregate.count +
              arkStatus.successful.aggregate.count
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleAccountSearch(accountId) {
      this.clearAccountSearch()

      WhdApi.searchAccount(accountId)
        .then((response) => {
          this.$store.dispatch("warmHomeDiscount/addSearchResults", {
            results: response.data.getApplications,
          })
        })
        .catch((error) => {
          error.message === "Request failed with status code 400"
            ? (this.searchError = "Invalid account number")
            : (this.searchError = error.message)
        })
    },
    clearAccountSearch() {
      if (this.searchResultAccountList.length) {
        this.$store.dispatch("warmHomeDiscount/clearSearchResults")
      }
    },
  },
}
</script>

<style lang="scss">
.manage-applications {
  min-height: 100vh;

  &__sidebar {
    padding-left: $spacing-6;
  }

  &__inner {
    min-width: 1024px;
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 0 $spacing-8 0;
  }

  &__header {
    text-align: left;
    padding: 4.5rem 0 $spacing-6;
    position: relative;

    h2 {
      font-size: $size-2;
      font-weight: $weight-bold;
      margin-bottom: $spacing-4;
    }

    p {
      font-size: $size-6;
      max-width: 900px;

      a {
        color: $water;
        font-weight: $weight-bold;
      }
    }

    .button {
      position: absolute;
      top: 75px;
      right: 0;
      font-weight: $weight-bold;

      .far {
        font-size: $size-7;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__search-container {
    position: relative;
  }

  &__search-account {
    position: absolute;
    top: 90px;
    width: 100%;
    padding-left: $spacing-6;

    .account-search {
      width: 100%;

      .form-group__input {
        border: 1px solid $night-light-2;
        padding-top: 0;
        line-height: 40px;
        height: 40px;
      }
      .account-search__icon {
        line-height: 40px;
      }

      .form-group__invalid-feedback {
        font-weight: $weight-bold;
        margin: -$space-2 0 0 0;
        text-align: left;
        position: absolute;
        top: -20px;
      }
    }
  }

  &__flex-box {
    display: flex;
  }

  &__left-column {
    flex: 0 0 75%;
  }

  &__right-column {
    flex: 0 0 25%;
  }

  &__filter-container {
    padding-bottom: 3rem;
  }

  &__filters {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  &__export-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: -6px;
  }

  &__export-label {
    font-size: $font-size-2;
    margin: 0 $space-3 0 $space-8;
    line-height: 20px;
    padding: $space-4 0;
    font-weight: $weight-bold;
  }

  &__sidebar {
    margin-top: $spacing-8;
  }

  &__reset-button {
    margin-right: $spacing-5;
  }

  &__info-icon {
    margin-right: $spacing-1;
    color: $water;
  }

  &__selected {
    margin-bottom: $spacing-5;
  }

  &__selected-total {
    float: right;
  }

  &__filter-text {
    margin: 0;
    line-height: 1;
    margin-top: $spacing-2;
    font-weight: $weight-bold;
  }
  &__flex-right {
    justify-content: flex-end;
  }

  &__process {
    font-weight: $weight-bold;
    font-size: $size-7;
    margin-bottom: $spacing-5;

    i {
      font-size: $size-7;
      margin-right: $spacing-1;
    }
  }

  &__process-failed {
    color: $fire;
  }

  &__process-successful {
    color: $earth;
  }

  &__loading {
    text-align: center;
    margin-bottom: $spacing-5;
  }
}

.generic-table {
  &__account-id {
  }
  &__status {
    font-size: 14px;
    display: block;
    padding: $spacing-1 $spacing-2;
    border-radius: 4px;
    background: $blue-100;
  }
  &__status--sent {
    background: $water-dark;
    color: $day;
  }

  &__status--approved {
    background: $earth-dark;
  }
  &__status--needs-verification {
    background: $blue-600;
    color: $day;
  }
  &__status--docs-verified {
    background: $green-100;
  }
  &__flex-box {
    display: flex;
  }
  &__flex-right {
    justify-content: flex-end;
  }
  &__flex-wrap {
    flex-wrap: wrap;
  }
  &__half-left-column {
    flex: 0 0 50%;
  }
  &__half-right-column {
    flex: 0 0 50%;
    padding: 0 0 0 $spacing-5;
  }
  &__dropdown {
    h3 {
      font-size: $size-7;
      margin-bottom: $spacing-4;
    }
  }
  &__textarea {
    width: 100%;
    max-width: 100%;
    min-height: 100px;
    margin-bottom: $spacing-5;
    border: 1px solid $night-light-2;
    border-radius: 4px;
  }
  &__customer-criteria {
    margin: $spacing-5 0px 0px;

    p {
      color: $night-light;
      font-weight: $weight-bold;
      margin-bottom: $spacing-2;
    }

    select {
      width: 100%;
      padding: $spacing-2;
      margin-bottom: $spacing-4;
      color: $night-light;
    }
  }
  &__submit-error,
  &__submit-success {
    width: 100%;
    text-align: right;
    margin-top: $spacing-2;
    font-weight: $weight-bold;

    i {
      margin-right: $spacing-1;
    }
  }
  &__submit-error {
    color: $fire;

    span {
      color: $night-light;
      font-weight: $weight-normal;
    }
  }
  &__submit-success {
    color: $earth-dark;
  }
  &__notes {
    color: $night-light;
  }
}
</style>
