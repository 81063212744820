<template>
  <Modal :is-modal-open="show" class="warning-modal" @closeModal="closeModal">
    <template slot="header">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <h4 v-html="modalSettings.title" />
    </template>
    <template slot="body">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-if="modalSettings.body" v-html="modalSettings.body" />
    </template>

    <template slot="footer">
      <AppButton
        ref="closeModal"
        variant="secondary"
        aria-label="Close modal"
        @click="closeModal"
      >
        {{ modalSettings.closeBtnText }}
      </AppButton>

      <div v-if="modalSettings.reload">
        <AppButton
          variant="secondary"
          aria-label="Reload page"
          @click="$router.go()"
        >
          {{ modalSettings.reloadBtnText }}
        </AppButton>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@soenergy/frontend-library/src/components/Modal"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"

export default {
  components: {
    Modal,
    AppButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    variation: {
      type: String,
      default: "generic", // generic, networkError, dateError, slotError, noSlotError, bookingError
    },
  },
  data() {
    return {
      generic: {
        title:
          "The booking cannot be completed - we are missing some information or something is failing.",
        closeBtnText: "Close",
        reload: false,
      },
      networkError: {
        title:
          "Sorry, there seems to have been a connection issue with the booking system. Please reload this page.",
        closeBtnText: "Close",
        reload: true,
        reloadBtnText: "Reload page",
      },
      getBookingError: {
        title: "We are unable to fetch booking data.",
        closeBtnText: "Close",
        reload: true,
        reloadBtnText: "Reload page",
      },
      getBookingMissing: {
        title: "No past or future appointments.",
        closeBtnText: "Close",
        reload: false,
      },
      dateError: {
        title:
          "The appointment you’ve selected is now in the past. Please reload this page.",
        closeBtnText: "Close",
        reload: true,
        reloadBtnText: "Reload page",
      },
      slotError: {
        title:
          "Sorry but this appointment slot is no longer available. It may have been snapped up by another customer in the last few minutes. <br/><br/> Would you like to book a new appointment?",
        closeBtnText: "No",
        reload: true,
        reloadBtnText: "Yes",
      },
      noSlotError: {
        title:
          "No slots available for the selected account. You could try to refresh, or come back later.",
        closeBtnText: "Close",
        reload: true,
        reloadBtnText: "Reload page",
      },
      bookingError: {
        title:
          "Sorry, your booking has failed. Please try again or report the issue.",
        closeBtnText: "Close",
        reload: false,
      },
      timeoutError: {
        title:
          "Sorry, the request timed out. The server may be busy or not responding. Please try again later.",
        closeBtnText: "Close",
        reload: false,
      },
    }
  },
  computed: {
    modalSettings() {
      return this[this.variation]
    },
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
.warning-modal {
  background-color: rgba(0, 0, 0, 0.63);
  opacity: 1;

  ::v-deep .modal {
    position: relative;
    width: auto;
    height: auto;
  }
}
</style>
