<template>
  <div class="file-preview">
    <template v-if="isPdf">
      <embed
        :src="fileData.url + '#toolbar=0&navpanes=0'"
        class="file-preview__embed"
      />
    </template>
    <template v-else-if="isImage">
      <img :src="fileData.url" class="file-preview__image" />
    </template>
    <template v-else>
      <p class="file-preview__error">This file can not be displayed.</p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    fileData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    fileType() {
      const splitFileName = this.fileData.name.split(".")
      return splitFileName[splitFileName.length - 1]
    },
    isImage() {
      return (
        this.fileType === "png" ||
        this.fileType === "jpg" ||
        this.fileType === "jpeg"
      )
    },
    isPdf() {
      return this.fileType === "pdf"
    },
  },
}
</script>

<style lang="scss">
.file-preview {
  &__embed {
    width: 100%;
    min-height: 400px;
    min-width: 400px;
  }
  &__image {
    width: 100%;
  }
  &__error {
    color: $fire-dark;
    font-size: $font-size-5;
    margin: $space-5 0;
  }
}
</style>
