export const trapFocus = {
  methods: {
    checkKeyEvent(event, container) {
      const focusableList = container.querySelectorAll(
        "button, [href], input, select, textarea"
      )
      if (focusableList.length < 1 && event.key === "Tab") {
        event.preventDefault()
        return
      }
      const last = focusableList.length - 1
      if (
        event.key === "Tab" &&
        event.shiftKey === false &&
        event.target === focusableList[last]
      ) {
        event.preventDefault()
        focusableList[0].focus()
      } else if (
        event.key === "Tab" &&
        event.shiftKey === true &&
        event.target === focusableList[0]
      ) {
        event.preventDefault()
        focusableList[last].focus()
      }
    },
  },
}
