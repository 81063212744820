import { roundNum } from "../round"
import store from "@/store/index"

export function refundAmount(review) {
  try {
    var refund, nextPayment
    var today = new Date()
    var todayMonth = today.getMonth() + 1
    var newPaymentAmount = review.suggested_payment_amount
    var liveAccBal =
      Number(review.account_balance) + Number(review.unbilled_readings_cost) //Credit is negative
    var refundTable = store.getters["sixMonthAdDayOne/refundTable"]
    var refundIndex = refundTable.findIndex(
      (s) =>
        s.month == todayMonth &&
        s.current_payment_seasonal_fl == review.current_payment_seasonal_fl
    )
    var refundWeight = parseFloat(refundTable[refundIndex].weighting) / 100

    if (review.current_payment_seasonal_fl === true) {
      if ([4, 5, 6, 7, 8, 9].includes(todayMonth)) {
        nextPayment = newPaymentAmount * 0.75
      } else {
        nextPayment = newPaymentAmount * 1.25
      }
    } else {
      nextPayment = newPaymentAmount
    }
    var manualAdj = store.getters["sixMonthAdDayOne/refundAdj"]
    var refundRaw =
      Math.round(liveAccBal + nextPayment * refundWeight) * manualAdj
    refund = Math.ceil(refundRaw / 10) * 10

    //Has to be greater than £30 refund
    if (refund > -30) {
      refund = 0
    }
    return roundNum(-refund, 0)
  } catch (e) {
    console.log(e, review)
  }
}
