/* eslint-disable no-debugger */
import email from "@/constants/email"
import { fetchTemplate } from "@/helpers/paymentAdequacy/initialTemplates"
import { listOrArray } from "@/helpers/arrayList"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import isBetween from "dayjs/plugin/isBetween"
dayjs.extend(advancedFormat)
dayjs.extend(isBetween)

export function fetchFollowUpTemplates(e) {
  try {
    var paymentAmountText,
      contactWording,
      paymentChangeNotification,
      missingReadings,
      PsrReadingText,
      psrWording

    var mpxnList = listOrArray

    //Next payment date
    var today = dayjs(new Date())
    var nextPaymentDate = dayjs(e.current_payment_next_dt)
    const nextPaymentDateOctNov = dayjs(nextPaymentDate).isBetween(
      "2022-10-01",
      "2022-11-31",
      "day",
      "[]"
    )
    const nextPaymentDateDecMar = dayjs(nextPaymentDate).isBetween(
      "2022-12-01",
      "2023-03-31",
      "day",
      "[]"
    )
    var dayDifference = nextPaymentDate.diff(today, "days")

    if (dayDifference < 21) {
      e.suggested_payment_next_dt = nextPaymentDate
        .add(1, "months")
        .format("YYYY-MM-DD")
    } else {
      e.suggested_payment_next_dt = nextPaymentDate.format("YYYY-MM-DD")
    }
    var paymentdt = dayjs(e.suggested_payment_next_dt).format("Do MMMM")

    //Time since inital contact -  REMOVED DUE TO TIME LAPSE FIXING PA V2!
    // var timeSinceFirstEmail = `About ${dayjs(e.email_dayone_sent).fromNow()}`

    // Payment amount formating
    var suggPayAmount = Math.round(e.suggested_payment_amount_on_follow_up)
    var suggPayAmount_summer = (
      e.suggested_payment_amount_on_follow_up * 0.75
    ).toFixed(2)
    var suggPayAmount_winter = (
      e.suggested_payment_amount_on_follow_up * 1.25
    ).toFixed(2)

    var firstName = e.account.contacts.filter(
      (contact) => contact.junifer_primary_contact
    )[0].first_name
    var accountNumber = e.account.junifer_account_number
    e.emailSubject = "We have reviewed your Direct Debit amount"

    //Addressing any customer contact
    if (e.customer_contact_fl) {
      // Customer has contacted us
      contactWording = ""
    } else {
      // Customer has contacted us
      contactWording = " and haven't heard from you"
    }

    // Missing fuel readings
    var missingFuel = []
    if (e.follow_up_reads_check == false) {
      e.account.meterpoints.forEach(function (meterpoint) {
        if (meterpoint.meterpoint_type === "MPAN") {
          var meterpointType = "MPAN:"
          var fuel = "electricity"
        } else {
          var meterpointType = "MPRN:"
          var fuel = "gas"
        }
        fuel += " (" + meterpointType + meterpoint.identifier + ")"
        missingFuel.push(fuel)
      })
    }

    if (e.follow_up_reads_check == false) {
      var list = mpxnList(missingFuel)
    }

    if (missingFuel.length > 1) {
      missingReadings = " meter readings for either your " + list
    } else {
      missingReadings = " a meter reading for " + list
    }
    // If customer is on PSR
    if (e.psr) {
      PsrReadingText = ``
      psrWording = ``
    } else {
      psrWording = `We haven't received ${missingReadings}${contactWording}`
      PsrReadingText = `<br><br>
      If you are unable to read the meter due to disability or having a special requirement, you may like to add 
      your details to our <b><a href='https://help.so.energy/support/solutions/articles/7000023268-priority-services-register-psr-' target='_blank'>
      Priority Services Register (PSR)</a></b>. Services can include quarterly meter readings, or assistance if you have difficulty 
      reading your statements.`
    }

    //Set template
    var bodyTextStyle = email.textStyle

    if (!e.has_meter_readings && !e.follow_up_reads_check) {
      // Without readings and havent submitted them
      if (e.perc_change > 0) {
        //Increaseing without any reads
        if (e.current_payment_seasonal_fl) {
          paymentChangeNotification = `we are letting you know that we are increasing your 
          monthly Direct Debit payment to summer payments &pound;${suggPayAmount_summer} (April - September) and winter payments &pound;${suggPayAmount_winter} (October - March) <b><u>from ${paymentdt} onwards</u></b>`
        } else {
          paymentChangeNotification = `we are letting you know that we are increasing your monthly 
          Direct Debit payment to &pound;${suggPayAmount} per month <b><u>from ${paymentdt} onwards</u></b>`
        }

        e.templateContent = `<html>
                                <body> 
                                  <div style='${bodyTextStyle}'>
                                      Hello ${firstName},
                                      <br><br>
                                      We have reviewed your monthly payment amount.
                                      ${psrWording}. 
                                      <br><br>Based on the 
                                      information we have ${paymentChangeNotification}.
                                      <br><br>
                                      Our review takes into consideration your expected future energy costs, as well as your current account balance. 
                                      Your account balance is shown on your most recent energy statement and can also be found in the 
                                      <b><a href='https://www.so.energy/accounts/balance' target='_blank'>Balance & Statements</a></b> section of your 
                                      online account.
                                      <br><br>
                                      Remember that your tariff is not changing, and that you are still paying the same price for fuel. You are only charged 
                                      for what you use, any remaining account balance can be settled at the end of your contract. 
                                      For more information please see our <a href='https://help.so.energy/support/solutions/articles/7000046258-how-do-you-review-my-payments-' target='_blank'><b>Direct Debit Review Guide</b></a>.
                                      ${PsrReadingText}
                                      <br><br>
                                      If you have any questions in the meantime, please don't hesitate to ask. You may be asked for 
                                      your So Energy Account Number which is ${accountNumber}.
                                  </div>
                                </body>
                            </html>`

        e.emailTag = [
          "paymentreview_nocalc",
          "Dispatch'r to Junifer (Outbound)",
        ]
        e.freshdesk_sub_1 = "Direct Debit Review"
        e.paymentChange = true
      } else {
        // Keeping the same without follow up read
        if (e.current_payment_seasonal_fl) {
          paymentAmountText = `summer payments &pound;${suggPayAmount_summer} (April - September) and winter payments &pound;${suggPayAmount_winter} (October - March)`
        } else {
          paymentAmountText = `&pound;${suggPayAmount} each month`
        }

        e.templateContent = `<html>
                                <body> 
                                  <div style='${bodyTextStyle}'>
                                    Hello ${firstName},
                                    <br><br>
                                    We have reviewed your monthly payment amount.
                                    <br><br>${psrWording}.
                                    <br><br>Based on the information we have for your property 
                                    we're keeping your payments the same at ${paymentAmountText}.
                                    ${PsrReadingText}
                                    <br><br>
                                    If you have any questions in the meantime, please don't hesitate to ask. You may be asked for your Account Number 
                                    which is ${accountNumber}.
                                  </div>
                                </body>
                            </html>`
        e.emailTag = [
          "paymentreview_nocalc",
          "Dispatch'r to Junifer (Outbound)",
        ]
        e.freshdesk_sub_1 = "Direct Debit Review"
        e.paymentChange = false
      }
    } else if (!e.has_meter_readings && e.follow_up_reads_check) {
      // Without readings and have submitted them
      e.followUpIntroWording = `Thank you for recently submitting meter readings for your payment review.<br><br>`
      fetchTemplate(e)
    } else if (
      e.has_meter_readings &&
      parseFloat(e.suggested_payment_amount_on_follow_up) >
        parseFloat(e.current_payment_amount)
    ) {
      if (e.current_payment_seasonal_fl) {
        paymentChangeNotification = `we are letting you know that we will be increasing your monthly Direct Debit 
         payment to summer payments &pound;${suggPayAmount_summer} (April - September) and winter payments &pound;${suggPayAmount_winter} (October - March) <b><u>from ${paymentdt} onwards</u></b>`
      } else {
        paymentChangeNotification = `we are letting you know that we are increasing your monthly Direct Debit 
         payment to &pound;${suggPayAmount} per month <b><u>from ${paymentdt} onwards</u></b>`
      }

      e.templateContent = `<html>
                              <body> 
                                <div style='${bodyTextStyle}'>
                                  Hello ${firstName},
                                  <br><br>
                                  We have reviewed your monthly payment amount and wrote to you letting 
                                  you know that your current payment amount isn't suitable for your energy usage.
                                  <br><br>Based on all the information we have for your property ${paymentChangeNotification}.
                                  <br><br>
                                  If you would like to speak to us or have any questions, please don't hesitate to ask. 
                                  You may be asked for your So Energy Account Number which is ${accountNumber}.
                                </div>
                              </body>
                          </html>`

      e.emailTag = ["paymentreview_nocalc", "Dispatch'r to Junifer (Outbound)"]
      e.freshdesk_sub_1 = "Direct Debit Review"
      e.paymentChange = true
    } else {
      if (e.current_payment_seasonal_fl) {
        paymentAmountText = `summer payments &pound;${suggPayAmount_summer} (April - September) and winter payments &pound;${suggPayAmount_winter} (October - March)`
      } else {
        paymentAmountText = `&pound;${suggPayAmount} each month`
      }

      e.templateContent = `<html>
                                <body> 
                                  <div style='${bodyTextStyle}'>
                                    Hello ${firstName},
                                    <br><br>
                                    We have reviewed your monthly payment amount.
                                    <br><br>Based on the information we have for your property 
                                    we're keeping your payments the same at ${paymentAmountText}.
                                    ${PsrReadingText}
                                    <br><br>
                                    If you have any questions in the meantime, please don't hesitate to ask. You may be asked for your Account Number 
                                    which is ${accountNumber}.
                                  </div>
                                </body>
                            </html>`
      e.emailTag = ["paymentreview_nocalc", "Dispatch'r to Junifer (Outbound)"]
      e.freshdesk_sub_1 = "Direct Debit Review"
      e.paymentChange = false
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
