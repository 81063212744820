<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Disputing meter readings"
        summary="Raises a dispute within Junifer, puts the account under review and sends an email to the customer via Freshdesk (optional)"
      >
      </header-block>
    </b-row>

    <page-section title="Meterpoint lookup">
      <template v-slot:content>
        <b-form inline>
          <input
            id="accountsearch"
            v-model="postMpxn"
            type="text"
            placeholder="MPxN...."
            style="width: 200px"
            class="form-control"
          />
          <span class="input-group-btn">
            <input
              id="search"
              type="button"
              value="search"
              class="btn btn-light custom-button-width light-blue"
              @click="mpxnLookup(postMpxn)"
            />
            <input
              id="clear"
              type="button"
              value="clear"
              class="btn btn-light custom-button-width red"
              @click="clear()"
            />
          </span>
        </b-form>
      </template>
    </page-section>

    <page-section v-if="DISPUTEDELECREAD.length !== 0">
      <template v-slot:content>
        <div v-if="DISPUTEDELECREAD.rejection.disputed_read === true">
          <div align="left">
            <h4>
              <img src="@/assets/icons/warning.png" /> A dispute has already
              been raised on {{ lookupMpxn }}
            </h4>
          </div>
          <table>
            <tr>
              <td>Account</td>
              <td>{{ DISPUTEDELECREAD.rejection.account_number }}</td>
            </tr>
            <tr>
              <td>Ticket ID</td>
              <td>{{ DISPUTEDELECREAD.rejection.disputed_read_ticket_id }}</td>
            </tr>
            <tr>
              <td>Ticket Key Identifier</td>
              <td>
                {{
                  DISPUTEDELECREAD.rejection.disputed_read_ticket_key_identifier
                }}
              </td>
            </tr>
          </table>
        </div>
        <div v-else>
          <b-card>
            <table>
              <tr>
                <td>Account Number</td>
                <td>{{ DISPUTEDELECREAD.rejection.account_number }}</td>
              </tr>
              <tr>
                <td>
                  <span
                    v-if="DISPUTEDELECREAD.meter_info.identifier.length === 13"
                    >MPAN</span
                  >
                  <span v-else>MPRN</span>
                </td>
                <td>
                  {{ DISPUTEDELECREAD.meter_info.identifier }}
                  <span
                    v-if="DISPUTEDELECREAD.meter_info.identifier.length === 13"
                    ><img src="@/assets/icons/elec.png" style="width: 18px" />
                  </span>
                  <span v-else
                    ><img src="@/assets/icons/gas.png" style="width: 18px"
                  /></span>
                </td>
              </tr>
              <tr>
                <td>Customer</td>
                <td>{{ DISPUTEDELECREAD.rejection.customer_name }}</td>
              </tr>
            </table>
          </b-card>
          <b-form @submit="onSubmit">
            <b-row
              v-if="DISPUTEDELECREAD.meter_info.identifier.length === 13"
              class="mb-3"
            >
              <b-col
                v-for="reg in DISPUTEDELECREAD.disputed_read
                  .meterRegisterReadings"
                :key="reg.meterRegisterId"
                :class="classObject"
              >
                <electricity-disputed-form
                  :post="reg"
                  :options="electrcityOptions"
                >
                  <template v-slot:previousRead>
                    <div
                      v-if="
                        previousReadFilter(
                          reg.readingSequenceType,
                          reg.meterRegisterId
                        )
                      "
                    >
                      <h3 align="left" class="m-4">D311</h3>
                      <b-form-group
                        label-cols-sm="3"
                        label="EAC"
                        label-align-sm="right"
                        label-for="readDate"
                      >
                        <b-form-input
                          id="readDate"
                          v-model="
                            previousReadFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).EAC
                          "
                          style="display: inline; float: left"
                          class="col-6"
                          disabled
                        />
                        <span style="display: inline; float: left"
                          >&nbsp;&nbsp;{{
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).unit
                          }}</span
                        >
                      </b-form-group>
                      <b-form-group
                        label-cols-sm="3"
                        label="Last Read"
                        label-align-sm="right"
                        label-for="source"
                      >
                        <b-form-input
                          id="source"
                          v-model="
                            previousReadFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).Read
                          "
                          style="display: inline; float: left"
                          class="col-6"
                          disabled
                        />
                        <span style="display: inline; float: left"
                          >&nbsp;&nbsp;{{
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).unit
                          }}</span
                        >
                      </b-form-group>
                      <b-form-group
                        label-cols-sm="3"
                        label="Read Date"
                        label-align-sm="right"
                        label-for="source"
                      >
                        <b-form-input
                          id="source"
                          v-model="
                            previousReadFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).ReadDate
                          "
                          disabled
                        />
                      </b-form-group>
                    </div>
                  </template>

                  <template v-slot:juniferRead>
                    <h3 align="left" class="m-4">Junifer information</h3>
                    <div
                      v-if="
                        readFilter(reg.readingSequenceType, reg.meterRegisterId)
                      "
                    >
                      <b-form-group
                        v-show="
                          readFilter(
                            reg.readingSequenceType,
                            reg.meterRegisterId
                          ).rateName
                        "
                        label-cols-sm="3"
                        label="Register"
                        label-align-sm="right"
                        label-for="rateName"
                      >
                        <b-form-input
                          id="rateName"
                          v-model="
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).rateName
                          "
                          style="display: inline; float: left"
                          class="col-6"
                          disabled
                        /><span style="display: inline; float: left" class="m-2"
                          >&nbsp;&nbsp;({{
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).register
                          }})</span
                        >
                      </b-form-group>
                      <b-form-group
                        label-cols-sm="3"
                        label="Read Date"
                        label-align-sm="right"
                        label-for="readDate"
                      >
                        <b-form-input
                          id="readDate"
                          v-model="
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).readingDttm
                          "
                          class="col-6"
                          disabled
                        />
                      </b-form-group>
                      <b-form-group
                        label-cols-sm="3"
                        label="Source"
                        label-align-sm="right"
                        label-for="source"
                      >
                        <b-form-input
                          id="source"
                          v-model="
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).source
                          "
                          class="col-6"
                          disabled
                        />
                      </b-form-group>
                      <b-form-group
                        label-cols-sm="3"
                        label="Quality"
                        label-align-sm="right"
                        label-for="quality"
                      >
                        <b-form-input
                          id="quality"
                          v-model="
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).quality
                          "
                          class="col-6"
                          disabled
                        />
                      </b-form-group>
                      <b-form-group
                        label-cols-sm="3"
                        label="Reading"
                        label-align-sm="right"
                        label-for="cumulative"
                      >
                        <b-form-input
                          id="cumulative"
                          v-model="
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).cumulative
                          "
                          class="col-6"
                          style="display: inline; float: left"
                          disabled
                        />
                        <span style="display: inline; float: left" class="m-2"
                          >&nbsp;&nbsp;{{
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).unit
                          }}</span
                        >
                      </b-form-group>
                    </div>
                  </template>

                  <template v-slot:readDifference>
                    <div
                      v-if="
                        readFilter(reg.readingSequenceType, reg.meterRegisterId)
                      "
                    >
                      <b-form-group
                        label-cols-sm="3"
                        label="Difference"
                        label-align-sm="right"
                        label-for="cumulative"
                      >
                        <p
                          :class="{
                            neg:
                              reg.proposedReading -
                                readFilter(
                                  reg.readingSequenceType,
                                  reg.meterRegisterId
                                ).cumulative <
                              0,
                            pos:
                              reg.proposedReading -
                                readFilter(
                                  reg.readingSequenceType,
                                  reg.meterRegisterId
                                ).cumulative >
                              0,
                          }"
                          style="display: inline; float: left"
                        >
                          {{
                            Math.round(
                              reg.proposedReading -
                                readFilter(
                                  reg.readingSequenceType,
                                  reg.meterRegisterId
                                ).cumulative
                            )
                          }}&nbsp;&nbsp;{{
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).unit
                          }}
                        </p>
                      </b-form-group>
                    </div>
                  </template>
                </electricity-disputed-form>
              </b-col>
            </b-row>

            <b-row v-else class="mb-3">
              <b-col
                v-for="reg in DISPUTEDELECREAD.disputed_read
                  .meterRegisterReadings"
                :key="reg.meterRegisterId"
              >
                <gas-disputed-form :post="reg" :options="gasOptions">
                  <template v-slot:previousRead>
                    <div
                      v-if="
                        previousReadFilter(
                          reg.readingSequenceType,
                          reg.meterRegisterId
                        ) &&
                        previousReadFilter(
                          reg.readingSequenceType,
                          reg.meterRegisterId
                        ).PreviousRead
                      "
                    >
                      <h3 align="left" class="m-4">NOSI</h3>
                      <b-form-group
                        label-cols-sm="3"
                        label="Last Read"
                        label-align-sm="right"
                        label-for="source"
                      >
                        <b-form-input
                          id="source"
                          v-model="
                            previousReadFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).PreviousRead
                          "
                          disabled
                        />
                      </b-form-group>
                      <b-form-group
                        label-cols-sm="3"
                        label="Read Date"
                        label-align-sm="right"
                        label-for="source"
                      >
                        <b-form-input
                          id="source"
                          v-model="
                            previousReadFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).PreviousReadDate
                          "
                          disabled
                        />
                      </b-form-group>
                    </div>
                  </template>

                  <template v-slot:juniferRead>
                    <h3 align="left" class="m-4">Junifer information</h3>
                    <div
                      v-if="
                        readFilter(reg.readingSequenceType, reg.meterRegisterId)
                      "
                    >
                      <b-form-group
                        label-cols-sm="3"
                        label="Read Date"
                        label-align-sm="right"
                        label-for="readDate"
                      >
                        <b-form-input
                          id="readDate"
                          v-model="
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).readingDttm
                          "
                          class="col-6"
                          disabled
                        />
                      </b-form-group>
                      <b-form-group
                        label-cols-sm="3"
                        label="Source"
                        label-align-sm="right"
                        label-for="source"
                      >
                        <b-form-input
                          id="source"
                          v-model="
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).source
                          "
                          class="col-6"
                          disabled
                        />
                      </b-form-group>
                      <b-form-group
                        label-cols-sm="3"
                        label="Quality"
                        label-align-sm="right"
                        label-for="quality"
                      >
                        <b-form-input
                          id="quality"
                          v-model="
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).quality
                          "
                          class="col-6"
                          disabled
                        />
                      </b-form-group>
                      <b-form-group
                        label-cols-sm="3"
                        label="Reading"
                        label-align-sm="right"
                        label-for="cumulative"
                      >
                        <b-form-input
                          id="cumulative"
                          v-model="
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).cumulative
                          "
                          class="col-6"
                          style="display: inline; float: left"
                          disabled
                        />
                        <span
                          style="display: inline; float: left"
                          class="m-2"
                          >{{
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).unit
                          }}</span
                        >
                      </b-form-group>
                    </div>
                  </template>

                  <template v-slot:readDifference>
                    <div
                      v-if="
                        readFilter(reg.readingSequenceType, reg.meterRegisterId)
                      "
                    >
                      <b-form-group
                        label-cols-sm="3"
                        label="Difference"
                        label-align-sm="right"
                        label-for="cumulative"
                      >
                        <p
                          :class="{
                            neg:
                              reg.proposedReading -
                                readFilter(
                                  reg.readingSequenceType,
                                  reg.meterRegisterId
                                ).cumulative <
                              0,
                            pos:
                              reg.proposedReading -
                                readFilter(
                                  reg.readingSequenceType,
                                  reg.meterRegisterId
                                ).cumulative >
                              0,
                          }"
                          style="display: inline; float: left"
                        >
                          {{
                            reg.proposedReading -
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).cumulative
                          }}&nbsp;&nbsp;{{
                            readFilter(
                              reg.readingSequenceType,
                              reg.meterRegisterId
                            ).unit
                          }}
                        </p>
                      </b-form-group>
                    </div>
                  </template>
                </gas-disputed-form>
              </b-col>
            </b-row>

            <div
              v-if="
                readCheck(DISPUTEDELECREAD.disputed_read.meterRegisterReadings)
              "
            >
              <h3 align="left" class="m-4">Other supplier information</h3>
              <b-form-group
                inline
                label-cols-sm="3"
                label="Energy Supplier Code"
                label-align-sm="right"
                label-for="supplierCode"
                data-toggle="tooltip"
                data-placement="top"
                title="Code for energy supplier the reading is being disputed with i.e. OVO or Bulb (not So Energy). This information can be found on the MPxN CREGI flow."
              >
                <b-form-input
                  id="supplierCode"
                  v-model="supplierCode"
                  class="col-6"
                  style="display: inline; float: left"
                  placeholder="e.g. BGAS"
                />
                <img
                  id="tooltip-target-1"
                  src="../../assets/icons/question.png"
                  style="display: inline; float: left; width: 24px"
                  class="m-2"
                />
              </b-form-group>
              <b-tooltip
                target="tooltip-target-1"
                triggers="hover"
                placement="righttop"
              >
                <span v-if="DISPUTEDELECREAD.meter_info.identifier.length < 13">
                  This information can be found on either the NOSI or MRI
                  dataflows
                </span>
                <span v-else>
                  This information can be found on the D311 dataflow
                </span>
              </b-tooltip>
              <b-form-group
                label-cols-sm="3"
                description="Optional"
                label="Additional Information"
                label-align-sm="right"
                label-for="addInfo"
              >
                <b-form-textarea
                  id="addInfo"
                  v-model="message"
                  placeholder="e.g. COR ON COS: xxx & 21/06/2019- xxx PHOTO"
                />
              </b-form-group>

              <h3 align="left" class="m-4">Contacting the customer</h3>
              <b-form-group
                v-show="
                  DISPUTEDELECREAD.emailPref === 'email' ||
                  DISPUTEDELECREAD.emailPref === 'freshdesk'
                "
                label-cols-sm="3"
                label="Email"
                label-align-sm="right"
                label-for="supplierCode"
              >
                <b-form-input
                  id="supplierCode"
                  v-model="DISPUTEDELECREAD.rejection.email"
                  class="col-6"
                  disabled
                />
              </b-form-group>
              <b-form-group
                v-show="DISPUTEDELECREAD.rejection.freshdesk_ticket !== null"
                label-cols-sm="3"
                label="FD Ticket"
                label-align-sm="right"
                label-for="supplierCode"
              >
                <b-form-input
                  id="supplierCode"
                  v-model="DISPUTEDELECREAD.rejection.freshdesk_ticket"
                  style="display: inline; float: left"
                  class="col-6"
                  disabled
                />
                <span style="display: inline; float: left">&nbsp;&nbsp;</span>
              </b-form-group>
              <b-form-group
                label-cols-sm="3"
                label="Customer Response"
                label-align-sm="right"
                label-for="emailPref"
              >
                <b-form-radio-group
                  id="email-radio"
                  v-model="emailPreferences"
                  name="email-radio"
                  style="display: inline; float: left"
                >
                  <b-form-radio value="email">New Email</b-form-radio>
                  <b-form-radio
                    v-show="
                      DISPUTEDELECREAD.rejection.freshdesk_ticket !== null
                    "
                    value="freshdesk"
                    >Freshdesk Reply</b-form-radio
                  >
                  <b-form-radio value="noEmail">No Email</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group
                v-show="
                  DISPUTEDELECREAD.emailPref === 'email' ||
                  DISPUTEDELECREAD.emailPref === 'freshdesk'
                "
                label-cols-sm="3"
                label="Email Response"
                label-align-sm="right"
                label-for="email"
              >
                <p
                  class="border rounded"
                  style="
                    text-align: left;
                    background-color: white;
                    padding: 5px;
                  "
                  v-html="emailContent"
                ></p>
              </b-form-group>
              <b-form-group
                v-show="
                  DISPUTEDELECREAD.emailPref === 'email' ||
                  DISPUTEDELECREAD.emailPref === 'freshdesk'
                "
                label-cols-sm="3"
                label="Freskdesk ticket status"
                label-align-sm="right"
                label-for="emailStatus"
              >
                <b-form-select
                  v-model="emailStatus"
                  class="col-6 float-left"
                  :options="freshdeskTicketOptions"
                  required
                />
              </b-form-group>
            </div>

            <div
              v-if="
                readCheck(DISPUTEDELECREAD.disputed_read.meterRegisterReadings)
              "
            >
              <b-button
                id="formSubmit"
                v-b-modal="'my-modal'"
                type="submit"
                size="lg"
                class="m-3"
                variant="primary"
                :disabled="DISPUTERESPONSE.disputedReadTicketId ? true : false"
              >
                {{
                  DISPUTERESPONSE.disputedReadTicketId
                    ? "Sucess &#10004;"
                    : "Submit"
                }}
              </b-button>
              <b-modal
                ref="my-modal"
                v-model="modalShow"
                hide-footer
                title="Disputed reading"
              >
                <div class="d-block text-center">
                  <h3 v-if="DISPUTERESPONSE.length === 0">
                    <span class="spinner-border spinner-border-sm"></span>
                    Loading..
                  </h3>
                  <div v-else>
                    <div
                      v-if="
                        DISPUTERESPONSE && DISPUTERESPONSE.disputedReadTicketId
                      "
                    >
                      <img
                        src="../../assets/icons/check.png"
                        style="width: 32px"
                      />
                    </div>
                    <div v-else>
                      <img src="../../assets/icons/warning.png" />
                    </div>
                    <table
                      v-for="(value, key) in DISPUTERESPONSE"
                      :key="key"
                      class="text-left"
                    >
                      <tr>
                        <td>{{ key }}</td>
                        <td>{{ value }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <b-button
                  class="mt-3"
                  variant="outline-danger"
                  block
                  @click="hideModal"
                  >Close</b-button
                >
              </b-modal>
            </div>
          </b-form>
        </div>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import disputedReadElectricity from "@/components/disputedReadFormElectricity"
import disputedReadGas from "@/components/disputedReadFormGas"
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"

export default {
  components: {
    "electricity-disputed-form": disputedReadElectricity,
    "gas-disputed-form": disputedReadGas,
    headerBlock,
    pageSection,
  },
  data() {
    return {
      electrcityOptions: {
        reasonCodeOptions: [
          { value: "01", text: "Reading too high" },
          { value: "02", text: "Reading too low" },
          {
            value: "15",
            text: "Cyclic Read received which invalidates CoS Reading",
          },
          {
            value: "16",
            text: "Customer disputed final account (or amended final account) from Old Supplier",
          },
          {
            value: "17",
            text: "Customer disputed initial account (or amended initial account) from the New Supplier",
          },
        ],
        readingTypeCodeOptions: [
          { value: "A", text: "Actual Change of Supplier Read" },
          { value: "C", text: "Customer Own Read" },
          {
            value: "D",
            text: "Deemed (Settlement Registers) or Estimated (Non-Settlement Registers)",
          },
          { value: "F", text: "Final" },
          { value: "I", text: "Initial" },
          { value: "M", text: "MAR" },
          { value: "O", text: "Old Suppliers Estimated CoS Reading" },
          { value: "P", text: "Electronically collected via PPMIP" },
          { value: "Q", text: "Meter Reading modified manually by DC" },
          { value: "R", text: "Routine" },
          { value: "S", text: "Special" },
          { value: "T", text: "Proving Test Reading" },
          { value: "W", text: "Withdrawn" },
          { value: "Z", text: "Actual Change of Tenancy Read" },
        ],
        readingSequenceType: [
          { value: "First", text: "First" },
          { value: "Last", text: "Last" },
        ],
      },
      gasOptions: {
        gasReadingTypeOptions: [
          { value: "A", text: "Actual read" },
          { value: "E", text: "Estimated read" },
        ],
        criteriaOptions: [
          { value: "A", text: "Bill would be unacceptably high" },
          { value: "B", text: "Closing read is lower than last actual read" },
          { value: "C", text: "Customer complaint about bill" },
          { value: "D", text: "Missing read" },
          {
            value: "E",
            text: "Actual read obtained, but not submitted on time",
          },
          { value: "F", text: "Pre-payment read not submitted in time" },
        ],
        statusResponseOptions: [
          { value: null, text: "" },
          {
            value: "A",
            text: "Alternative Proposed Read as the read submitted by the other party is not acceptable",
          },
          {
            value: "C",
            text: "CPM Query raised, please allow 20 Working Days before escalation",
          },
          {
            value: "M",
            text: "Actual Meter Reading requested, please allow 10 Working Days before escalation",
          },
          {
            value: "P",
            text: "Proposed Read, submitted by the Initiating Supplier",
          },
          {
            value: "TP",
            text: "Telephone Proposed Read, submitted by the record initiator following verbal agreement",
          },
          {
            value: "TY",
            text: "Telephone Agreed Read, the TP read is as expected and agreed",
          },
          { value: "Y", text: "Yes; the proposed read is agreed" },
        ],
        readingSequenceType: [
          { value: "First", text: "First" },
          { value: "Last", text: "Last" },
        ],
      },
      freshdeskTicketOptions: [
        { value: 2, text: "Open" },
        { value: 3, text: "Pending" },
        { value: 4, text: "Resolved" },
        { value: 5, text: "Closed" },
        { value: 6, text: "Waiting on Customer" },
        { value: 7, text: "Waiting on Third Party" },
      ],
      postMpxn: null,
      email: "email",
      modalShow: false,
    }
  },
  methods: {
    ...mapActions({
      mpxnLookup: "openingReadings/mpxnLookup",
      disputeFormSubmit: "openingReadings/disputeFormSubmit",
      clear: "openingReadings/clear",
    }),
    ...mapMutations("openingReadings", [
      "SET_EMAIL",
      "SET_EMAIL_PREF",
      "SET_ADD_INFO",
      "SET_SUPPLIER_CODE",
      "SET_EMAIL_STATUS",
    ]),
    readFilter(seq, regId) {
      var reads = this.DISPUTEDELECREAD.first_meter_readings
      if (reads != null) {
        var filteredReads = reads.filter(function (x) {
          return x.registerid == regId && x.sequenceType == seq
        })
        return filteredReads[0]
      }
    },
    hideModal() {
      this.modalShow = !this.modalShow
    },
    readCheck(seq) {
      var reads = this.DISPUTEDELECREAD.first_meter_readings
      let filteredReads = reads[0].sequenceType == seq[0].readingSequenceType

      if (filteredReads.length !== 0) {
        return true
      } else {
        return false
      }
    },
    previousReadFilter(seq, regId) {
      var reading = this.readFilter(seq, regId)
      if (reading != null) {
        var reads = this.DISPUTEDELECREAD.backing_data
        if (reads != null) {
          let filteredReads = reads.RegisterID == reading.register

          return filteredReads[0]
        }
      }
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.SET_EMAIL(this.emailContent)
      this.disputeFormSubmit()
      this.modalShow = !this.modalShow
    },
  },
  computed: {
    ...mapGetters("openingReadings", [
      "DISPUTEDELECREAD",
      "DISPUTERESPONSE",
      "lookupMprnResult",
      "lookupMpxn",
    ]),
    classObject: function () {
      var style = ``
      if (this.DISPUTEDELECREAD.disputed_read.meterRegisterReadings > 1) {
        style = "border rounded m-5"
      }
      return style
    },
    emailPreferences: {
      get() {
        return this.DISPUTEDELECREAD.emailPref
      },
      set(value) {
        this.SET_EMAIL_PREF(value)
      },
    },
    emailStatus: {
      get() {
        return this.DISPUTEDELECREAD.emailStatus
      },
      set(value) {
        this.SET_EMAIL_STATUS(value)
      },
    },
    emailContent() {
      let reading = ""
      let rateName = ""
      let unit = this.DISPUTEDELECREAD.first_meter_readings[0].unit
      let meterReads = this.DISPUTEDELECREAD.disputed_read.meterRegisterReadings
      let reads = this.DISPUTEDELECREAD.first_meter_readings

      meterReads.forEach(function (meterRead) {
        rateName = reads.filter(
          (account) => account.meterRegisterId === meterRead.meterRegisterId
        )
        reading += `  ${meterRead.proposedReading} ${unit} (${rateName[0].rateName})`
      })

      let email =
        this.DISPUTEDELECREAD.emailContentStart +
        reading +
        this.DISPUTEDELECREAD.emailContentEnd
      return email
    },
    message: {
      get() {
        return this.DISPUTEDELECREAD.disputed_read.additionalInformation
      },
      set(value) {
        this.SET_ADD_INFO(value)
      },
    },
    supplierCode: {
      get() {
        return this.DISPUTEDELECREAD.disputed_read.associatedSupplierCode
      },
      set(value) {
        this.SET_SUPPLIER_CODE(value)
      },
    },
  },
}
</script>

<style scoped lang="scss">
.modal-header .close {
  display: none;
}
</style>
