import apiSoMessages from "./SoMessages"

export default {
  // Contact Centers
  fetchCurrentStatus() {
    return apiSoMessages.get("contact_centers/1")
  },
  fetchStatuses() {
    return apiSoMessages.get("contact_center_statuses")
  },
  updateStatuses(payload) {
    return apiSoMessages.put("contact_centers/1", payload)
  },

  //Emergency Contacts
  fetchContacts() {
    return apiSoMessages.get("emergency_contacts")
  },
  updateContact(contactId, payload) {
    return apiSoMessages.put("emergency_contacts/" + contactId, payload)
  },
  deleteContact(contactId) {
    return apiSoMessages.delete("emergency_contacts/" + contactId)
  },
  createContact(payload) {
    return apiSoMessages.post("emergency_contacts", payload)
  },
}
