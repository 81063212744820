<template>
  <AppModal :show="show" style-modifiers="modal--error" @close="closeModal">
    <template slot="header">
      <h2 class="modal__header" v-html="modalSettings.title"></h2>
      <p class="modal__subheader" v-html="modalSettings.subheading"></p>
    </template>

    <template slot="body">
      <div class="flex-container">
        <div
          v-if="errorModal.orangeColumn && errorModal.orangeColumn.length"
          class="errors-displayed errors-displayed__orange"
        >
          <div
            class="errors-displayed__headers errors-displayed__orange--headers"
          >
            <h3
              class="
                errors-displayed__heading errors-displayed__orange--heading
              "
            >
              <i class="fas fa-clone errors-displayed__heading--icon"></i>
              <span v-html="modalSettings.orangeColumnHeading"></span>
              ({{ errorModal.orangeColumn.length }})
            </h3>
          </div>
          <div class="errors-displayed__listed">
            <table>
              <tr v-for="(user, index) in errorModal.orangeColumn" :key="index">
                <td class="errors-displayed__listed--account">
                  {{ user }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          v-if="errorModal.redColumn && errorModal.redColumn.length"
          class="errors-displayed errors-displayed__invalid"
        >
          <div class="errors-displayed__headers">
            <h3 class="errors-displayed__heading">
              <i
                class="
                  fas
                  fa-exclamation-triangle
                  errors-displayed__heading--icon
                "
              ></i>
              <span v-html="modalSettings.redColumnHeading"></span>
              ({{ errorModal.redColumn.length }})
            </h3>
          </div>
          <div class="errors-displayed__listed">
            <table>
              <tr v-for="(user, index) in errorModal.redColumn" :key="index">
                <td class="errors-displayed__listed--account">
                  {{ user }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          v-if="errorModal.blueColumn && errorModal.blueColumn.length"
          class="errors-displayed errors-displayed__blue"
        >
          <div
            class="errors-displayed__headers errors-displayed__blue--headers"
          >
            <h3
              class="errors-displayed__heading errors-displayed__blue--heading"
            >
              <i class="fas fa-info-circle errors-displayed__heading--icon"></i>
              <span v-html="modalSettings.blueColumnHeading"></span>
              ({{ errorModal.blueColumn.length }})
            </h3>
          </div>
          <div class="errors-displayed__listed">
            <table>
              <tr v-for="(user, index) in errorModal.blueColumn" :key="index">
                <td class="errors-displayed__listed--account">
                  {{ user }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          v-if="errorModal.greyColumn && errorModal.greyColumn.length"
          class="errors-displayed errors-displayed__grey"
        >
          <div
            class="errors-displayed__headers errors-displayed__grey--headers"
          >
            <h3
              class="errors-displayed__heading errors-displayed__grey--heading"
            >
              <i class="fas fa-info-circle errors-displayed__heading--icon"></i>
              <span v-html="modalSettings.greyColumnHeading"></span>
              ({{ errorModal.greyColumn.length }})
            </h3>
          </div>
          <div class="errors-displayed__listed">
            <table>
              <tr v-for="(user, index) in errorModal.greyColumn" :key="index">
                <td class="errors-displayed__listed--account">
                  {{ user }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          v-if="errorModal.yellowColumn && errorModal.yellowColumn.length"
          class="errors-displayed errors-displayed__yellow"
        >
          <div
            class="errors-displayed__headers errors-displayed__yellow--headers"
          >
            <h3
              class="
                errors-displayed__heading errors-displayed__yellow--heading
              "
            >
              <i class="fas fa-info-circle errors-displayed__heading--icon"></i>
              <span v-html="modalSettings.yellowColumnHeading"></span>
              ({{ errorModal.yellowColumn.length }})
            </h3>
          </div>
          <div class="errors-displayed__listed">
            <table>
              <tr v-for="(user, index) in errorModal.yellowColumn" :key="index">
                <td class="errors-displayed__listed--account">
                  {{ user }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          v-if="errorModal.tealColumn && errorModal.tealColumn.length"
          class="errors-displayed errors-displayed__teal"
        >
          <div
            class="errors-displayed__headers errors-displayed__teal--headers"
          >
            <h3
              class="errors-displayed__heading errors-displayed__teal--heading"
            >
              <i class="fas fa-info-circle errors-displayed__heading--icon"></i>
              <span v-html="modalSettings.tealColumnHeading"></span>
              ({{ errorModal.tealColumn.length }})
            </h3>
          </div>
          <div class="errors-displayed__listed">
            <table>
              <tr v-for="(user, index) in errorModal.tealColumn" :key="index">
                <td class="errors-displayed__listed--account">
                  {{ user }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div
          v-if="errorModal.whiteColumn && errorModal.whiteColumn.length"
          class="errors-displayed errors-displayed__white"
        >
          <div
            class="errors-displayed__headers errors-displayed__white--headers"
          >
            <h3
              class="errors-displayed__heading errors-displayed__white--heading"
            >
              <i class="fas fa-info-circle errors-displayed__heading--icon"></i>
              <span v-html="modalSettings.whiteColumnHeading"></span>
              ({{ errorModal.whiteColumn.length }})
            </h3>
          </div>
          <div class="errors-displayed__listed">
            <table>
              <tr v-for="(user, index) in errorModal.whiteColumn" :key="index">
                <td class="errors-displayed__listed--account">
                  {{ user }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </template>

    <template slot="footer">
      <p v-if="errorModal.successfulTotal.length" class="footer__success-msg">
        {{ errorModal.successfulTotal.length }}
        <template v-if="errorModal.successfulMessage">
          {{ errorModal.successfulMessage }}
        </template>
        <template v-else> applications were processed successfully </template>
      </p>
      <div class="footer__buttons flex-container">
        <button
          class="footer__buttons--button button button--blue"
          @click="closeModal"
        >
          Close
        </button>
      </div>
    </template>
  </AppModal>
</template>

<script>
import AppModal from "@/components/smartMeter/AppModal"

export default {
  components: {
    AppModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    errorModal: {
      type: Object,
      default: () => {},
    },
    variation: {
      type: String,
      default: "updateAccountErrorWHD",
    },
  },
  data() {
    return {
      updateAccountErrorWHD: {
        title: "Error updating accounts",
        subheading:
          "We were unable to update the status of the following accounts:",
        redColumnHeading: "Invalid Accounts",
        orangeColumnHeading: "Accounts not found",
      },
      updateApplicationErrorWHD: {
        title: "Error updating application status",
        subheading:
          "We were unable to update the status of the following accounts:",
        redColumnHeading: "No associated application",
        orangeColumnHeading: "Core group accounts",
        blueColumnHeading: "Closed account numbers",
        greyColumnHeading: "Invalid account numbers",
      },
      coreAccountErrorWHD: {
        title: "Error updating Junifer accounts",
        subheading:
          "We were unable to update the 'core group' status of the following accounts:",
        redColumnHeading: "Invalid account numbers",
        orangeColumnHeading: "No associated Junifer account",
        blueColumnHeading: "Already core group",
      },
      cancelCreditWHD: {
        title: "Error cancelling application credits",
        subheading:
          "We were unable to update the status of the following accounts:",
        redColumnHeading: "No credit",
        orangeColumnHeading: "No application",
        blueColumnHeading: "Junifer error",
        greyColumnHeading: "Could not cancel credit",
        yellowColumnHeading: "No application",
        tealColumnHeading: "Application not approved or paid",
        whiteColumnHeading: "Unknown error error",
      },
    }
  },
  computed: {
    modalSettings() {
      return this[this.variation]
    },
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
  },
}
</script>

<style scoped lang="scss">
.modal-mask {
  overflow: hidden;
}

.modal__header,
.modal__subheader {
  font-weight: $weight-bold;
}

.modal__header {
  font-size: $size-5;
  color: $night-light;
  margin-bottom: $spacing-2;
}

.modal__subheader {
  font-size: $size-7;
  color: $night-light-2;
  margin-bottom: $spacing-6;
}

.flex-container {
  display: flex;
}

.errors-displayed {
  flex: 1;
  border-radius: 4px;
  padding-bottom: $spacing-7;
  border: 1px solid $red-100;
  max-height: 400px;
  overflow-y: scroll;
  margin: $spacing-2;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &__headers {
    padding: $spacing-2 $spacing-4;
    background: $red-100;
  }

  &__heading {
    font-weight: $weight-bold;
    font-size: $size-7;
    color: $fire;

    &--icon {
      padding-right: $spacing-1;
    }
  }

  &__orange {
    border: 1px solid $yellow-50;
    &--headers {
      background: $yellow-50;
    }

    &--heading {
      color: $yellow-500;
    }
  }

  &__blue {
    border: 1px solid $blue-100;

    &--headers {
      background: $blue-100;
    }

    &--heading {
      color: $water;
    }
  }

  &__grey {
    border: 1px solid $day-dark;

    &--headers {
      background: $day-dark;
    }

    &--heading {
      color: $night-light;
    }
  }

  &__yellow {
    border: 1px solid $sun-light;

    &--headers {
      background: $sun-light;
    }

    &--heading {
      color: $night-light;
    }
  }

  &__teal {
    border: 1px solid $water-light;

    &--headers {
      background: $water-light;
    }

    &--heading {
      color: $night-light;
    }
  }

  &__teal {
    border: 1px solid $night-light;

    &--headers {
      background: $day;
    }

    &--heading {
      color: $night-light;
    }
  }

  &__listed {
    padding: $spacing-4 $spacing-4 $spacing-2 3.25rem;

    &--account {
      font-weight: $weight-medium;
      height: 30px;

      color: $night-light-2;
    }
  }
}

.footer {
  &__success-msg {
    font-weight: $weight-bold;
    color: $earth;
    text-align: right;
  }

  &__buttons {
    margin: $spacing-6 0px $spacing-7 0px;

    &--button {
      margin-left: auto;
    }
  }
}
</style>
