import apiSoMessages from "./SoMessages"

export default {
  outboundSms(payload) {
    return apiSoMessages.post("messages/outbound_sms", payload)
  },
  fetchEmailResponses(params) {
    return apiSoMessages.get("mandrill_events?" + params)
  },
}
