<template>
  <section class="booking-confirmation">
    <div class="booking-confirmation__header">
      <div class="booking-confirmation__header-inner">
        <i class="booking-confirmation__icon icon icon-selected"></i>
        <h2 class="booking-confirmation__title">All Done</h2>
      </div>
    </div>
    <div class="booking-confirmation__body">
      <ul
        v-if="!customerPending && currentBooking"
        class="booking-confirmation__list"
      >
        <li>Booking reference: {{ currentBooking["aes-job-number"] }}</li>
        <li>Address: {{ addressFormatted }}</li>
        <li>Date: {{ appointmentDate }}</li>
        <li>Time: {{ appointmentTime }}</li>
      </ul>
      <div v-else-if="customerPending">
        <img
          class="booking-confirmation__loader"
          src="@/assets/icons/loading-bar.gif"
        />
      </div>
      <AppButton
        v-if="!customerPending && currentBooking"
        class="booking-confirmation__button"
        variant="secondary"
        @click="bookNewAppointment"
      >
        Book New Appointment
      </AppButton>
    </div>
  </section>
</template>

<script>
import dayjs from "dayjs"
import AppButton from "@soenergy/frontend-library/src/components/AppButton"
import { dateFormatMixin } from "../../mixins/dateFormatMixin"

export default {
  components: {
    AppButton,
  },
  mixins: [dateFormatMixin],
  props: {
    customerDetails: {
      type: Object,
      default: () => {},
    },
    customerPending: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    currentBooking() {
      const bookings = this.customerDetails?.bookings ?? []

      return bookings.reduce((latestBooking, currentBooking) => {
        if (!latestBooking) return currentBooking
        const currentBookingDate = dayjs(currentBooking["created-at"])
        const latestBookingDate = dayjs(latestBooking["created-at"])

        return currentBookingDate.isValid() &&
          currentBookingDate.isAfter(latestBookingDate)
          ? currentBooking
          : latestBooking
      }, null)
    },
    addressFormatted() {
      const address = this.currentBooking.address
      const postcode = this.currentBooking.postcode
      return `${address},  ${postcode}`
    },
    appointmentDate() {
      const slotStartDateTime = this.currentBooking?.["slot-start-datetime"]
      return this.appointmentDateFormatted(slotStartDateTime)
    },
    appointmentTime() {
      const slotStartDateTime = this.currentBooking?.["slot-start-datetime"]
      const slotEndDateTime = this.currentBooking?.["slot-end-datetime"]
      return this.appointmentTimeFormatted(slotStartDateTime, slotEndDateTime)
    },
  },
  created() {
    this.$emit("get-customer")
  },
  methods: {
    bookNewAppointment() {
      this.$store.dispatch("imbp/resetForm")
    },
  },
}
</script>

<style scoped lang="scss">
.booking-confirmation {
  text-align: left;
  font-size: $font-size-5;
  margin-bottom: $space-14;

  &__header {
    background-color: $earth;
  }

  &__header-inner {
    max-width: 1148px;
    margin: 0 auto;
    padding: $space-10 0;
  }

  &__body {
    max-width: 1148px;
    margin: 0 auto;
  }

  &__loader {
    margin-top: $space-14;
  }

  &__icon {
    display: inline-block;
    font-size: 5rem;
    margin-right: $space-7;
    position: relative;
    top: 10px;
  }

  &__title {
    text-transform: uppercase;
    font-weight: $weight-bold;
    font-size: $font-size-9;
    line-height: 1;
    display: inline-block;
  }

  &__list {
    margin-top: $space-14;

    li {
      margin-left: $space-8;
      margin-bottom: $space-2;
    }
  }

  &__button {
    margin-top: $space-14;
  }
}
</style>
