<template>
  <b-container fluid class="has-text-left">
    <b-row class="ml-5">
      <header-block title="Payment Adequacy Bulk Uploader"></header-block>
    </b-row>
    <page-section title="Upload current payment schedules to be reviewed">
      <template slot="content">
        <div class="bulk-uploader">
          <div class="bulk-uploader__upload">
            <p class="bulk-uploader__upload-description">
              Upload a csv of current payment schedules to be reviewed. For
              variable price changes and the Energy Bill Support Scheme.
            </p>
            <div class="upload-csv">
              <UploadCsv
                ref="bulkUploader"
                :parser="parser"
                :api-call="bulkUploadApiCall"
                input-label="Review ID"
                input-name="bulk-upload-id"
                input-error-message="Please enter Review ID"
                input-field
                v-on="$listeners"
              ></UploadCsv>
            </div>
          </div>
          <div class="bulk-uploader__status-input">
            <h4>Check the status of an existing review:</h4>
            <div class="bulk-uploader__date-selector">
              <label for="fromDate">From:</label>
              <input
                id="fromDate"
                v-model="reviewFromDate"
                type="date"
                name="fromDate"
                :min="minDate"
                :max="defaultToDate"
              />
              <label for="toDate">To:</label>
              <input
                id="toDate"
                v-model="reviewToDate"
                type="date"
                name="toDate"
                :min="minDate"
                :max="defaultToDate"
              />
            </div>
            <InputField
              v-model="reviewIdToCheck"
              name="reviewIdToCheck"
              label="Review ID"
              class="upload-csv__input"
              :error-message="hasReviewError ? reviewErrorMessage : ''"
              :has-error="hasReviewError"
              @keyup.enter="getBulkUploadStatus"
            />
            <div class="bulk-uploader__status-button">
              <button class="button button--blue" @click="getBulkUploadStatus">
                Check status
              </button>
            </div>
          </div>
          <div class="bulk-uploader__status-table">
            <table>
              <tr>
                <th>Status</th>
                <th>Count</th>
              </tr>
              <tr>
                <td>Pending</td>
                <td>{{ pendingReviews }}</td>
              </tr>
              <tr>
                <td>Skipped</td>
                <td>{{ skippedReviews }}</td>
              </tr>
              <tr>
                <td>Failed</td>
                <td>{{ failedReviews }}</td>
              </tr>
              <tr>
                <td>Completed</td>
                <td>{{ completedReviews }}</td>
              </tr>
            </table>
          </div>
        </div>
      </template>
    </page-section>

    <page-section title="Payment adequacy exclusions">
      <template slot="content">
        <p>
          Upload a list of accounts to exclude for a given payment review ID.
          Exclusions can be added or removed from a list. Check the box to
          remove exclusions.
        </p>
        <div class="upload-csv">
          <UploadCsv
            ref="exclusionUploader"
            :parser="parser"
            :api-call="exclusionApiCall"
            input-label="Review ID"
            input-name="exclusion-review-id"
            input-error-message="Please enter Review ID"
            input-field
            v-on="$listeners"
          >
            <Checkbox
              ref="deleteExclusionsCheck"
              v-model="willRemoveFromExclusionList"
              class="mb-2"
              input-id="remove-from-exclusion-list"
              label="Remove exclusions"
            />
          </UploadCsv>
        </div>
      </template>
    </page-section>

    <page-section title="Payment adequacy retries">
      <template slot="content">
        <p>
          Upload a list of retry IDs using the checkboxes to select retry
          options.
        </p>
        <div class="upload-csv">
          <UploadCsv
            ref="retriesUploader"
            :parser="parser2"
            :api-call="retriesApiCall"
            :validators="[idColumnOnly]"
            v-on="$listeners"
          >
            <Checkbox
              ref="overrideIncreaseThresholdCheck"
              v-model="overrideIncreaseThreshold"
              class="mb-2 mt-5"
              input-id="override-increase-threshold"
              label="Override increase threshold"
            />
            <Checkbox
              ref="ignoreAccountBalanceCheck"
              v-model="ignoreAccountBalance"
              class="mb-2"
              input-id="ignore-account-balance"
              label="Ignore account balance"
            />
          </UploadCsv>
        </div>
      </template>
    </page-section>

    <page-section class="mt-6" title="Download Results">
      <template slot="content">
        <p>
          <strong>
            If there is a large amount of data, this may take some time.
          </strong>
        </p>
        <h4>Download results for a given payment review ID.</h4>
        <DownloadButton
          ref="downloadDDReview"
          :api-call="downloadDDReview"
          btn-label="Download csv"
          input-label="Review ID"
          input-field
          @error="onDDReviewDownloadError"
        />
        <p v-if="errorMessage" class="error-msg">
          {{ errorMessage }}
        </p>
        <h4>Download exclusion list for a given payment review ID.</h4>
        <DownloadButton
          ref="downloadExclusionList"
          :api-call="downloadExclusionList"
          btn-label="Download csv"
          input-label="Review ID"
          input-field
          @error="onExclusionListDownloadError"
        />
        <p v-if="errorExclusionList" class="error-msg">
          {{ errorExclusionList }}
        </p>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import UploadCsv from "../../components/csv/UploadCsv.vue"
import { csvToFormData, yetAnotherCSVParseMethod } from "@/helpers/csv/parsers"
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import PaymentAdequacy from "@/api/PaymentAdequacy.js"
import DownloadButton from "@soenergy/frontend-library/src/components/DownloadButton"
import Checkbox from "@soenergy/frontend-library/src/components/Checkbox"
import InputField from "@soenergy/frontend-library/src/components/InputField"
import dayjs from "dayjs"
import { idColumnOnly } from "../../helpers/csv/validators"

export default {
  components: {
    pageSection,
    headerBlock,
    UploadCsv,
    DownloadButton,
    Checkbox,
    InputField,
  },
  data() {
    return {
      parser: csvToFormData,
      parser2: yetAnotherCSVParseMethod,
      PaymentAdequacy: PaymentAdequacy,
      errorMessage: null,
      errorExclusionList: null,
      willRemoveFromExclusionList: false,
      overrideIncreaseThreshold: false,
      ignoreAccountBalance: false,
      reviewIdToCheck: null,
      reviewsToDisplay: [],
      hasReviewError: false,
      reviewErrorMessage: "",
      reviewFromDate: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
      reviewToDate: dayjs().format("YYYY-MM-DD"),
      idColumnOnly,
    }
  },
  computed: {
    pendingReviews() {
      return this.reviewsToDisplay.reduce((overall, current) => {
        return current.status === "pending" ? overall + current.count : overall
      }, 0)
    },
    skippedReviews() {
      return this.reviewsToDisplay.reduce((overall, current) => {
        return current.status === "skipped" ? overall + current.count : overall
      }, 0)
    },
    failedReviews() {
      return this.reviewsToDisplay.reduce((overall, current) => {
        return current.status === "failed" ? overall + current.count : overall
      }, 0)
    },
    completedReviews() {
      return this.reviewsToDisplay.reduce((overall, current) => {
        return current.status === "completed"
          ? overall + current.count
          : overall
      }, 0)
    },
    minDate() {
      return dayjs().subtract(5, "year").format("YYYY-MM-DD")
    },
    defaultToDate() {
      return dayjs().format("YYYY-MM-DD")
    },
  },
  methods: {
    bulkUploadApiCall(formData, inputValue) {
      return PaymentAdequacy.bulkUploader(inputValue, formData)
    },
    getBulkUploadStatus() {
      // reset
      this.hasReviewError = false
      this.reviewErrorMessage = ""

      if (isNaN(this.reviewIdToCheck) || this.reviewIdToCheck === null) {
        this.hasReviewError = true
        this.reviewErrorMessage = "Invalid review ID. Value must be an integer"
        return
      }

      // send data
      let data = {
        from: this.reviewFromDate + " 00:00:00",
        to: this.reviewToDate + " 00:00:00",
        reviewId: this.reviewIdToCheck,
      }
      PaymentAdequacy.bulkUploaderStatus(data)
        .then((data) => {
          if (data?.data && data.data.length) {
            this.reviewsToDisplay = data.data
          } else if (data?.data && data.data.length === 0) {
            this.reviewsToDisplay = []
            this.hasReviewError = true
            this.reviewErrorMessage =
              "No status data were found for this review id"
          } else {
            throw Error
          }
        })
        .catch((error) => {
          this.reviewsToDisplay = []
          this.hasReviewError = true
          this.reviewErrorMessage = error.message
            ? error.message
            : "Unknown error"
        })
    },
    exclusionApiCall(formData, inputValue) {
      const endpoint = this.willRemoveFromExclusionList
        ? PaymentAdequacy.removeExclusionsForReviewId
        : PaymentAdequacy.addExclusionsForReviewId
      return endpoint(inputValue, formData)
    },
    retriesApiCall(data) {
      let payload = {
        ids: data,
        enableDelay: true,
        overrideIncreaseThreshold: this.overrideIncreaseThreshold,
        ignoreAccountBalance: this.ignoreAccountBalance,
      }
      return PaymentAdequacy.retriesUploader(payload)
    },
    downloadDDReview(inputValue) {
      this.errorMessage = this.resolveInputErrorMessage(inputValue)
      if (this.errorMessage) return
      return PaymentAdequacy.paymentReviewResultsCsv(Number(inputValue))
    },
    downloadExclusionList(inputValue) {
      this.errorExclusionList = this.resolveInputErrorMessage(inputValue)
      if (this.errorExclusionList) return
      return PaymentAdequacy.paymentExclusionsListCsv(Number(inputValue))
    },
    resolveInputErrorMessage(inputValue) {
      let error = null
      if (!inputValue) {
        error = "Review ID required"
      }
      const reviewId = Number(inputValue)
      if (isNaN(reviewId)) {
        error = "Invalid review ID. Value must be an integer"
      }
      return error
    },
    onDDReviewDownloadError(error) {
      this.errorMessage = this.resolveDownloadErrorMessage(error)
    },
    onExclusionListDownloadError(error) {
      this.errorExclusionList = this.resolveDownloadErrorMessage(error)
    },
    resolveDownloadErrorMessage(error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorCode === "000"
      ) {
        return "Payment review ID does not exist"
      } else {
        return "Something went wrong"
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-csv {
  background-color: $day-dark;
  max-width: 450px;
  padding: 1rem;
  border-radius: 0.25rem;
}
.error-msg {
  color: $fire;
  font-weight: $weight-medium;
}

.bulk-uploader {
  display: flex;
  flex-wrap: wrap;

  &__upload {
    min-width: 450px;
    flex: 1;
  }

  &__upload-description {
    width: 450px;
  }

  &__date-selector {
    margin-bottom: $space-5;
    display: flex;
    align-items: center;

    input {
      flex: 1 0 auto;
      padding: 10px;
      border-radius: 6px;
      border: 1px solid $grey-700;
    }

    label {
      flex: 1;
      margin: 0 $space-3 0 $space-3;

      &:first-child {
        margin: 0 $space-3 0 0;
      }
    }
  }

  &__status-input {
    max-width: 450px;
    padding: 60px 30px 0 30px;
    flex: 1;
    h4 {
      font-size: $font-size-5;
    }
  }
  &__status-button {
    text-align: right;
  }

  &__status-table {
    min-width: 469px;
    flex: 1;
    padding: 60px 0 0 0;

    th {
      background: $day-dark;
    }
    td,
    th {
      border: 1px solid $grey-600;
      padding: 10px;
    }
  }
}
</style>
