<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Payment adequacy pre-review"
        summary="We review our customers payment amount 6 months after their payment schedule has been created."
      >
        <template v-slot:description-body>
          <h5>Tool features</h5>
          <ul>
            <li>
              Shortly before a customer is due for a payment review, we email
              them to let them know the review is coming up. We ask for readings
              if needed. ‘NeedsRead’ looks for readings on all meterpoints in
              the last 90 days from today (or last page refresh).
            </li>
            <li>
              Most pre-review emails are sent automatically. Those showing in
              ARK have not yet been sent, either because they have been flagged
              for ‘Checks’ or because the overnight sending task failed.
            </li>
          </ul>
          <h5>How to use</h5>
          <p>Choose whether to ‘Send’, ‘Skip’, or ‘Delete’ each pre-review.</p>
          <ul>
            <li>
              ‘Send’ sends the pre-review email, which you can ‘Show’ to see.
            </li>
            <li>
              ‘Skip’ skips the pre-review step, the account will still be
              reviewed.
            </li>
            <li>‘Delete’ means the account will not be reviewed.</li>
          </ul>
        </template>
      </header-block>
    </b-row>

    <page-section title="">
      <template v-slot:content>
        <b-button
          size="sm"
          variant="outline-success"
          :disabled="isBusy"
          @click="importPreReview()"
        >
          <span v-show="!isBusy">Repopulate first 250 reviews</span>
          <span v-show="isBusy"><b-spinner large /></span>
        </b-button>
        <div v-if="preReview.length > 0">
          <p class="m-2">
            Total Pre-reviews waiting: {{ totalPreReviews.review_count }}
          </p>
          <p class="m-2 float-right">First {{ preReview.length }} Reviews</p>
        </div>

        <b-table
          hover
          small
          responsive
          fixed
          :busy="isBusy"
          :sort-by.sync="dataTable.sortBy"
          :sort-desc.sync="dataTable.sortDesc"
          :items="dataTable.items"
          :fields="dataTable.fields"
          :per-page="perPage"
          :current-page="currentPage"
          class="mt-5"
        >
          <template v-slot:cell(show)="row">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="fetchCompleteRecord(row.item)"
            >
              {{ row.detailsShowing ? "Hide" : "Show" }}
            </b-button>
          </template>
          <template slot="row-details" slot-scope="row">
            <b-row class="text-left">
              <b-col>
                <b-card>
                  <h6 slot="header" class="mb-0">
                    Subject: {{ row.item.subject }}
                  </h6>
                  <b-card-text>
                    <p style="text-align: left" v-html="row.item.html_body"></p>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(checks)="row">
            <div
              v-if="
                row.item.account_checks !== null &&
                row.item.account_checks.length > 0
              "
            >
              <div
                v-for="(tag, index) in row.item.account_checks"
                :key="index"
                style="display: inline-block"
              >
                <b-badge
                  style="white-space: normal; max-width: 150px; margin: 1px"
                  variant="info"
                >
                  {{ tag }}
                </b-badge>
              </div>
            </div>
          </template>
          <template v-slot:cell(current_payment_seasonal_fl)="row">
            <div v-if="row.item.current_payment_seasonal_fl === true">
              <b-icon icon="shuffle" />
            </div>
            <div v-else>
              <b-icon icon="arrow-right" />
            </div>
          </template>
          <template v-slot:cell(created_at)="row">
            {{ row.item.created_at | formattedDate }}
          </template>
          <template v-slot:cell(current_payment_amount)="row">
            <span
              :class="[
                row.item.current_payment_amount === null ? 'text-danger' : '',
              ]"
            >
              £{{ Number(row.item.current_payment_amount).toFixed(2) }}
            </span>
          </template>
          <template v-slot:cell(has_meter_readings)="row">
            <span
              v-if="row.item.has_meter_readings"
              class="badge badge-primary"
            >
              Meter Read
            </span>
            <span v-else class="badge badge-warning"> Need Read </span>
          </template>
          <template v-slot:cell(process)="row">
            <b-button
              v-show="row.item.current_payment_amount !== null"
              size="sm"
              variant="outline-success"
              @click="sendEmail(row.item)"
            >
              Send
            </b-button>
          </template>
          <template v-slot:cell(skip)="row">
            <b-button
              v-show="row.item.current_payment_amount !== null"
              size="sm"
              variant="outline-dark"
              @click="skipEmail(row.item)"
            >
              Skip
            </b-button>
          </template>
          <template v-slot:cell(delete)="row">
            <b-button
              size="sm"
              variant="outline-danger"
              @click="softdelete(row.item)"
            >
              Delete
            </b-button>
          </template>
        </b-table>

        <div v-if="preReview.length > perPage">
          <b-pagination
            v-model="currentPage"
            :total-rows="preReview.length"
            :per-page="perPage"
            aria-controls="my-table"
          >
          </b-pagination>
          <p class="mt-3">
            Page: {{ currentPage }} /
            {{ Math.ceil(preReview.length / perPage) }}
          </p>
        </div>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import Vue from "vue"
import PaymentAdequacy from "@/api/PaymentAdequacy"
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import { paymentAdequacyMixin } from "@/mixins/paymentAdequacyMixin"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

export default {
  filters: {
    formattedDate: function (date) {
      return dayjs(date).format("Do MMM YY")
    },
  },
  components: {
    headerBlock,
    pageSection,
  },
  mixins: [paymentAdequacyMixin],
  data() {
    return {
      isBusy: false,
      preReview: [],
      perPage: 20,
      currentPage: 1,
    }
  },
  computed: {
    dataTable() {
      var t = {
        sortBy: "Status",
        sortDesc: false,
        fields: [
          { key: "checks", label: "Checks", sortable: true },
          {
            key: "account.junifer_account_number",
            label: "Account Number",
            sortable: false,
          },
          {
            key: "current_payment_amount",
            label: "Current Payment",
            sortable: true,
          },
          { key: "created_at", label: "Created", sortable: true },
          { key: "current_payment_seasonal_fl", label: "", sortable: false },
          { key: "has_meter_readings", label: "", sortable: false },
          { key: "show", label: "", sortable: false },
          { key: "process", label: "", sortable: false },
          { key: "skip", label: "", sortable: false },
          { key: "delete", label: "", sortable: false },
        ],
        items: this.preReview,
      }
      return t
    },
  },
  created() {
    // this.setupStream();
    this.importPreReview()
  },
  channels: {
    PaymentAdequacyChannel: {
      connected() {},
      received(data) {
        var index = this.preReview.findIndex((s) => s.id == data.id)
        delete data.id
        if (
          (data.deleted_fl !== undefined && data.deleted_fl === true) ||
          data.pre_review_status === true
        ) {
          this.preReview.splice(index, 1)
        } else {
          for (const [key, value] of Object.entries(data)) {
            this.preReview[index][key] = value
          }
        }
      },
    },
  },
  methods: {
    setupStream() {
      this.$cable.subscribe({
        channel: "PaymentAdequacyChannel",
      })
    },
    importPreReview() {
      this.isBusy = true
      PaymentAdequacy.importPreReview()
        .then((response) => {
          response.data.forEach(function (x) {
            x._showDetails = false
          })
          console.log(response.data)
          this.totalPreReviews = response.data.pop()
          console.log(this.totalPreReviews)
          this.preReview = response.data
          this.isBusy = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    skipEmail(row) {
      this.isBusy = true
      PaymentAdequacy.skipPreReview(row.id)
        .then(() => {
          var index = this.preReview.findIndex((s) => s.id == row.id)
          this.preReview.splice(index, 1)
          this.isBusy = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchCompleteRecord(data) {
      PaymentAdequacy.getPreReviewEmail(data.id).then((response) => {
        var index = this.preReview.findIndex((s) => s.id == data.id)
        var obj = { ...this.preReview[index], ...response.data.payload }
        obj._showDetails = !obj._showDetails
        Vue.set(this.preReview, index, obj)
      })
    },
    softdelete(row) {
      PaymentAdequacy.softDelete(row.id)
        .then(() => {
          var index = this.preReview.findIndex((s) => s.id == row.id)
          this.preReview.splice(index, 1)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    sendEmail(row) {
      this.isBusy = true
      PaymentAdequacy.emailPreReview(row.id, true)
        .then(() => {
          var index = this.preReview.findIndex((s) => s.id == row.id)
          this.preReview.splice(index, 1)
          this.isBusy = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
