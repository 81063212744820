import Freshdesk from "@/api/Freshdesk"

const state = {
  results: [],
  subresults: [],
  selectedFile: [],
  freshdeskTicketOptions: [
    { value: 2, text: "Open" },
    { value: 3, text: "Pending" },
    { value: 4, text: "Resolved" },
    { value: 5, text: "Closed" },
    { value: 6, text: "Waiting on Customer" },
    { value: 7, text: "Waiting on Third Party" },
  ],
  ticketFields: [],
  ticketSubject: [
    {
      value: "Change of Tenancy",
      text: "Change of Tenancy",
      subTypes: [
        "Deceased customer",
        "Landlord /letting agency",
        "Moving out",
        "New Occupier",
        "Other",
      ],
    },
    {
      value: "Contact Details",
      text: "Contact Details",
      subTypes: [],
    },
    {
      value: "Erroneous Transfers",
      text: "Erroneous Transfers",
      subTypes: ["Gain initiated by us", "Gain initiated by other supplier"],
    },
    {
      value: "Feedback/Thanks",
      text: "Feedback/Thanks",
      subTypes: [],
    },
    {
      value: "Meter Reads",
      text: "Meter Reads",
      subTypes: [
        "Disputed Reads",
        "Current meter reads",
        "How to read meter",
        "Opening meter reads",
        "Other",
      ],
    },
    {
      value: "Metering",
      text: "Metering",
      subTypes: ["Smart meters", "Faulty", "Not Faulty", "Other"],
    },
    { value: " No Bill", text: " No Bill", subTypes: [] },
    {
      value: "Payments",
      text: "Payments",
      subTypes: [
        "Cancelled Direct Debit",
        "Direct Debit Review",
        "Dunning",
        "Failed Payment",
        "Final Bill Collection",
        "Refund",
        "Seasonal / Equal enquiry",
        "Other /no action",
        "Worldpay",
        "Change date /amount /details",
      ],
    },
    {
      value: "Registrations",
      text: "Registrations",
      subTypes: [
        "Cancellation",
        "Multiple property enrolment",
        "New Customer / Quote",
        "Objection",
        "Other",
      ],
    },
    {
      value: "Renewal",
      text: "Renewal",
      subTypes: ["Discussion", "Manual quote", "Other"],
    },
    {
      value: "Statement discussion",
      text: "Statement discussion",
      subTypes: [],
    },
    {
      value: "Switching away from So",
      text: "Switching away from So",
      subTypes: [],
    },
    { value: "Tariffs", text: "Tariffs", subTypes: [] },
    {
      value: "Website",
      text: "Website",
      subTypes: ["Error", "Log in issue", "Other"],
    },
    {
      value: "Other",
      text: "Other",
      subTypes: [
        "About So Energy",
        "Emergency /power loss",
        "Green/renewables",
        "Homeserve",
        "Refer a friend",
        "Vulnerable (PSR)/Warm Home Discount/other schemes",
        "Junk/Auto-reply/Failed call, etc",
      ],
    },
    { value: "Industry Party", text: "Industry Party", subTypes: [] },
    { value: "Left voicemail", text: "Left voicemail", subTypes: [] },
    { value: "Did not answer", text: "Did not answer", subTypes: [] },
  ],
}

const mutations = {
  SET_FRESHDESK(state, data) {
    state.results = data
  },
  SET_FRESHDESK_GROUP(state, data) {
    state.subresults = data
  },
  SET_FRESHDESK_SELECTED(state, data) {
    state.selectedFile = data
  },
  DELETE_FRESHDESK_TICKET(state, data) {
    var index = state.subresults.findIndex((s) => s.id == data)
    state.subresults.splice(index, 1)
  },
  SET_TICKET_FIELDS(state, data) {
    state.ticketFields = data
  },
}

const getters = {
  groupCategories: (state) => {
    return state.results
  },
  selectedGroup: (state) => {
    return state.selectedFile
  },
  groupTickets: (state) => {
    return state.subresults
  },
  groupTicketStatus: (state) => {
    return state.freshdeskTicketOptions
  },
  ticketFields: (state) => {
    return state.ticketFields
  },
  ticketSubjectTypes: (state) => {
    return state.ticketSubject
  },
}

const setters = {}

const actions = {
  getFD({ commit }) {
    return Freshdesk.getGroups()
      .then((response) => {
        var arr = []
        response.data.forEach(function (x) {
          var z = {}
          z.text = x.name
          z.value = x.id
          arr.push(z)
        })
        commit("SET_FRESHDESK", arr)
      })
      .catch(() => {})
  },
  getTicketFields({ commit }) {
    Freshdesk.getTicketFields()
      .then((response) => {
        commit("SET_TICKET_FIELDS", response.data)
      })
      .catch(() => {})
  },
  getOpenGroupTickets(context, result) {
    const index = state.results.findIndex((s) => s.value == result)
    context.commit("SET_FRESHDESK_SELECTED", state.results[index])
    Freshdesk.getGroupTickets(result)
      .then((response) => {
        var data = response.data.results
        var numLoops = Math.ceil(response.data.total / 30)
        var promises = []
        if (response.data.total > 30) {
          for (var page = 2; page <= Math.min(numLoops, 10); page++) {
            promises.push(
              Freshdesk.getGroupTicketsPages(result, page)
                .then((pageresponse) => {
                  pageresponse.data.results.forEach(function (e) {
                    data.push(e)
                  })
                })
                .catch(() => {})
            )
          }
        }

        Promise.all(promises).then(() => {
          data.forEach(function (e) {
            Freshdesk.getTicketConversations(e.id).then((ticketResponse) => {
              e.conversations = ticketResponse.data
            })
          })

          const sortedData = data.sort(function (a, b) {
            var dateA = new Date(a.updated_at),
              dateB = new Date(b.updated_at)
            return dateA - dateB
          })
          var newArr = {
            total: response.data.total,
            results: sortedData,
          }
          context.commit("SET_FRESHDESK_GROUP", newArr)
        })
      })
      .catch(() => {})
  },
  reply(context, review) {
    Freshdesk.reply(review)
      .then((response) => {
        var index = this.subresults.indexOf(review)
        context.commit(this.subresults, index, response.data)
      })
      .catch(() => {})
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  setters,
  state,
}
