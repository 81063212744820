import Trading from "../../api/Trading.js"

const state = {
  alps: [],
  tradingBusy: true,
}
const mutations = {
  SetALPs(state, data) {
    state.alps = data
  },
  SetTradingBusy(state, data) {
    state.tradingBusy = data
  },
}

const getters = {
  ALPs: (state) => {
    return state.alps
  },
  TradingBusy: (state) => {
    return state.tradingBusy
  },
}

const setters = {}
const actions = {
  getALPs(context) {
    Trading.getALPs()
      .then((response) => {
        context.commit("SetALPs", response.data)
        context.commit("SetTradingBusy", false)
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  setters,
  state,
}
