const EnrolmentType = {
  NEW_CUSTOMER: "NEW_CUSTOMER",
  CHANGE_OF_TENANCY: "CHANGE_OF_TENANCY",
}

const FuelType = {
  ELECTRICITY: "ELECTRICITY",
  GAS: "GAS",
}

const BillingMethodMapping = {
  ebilling: "ELECTRONIC",
  paper: "PAPER",
  both: "BOTH",
}

const serializeContact = (contact) => ({
  title: contact.title,
  firstName: contact.forename,
  lastName: contact.surname,
  email: contact.email,
  phone: contact.phone1,
  birthdate: contact.dateOfBirth,
  primaryContact: true,
  communicationMethod:
    BillingMethodMapping[contact.billingMethod.toLowerCase()],
  address: {
    address1: contact.address.address1,
    address2: contact.address.address2,
    address3: contact.address.address3,
    postcode: contact.address.postcode,
    countryCode: contact.address.countryCode,
    careOf: contact.address.careOf,
  },
})

const serializeProduct = (product, estimate, type) => ({
  code: product.productCode,
  meterPointIdentifiers: product.meterPoints.map(
    (meterpoint) => meterpoint.core
  ),
  type,
  consumptionEstimation: {
    consumption: estimate.consumption,
    estimatedCost: estimate.estimatedCost,
    period: estimate.period.toUpperCase(),
    e7DaytimeConsumptionProportion: estimate.e7DaytimeConsumptionProportion,
  },
})

export default (rawData) => {
  const products = []

  if (rawData.electricityProduct) {
    products.push(
      serializeProduct(
        rawData.electricityProduct,
        rawData.electricityEstimate,
        FuelType.ELECTRICITY
      )
    )
  }

  if (rawData.gasProduct) {
    products.push(
      serializeProduct(rawData.gasProduct, rawData.gasEstimate, FuelType.GAS)
    )
  }

  return {
    supplyAddress: [
      {
        address1: rawData.supplyAddress.address1,
        address2: rawData.supplyAddress.address2,
        address3: rawData.supplyAddress.address3,
        postcode: rawData.supplyAddress.postcode,
        countryCode: rawData.supplyAddress.countryCode,
        careOf: rawData.careOf,
      },
    ],
    paymentMethod: {
      type: "DIRECT_DEBIT",
      paymentDayOfMonth: rawData.directDebitInfo.regularPaymentDayOfMonth,
      paymentAmount: rawData.directDebitInfo.regularPaymentAmount,
      bankAccountName: rawData.directDebitInfo.bankAccountName,
      bankAccountNumber: rawData.directDebitInfo.bankAccountNumber,
      bankAccountSortCode: rawData.directDebitInfo.bankAccountSortCode,
      seasonalPayment: rawData.directDebitInfo.seasonalPaymentFl,
    },
    type: rawData.changeOfTenancyFl
      ? EnrolmentType.CHANGE_OF_TENANCY
      : EnrolmentType.NEW_CUSTOMER,
    contacts: rawData.contacts.map(serializeContact),
    broker: rawData.senderReference,
    marketingOptedIn: !rawData.marketingOptOutFl,
    users: [],
    quoteId: null,
    products,
  }
}
