export function checks(e) {
  try {
    //Add tags
    e.notOkToSend = false
    e.psr = false
    e.tags = []
    var z, x

    if (e.account_checks !== undefined && e.account_checks !== null) {
      e.account_checks.forEach(function (x) {
        if (
          x.includes("New Schedule in place") ||
          x.includes("Under Review") ||
          x.includes("Account Terminating") ||
          x.includes("Account Closed") ||
          x.includes("No Active Mandate")
        ) {
          z = { text: x, class: "danger" }
          e.notOkToSend = true
        } else if (x.includes("PSR")) {
          z = { text: x, class: "info" }
          e.psr = true
          if (!e.has_meter_readings) {
            //If customer is on PSR but no reading, flag it
            e.notOkToSend = true
          }
        } else {
          z = { text: x, class: "warning" }
        }
        e.tags.push(z)
      })
    }

    // TEMPORARILY REMOVED WHILE RB SYSopen bug is in the system !!!
    //If theres an error
    if (
      e.error_reason !== null &&
      !e.error_reason.includes("Failed - No such file or directory")
    ) {
      z = { text: "Error", class: "danger" }
      e.tags.push(z)
      e.notOkToSend = true
    }

    //Big difference between junifer annual projection and annual cost
    var ppDiffAbs = Math.abs(
      (e.junifer_personal_projection / e.current_tariff_annual_cost) * 100
    )
    if (ppDiffAbs > 250 || ppDiffAbs < 50) {
      z = {
        text:
          "PP £" +
          e.junifer_personal_projection +
          " Annual Cost £" +
          e.current_tariff_annual_cost,
        class: "warning",
      }
      e.tags.push(z)
      e.notOkToSend = true
    }

    if (Math.abs(e.unbilled_readings_cost) > 200) {
      x = { text: "Unbilled > £200", class: "warning" }
      e.tags.push(x)
      e.notOkToSend = true
    }

    if (Number(e.junifer_personal_projection) === 0) {
      x = { text: "Personal Projection = £0", class: "danger" }
      e.tags.push(x)
      e.notOkToSend = true
    }

    if (Number(e.current_payment_amount) === 0) {
      x = { text: "Current Payment = £0", class: "danger" }
      e.tags.push(x)
      e.notOkToSend = true
    }

    if (e.tickets !== undefined && e.tickets !== null && e.tickets.length > 0) {
      e.tickets.forEach(function (x) {
        z = { text: "Junifer Ticket: " + x.summary, class: "success" }
        e.notOkToSend = true
        e.tags.push(z)
      })
    }

    //Flag if payment % increase is greater than x3 or more than 50% reduction
    var perChg = e.perc_change
    if ((perChg >= 300 || perChg < -50) && !e.notOkToSend) {
      x = { text: Math.round(perChg) + "% payment ", class: "warning" }
      e.notOkToSend = true
      e.tags.push(x)
    }

    if (e.current_payment_amount === 0) {
      x = { text: "£0 being reviewed", class: "danger" }
      e.notOkToSend = true
      e.tags.push(x)
    }

    //Address when we should increase payments and refund as well.
    if (perChg > 0 && e.refund > 0 && e.has_meter_readings) {
      x = { text: "Increase & Refund", class: "warning" }
      e.notOkToSend = true
      var curr12MonBal = e.current_payment_amount * 12
      var sugg12MonBal =
        Number(e.suggested_payment_amount) * 12 +
        Number(e.account_balance) +
        Number(e.unbilled_readings_cost)
      var newPayment = Math.round(
        Number(e.current_payment_amount) + (sugg12MonBal - curr12MonBal) / 12
      )
      if (e.current_payment_amount > newPayment) {
        e.suggested_payment_amount = Math.round(e.current_payment_amount)
      } else {
        e.suggested_payment_amount = newPayment
      }
      e.tags.push(x)
    }

    if (e.refund > 500 && e.has_meter_readings) {
      x = { text: "Large refund", class: "warning" }
      e.notOkToSend = true
      e.tags.push(x)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
