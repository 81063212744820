<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Resubmitting rejected Junifer enrolments"
        summary="Allows users to edit rejected registration attempts and resubmit."
        description=""
      ></header-block>
    </b-row>

    <b-row class="ml-5 mr-3 mb-3">
      <b-card bg-variant="light" style="width: 100%">
        <b-modal v-model="modalShow" hide-footer title="Junifer">
          {{ modalDescription }}
        </b-modal>

        <b-input-group>
          <b-form-select v-model="brokerCode" :options="brokerCodes">
            <template v-slot:first>
              <option :value="null" disabled>
                -- Please select an option --
              </option>
            </template>
          </b-form-select>
          <b-form-input v-model="searchFromDate" type="date" />
          <b-form-input v-model="searchToDate" type="date" />
          <b-input-group-append>
            <b-button
              :disabled="
                isBusy === true ||
                brokerCode === null ||
                searchFromDate === null
              "
              variant="outline-primary"
              @click="search()"
            >
              <div v-if="isBusy === true">
                <span><b-spinner small /></span>
              </div>
              <div v-else>Search &#8250;</div>
            </b-button>
            <b-button
              :disabled="isBusy === true"
              variant="outline-danger"
              @click="
                ;(brokerCode = null),
                  (searchToDate = null),
                  (searchFromDate = null),
                  (results = [])
              "
              >Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <h4 v-if="results" class="mt-3" style="text-align: left">
          Count: {{ results.length }}
        </h4>
        <div v-show="results && results.length !== 0">
          <b-table
            hover
            small
            responsive
            :items="results"
            :fields="resultsFields"
          >
            <template v-slot:cell(error_message)="row">
              {{ row.item.message }}
            </template>

            <template v-slot:cell(submitted_dttm)="row">
              {{ row.item.submittedDttm | formattedDate }}
            </template>

            <template v-slot:cell(meterpoints)="row">
              <div v-for="meter in row.item.meterPoints" :key="meter">
                {{ meter }}
              </div>
            </template>

            <template v-slot:cell(show_details)="row">
              <b-button
                ref="show-btn"
                size="sm"
                class="mr-2"
                variant="outline-primary"
                @click="row.toggleDetails"
              >
                {{ row.detailsShowing ? "Hide" : "Show" }}
              </b-button>
            </template>

            <template slot="row-details" slot-scope="row">
              <b-card>
                <b-button
                  ref="register-btn"
                  size="lg float-right"
                  variant="success"
                  :disabled="row.item.completedFl"
                  @click="registerSubmit(row.item)"
                  >Re-register</b-button
                >

                <b-button
                  size="lg"
                  class="mr-2"
                  variant="outline-info"
                  @click="editable(row)"
                >
                  {{ row.item.editable ? "Save" : "Edit" }}
                </b-button>

                <div
                  v-for="(value, key, index) in row.item.submittedRawString"
                  :key="index"
                >
                  <table class="submission">
                    <tr
                      v-if="
                        key !== 'directDebitInfo' &&
                        key !== 'contacts' &&
                        key !== 'supplyAddress' &&
                        key !== 'electricityProduct' &&
                        key !== 'gasProduct'
                      "
                    >
                      <td class="submission__field">{{ key }}</td>
                      <td v-if="typeof value === 'object'">
                        <table>
                          <tr v-for="(v, k, i) in value" :key="i">
                            <td>{{ k }}</td>
                            <td>{{ v }}</td>
                          </tr>
                        </table>
                      </td>
                      <td v-else-if="typeof value === 'array'">
                        <table>
                          <tr v-for="(v, i) in value" :key="i">
                            <td>{{ v }}</td>
                          </tr>
                        </table>
                      </td>
                      <td v-else>
                        {{ value }}
                      </td>
                    </tr>
                    <tr v-else-if="key === 'contacts'">
                      <td class="submission__field">{{ key }}</td>
                      <td>
                        <div v-for="(val, ind) in value" :key="ind">
                          <table>
                            <tr v-for="(v, k) in val" :key="k">
                              <td>{{ k }}</td>
                              <td v-if="typeof v === 'object'">
                                <table>
                                  <tr v-for="(a, b, i) in v" :key="i">
                                    <td>{{ b }}</td>
                                    <td>{{ a }}</td>
                                  </tr>
                                </table>
                              </td>
                              <td v-else>{{ v }}</td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                    <tr v-else-if="key === 'electricityProduct'">
                      <td class="submission__field">Electrcitiy</td>
                      <td>
                        <table>
                          <tr>
                            <td>Previous Supplier (tariff)</td>
                            <td>
                              {{ value.previousSupplier }} ({{
                                value.previousTariff
                              }})
                            </td>
                          </tr>
                          <tr>
                            <td>So Energy Product Code</td>
                            <td>
                              <input
                                v-model="value.productCode"
                                :disabled="!row.item.editable"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>meterPoints</td>
                            <td>
                              <div v-for="(k, i) in value.meterPoints" :key="i">
                                MPAN:
                                <input
                                  v-model="k.core"
                                  :disabled="!row.item.editable"
                                />
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr v-else-if="key === 'gasProduct'">
                      <td class="submission__field">Gas</td>
                      <td>
                        <table>
                          <tr>
                            <td>Previous Supplier (tariff)</td>
                            <td>
                              {{ value.previousSupplier }} ({{
                                value.previousTariff
                              }})
                            </td>
                          </tr>
                          <tr>
                            <td>So Energy Product Code</td>
                            <td>{{ value.productCode }}</td>
                          </tr>
                          <tr>
                            <td>meterPoints</td>
                            <td>
                              <div v-for="(k, i) in value.meterPoints" :key="i">
                                MPRN:
                                <input
                                  v-model="k.core"
                                  :disabled="!row.item.editable"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>domesticLargeSupplyPointFl</td>
                            <td>{{ value.domesticLargeSupplyPointFl }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr v-else-if="key === 'supplyAddress'">
                      <td class="submission__field">{{ key }}</td>
                      <td>
                        <table>
                          <tr>
                            <td>careOf</td>
                            <td>{{ value.careOf }}</td>
                          </tr>
                          <tr>
                            <td>address1</td>
                            <td>{{ value.address1 }}</td>
                          </tr>
                          <tr>
                            <td>address2</td>
                            <td>{{ value.address2 }}</td>
                          </tr>
                          <tr>
                            <td>address3</td>
                            <td>{{ value.address3 }}</td>
                          </tr>
                          <tr>
                            <td>town</td>
                            <td>{{ value.town }}</td>
                          </tr>
                          <tr>
                            <td>county</td>
                            <td>{{ value.county }}</td>
                          </tr>
                          <tr>
                            <td>postcode</td>
                            <td>
                              <input
                                v-model="value.postcode"
                                :disabled="!row.item.editable"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>countryCode</td>
                            <td>{{ value.countryCode }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr v-else-if="key === 'directDebitInfo'">
                      <td class="submission__field">{{ key }}</td>
                      <td>
                        <table>
                          <tr>
                            <td>regularPaymentAmount</td>
                            <td>{{ value.regularPaymentAmount }}</td>
                          </tr>
                          <tr>
                            <td>regularPaymentDayOfMonth</td>
                            <td>{{ value.regularPaymentDayOfMonth }}</td>
                          </tr>
                          <tr>
                            <td>seasonalPaymentFl</td>
                            <td>{{ value.seasonalPaymentFl }}</td>
                          </tr>
                          <tr>
                            <td>bankAccountSortCode</td>
                            <td>
                              {{
                                value.bankAccountSortCode.substring(0, 2)
                              }}-XX-XX
                            </td>
                          </tr>
                          <tr>
                            <td>bankAccountNumber</td>
                            <td>
                              {{
                                value.bankAccountNumber.substring(0, 3)
                              }}XXXXXX
                            </td>
                          </tr>
                          <tr>
                            <td>bankAccountName</td>
                            <td>{{ value.bankAccountName }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>

                <b-button size="sm" @click="row.toggleDetails">
                  Hide Details
                </b-button>
              </b-card>
            </template>
          </b-table>
        </div>
      </b-card>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Registrations from "@/api/Registrations.js"
import dayjs from "dayjs"
import headerBlock from "@/components/header"
import advancedFormat from "dayjs/plugin/advancedFormat"
import serializeEnrolmentData from "@/helpers/enrolments/serializeEnrolmentData"
dayjs.extend(advancedFormat)

export default {
  data() {
    return {
      brokerCodes: [
        { value: "USW", text: "uSwitch" },
        { value: "MSM", text: "Money Supermarket" },
        { value: "MUG", text: "My Utility Genius" },
        { value: "MUG_BCS", text: "Big Clean Switch" },
        { value: "ESB_SO", text: "ESB" },
        { value: "SoEnergyQuotePortal", text: "So Energy Website" },
      ],
      isBusy: false,
      brokerCode: null,
      searchFromDate: null,
      searchToDate: null,
      modalShow: false,
      modalDescription: null,
      results: null,
      resultsFields: [
        "customerReference",
        "meterpoints",
        "error_message",
        "submitted_dttm",
        "show_details",
      ],
    }
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions([]),
    registerSubmit(data) {
      var index = this.results.findIndex(
        (s) => s.customerReference == data.customerReference
      )
      var submittedRawString = this.results[index].submittedRawString

      this.results[index].completedFl = true
      Registrations.submitEnrolment(serializeEnrolmentData(submittedRawString))
        .then((response) => {
          this.modalShow = true
          this.modalDescription = "Success!! Enrolement submitted"
          var index = this.results.findIndex(
            (s) => s.customerReference == data.customerReference
          )
          this.results[index].completedFl = true
        })
        .catch((error) => {
          this.results[index].completedFl = false
          this.modalShow = true
          const errorMessage = error?.response?.data?.message || error.message
          this.modalDescription = `Error occurred: ${errorMessage}`
          throw error
        })
    },
    editable(data) {
      data.item.editable = !data.item.editable
      var index = this.results.findIndex(
        (s) => s.customerReference == data.item.customerReference
      )
      this.results[index] = data.item
      console.log(this.results[index])
    },
    search() {
      this.isBusy = true
      if (this.searchToDate === null) {
        var today = new Date()
        var dd = String(today.getDate()).padStart(2, "0")
        var mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
        var yyyy = today.getFullYear()
        this.searchToDate = yyyy + "-" + mm + "-" + dd
      }
      var data = {
        broker: this.brokerCode,
        from: this.searchFromDate,
        to: this.searchToDate,
      }
      Registrations.searchUKDomesticEnrolment(data)
        .then((response) => {
          console.log(response.data)
          response.data.forEach(function (x) {
            x.submittedRawString = JSON.parse(x.submittedRawString)
            x.completedFl = false
            x.editable = false
          })
          this.results = response.data.slice()
          this.isBusy = false
        })
        .catch((error) => {
          console.log(error)
          this.isBusy = false
        })
    },
  },
  filters: {
    formattedDate: function (date) {
      return dayjs(date).format("Do MMM YY HH:mm")
    },
  },
  components: { headerBlock },
}
</script>

<style lang="scss" scoped>
::v-deep .submission {
  text-align: left;

  &__field {
    min-width: $width-2;
    font-weight: $weight-medium;
  }

  td:last-child {
    width: 100%;
  }
}
</style>
