var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"dashboard"},[_c('tr',[_c('td',{staticClass:"dashboard-col",class:{
          'blue ': _vm.countBoxOne !== 0,
          'outline-blue': _vm.countBoxOne === 0,
        }},[_c('h2',[_vm._v(_vm._s(_vm.titleBoxOne))]),_c('h2',[(_vm.countBoxOne !== 0)?_c('span',[_vm._v(" "+_vm._s(_vm.countBoxOne)+" ")]):_c('span',[_vm._v(" ... ")])]),_vm._t("box-one-slot")],2),_c('td',{staticClass:"dashboard-col",class:{
          'red ': _vm.countBoxTwo !== 0,
          'outline-blue': _vm.countBoxTwo === 0,
        }},[_c('h2',[_vm._v(_vm._s(_vm.titleBoxTwo))]),_c('h2',[(_vm.countBoxTwo !== 0)?_c('span',[_vm._v(" "+_vm._s(_vm.countBoxTwo)+" ")]):_c('span',[_vm._v(" ... ")])]),_vm._t("box-two-slot")],2),_c('td',{staticClass:"dashboard-col",class:{
          'light-blue': _vm.countBoxThree !== 0,
          'outline-blue': _vm.countBoxThree === 0,
        }},[_c('h2',[_vm._v(_vm._s(_vm.titleBoxThree))]),_c('h2',[(_vm.countBoxThree !== 0)?_c('span',[_vm._v(" "+_vm._s(_vm.countBoxThree)+" ")]):_c('span',[_vm._v(" ... ")])]),_vm._t("box-three-slot")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }