import Billing from "@/api/JuniferBilling.js"
import { billingChecks } from "@/helpers/billing/billingChecks"
import { billingTemplates } from "@/helpers/billing/billingTemplates"
import Ticket from "@/api/JuniferTickets.js"

const titleize = require("titleize")

const state = {
  billValueChecks: [],
  billValueResultsIsBusy: false,
}

const mutations = {
  SET_BILL_VALUE_DATA(state, data) {
    state.billValueChecks = data
  },
  SET_BILL_VALUE_ROW(state, data) {
    var index = state.billValueChecks.indexOf(data.item)
    state.billValueChecks[index] = data.item
  },
  SET_BILL_VALUE_STATE(state, data) {
    state.billValueResultsIsBusy = data
  },
  DELETE_ROW(state, billId) {
    var index = state.billValueChecks.findIndex((s) => s.billId == billId)
    state.billValueChecks.splice(index, 1)
  },
}

const getters = {
  billValue: (state) => {
    return state.billValueChecks
  },
  billValueTable: (state) => {
    return {
      sortBy: "Status",
      sortDesc: false,
      fields: [
        { key: "check_box", label: "", sortable: false },
        { key: "tickets", label: "Ticket Count", sortable: false },
        { key: "dirtyBill", label: "", sortable: false },
        { key: "bill_created_date", label: "Created", sortable: false },
        { key: "billNumber", label: "Bill", sortable: false },
        { key: "finalBillFlag", label: "", sortable: false },
        { key: "bill_flag_type", label: "Exeception", sortable: false },
        { key: "billStatus", label: "Status", sortable: false },
        { key: "billValue", label: "Value", sortable: true },
        { key: "accountBalance", label: "Account Balance", sortable: false },
        { key: "accountNumber", label: "Account Number", sortable: false },
        { key: "customerName", label: "Customer", sortable: false },
        { key: "first_name", label: "", sortable: false },
        { key: "show_details", label: "", sortable: false },
        { key: "send_email", label: "", sortable: false },
        { key: "delete", label: "", sortable: false },
      ],
      items: state.billValueChecks,
    }
  },
  billValueIsBusy: (state) => {
    return state.billValueResultsIsBusy
  },
}

const setters = {}
const actions = {
  getAllBillValueChecks(context) {
    context.commit("SET_BILL_VALUE_STATE", true)
    Billing.billValueChecks()
      .then((response) => {
        response.data.forEach(function (e) {
          e.clicked = false
          e.edited = false
          e.disabled = false
          e.templateContent = null
          e.sendEmailFl = true
          e.accountTicket = true
          e.ticketContent = `Ticket raised, email sent to customer.`
          e.template = null
          e.replyContent = null
          e.completedFl = false
          e.firstName = titleize(`${e.firstName}`)
          e.tags = []

          billingChecks(e)
          billingTemplates(e)

          if (e.tags.length !== 0) {
            e.clicked = true
          }
        })
        console.log(response.data)
        context.commit("SET_BILL_VALUE_DATA", response.data)
        context.commit("SET_BILL_VALUE_STATE", false)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  importBillValueTickets(context) {
    context.commit("SET_BILL_VALUE_STATE", true)
    Billing.importBillValueTickets()
      .then((response) => {
        context.dispatch("getAllBillValueChecks")
      })
      .catch((error) => {
        console.log("error")
      })
  },
  sendBillValueEmail(context, data) {
    Billing.sendBillValueEmail(data.item.id, data.item)
      .then((response) => {
        response.data.completedFl = true
        context.commit("SET_BILL_VALUE_ROW", response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  acceptBill(context, data) {
    Billing.acceptBill(data.billId)
      .then((response) => {
        context.commit("DELETE_ROW", data.billId)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  closeBillValueTicket(context, data) {
    if (Array.isArray(data.ticket_id)) {
      data.ticket_id.forEach(function (x) {
        Ticket.closeTicket(x, "")
      })
    } else {
      Ticket.closeTicket(data.ticket_id, "")
    }
    context.commit("DELETE_ROW", data.billId)
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  setters,
  state,
}
