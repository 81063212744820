<template>
    <b-container fluid>
      <b-row class="ml-5">
        <header-block
          title="List Smart Meter Readings"
          summary="Allows users to list meter readings for a particular meterpoint"
          description=""
        ></header-block>
      </b-row>
  
      <b-row class="ml-5 mr-3 mb-3">
        <b-card bg-variant="light" style="width: 100%">
          <b-modal v-model="modalShow" hide-footer title="Junifer">
            {{ modalDescription }}
          </b-modal>
  
          <b-input-group>
            <b-form-input v-model="searchMPXN" type="text" placeholder="MPXN"/>
            <b-form-input v-model="searchFromDate" type="date" />
            <b-form-input v-model="searchToDate" type="date" />
            <b-input-group-append>
              <b-button
                :disabled="
                  isBusy ||
                  (searchMPXN === null && searchFromDate === null)
                "
                variant="outline-primary"
                @click="search()"
              >
                <div v-if="isBusy">
                  <span><b-spinner small /></span>
                </div>
                <div v-else>Search &#8250;</div>
              </b-button>
              <b-button
                :disabled="isBusy"
                variant="outline-danger"
                @click="resetInputs()"
                >Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
  
          <div v-show="results.length !== 0">
            <h4 class="mt-5" style="text-align: left">
              Count: {{ results.length }}
            </h4>
            <b-table
              hover
              small
              responsive
              show-empty
              :items="results"
              :fields="resultsFields"
            >
              <template v-slot:cell(error_message)="row">
                {{ row.item.message }}
              </template>
  
              <template v-slot:cell(date)="row">
                {{ row.item.date | formattedDate }}
              </template>
  
              <template v-slot:cell(reading)="row">
                {{ row.item.reading }}
              </template>
            </b-table>
          </div>
        </b-card>
      </b-row>
    </b-container>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex"
  import MeterReadingsApi from "@/api/SmartMeterReadings.js"
  import dayjs from "dayjs"
  import headerBlock from "@/components/header"
  import advancedFormat from "dayjs/plugin/advancedFormat"
  dayjs.extend(advancedFormat)
  
  export default {
    data() {
      return {
        isBusy: false,
        searchMPXN: null,
        searchFromDate: null,
        searchToDate: null,
        modalShow: false,
        modalDescription: null,
        results: [],
        resultsFields: [
            { key: 'date', label: 'Date', sortable: true, class: 'text-center' },
            { key: 'reading', label: 'Reading', class: 'text-center' },
            { key: 'meter_serial_number', label: 'Meter Serial Number', sortable: true, class: 'text-center' },
            { key: 'register_id', label: 'Register', sortable: true, class: 'text-center' },
        ],
      }
    },
    computed: {
      ...mapGetters([]),
    },
    methods: {
      ...mapActions([]),
      resetInputs() {
        this.searchMPXN = null
        this.searchToDate = null
        this.searchFromDate = null
        this.results = []
      },
      search() {
        this.isBusy = true
        if (this.searchToDate === null) {
          this.searchToDate = dayjs(new Date()).format('YYYY-MM-DD')
        }
        var data = {
          mpxn: this.searchMPXN,
          from: this.searchFromDate,
          to: this.searchToDate,
        }
        MeterReadingsApi.fetchMeterReadings(data.mpxn, data.from, data.to)
          .then((response) => {
            if (response.data.status === 404) {
                this.searchError = response.data.message
            } else {
                this.results = response.data.account.readings
                this.isBusy = false
            }
          })
          .catch((error) => { 
            if (error.response.status !== 404) {
                console.log(error)
                this.isBusy = false
                throw error
            }
          })
      },
    },
    filters: {
      formattedDate: function (date) {
        return dayjs(date).format("Do MMM YY HH:mm")
      },
    },
    components: { headerBlock },
  }
  </script>
  