import apiArk from "./Ark"
import apiV2 from "./ApiV2"

export default {
  // Enrolment
  searchUKDomesticEnrolment(payload) {
    return apiArk.post("enrolment_rejections/lookup", payload)
  },
  submitEnrolment(enrolmentData) {
    return apiV2.post("/enrolments", enrolmentData)
  },

  // Junifer Rejected
  rejectedRegistrations() {
    return apiArk.get("enrolment_rejections/all")
  },
  rejectedRegistrationSendEmail(id, payload) {
    return apiArk.post("enrolment_rejections/" + id + "/send_email", payload)
  },
  rejectedRegistrationSendAllEmail(payload) {
    return apiArk.post("enrolment_rejections/multi_send_email", payload)
  },
  rejectedRegistrationSoftDelete(id) {
    return apiArk.delete("enrolment_rejections/" + id)
  },

  // Industry rejections
  industryRejections() {
    return apiArk.get("industry_rejections")
  },
  importIndustryRejections() {
    return apiArk.get("industry_rejections/import")
  },
  sendIndRejEmail(id, payload) {
    return apiArk.post("industry_rejections/" + id + "/send_email", payload)
  },
  deleteRejEmail(id) {
    return apiArk.delete("industry_rejections/" + id)
  },
  lookupRecord(payload) {
    return apiArk.post("industry_rejections/show", { payload })
  },
}
