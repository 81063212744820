<template>
  <AppModal :show="show" style-modifiers="modal--error" @close="closeModal">
    <template slot="header">
      <h2 class="modal__header">Delete Customer</h2>
    </template>

    <template slot="body">
      <div class="modal__text">
        <p>Are you sure you want to delete {{ customer.customer_name }}?</p>
        <p>Account No. {{ customer.account_number }}</p>
      </div>
    </template>

    <template slot="footer">
      <div class="footer__buttons flex-container">
        <button
          class="footer__buttons--button button button--blue"
          @click="closeModal"
        >
          No, don't delete
        </button>
        <button class="button button--red-outlined" @click="deleteCustomer">
          Yes, delete customer
        </button>
      </div>
    </template>
  </AppModal>
</template>

<script>
import AppModal from "@/components/smartMeter/AppModal.vue"
export default {
  components: {
    AppModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
    deleteCustomer() {
      this.$emit("deleteCustomer", this.customer.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  &__text {
    margin: $spacing-5 0 $spacing-7 $spacing-2;

    p {
      color: $night-light-2;
      font-weight: $weight-bold;
    }
  }

  .footer__buttons {
    margin-bottom: $spacing-6;

    &--button {
      margin: 0 3rem 0 $spacing-6;
    }
  }
}
</style>
