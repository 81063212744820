import OpeningReadings from "@/api/OpeningReadings"
import email from "@/constants/email"

const state = {
  disputed_search: null,
  isFetchingDisputedAccount: "",
  isSubmittingForm: "",
  responseMessage: null,
}

const mutations = {
  DISPUTED_SEARCH(state, results) {
    state.disputed_search = results
  },
  CLEAR_DISPUTED_SEARCH(state) {
    state.disputed_search = null
  },
  IS_FETCHNIG_DISPUTED_ACCOUNT(state, results) {
    state.isFetchingDisputedAccount = results
  },
  SET_ADDITIONAL_INFO(state, data) {
    state.disputed_search.disputed_read.additionalInformation = data
  },
  SET_SUPPLIER_CODE(state, data) {
    state.disputed_search.disputed_read.associatedSupplierCode = data
  },
  SET_EMAIL_PREFERENCE(state, data) {
    state.disputed_search.emailPref = data
  },
  SET_EMAIL_STATUS(state, data) {
    state.disputed_search.emailStatus = data
  },
  SET_EMAIL_CONTENT(state, data) {
    state.disputed_search.emailContent = data
  },
  IS_SUBMITTING_FORM(state, data) {
    state.isSubmittingForm = data
  },
  CLEAR_IS_SUBMITTING_FORM(state) {
    state.isSubmittingForm = ""
  },
  SET_RESPONSE_MESSAGE(state, data) {
    state.responseMessage = data
  },
  CLEAR_RESPONSE_MESSAGE(state) {
    state.responseMessage = null
  },
}

const actions = {
  disputedSearch({ commit }, mpxn) {
    commit("IS_FETCHNIG_DISPUTED_ACCOUNT", "loading")

    OpeningReadings.mpxn_lookup(mpxn)
      .then((response) => {
        let fuel
        const mpxnData = response.data
        const footer = email.footer
        const textBodyStyle = email.textStyle
        let firstName = mpxnData.rejection.customer_forename
        if (mpxnData.meter_info.identifier.length === 13) {
          fuel = "electricity"
        } else {
          fuel = "gas"
        }
        mpxnData.emailContent = ""
        mpxnData.emailContentStart = `<html>
                                      <body> 
                                        <div style='${textBodyStyle}'>
                                          Hello ${firstName},
                                          <br><br>
                                          We have now raised a dispute with your previous supplier
                                          to get your opening ${fuel} read amended.
                                          <br><br>
                                          The reading we have proposed is `
        mpxnData.emailContentEnd = `.
                                    <br><br>
                                    The disputed read process can take up to 6 weeks. Once we have resolved this, we will revise your statements
                                    accordingly and send out your latest one. The reading will be based on the information we have received from you and any readings your previous supplier has.
                                    <br><br>
                                    In the meantime, if you have any questions please do get in contact.
                                    </div>
                                    ${footer}
                                </body>
                              </html>`

        if (mpxnData.rejection.freshdesk_ticket !== null) {
          mpxnData.emailPref = "freshdesk"
        } else {
          mpxnData.emailPref = "email"
        }
        mpxnData.emailStatus = null
        commit("DISPUTED_SEARCH", mpxnData)
        commit("IS_FETCHNIG_DISPUTED_ACCOUNT", "success")
      })
      .catch((error) => {
        console.log(error)
        commit("IS_FETCHNIG_DISPUTED_ACCOUNT", "error")
      })
  },
  clearDisputedSearchResults({ commit }) {
    commit("CLEAR_DISPUTED_SEARCH")
    commit("CLEAR_RESPONSE_MESSAGE")
    commit("CLEAR_IS_SUBMITTING_FORM")
  },
  disputeFormSubmit({ commit }) {
    commit("IS_SUBMITTING_FORM", "loading")
    OpeningReadings.disputeReadForm(state.disputed_search)
      .then((response) => {
        if (response.data.message) {
          commit("SET_RESPONSE_MESSAGE", response.data.message)
          commit("IS_SUBMITTING_FORM", "error")
        } else {
          commit("SET_RESPONSE_MESSAGE", response.data)
          commit("IS_SUBMITTING_FORM", "success")
        }
      })
      .catch((error) => {
        console.log(error)
        commit("IS_SUBMITTING_FORM", "error")
      })
  },
}

const getters = {
  disputedSearchResult: (state) => {
    return state.disputed_search
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}
