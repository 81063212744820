<template>
  <table v-if="columnLength() > 1" :class="tableClass">
    <tr
      v-if="tableHeader.length"
      class="generic-table__row generic-table__row--header"
    >
      <th
        v-for="(item, index) in tableHeader"
        :key="index"
        :width="columnRatio.length ? columnRatio[index] + '%' : false"
      >
        {{ item }}
      </th>
    </tr>
    <tr
      v-if="separatorText"
      class="generic-table__row generic-table__row--separator"
      @click="toggleTable"
    >
      <td :colspan="columnLength()">
        <span>
          <i :class="iconClass"></i>
          {{ separatorText }}
        </span>
        <hr />
      </td>
    </tr>
    <slot></slot>
    <tr v-if="isLoadMore" class="generic-table__row generic-table__row--loader">
      <td :colspan="columnLength()">
        <button
          class="button button--grey-outlined"
          :disabled="loadingStatus === 'loading'"
          @click="loadMoreTableRows"
        >
          <img
            v-if="loadingStatusActive && loadingStatus === 'loading'"
            class="button__icon"
            src="@/assets/icons/loading-circle.gif"
          />
          <template v-else>Load more</template>
        </button>
        <p v-if="showLoadingEmpty" class="generic-table__message">
          We did not find any new datas, please try again later.
        </p>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    tableHeader: {
      type: Array,
      default: () => [],
    },
    colorScheme: {
      type: String,
      default: "blue",
    },
    isLoadMore: {
      type: Boolean,
      default: false,
    },
    isAttached: {
      type: Boolean,
      default: false,
    },
    separatorText: {
      type: String,
      default: "",
    },
    columnRatio: {
      type: Array,
      default: () => [],
    },
    loadingStatus: {
      type: String,
      default: "",
    },
    loadingStatusActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTableOpen: true,
      showLoadingEmpty: false,
    }
  },
  computed: {
    tableClass() {
      const colorModifier = " generic-table--" + this.colorScheme
      const toggleModifier = this.isTableOpen ? "" : " generic-table--closed"
      const attachedModifier = this.isAttached ? " generic-table--attached" : ""
      return "generic-table" + colorModifier + toggleModifier + attachedModifier
    },
    iconClass() {
      const tableState = this.isTableOpen ? "arrow--open" : "arrow--closed"
      return "fa fa-caret-right " + tableState
    },
  },
  watch: {
    loadingStatus(status) {
      if (this.loadingStatusActive && status === "emptyResponse") {
        this.showLoadingEmpty = true
        setTimeout(() => (this.showLoadingEmpty = false), 3000)
      }
    },
  },
  methods: {
    loadMoreTableRows() {
      this.$emit("loadMoreTableRows")
    },
    toggleTable() {
      this.isTableOpen = !this.isTableOpen
    },
    columnLength() {
      let childSlots =
        this.$slots.default && this.$slots.default[0].data
          ? Object.keys(this.$slots.default[0].data.scopedSlots)
          : []
      return childSlots.reduce(
        (total, slot) => (slot.indexOf("column") != -1 ? total + 1 : total),
        1
      )
    },
  },
}
</script>

<style lang="scss">
.generic-table {
  text-align: left;
  box-shadow: $box-shadow;
  background-color: $day;
  border-collapse: collapse;

  &--attached {
    box-shadow: $box-shadow-no-top;
  }

  &--closed {
    // this is to collapse table, but make it visually hidden only (so table column sizes wont change)
    .generic-table__row--primary,
    .generic-table__row--secondary {
      opacity: 0;
      padding: 0 $spacing-2;
      transition: none;

      .generic-table__column {
        line-height: 0;
        padding: 0 $spacing-2;

        input {
          display: none;
        }
      }
    }
    .arrow {
      line-height: 0;
    }
    .generic-table__row--loader {
      display: none;
    }
  }

  &__row {
    &--header {
      border-bottom: 18px solid $day;

      th {
        padding: $spacing-3 $spacing-2;
        font-weight: $weight-bold;
        font-size: $size-7;
      }
    }

    &--separator {
      td {
        position: relative;
      }

      span {
        position: absolute;
        top: calc(50% - 11px);
        left: 16px;
        font-size: $size-7;
        color: $night-light-2;
        font-weight: $weight-bold;
        display: inline-block;
        background-color: $day;
        padding: 0 $spacing-2 0 0;

        .fa {
          margin-right: $spacing-2;
        }
      }

      hr {
        margin: $spacing-4;
        border: 1px solid $night-light-2;
      }

      &:hover {
        cursor: pointer;

        span {
          color: $night-light;
        }

        hr {
          border: 1px solid $night-light;
        }
      }
    }

    &--loader {
      text-align: center;

      td {
        padding: $spacing-6 0;
      }
      .button__icon {
        height: 16px;
        margin-bottom: 4px;
        padding: 0 $spacing-5 0;
      }
    }
  }

  &__message {
    margin: $spacing-4 0 0;
  }

  .arrow--closed {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
  }

  .arrow--open {
    transform: rotate(90deg);
    transition: transform 0.2s linear;
  }
}
</style>
