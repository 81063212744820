import WarmHomeDiscountApi from "@/api/WarmHomeDiscount.js"

const state = {
  accountSearch: [],
  applicationReceived: [],
  auditCandidates: [],
  closedApplicants: [],
  approvedApplicants: [],
  creditApplied: [],
  criteria: [],
  agents: [
    "Nina Miller",
    "Kevin Minter",
    "John Cunningham",
    "WHD analyst",
    "WHD manager",
  ],
  statuses: [
    {
      value: "application_received",
      text: "Application received",
    },
    {
      value: "audit_candidate",
      text: "Audit candidate",
    },
    {
      value: "sent_for_audit",
      text: "Sent for audit",
    },
    {
      value: "approved_at_audit",
      text: "Approved at audit",
    },
    {
      value: "document_verification",
      text: "Document verification",
    },
    {
      value: "no_evidence_provided",
      text: "No evidence provided",
    },
  ],
  process: [
    { value: "audit_candidate", text: "Choose for audit" },
    { value: "successful", text: "Process applications" },
  ],
  loadMoreStatus: {
    name: "",
    type: "",
  },
}

const mutations = {
  CLEAR_ACCOUNTS(state) {
    state.applicationReceived = []
    state.auditCandidates = []
    state.closedApplicants = []
    state.approvedApplicants = []
    state.creditApplied = []
  },
  ADD_ACCOUNTS(state, { filter, accounts }) {
    state[filter] = state[filter].concat(accounts)
  },
  ADD_CRITERIA(state, data) {
    state.criteria = data.getCriteria
  },
  LOAD_MORE_STATUS(state, { name, type }) {
    state.loadMoreStatus.name = name
    state.loadMoreStatus.type = type
  },
  ADD_SEARCH(state, results) {
    state.accountSearch = results
  },
  CLEAR_SEARCH(state) {
    state.accountSearch = []
  },
  MODIFY_ACCOUNTS(state, { tableName, accountId, newData }) {
    let itemIndex
    let application = state[tableName].find((item, index) => {
      if (item.account_id === accountId) {
        itemIndex = index
        return item
      }
    })
    state[tableName].splice(itemIndex, 1, { ...application, ...newData })
  },
}

const actions = {
  clearAccounts({ commit }) {
    commit("CLEAR_ACCOUNTS")
  },
  triggerAccountAPI(
    { commit },
    { filter, downloadedAccounts, queryAmount, loadMoreUpdate }
  ) {
    let datas = {
      warm_home_discounts_interface: {
        status: filter,
        skip_account_numbers: downloadedAccounts,
        number_of_applications: queryAmount,
      },
    }
    WarmHomeDiscountApi.getAccounts(datas)
      .then((response) => {
        let mapFilter = (filter) => {
          switch (filter) {
            case "application_received":
              return "applicationReceived"
            case "audit_candidate":
              return "auditCandidates"
            case "sent_for_audit":
              return "auditCandidates"
            case "approved_at_audit":
              return "auditCandidates"
            case "document_verification":
              return "auditCandidates"
            case "core_group":
              return "closedApplicants"
            case "closed_account":
              return "closedApplicants"
            case "no_evidence_provided":
              return "closedApplicants"
            case "rejected_approved":
              return "closedApplicants"
            case "rejected_credited":
              return "closedApplicants"
            case "successful":
              return "approvedApplicants"
            case "paid":
              return "creditApplied"
          }
        }

        commit("ADD_ACCOUNTS", {
          filter: mapFilter(filter),
          accounts: response.data.getApplications,
        })

        if (loadMoreUpdate) {
          if (response.data.getApplications.length) {
            commit("LOAD_MORE_STATUS", { name: "dataResponse", type: filter })
          } else {
            commit("LOAD_MORE_STATUS", { name: "emptyResponse", type: filter })
          }
        } else if (state.loadMoreStatus) {
          commit("LOAD_MORE_STATUS", { name: "", type: "" })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  triggerCriteriaAPI({ commit }) {
    WarmHomeDiscountApi.getCriteria().then((response) => {
      commit("ADD_CRITERIA", response.data)
    })
  },
  addSearchResults({ commit }, { results }) {
    commit("ADD_SEARCH", results)
  },
  clearSearchResults({ commit }) {
    commit("CLEAR_SEARCH")
  },
}

const getters = {
  accountListLength: (state) => {
    return (
      state.applicationReceived.length +
      state.auditCandidates.length +
      state.closedApplicants.length +
      state.approvedApplicants.length +
      state.creditApplied.length
    )
  },
  appReceived: (state) => {
    let appReceived = []

    if (state.accountSearch.length) {
      appReceived = state.accountSearch.filter(
        (account) => account.ark_status === "application_received"
      )
    } else {
      appReceived = state.applicationReceived
    }
    return appReceived
  },
  auditApp: (state) => {
    let auditApp = []
    if (state.accountSearch.length) {
      auditApp = state.accountSearch.filter(
        (account) =>
          account.ark_status === "audit_candidate" ||
          account.ark_status === "sent_for_audit" ||
          account.ark_status === "approved_at_audit" ||
          account.ark_status === "document_verification"
      )
    } else {
      auditApp = state.auditCandidates
    }
    return auditApp
  },
  closedApp: (state) => {
    let closedApp = []
    if (state.accountSearch.length) {
      closedApp = state.accountSearch.filter(
        (account) =>
          account.ark_status === "closed_account" ||
          account.ark_status === "no_evidence_provided" ||
          account.ark_status === "core_group" ||
          account.ark_status === "rejected_approved" ||
          account.ark_status === "rejected_credited"
      )
    } else {
      closedApp = state.closedApplicants
    }
    return closedApp
  },
  successfulApp: (state) => {
    let successfulApp = []

    if (state.accountSearch.length) {
      successfulApp = state.accountSearch.filter(
        (account) => account.ark_status === "successful"
      )
    } else {
      successfulApp = state.approvedApplicants
    }

    return successfulApp
  },
  creditedApp: (state) => {
    let creditedApp = []

    if (state.accountSearch.length) {
      creditedApp = state.accountSearch.filter(
        (account) => account.ark_status === "paid"
      )
    } else {
      creditedApp = state.creditApplied
    }

    return creditedApp
  },
  dropdownOptions: (state) => {
    let options = {}

    options.criteria = state.criteria.map((value) => ({
      value: value.id,
      ...value,
    }))
    options.assignedTo = state.agents.map((value) => ({ value, text: value }))
    options.process = state.process
    options.status = state.statuses

    return options
  },
  existingAccountList: (state) => {
    let accountsTypes = [
      "applicationReceived",
      "auditCandidates",
      "closedApplicants",
      "approvedApplicants",
      "creditApplied",
    ]

    return accountsTypes.reduce((allAccounts, type) => {
      let accountList = state[type].map((account) => {
        return account.junifer_account_number
      })
      return allAccounts.concat(accountList)
    }, [])
  },
  searchResultAccountList: (state) => {
    return state.accountSearch.map((account) => {
      return account.junifer_account_number
    })
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}
