/* eslint-disable no-console */
<template>
  <b-container fluid>
    <b-row class="ml-3">
      <header-block
        title="Payment adequacy reviews"
        summary="We review our customers payment amount 6 months after their payment schedule has been created."
      >
        <template v-slot:description-body>
          <PaymentAdequacyDescription />
        </template>
      </header-block>
    </b-row>

    <page-section title="Bulk Upload Customers">
      <template v-slot:content>
        <BulkUploadCSV @reponse="handleBulkAccountsUpload" />
      </template>
    </page-section>

    <AccountsErrorUploadModal
      :show="showFailedReviewsUploadModal"
      :error-modal="failedReviewsErrorModal"
      :invalid-account-numbers="failedReviewsErrorModal.uploadFailedInvalid"
      :successful-total="failedReviewsErrorModal.successfulTotal"
      @close="showFailedReviewsUploadModal = false"
    />

    <page-section title="Individual Account Lookup">
      <template v-slot:content>
        <b-form inline>
          <input
            id="accountsearch"
            v-model="oneDaylookup"
            type="text"
            :disabled="isBusy"
            placeholder="Account number...."
            style="width: 300px"
            class="form-control mb-2 mr-sm-2 mb-sm-0"
          />
          <span class="input-group-btn">
            <b-button
              id="search"
              :disabled="isBusy || !validLookup"
              variant="outline-primary"
              class="mb-2 mr-sm-2 mb-sm-0"
              @click="accountLookup(oneDaylookup)"
            >
              Search
            </b-button>
            <b-button
              id="clear"
              :disabled="isBusy"
              class="mb-2 mr-sm-2 mb-sm-0"
              variant="outline-danger"
              @click="clearLookup()"
            >
              Clear
            </b-button>
          </span>
        </b-form>

        <b-row>
          <b-col class="text-left mt-2">
            <i
              >Only returns payment adequacy reviews that haven't been initially
              reviewed.</i
            >
          </b-col>
        </b-row>
        <InitialReviewTable
          v-if="lookupResults.length > 0"
          :data="lookupResults"
        />
      </template>
    </page-section>

    <page-section :title="'Awaiting Reviews (' + totalReviews + ')'">
      <template v-slot:content>
        <b-form inline>
          <b-form-select
            id="inline-form-custom-select-pref"
            v-model="importCount"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="importCountOptions"
          >
            <template v-slot:first>
              <option :value="null">Choose...</option>
            </template>
          </b-form-select>
          <b-form-select
            id="inline-form-custom-select-pref"
            v-model="importRefreshFilter"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="importRefreshFilterOptions"
          >
          </b-form-select>
          <b-form-select
            id="inline-form-custom-select-pref"
            v-model="importCreatedFilter"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="importCreatedFilterOptions"
          >
          </b-form-select>
          <b-button
            class="mb-2 mr-sm-2 mb-sm-0"
            :disabled="
              isBusy || groupActionDisable || progressBar.jobId !== null
            "
            variant="outline-success"
            @click="importReviews(importCount, importRefreshFilter)"
          >
            Fetch
          </b-button>
          <b-button
            :disabled="
              isBusy || groupActionDisable || progressBar.jobId !== null
            "
            variant="outline-danger"
            @click="clearReviews()"
          >
            Clear
          </b-button>
        </b-form>

        <b-spinner v-show="isBusy" />

        <div v-if="progressBar.jobId !== null" class="p-5">
          Job: {{ progressBar.jobId }} - {{ progressBar.stage }} ({{
            progressBar.counter
          }}/{{ progressBar.max }})
          <b-progress
            v-if="progressBar.jobId !== null"
            b-progress
            :value="progressBar.counter"
            :max="progressBar.max"
            show-progress
            animated
          />
        </div>
        <div v-else>
          <div v-if="data.length > 0">
            <b-row class="mt-3 mb-3" cols="1" cols-sm="2">
              <b-col>
                <b-card bg-variant="light">
                  <b-form-group label-cols-sm="3" label="Bulk">
                    <b-input-group>
                      <b-form-select
                        v-model="groupAction"
                        :options="actionOptions"
                      >
                        <option slot="first" :value="null">-- none --</option>
                      </b-form-select>
                      <b-input-group-append>
                        <b-button
                          :disabled="!groupAction || groupActionDisable"
                          variant="success"
                          @click="groupActionClick(clickedReviews)"
                        >
                          <span v-if="!groupActionDisable">
                            ({{ clickedReviews.length }}) Go
                          </span>
                          <span v-else>
                            <b-spinner small></b-spinner>
                          </span>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group label-cols-sm="3" label="Select">
                    <b-form-radio-group
                      v-model="selected"
                      style="text-align: left"
                      :options="selectOptions"
                      name="radios-stacked"
                      stacked
                    >
                    </b-form-radio-group>
                  </b-form-group>
                </b-card>
              </b-col>
            </b-row>

            <div v-show="groupActionDisable">
              <h4>Please wait while Ark performs this bulk process</h4>
            </div>

            <results-table
              :count-box-one="day1Ready.length"
              :count-box-two="day1NotReady.length"
              :count-box-three="day1Completed.length"
            >
              <div slot="box-three-slot">
                <b-button
                  v-show="day1Completed.length > 0"
                  size="sm"
                  variant="outline-light"
                  :disabled="isBusy || progressBar.jobId !== null"
                  @click="clearAll(day1Completed)"
                >
                  Clear all
                </b-button>
              </div>
            </results-table>

            <InitialReviewTable
              v-if="data.length > 0"
              :is-busy="isBusy || groupActionDisable"
              :data="data"
            />
          </div>
        </div>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import { paymentAdequacyMixin } from "@/mixins/paymentAdequacyMixin"
import PaymentAdequacy from "@/api/PaymentAdequacy"
import Jobs from "@/api/Jobs"
import BulkUploadCSV from "@/components/paymentAdequacy/BulkUploadCSV"
import AccountsErrorUploadModal from "@/components/smartMeter/AccountsErrorUploadModal"
import PaymentAdequacyDescription from "@/components/paymentAdequacy/paymentAdequacyDescription"
import InitialReviewTable from "@/components/paymentAdequacy/initialReviewTable"
import headerBlock from "@/components/header"
import resultsTable from "@/components/resultsTable"
import pageSection from "@/components/pageSection"
import advancedFormat from "dayjs/plugin/advancedFormat"
import dayjs from "dayjs"
dayjs.extend(advancedFormat)

export default {
  components: {
    InitialReviewTable,
    PaymentAdequacyDescription,
    headerBlock,
    resultsTable,
    pageSection,
    BulkUploadCSV,
    AccountsErrorUploadModal,
  },
  filters: {
    formattedDate: function (date) {
      return dayjs(date).format("Do MMM YY")
    },
  },
  mixins: [paymentAdequacyMixin],
  data() {
    return {
      selectedReview: {},
      selectOptions: [
        { text: "Large increase", value: "selectLgChange" },
        { text: "Need a reading", value: "selectNoReading" },
        { text: "Erroneous reviews", value: "selectErroneousReviews" },
        { text: "Awaiting", value: "selectAllAwaiting" },
        { text: "All", value: "selectAll" },
        { text: "None", value: "selectClear" },
      ],
      selected: null,
      showModal: false,
      filter: null,
      groupAction: null,
      actionOptions: [
        { text: "Delete", value: "softDelete" },
        { text: "Refresh", value: "refresh" },
        { text: "Process", value: "process" },
      ],
      oneDaylookup: "",
      importCount: 100,
      importCountOptions: [
        100, 500, 1000, 1500,
        // 10000
      ],
      importRefreshFilter:
        "updated_at >= '" +
        dayjs().subtract(0, "days").format("YYYY-MM-DD") +
        "'",
      importRefreshFilterOptions: [
        {
          text: "Refreshed today",
          value:
            "updated_at >= '" +
            dayjs().subtract(0, "days").format("YYYY-MM-DD") +
            "'",
        },
        {
          text: "Refreshed before today",
          value:
            "updated_at < '" +
            dayjs().subtract(0, "days").format("YYYY-MM-DD") +
            "'",
        },
        { text: "All", value: "" },
      ],
      importCreatedFilter: null,
      importCreatedFilterOptions: [
        { text: "Any order", value: null },
        { text: "Order by newest", value: '"review_created_date" DESC' },
        { text: "Order by oldest", value: '"review_created_date" ASC' },
      ],
      jobsProgressList: [],
      progressBar: {
        jobId: null,
        stage: null,
        counter: null,
        max: null,
      },
      groupActionDisable: false,
      showFailedReviewsUploadModal: false,
      failedReviewsErrorModal: {
        heading: "Failed to create some reviews",
        subheading:
          "We were unable to create reviews for the following accounts:",
        redColumnHeading: "Please investigate in Junifer",
        uploadFailedInvalid: [],
        successfulMessage:
          " Initial Reviews were created and added to the table",
        successfulTotal: [],
      },
    }
  },
  computed: {
    ...mapGetters("sixMonthAdDayOne", [
      "data",
      "isBusy",
      "refundTable",
      "totalReviews",
      "lookupResults",
    ]),
    searchFilter() {
      return {
        filter: this.importRefreshFilter,
        count: this.importCount,
        order: this.importCreatedFilter,
      }
    },
    day1NotReady() {
      return this.data.filter(
        (x) => !x.initial_review_completed && x.notOkToSend
      )
    },
    clickedReviews() {
      return this.data.filter((x) => x.clicked && !x.initial_review_completed)
    },
    day1Ready() {
      return this.data.filter(
        (x) => !x.initial_review_completed && !x.notOkToSend
      )
    },
    day1Completed() {
      return this.data.filter((x) => x.initial_review_completed)
    },
    editedArrays() {
      var arr = []
      this.data.forEach(function (x) {
        arr.push(x.edited)
      })
      return arr
    },
    lookupEditedArrays() {
      var arr = []
      this.lookupResults.forEach(function (x) {
        arr.push(x.edited)
      })
      return arr
    },
    validLookup() {
      return this.oneDaylookup.length >= 6 && this.oneDaylookup.length <= 8
    },
  },
  watch: {
    "progressBar.jobId": function (newValue) {
      var that = this
      if (newValue !== null) {
        var progress = this.progressBar
        var interval = setInterval(function () {
          Jobs.getDelayedJobQueue("payment_adequacy_initial_review")
            .then((job) => {
              progress.stage = job.data[0].progress_stage
              progress.counter = job.data[0].progress_current
              progress.max = job.data[0].progress_max
            })
            .catch(() => {
              progress.jobId = null
              progress.stage = null
              progress.counter = null
              progress.max = null
              clearInterval(interval)
              that.SET_DAYONEDATA([])
              that.$store.dispatch("sixMonthAdDayOne/getDayOneReviewTotal") //Update running total
            })
        }, 2000)
      }
    },
    selected: function (newValue) {
      if (newValue === "selectErroneousReviews") {
        this.selectErroneousReviews()
      } else if (newValue === "selectNoReading") {
        this.selectNoReading()
      } else if (newValue === "selectAllAwaiting") {
        this.selectAllAwaiting()
      } else if (newValue === "selectAll") {
        this.selectAll()
      } else if (newValue === "selectClear") {
        this.selectClear()
      } else if (newValue === "selectLgChange") {
        this.selectLgChange()
      } else if (newValue === "selectcancelledDD") {
        this.selectcancelledDD()
      }
    },
    editedArrays: {
      handler: function (newValue, oldValue) {
        var index = this.compareArrays(newValue, oldValue)
        if (index !== undefined && newValue[index] === false) {
          var updatedRecord = this.data[index]
          this.SET_REFRESH(updatedRecord)
          PaymentAdequacy.updateRecord(updatedRecord.id, updatedRecord)
        }
      },
    },
  },
  created() {
    // this.setupStream();
    this.$store.dispatch("sixMonthAdDayOne/getDayOneReviewTotal")
    var progress = this.progressBar
    if (progress.jobId === null) {
      Jobs.getDelayedJobQueue("payment_adequacy_initial_review")
        .then((response) => {
          if (response.data.length > 0) {
            progress.jobId = response.data[0].id
          }
        })
        .catch(() => {})
    }
  },
  channels: {
    PaymentAdequacyChannel: {
      connected() {},
      received(data) {
        if (data.deleted_fl) {
          this.DELETE_ROW_ID(data.id)
        } else {
          this.EDIT_ROW(data)
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.showModal = false
    },
    setupStream() {
      this.$cable.subscribe({
        channel: "PaymentAdequacyChannel",
      })
    },
    ...mapActions({
      paymentscript1dayaction: "sixMonthAdDayOne/paymentscript1dayaction",
      softdelete: "sixMonthAdDayOne/softdelete",
      fetchRowInfo: "sixMonthAdDayOne/fetchRowInfo",
    }),
    ...mapMutations("sixMonthAdDayOne", [
      "SET_REFRESH",
      "DELETE_ROW_ID",
      "SET_DAYONEDATA",
      "SET_STATE",
      "EDIT_ROW",
      "SET_LOOKUP_RESULTS",
    ]),
    editable(row) {
      row.edited = !row.edited
      this.EDIT_ROW(row)
      PaymentAdequacy.updateRecord(row.id, row)
    },
    compareArrays(arr1, arr2) {
      var chgValueIndex
      arr1.forEach(function (x, index) {
        if (x !== arr2[index] && arr2[index] !== undefined) {
          chgValueIndex = index
        }
      })
      return chgValueIndex
    },
    refresh(data) {
      var that = this
      data.disabled = true
      this.SET_REFRESH(data)
      // eslint-disable-next-line no-console,no-debugger
      PaymentAdequacy.initialRefresh(data.id, data)
        .then((response) => {
          // eslint-disable-next-line no-console
          var e = response.data
          e.disabled = false
          // eslint-disable-next-line no-console
          this.initialReview(e)
          var d = that.reviewFormatter(e)
          // eslint-disable-next-line no-console

          that.SET_REFRESH(d)
        })
        .catch(() => {
          data.disabled = false
          that.SET_REFRESH(data)
        })
    },
    clearAll(data) {
      var that = this
      var arr = []
      data.forEach(function (x) {
        that.DELETE_ROW_ID(x.id)
        arr.push(x.id)
      })
      PaymentAdequacy.softDeleteGroup(arr)
    },
    clearLookup() {
      this.SET_LOOKUP_RESULTS([])
      this.oneDaylookup = ""
    },
    accountLookup(account) {
      var that = this
      this.SET_STATE(true)
      PaymentAdequacy.fetchAccountPaymentReviews(account)
        .then((response) => {
          var dayOneReviews = response.data.filter(
            (x) => !x.initial_review_completed && !x.deleted_fl
          )
          dayOneReviews.forEach(function (x) {
            that.initialReview(x)
          })
          this.SET_LOOKUP_RESULTS(response.data)
          this.SET_STATE(false)
        })
        .catch(() => {})
    },
    clearReviews() {
      this.SET_DAYONEDATA([])
    },
    async fetchCompleteRecord(row) {
      await this.fetchRowInfo(row)
      this.selectedReview = row
      this.showModal = true
    },
    formatObj(x) {
      this.fetchTemplate(x)
      delete x.meterpoints
      var row = {
        review: x,
        new_payment: x.suggested_payment_amount,
        refund: x.refund,
      }
      return row
    },
    dayOneProcess(data) {
      var that = this
      PaymentAdequacy.getReview(data.id).then((response) => {
        let newOb = Object.assign({}, response.data, data)
        var row = this.formatObj(newOb)
        this.$store.dispatch("sixMonthAdDayOne/paymentscript1dayaction", row)
        if (row.review.seven_day_follow_up) {
          that.DELETE_ROW_ID(row.review.id) // Slice from array if follow up
        }
      })
    },
    importReviews() {
      this.$store.dispatch("sixMonthAdDayOne/getDayOneData", this.searchFilter)
    },
    selectNoReading() {
      var noReads = this.data.filter(
        (x) =>
          x.billedMeterReading === false &&
          x.unbilled_readings_fl === false &&
          x.notOkToSend === false
      )
      var reads = this.data.filter(
        (x) => x.billedMeterReading === true || x.unbilled_readings_fl === true
      )
      var that = this
      noReads.forEach(function (x) {
        x.clicked = true
        that.SET_REFRESH(x)
      })
      reads.forEach(function (x) {
        x.clicked = false
        that.SET_REFRESH(x)
      })
    },
    selectLgChange() {
      this.selectClear()
      var that = this
      var lgChange = this.data.filter(
        (x) =>
          (x.billedMeterReading === true || x.unbilled_readings_fl === true) &&
          (x.suggested_payment_amount / Number(x.current_payment_amount)) *
            100 >
            250
      )
      lgChange.forEach(function (x) {
        x.clicked = false
        that.SET_REFRESH(x)
      })
    },
    selectBilledReading() {
      var noReads = this.data.filter(
        (x) => x.billedMeterReading === true && x.notOkToSend === false
      )
      var reads = this.data.filter(
        (x) => x.billedMeterReading === false && x.notOkToSend === false
      )
      var that = this
      noReads.forEach(function (x) {
        x.clicked = true
        that.SET_REFRESH(x)
      })
      reads.forEach(function (x) {
        x.clicked = false
        that.SET_REFRESH(x)
      })
    },
    selectUnbilledReading() {
      var noReads = this.data.filter(
        (x) => x.unbilled === true && x.notOkToSend === false
      )
      var reads = this.data.filter(
        (x) => x.unbilled === false && x.notOkToSend === false
      )
      var that = this
      noReads.forEach(function (x) {
        x.clicked = true
        that.SET_REFRESH(x)
      })
      reads.forEach(function (x) {
        x.clicked = false
        that.SET_REFRESH(x)
      })
    },
    selectErroneousReviews() {
      var that = this
      this.selectClear()
      var accountChecks = this.data.filter(
        (x) => x.tags !== null && x.account_checks !== null
      )
      accountChecks = accountChecks.filter(
        (x) => x.account_checks.length > 0 || x.tags.length > 0
      )
      accountChecks.forEach(function (x) {
        if (typeof x.account_checks[0] === "string") {
          x.account_checks.forEach(function (y) {
            if (typeof y == "string") {
              if (
                y.includes("New Schedule") ||
                y.includes("Account Closed") ||
                y.includes("Account Terminating") ||
                y.includes("No Active Mandate") ||
                y.includes("Under Review")
              ) {
                x.clicked = true
              }
            }
          })
        }

        if (x.tags.length > 0) {
          x.tags.forEach(function (y) {
            if (y.text === "Account Terminating") {
              x.clicked = true
            }
          })
        }

        if (x.clicked) {
          that.SET_REFRESH(x)
        }
      })
    },
    selectAllAwaiting() {
      var that = this
      this.day1Ready.forEach(function (x) {
        x.clicked = true
        that.SET_REFRESH(x)
      })
      this.day1NotReady.forEach(function (x) {
        x.clicked = false
        that.SET_REFRESH(x)
      })
    },
    selectAll() {
      var that = this
      this.data.forEach(function (x) {
        x.clicked = true
        that.SET_REFRESH(x)
      })
    },
    selectClear() {
      var that = this
      this.data.forEach(function (x) {
        x.clicked = false
        that.SET_REFRESH(x)
      })
    },
    check(e) {
      e.item.clicked = !e.item.clicked
      this.SET_REFRESH(e)
    },
    switchToggle(e) {
      e.item.okToSend = !e.item.okToSend
      this.SET_REFRESH(e)
    },
    import6mPaymentAdequacy(data) {
      PaymentAdequacy.import6mPaymentAdequacy(data)
        .then((response) => {
          this.progressBar.jobId = response.data.id
          this.progressBar.counter = response.data.progress_current
          this.progressBar.max = this.countAwaiting.length
          this.progressBar.stage = response.data.progress_stage
        })
        .catch(() => {})
    },
    sum(data) {
      var total = 0
      for (var i = 0; i < data.length; i++) {
        if (isNaN(data[i].alp)) {
          continue
        }
        total += Number(data[i].alp)
      }
      return total
    },
    groupActionClick(clickedReviews) {
      var arr = []
      var that = this
      this.groupActionDisable = true
      if (this.groupAction === "softDelete") {
        this.clearAll(clickedReviews)
        this.groupActionDisable = false
        this.groupAction = null
      } else if (this.groupAction === "process") {
        var promises = []

        clickedReviews.forEach(function (x) {
          promises.push(
            PaymentAdequacy.getReview(x.id).then((response) => {
              let newOb = Object.assign({}, response.data, x)
              var row = that.formatObj(newOb)
              arr.push(row)
            })
          )
        })

        Promise.all(promises).then(() => {
          PaymentAdequacy.processGroup(arr).then((response) => {
            this.groupActionDisable = false
            this.groupAction = null
            this.progressBar.jobId = response.data.id
            this.progressBar.counter = response.data.progress_current
            this.progressBar.max = arr.length
            this.progressBar.stage = response.data.progress_stage
            this.SET_DAYONEDATA([])
          })
        })
      } else if (this.groupAction === "refresh") {
        clickedReviews.forEach(function (x) {
          arr.push(x.id)
        })
        PaymentAdequacy.refreshGroup(arr).then((response) => {
          this.groupActionDisable = false
          this.groupAction = null
          this.progressBar.jobId = response.data.id
          this.progressBar.counter = response.data.progress_current
          this.progressBar.max = arr.length
          this.progressBar.stage = response.data.progress_stage
          this.SET_DAYONEDATA([])
        })
      }
    },

    //TODO: SO-7996 - this needs refactoring, ideally move error handling to BulkUploadCSV.
    //      ... Was originally written here: https://github.com/soenergy/ark-frontend-new/commit/108b98b
    handleBulkAccountsUpload(response) {
      const res = response.data
      if (res && res.failed_reviews && res.failed_reviews.length) {
        this.showFailedReviewsUploadModal = true
        this.failedReviewsErrorModal.uploadFailedInvalid = res.failed_reviews

        if (res.successful_reviews.length) {
          this.failedReviewsErrorModal.successfulTotal = res.successful_reviews
        }
      } else {
        this.uploadAccounts.successful = true
      }
    },
  },
}
</script>

<style lang="scss">
.email-template-content {
  table {
    background-color: transparent;
    width: auto;
    td {
      padding: 0;
      border: 0;
    }
    td[width] {
      display: inline-block;
    }
  }
  table[width="100%"] {
    width: 100%;
  }
  tbody tr:hover {
    color: unset !important;
    background-color: transparent !important;
  }
}
</style>
