<template>
  <section class="extra-information">
    <p class="generic-text generic-text__medical">
      During the appointment, the electricity will be shut off for a short
      while.
      <strong class="strong-block">
        Does the customer have any medical equipment which requires electricity?
      </strong>
    </p>

    <div class="radio-container">
      <RadioGroup v-model="hasMedicalEquipment" :options="radioButtons" />
    </div>
  </section>
</template>

<script>
import RadioGroup from "@soenergy/frontend-library/src/components/RadioButton/RadioGroup"

export default {
  components: {
    RadioGroup,
  },
  data() {
    return {
      radioButtons: [
        {
          inputId: "no",
          value: false,
          label: "No",
        },
        {
          inputId: "yes",
          value: true,
          label: "Yes",
        },
      ],
    }
  },
  computed: {
    hasMedicalEquipment: {
      get() {
        return this.$store.state.imbp.hasMedicalEquipment
      },
      set(value) {
        this.$store.commit("imbp/SET_MEDICAL_EQUIPMENT", value)
      },
    },
  },
}
</script>

<style scoped lang="scss">
.generic-text {
  font-size: $font-size-5;
  margin: $space-4 $space-0 $space-3 $space-0;

  &__medical {
    margin-bottom: $space-7;
  }
}

.radio-container {
  .radio-group {
    margin-top: -$space-6;
    border: 1px sold red !important;
  }
}
</style>
