<template>
  <div :class="[filterClass, energyClass]" @click="filterClick">
    <slot></slot>
    <button class="filter-button__button">{{ title }}</button>
    <i class="filter-button__arrow" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    energyType: {
      type: String,
      default: "",
    },
  },
  computed: {
    filterClass() {
      return "filter-button" + (this.active ? " filter-button--active" : "")
    },
    energyClass() {
      return this.energyType
        ? "filter-button--rejected filter-button--rejected-" + this.energyType
        : ""
    },
  },
  methods: {
    filterClick() {
      this.$emit("filterClick", this.name)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-button {
  position: relative;
  height: 44px;
  border: 1px solid $night-light-2;
  border-radius: 20px;
  background-color: $day;
  width: 100%;
  margin: 0 $spacing-2;
  text-align: center;

  &__button {
    background-color: $day;
    border-radius: 20px;
    line-height: 40px;
    border: 0;
    outline: none;
    padding: -$spacing-1 0 $spacing-1 0px;
    z-index: 2;
    position: relative;
    font-weight: $weight-bold;
    color: $night-light-2;
  }
  &__arrow {
    position: absolute;
    width: 17px;
    height: 17px;
    right: 50%;
    bottom: -17px;
    transform: translate(50%, -50%) rotate(45deg);
    z-index: 1;
    background-color: $day;
    border: 1px solid $night-light-2;
    display: none;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid $water;

    .filter-button__button {
      color: $water;
    }
    .filter-button__arrow {
      border: 1px solid $water;
    }
  }
  &--active {
    border: 1px solid $water;

    .filter-button__button {
      color: $water;
    }
    .filter-button__arrow {
      border: 1px solid $water;
      display: inline-block;
    }

    &:hover {
      cursor: default;
      border: 1px solid $water;

      .filter-button__button {
        cursor: default;
        color: $water;
      }
      .filter-button__arrow {
        border: 1px solid $water;
      }
    }
  }

  &--rejected {
    font-size: $size-6;
    height: 54px;
    border-radius: 40px;

    .filter-button--rejected-icon {
      padding: $spacing-2 $spacing-3;
      border-radius: 50%;
      margin-right: $spacing-3;
      font-size: $size-6;
      position: absolute;
      left: 8px;
      top: 6px;
    }

    .filter-button__button {
      line-height: 50px;
    }
  }

  &--rejected-electric {
    border: 1px solid $yellow-300;

    .filter-button--rejected-icon {
      background: $yellow-300;
      color: $day;
    }

    .filter-button__button {
      color: $yellow-300;
    }

    .filter-button__arrow {
      border: 1px solid $yellow-300;
    }

    &:hover {
      border: 1px solid $yellow-500;
      background: $yellow-500;

      .filter-button--rejected-icon {
        background: $day;
        color: $yellow-500;
      }

      .filter-button__button {
        color: $day;
        background: $yellow-500;
      }

      .filter-button__arrow {
        border: 1px solid $yellow-500;
        background: $yellow-500;
      }
    }
  }

  &--rejected-gas {
    border: 1px solid $blue-600;

    .filter-button--rejected-icon {
      background: $blue-600;
      color: $day;
    }

    .filter-button__button {
      color: $blue-600;
    }

    .filter-button__arrow {
      border: 1px solid $blue-600;
    }

    &:hover {
      border: 1px solid $blue-600;
      background: $blue-600;

      .filter-button--rejected-icon {
        background: $day;
        color: $blue-600;
      }

      .filter-button__button {
        color: $day;
        background: $blue-600;
      }

      .filter-button__arrow {
        border: 1px solid $blue-600;
        background: $blue-600;
      }
    }
  }
}

.filter-button--rejected-gas.filter-button--active {
  background: $blue-100;
  border: 1px solid $blue-100;

  .filter-button__button {
    background: $blue-100;
    color: $blue-600;
  }

  .filter-button--rejected-icon {
    color: $blue-100;
    background: $blue-600;
  }

  .filter-button__arrow {
    border: 1px solid $blue-100;
    background: $blue-100;
  }
}

.filter-button--rejected-electric.filter-button--active {
  background: $yellow-50;
  border: 1px solid $yellow-50;

  .filter-button__button {
    background: $yellow-50;
    color: $yellow-500;
  }

  .filter-button--rejected-icon {
    color: $yellow-50;
    background: $yellow-500;
  }

  .filter-button__arrow {
    border: 1px solid $yellow-50;
    background: $yellow-50;
  }
}
</style>
