import axios from "axios"
import apiArk2 from "@/api/ApiV2"

const server = process.env.VUE_APP_ROOT_API  
const appURL = server + "/smart_meters/"

const apiSmartMeterReadings = axios.create({
  baseURL: appURL,
  timeout: 1000000,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Headers":
      "Content-Type, Accept, Authorization, X-Request-With",
  },
})

export default {
  fetchMeterReadings(mpxn, fromDate, toDate) {
    return apiSmartMeterReadings.get(
      `reads?mpxn=${mpxn}&from_date=${fromDate}&to_date=${toDate}`)
  }
}
