import SmartMeterApi from "@/api/SmartMeter.js"

const state = {
  accountSearch: [],
  prebooking: [],
  campaign_ended: [],
  booked: [],
  failed: [],
  ineligible: [],
  installed: [],
  loadMoreStatus: {
    name: "",
    type: "",
  },
}

const mutations = {
  CLEAR_ACCOUNTS(state) {
    state.prebooking = []
    state.campaign_ended = []
    state.booked = []
    state.failed = []
    state.ineligible = []
    state.installed = []
  },
  ADD_ACCOUNTS(state, { filter, accounts }) {
    state[filter] = state[filter].concat(accounts)
  },
  CLEAR_SEARCH(state) {
    state.accountSearch = []
  },
  ADD_SEARCH(state, results) {
    state.accountSearch = results
  },
  LOAD_MORE_STATUS(state, { name, type }) {
    state.loadMoreStatus.name = name
    state.loadMoreStatus.type = type
  },
}
const actions = {
  clearAccounts({ commit }) {
    commit("CLEAR_ACCOUNTS")
  },
  clearSearchResults({ commit }) {
    commit("CLEAR_SEARCH")
  },
  triggerAccountAPI(
    { commit },
    { filter, downloadedAccounts, loadMoreUpdate }
  ) {
    let datas = {
      filter,
      skip_junifer_account_numbers: downloadedAccounts,
    }
    SmartMeterApi.getAccounts(datas)
      .then((response) => {
        commit("ADD_ACCOUNTS", { filter, accounts: response.data.results })

        if (loadMoreUpdate) {
          if (response.data.results.length) {
            commit("LOAD_MORE_STATUS", { name: "dataResponse", type: filter })
          } else {
            commit("LOAD_MORE_STATUS", { name: "emptyResponse", type: filter })
          }
        } else if (state.loadMoreStatus) {
          commit("LOAD_MORE_STATUS", { name: "", type: "" })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  addSearchResults({ commit }, { results }) {
    commit("ADD_SEARCH", results)
  },
}

const getters = {
  preBooking: (state) => {
    let preBooking = []

    if (state.accountSearch.length) {
      preBooking = state.accountSearch.filter(
        (account) =>
          account.campaign_status.eligible &&
          !(
            account.campaign_status.first_email_sent &&
            account.campaign_status.second_email_sent &&
            account.campaign_status.third_email_sent
          ) &&
          !account.booking_status.booked &&
          !account.booking_status.installed &&
          !account.booking_status.failed
      )
    } else {
      preBooking = state.prebooking
    }

    return preBooking
  },
  campaignEnded: (state) => {
    let campaignEnded = []

    if (state.accountSearch.length) {
      campaignEnded = state.accountSearch.filter(
        (account) =>
          account.campaign_status.eligible &&
          account.campaign_status.first_email_sent &&
          account.campaign_status.second_email_sent &&
          account.campaign_status.third_email_sent &&
          !account.booking_status.booked &&
          !account.booking_status.installed &&
          !account.booking_status.failed
      )
    } else {
      campaignEnded = state.campaign_ended
    }

    return campaignEnded
  },
  booked: (state) => {
    let booked = []

    if (state.accountSearch.length) {
      booked = state.accountSearch.filter(
        (account) =>
          account.campaign_status.eligible &&
          account.booking_status.current_status === "booked"
      )
    } else {
      booked = state.booked
    }

    return booked
  },
  failed: (state, getters) => {
    let failed = []

    if (state.accountSearch.length) {
      failed = state.accountSearch.filter(
        (account) => account.booking_status.current_status === "failed"
      )
    } else {
      failed = state.failed
    }
    return failed
  },
  ineligible: (state, getters) => {
    let ineligible = []

    if (state.accountSearch.length) {
      ineligible = state.accountSearch.filter(
        (account) =>
          !account.campaign_status.eligible && !account.booking_status.installed
      )
    } else {
      ineligible = state.ineligible
    }

    return ineligible
  },
  installed: (state) => {
    let installed = []

    if (state.accountSearch.length) {
      installed = state.accountSearch.filter(
        (account) =>
          !account.campaign_status.eligible && account.booking_status.installed
      )
    } else {
      installed = state.installed
    }

    return installed
  },
  existingAccountList: (state) => {
    let accountsTypes = [
      "prebooking",
      "campaign_ended",
      "booked",
      "failed",
      "ineligible",
      "installed",
    ]

    return accountsTypes.reduce((allAccounts, type) => {
      let accountList = state[type].map((account) => {
        return account.junifer_account_number
      })
      return allAccounts.concat(accountList)
    }, [])
  },
  searchResultAccountList: (state) => {
    return state.accountSearch.map((account) => {
      return account.junifer_account_number
    })
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
}
