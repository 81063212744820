import { render, staticRenderFns } from "./paymentAdequacyPreviewModal.vue?vue&type=template&id=36172a68"
import script from "./paymentAdequacyPreviewModal.vue?vue&type=script&lang=js"
export * from "./paymentAdequacyPreviewModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports