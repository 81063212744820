/* eslint-disable no-debugger */
import Rejections from "@/api/Registrations"
import email from "@/constants/email"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

const state = {
  lookupResults: [],
  industryRejections: [],
  indRejIsBusy: true,
  deliveryError: { error: false },
}

const mutations = {
  SET_LOOKUP_RESULTS(state, data) {
    state.lookupResults = data
  },
  SET_INDUSTRY_REJECTION(state, data) {
    state.industryRejections = data
  },
  DELETE_INDREJ_ROW(state, data) {
    var index = state.industryRejections.findIndex(
      (s) => s.accountnumber == data.accountnumber
    )
    state.industryRejections.splice(index, 1)
  },
  SET_INDUSTRY_REJECTION_STATE(state, data) {
    state.indRejIsBusy = data
  },
  UPDATE_INDREJ_ROW(state, data) {
    var index = state.industryRejections.findIndex(
      (s) => s.accountnumber == data.accountnumber
    )
    state.industryRejections[index].completedFl = true
  },
  SET_DELIVERY_ERROR_STATUS(state, data) {
    state.deliveryError = data
  },
}

const getters = {
  lookupResults: (state) => {
    return state.lookupResults
  },
  IndustryRejectedTable: (state) => {
    return {
      sortBy: "Status",
      sortDesc: false,
      fields: [
        { key: "accountnumber", label: "Account Number", sortable: true },
        { key: "forename", label: "First Name", sortable: false },
        { key: "email", label: "Email", sortable: false },
        "old_dd_amount",
        "new_payment_amount",
        "MPAN",
        "mpan_ssd",
        "mpan_supplystatus",
        "MPRN",
        "mprn_ssd",
        "mprn_supplystatus",
        "mpxn_ticket",
        "mpxn_rejection_reason",
        "last_updated",
        "force_send",
        "show_details",
        "process",
        "delete",
      ],
      items: state.industryRejections,
    }
  },
  indRejIsBusy: (state) => {
    return state.indRejIsBusy
  },
  deliveryError: (state) => {
    return state.deliveryError
  },
}

const setters = {}

const actions = {
  getIndustryRejected(context) {
    Rejections.industryRejections()
      .then((response) => {
        var mpanRejectionReason = [
          "Registration Too Soon After Previous",
          "Invalid Supplier Registration Effective From Dates",
          "Future Registration Already Exists",
        ]
        var mpanRejectionReason2 = [
          "Inconsistency Between Measurement Class and DA/DC Type",
          "Supplier not a Green Deal Licensee",
        ]
        var mprnRejectionReason = [
          "Invalid Supplier Registration Effective From Dates",
          "Meter Reading Frequency is not acceptable for the Meter Point",
          "Supply Meter Point has an  AQ greater than the Competitive Market Threshold",
          "Supply meter point is either extinct or duplicate",
        ]
        var onSupplyStatus = [
          "RegistrationAccepted",
          "RegistrationRequested",
          "RegistrationConfirmed",
        ]
        var footer = email.footer
        response.data.forEach(function (e) {
          var rejectedMpxn = e.rejected_mpxn[0]
          e.clicked = false
          e.edited = false
          e.disabled = false
          e.paymentAdjFl = false
          e.newPaymentAmount = null
          e.mpan_ssd = dayjs(rejectedMpxn.mpan_ssd).format("Do MMMM")
          e.mprn_ssd = dayjs(rejectedMpxn.mprn_ssd).format("Do MMMM")
          if (
            mpanRejectionReason2.includes(rejectedMpxn.mpan_rejection_reason)
          ) {
            if (rejectedMpxn.mpan !== null && rejectedMpxn.mprn !== null) {
              e.paymentAdjFl = true
              e.newPaymentAmount = Math.round(parseFloat(e.old_dd_amount) / 2)
              if (!e.seasonalFl === true) {
                var paymentChangeAmount = "&pound;" + e.newPaymentAmount
              } else {
                var paymentChangeAmount =
                  "Summer &pound;" +
                  e.newPaymentAmount * 0.75 +
                  " Winter &pound;" +
                  e.newPaymentAmount * 1.25
              }
              var fuel_registered = "gas and electricity "
              var gas_insert = `<br><br>Your gas supply registration appears to be progressing fine and your gas supply start date is expected to be ${e.mprn_ssd}.`
              var para_opening = "However, your electricity supply registration"
              var payment_para = `To reflect this, we have reduced your monthly Direct Debit payment amount to ${paymentChangeAmount}.`
            } else {
              var fuel_registered = "electricity supply"
              var gas_insert = ""
              var para_opening = "Unfortunately, your registration<br>"
              var payment_para =
                "As we are not expecting to take over your supply, we have now cancelled your Direct Debit schedule"
            }
            if (
              rejectedMpxn.mpan_rejection_reason ===
              "Inconsistency Between Measurement Class and DA/DC Type"
            ) {
              e.templateContent = `<html>
                                  <body>
                                    <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                      Hi ${e.forename},
                                      <br>
                                      <br>Thank you very much for signing up for your ${fuel_registered} with So Energy.
                                      ${gas_insert}
                                      <br>
                                      <br>${para_opening} has been rejected by central industry as the national electricity industry database
                                      shows that your property has a non-domestic electricity meter point. We can only supply
                                      domestic meter points and are therefore unable to supply your property with electricity.
                                      <br>
                                      <br>If you believe that you do have a domestic electricity meter, I highly recommend that you contact
                                      your current electricity supplier requesting that they update the national electricity industry database to
                                      show this. I also recommend that you contact us too so that we can monitor for this update.
                                      <br>
                                      <br>${payment_para}
                                      <br>
                                      <br>Please don't hesitate to get in contact if you have any questions.
                                    </div>
                                    ${footer}
                                  </body>
                                </html>`
            } else {
              e.templateContent = `<html>
                                  <body>
                                    <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                      Hi ${e.forename},
                                      <br>
                                      <br>Thank you very much for signing up for your ${fuel_registered} with So Energy.
                                      ${gas_insert}
                                      <br>
                                      <br>${para_opening} has been rejected by central industry as your electricity meterpoint
                                      is configured to be supplied by a Green Deal Licensee. Unfortunately, we currently don't offer any Green Deals Plans
                                      and therefore cannot take over your electricity supply as we are currently not a Green Deal Licensee.
                                      <br>
                                      <br>${payment_para}
                                      <br>
                                      <br>Please don't hesitate to get in contact if you have any questions.
                                    </div>
                                    ${footer}
                                  </body>
                                </html>`
            }
          } else if (
            mprnRejectionReason.includes(rejectedMpxn.mprn_rejection_reason)
          ) {
            if (rejectedMpxn.mpan !== null && rejectedMpxn.mprn !== null) {
              e.paymentAdjFl = true
              e.newPaymentAmount = Math.round(parseFloat(e.old_dd_amount) / 2)
              if (!e.seasonalFl === true) {
                var paymentChangeAmount = "&pound;" + e.newPaymentAmount
              } else {
                var paymentChangeAmount =
                  "Summer &pound;" +
                  e.newPaymentAmount * 0.75 +
                  " Winter &pound;" +
                  e.newPaymentAmount * 1.25
              }
              var fuel_registered = "gas and electricity supplies"
              var elec_insert = `<br><br>Your electricity supply registration appears to be progressing fine and your electricity supply start date is expected to be ${e.mpan_ssd}.`
              var para_opening = "However, your gas supply registration"
              var payment_para = `To reflect this, we have reduced your monthly Direct Debit payment amount to ${paymentChangeAmount}.`
            } else if (rejectedMpxn.mpan === null) {
              var fuel_registered = "gas supply"
              var elec_insert = ""
              var para_opening = "Unfortunately, your registration"
              var payment_para =
                "As we are not expecting to take over your supply, we have now cancelled your Direct Debit schedule"
            }
            if (
              rejectedMpxn.mprn_rejection_reason === "Meter Point is extinct" ||
              rejectedMpxn.mprn_rejection_reason ===
                "Supply meter point is either extinct or duplicate"
            ) {
              e.templateContent = `<html>
                                  <body>
                                    <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                    Hi ${e.forename},
                                    <br>
                                    <br>Thank you very much for signing up for your ${fuel_registered} with So Energy.
                                    ${elec_insert}
                                    <br>
                                    <br>${para_opening} has been rejected by the central gas industry.
                                    This is because the details held on the central gas industry database show your
                                    SMP (Supply Meter Point) status as ‘De-Energised’ (DE).
                                    <br>
                                    <br>Please contact your current gas supplier and request them to change your SMP
                                    status to ‘Live’ (LI) Please also contact us so we can monitor for the update.
                                    Once the central gas industry database has been updated, we will submit another
                                    registration to take over your gas supply.
                                    <br>
                                    <br>${payment_para}
                                    <br>
                                    <br>Please don't hesitate to get in contact if you have any questions.
                                  </div>
                                  ${footer}
                                </body>
                              </html>`
            } else if (
              rejectedMpxn.mprn_rejection_reason ===
              "Meter Reading Frequency is not acceptable for the Meter Point"
            ) {
              e.templateContent = `<html>
                                <body>
                                  <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                    Hi ${e.forename},
                                    <br>
                                    <br>Thank you very much for signing up for your ${fuel_registered} with So Energy.
                                    ${elec_insert}
                                    <br>
                                    <br>${para_opening}  has been rejected by the central gas industry as your annual gas
                                    consumption is greater than what we can supply. We can only supply domestic gas meterpoints
                                    with an annual quantity of up to 73,200 kWh and unfortunately, the annual quantity of your
                                    property's gas meter point is greater than 73.200 kWh on the national gas industry database.
                                    We are therefore unable to take over your gas supply as our registrations get rejected.
                                    <br>
                                    <br>${payment_para}
                                    <br>
                                    <br>Please don't hesitate to get in contact if you have any questions.  
                                  </div>
                                  ${footer}
                                </body>
                              </html>`
            } else if (
              rejectedMpxn.mprn_rejection_reason ===
              "Supply Meter Point has an  AQ greater than the Competitive Market Threshold"
            ) {
              e.templateContent = `<html>
                                <body>
                                  <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                  Hi ${e.forename},
                                  <br>
                                  <br>Thank you very much for signing up for your ${fuel_registered} with So Energy.
                                  ${elec_insert}
                                  <br>
                                  <br>${para_opening} has been rejected by the central gas industry as your property
                                  is listed as having an industrial gas meter. We can only supply domestic gas meters
                                  as we do not offer industrial tariffs. Although you may have domestic consumption usage,
                                  your Gas meter itself is listed as being industrial. We are therefore unable to take over
                                  your gas supply as our registrations get rejected.
                                  <br>
                                  <br>If you believe you have a domestic gas meter, please do contact your current supplier
                                  and request they update the national industry database to show your meter as a domestic meter.
                                  Please also let us know once your current supplier confirms the database has been
                                  updated and we will re-register for your supply.
                                  <br>
                                  <br>${payment_para}
                                  <br>
                                  <br>Please don't hesitate to get in contact if you have any questions.
                                </div>
                                ${footer}
                              </body>
                            </html>`
            }
          } else if (rejectedMpxn.mpan !== null && rejectedMpxn.mprn !== null) {
            // Dual fuel
            if (
              mpanRejectionReason.includes(
                rejectedMpxn.mpan_rejection_reason
              ) ||
              rejectedMpxn.mprn_rejection_reason ===
                "Confirmation already exists"
            ) {
              if (onSupplyStatus.includes(rejectedMpxn.mprn_supplystatus)) {
                // Gas on supply
                var fuel_ok = "gas"
                var fuel_ok_ssd = dayjs(rejectedMpxn.mprn_ssd).format("Do MMMM")
                var fuel_rejected = "electricity"
                e.paymentAdjFl = true
                e.newPaymentAmount = Math.round(parseFloat(e.old_dd_amount) / 2)
                if (!e.seasonalFl === true) {
                  var paymentChangeAmount = "&pound;" + e.newPaymentAmount
                } else {
                  var paymentChangeAmount =
                    "Summer &pound;" +
                    e.newPaymentAmount * 0.75 +
                    " Winter &pound;" +
                    e.newPaymentAmount * 1.25
                }
                e.templateContent = `<html>
                                  <body>
                                    <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                      Hi ${e.forename},
                                      <br>
                                      <br>Thank you very much for signing up for gas and electricity supply with So Energy.
                                      <br>
                                      <br>Your ${fuel_ok} supply registration appears to be progressing fine and your ${fuel_ok} supply start date is expected to be ${fuel_ok_ssd}.
                                      <br>
                                      <br>However, your ${fuel_rejected} supply registration with us has been rejected by central industry as you have either signed up with another
                                      supplier before we requested to register you for supply or have recently joined another supplier.
                                      If you would like us to supply your ${fuel_rejected} and have recently signed up with another supplier,
                                      please contact them to request they withdraw their registration. If another supplier has already taken over
                                      your ${fuel_rejected} supply, you will have to stay on their supply for 28 days before we can submit another registration.
                                      Please keep us informed of the next steps you would like us to take regarding your ${fuel_rejected} supply.
                                      <br>
                                      <br>
                                      As we are not currently expected to take over your ${fuel_rejected} supply, we have reduced your Direct Debit
                                      monthly payment amount to ${paymentChangeAmount}. If we do not hear back from you in 5 working days, we will
                                      assume that you do not want us to take over your ${fuel_rejected} supply and will not submit any further registrations.
                                      <br>
                                      <br>If you have any questions, please do not hesitate to get in touch.
                                    </div>
                                    ${footer}
                                  </body>
                                </html>`
              } else if (
                onSupplyStatus.includes(rejectedMpxn.mpan_supplystatus)
              ) {
                // Elec on supply
                var fuel_ok = "electricity"
                var fuel_ok_ssd = dayjs(rejectedMpxn.mpan_ssd).format("Do MMMM")
                var fuel_rejected = "gas"
                e.paymentAdjFl = true
                e.newPaymentAmount = Math.round(parseFloat(e.old_dd_amount) / 2)
                if (!e.seasonalFl === true) {
                  var paymentChangeAmount = "&pound;" + e.newPaymentAmount
                } else {
                  var paymentChangeAmount =
                    "Summer &pound;" +
                    e.newPaymentAmount * 0.75 +
                    " Winter &pound;" +
                    e.newPaymentAmount * 1.25
                }
                e.templateContent = `<html>
                                  <body>
                                    <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                      Hi ${e.forename},
                                      <br>
                                      <br>Thank you very much for signing up for gas and electricity supply with So Energy.
                                      <br>
                                      <br>Your ${fuel_ok} supply registration appears to be progressing fine and your ${fuel_ok} supply start date is expected to be ${fuel_ok_ssd}.
                                      <br>
                                      <br>However, your ${fuel_rejected} supply registration with us has been rejected by central industry as you have either signed up with another
                                      supplier before we requested to register you for supply or have recently joined another supplier.
                                      If you would like us to supply your ${fuel_rejected} and have recently signed up with another supplier,
                                      please contact them to request they withdraw their registration. If another supplier has already taken over
                                      your ${fuel_rejected} supply, you will have to stay on their supply for 28 days before we can submit another registration.
                                      Please keep us informed of the next steps you would like us to take regarding your ${fuel_rejected} supply.
                                      <br>
                                      <br>
                                      As we are not currently expected to take over your ${fuel_rejected} supply, we have reduced your Direct Debit
                                      monthly payment amount to ${paymentChangeAmount}. If we do not hear back from you in 5 working days, we will
                                      assume that you do not want us to take over your ${fuel_rejected} supply and will not submit any further registrations.
                                      <br>
                                      <br>If you have any questions, please do not hesitate to get in touch.
                                    </div>
                                    <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                      Thanks,
                                      <br>Charlie
                                      <br>
                                      <br><img src='https://s3-eu-west-1.amazonaws.com/soenergy-production/assets/freshdesk/So+Energy+Logo+v2.png' style='vertical-align: middle;' valign='middle' width='140'>
                                    </div>
                                  </body>
                                </html>`
              } else {
                e.templateContent = `<html>
                                <body>
                                  <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                    Hi ${e.forename},
                                    <br>
                                    <br>Thank you very much for signing up for gas and electricity supply with So Energy.
                                    <br>
                                    <br>Unfortunately, your gas and electricity supply registrations with us have been
                                    rejected by central industry as you have either signed up with another supplier before we
                                    requested to register your supplies or have recently joined another supplier. If you
                                    would like us to take over your energy supply and have recently signed up with another
                                    supplier, please contact them to request they withdraw their registrations. If another
                                    supplier has already taken over your energy supplies, you will have to stay on their supply
                                    for 28 days before we can submit further registrations. Please keep us informed of the next
                                    steps you would like us to take regarding your gas and electricity supplies.
                                    <br>
                                    <br>As we are not currently expected to take over your energy supplies, we have cancelled
                                    your direct debit monthly payment schedule. If we do not hear back from you in 5 working days,
                                    we will assume that you do not want us to take over your energy supplies and will not submit any
                                    further registrations.
                                    <br>
                                    <br>If you have any questions, please do not hesitate to get in touch.
                                  </div>
                                  ${footer}
                                </div>
                              </body>
                            </html>`
              }
            }
          } else if (rejectedMpxn.mpan !== null) {
            // Elec only
            if (
              mpanRejectionReason.includes(rejectedMpxn.mpan_rejection_reason)
            ) {
              var fuel_rejected = "electricity"
              e.templateContent = `<html>
                                <body>
                                  <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                  Hi ${e.forename},
                                  <br>
                                  <br>Thank you very much for signing up for your electricity supply with So Energy.
                                  <br>
                                  <br>Unfortunately, your ${fuel_rejected} supply registration with us has been rejected
                                  by central industry as you have either signed up with another supplier before we requested
                                  to register you for supply or have recently joined another supplier. If you would like us to supply
                                  your ${fuel_rejected} and have recently signed up with another supplier,
                                  please contact them to request they withdraw their registration. If another supplier
                                  has already taken over your ${fuel_rejected} supply, you will have to stay on their supply
                                  for 28 days before we can submit another registration. Please keep us informed of the next steps
                                  you would like us to take regarding your ${fuel_rejected} supply.
                                  <br><br>
                                  As we are not currently expected to take over your ${fuel_rejected} supply, we have
                                  cancelled your Direct Debit monthly payment schedule. If we do not hear back from you in 5 working days,
                                  we will assume that you do not want us to take over your ${fuel_rejected} supply and will not
                                  submit any further registrations.
                                  <br><br>
                                  If you have any questions, please do not hesitate to get in touch.
                                  </div>
                                  ${footer}
                                </body>
                              </html>`
            }
          } else {
            // Gas only
            if (
              rejectedMpxn.mprn_rejection_reason ===
              "Confirmation already exists"
            ) {
              var fuel_rejected = "gas"
              e.templateContent = `<html>
                                <body>
                                  <div style='max-width:700px; padding: 10px; margin: 0 auto;'>
                                    Hi ${e.forename},
                                    <br>
                                    <br>Thank you very much for signing up for you gas supply with So Energy.
                                    <br>
                                    <br>Unfortunately, your ${fuel_rejected} supply registration with us has been rejected
                                    by central industry as you have either signed up with another supplier before we requested
                                    to register you for supply or have recently joined another supplier. If you would like us to supply
                                    your ${fuel_rejected} and have recently signed up with another supplier,
                                    please contact them to request they withdraw their registration. If another supplier
                                    has already taken over your ${fuel_rejected} supply, you will have to stay on their supply
                                    for 28 days before we can submit another registration. Please keep us informed of the next steps
                                    you would like us to take regarding your ${fuel_rejected} supply.
                                    <br>
                                    <br>
                                    As we are not currently expected to take over your ${fuel_rejected} supply, we have
                                    cancelled your Direct Debit monthly payment schedule. If we do not hear back from you in 5 working days,
                                    we will assume that you do not want us to take over your ${fuel_rejected} supply and will not
                                    submit any further registrations.
                                    <br>
                                    <br>If you have any questions, please do not hesitate to get in touch.
                                  </div>
                                  ${footer}
                                </body>
                              </html>`
            }
          }
          if (!e.templateContent) {
            e._rowVariant = "danger"
          }
        })
        context.commit("SET_DELIVERY_ERROR_STATUS", { error: false })
        context.commit("SET_INDUSTRY_REJECTION", response.data)
        context.commit("SET_INDUSTRY_REJECTION_STATE", false)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  importRecords(context) {
    context.commit("SET_DELIVERY_ERROR_STATUS", { error: false })
    context.commit("SET_INDUSTRY_REJECTION_STATE", true)
    Rejections.importIndustryRejections()
      .then((response) => {
        context.dispatch("getIndustryRejected")
      })
      .catch((error) => {
        console.log(error)
      })
  },
  lookupAccount(context, accountNumber) {
    Rejections.lookupRecord(accountNumber)
      .then((response) => {
        if (response.data.length === 0) {
          response.data.push("No Record Found")
        }
        context.commit("SET_LOOKUP_RESULTS", response.data)
        context.commit("SET_DELIVERY_ERROR_STATUS", { error: false })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  send(context, data) {
    Rejections.sendIndRejEmail(data.id, data)
      .then((response) => {
        context.commit("SET_DELIVERY_ERROR_STATUS", { error: false })
        if (response.data.error) {
          context.commit("SET_DELIVERY_ERROR_STATUS", response.data)
        }
        context.commit("UPDATE_INDREJ_ROW", data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  sendAll(context, data) {
    data.forEach(function (x) {
      context.dispatch("send", x)
    })
  },
  deleteRow(context, data) {
    Rejections.deleteRejEmail(data.id)
      .then((response) => {
        context.commit("SET_DELIVERY_ERROR_STATUS", { error: false })
        context.commit("DELETE_INDREJ_ROW", data)
      })
      .catch((error) => {
        console.log(error.response)
      })
  },
  deleteAll(context, data) {
    data.forEach(function (x) {
      context.dispatch("deleteRow", x)
    })
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  setters,
  state,
}
