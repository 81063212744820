<template>
  <div>
    <table class="dashboard">
      <tr>
        <td
          :class="{
            'blue ': countBoxOne !== 0,
            'outline-blue': countBoxOne === 0,
          }"
          class="dashboard-col"
        >
          <h2>{{ titleBoxOne }}</h2>
          <h2>
            <span v-if="countBoxOne !== 0">
              {{ countBoxOne }}
            </span>
            <span v-else> ... </span>
          </h2>
          <slot name="box-one-slot"> </slot>
        </td>
        <td
          :class="{
            'red ': countBoxTwo !== 0,
            'outline-blue': countBoxTwo === 0,
          }"
          class="dashboard-col"
        >
          <h2>{{ titleBoxTwo }}</h2>
          <h2>
            <span v-if="countBoxTwo !== 0">
              {{ countBoxTwo }}
            </span>
            <span v-else> ... </span>
          </h2>
          <slot name="box-two-slot"> </slot>
        </td>
        <td
          :class="{
            'light-blue': countBoxThree !== 0,
            'outline-blue': countBoxThree === 0,
          }"
          class="dashboard-col"
        >
          <h2>{{ titleBoxThree }}</h2>
          <h2>
            <span v-if="countBoxThree !== 0">
              {{ countBoxThree }}
            </span>
            <span v-else> ... </span>
          </h2>
          <slot name="box-three-slot"> </slot>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    titleBoxOne: {
      type: String,
      default: "Awaiting",
    },
    countBoxOne: Number,
    titleBoxTwo: {
      type: String,
      default: "Investigate",
    },
    countBoxTwo: Number,
    titleBoxThree: {
      type: String,
      default: "Completed",
    },
    countBoxThree: Number,
  },
}
</script>

<style lang="scss">
.dashboard-col {
  width: 33%;
  padding: $spacing-2 0;
  margin: $spacing-2;
  border-radius: 5px;
  vertical-align: top;
}
.dashboard {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 15px;
}
</style>
