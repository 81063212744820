import { checks } from "./checks"
import { unbilledChecks } from "./unbilledCheck"
import { followUpChecks } from "./followUpChecks"
import { fetchFollowUpTemplates } from "./followTemplates"

export function followUpReview(e) {
  try {
    e.clicked = false
    e.edited = false
    e.disabled = false
    e.process_disabled = false
    e.unbilled = false

    unbilledChecks(e)
    checks(e)
    followUpChecks(e)
    //If the check has taken place we can compile a template
    if (e.follow_up_fl === true) {
      fetchFollowUpTemplates(e)
    }
    if (e.templateContent === null || e.templateContent === undefined) {
      e._rowVariant = "danger"
      e.notOkToSend = true
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
