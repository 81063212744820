import apiArk from "./Ark"
import apiArk2 from "@/api/ApiV2"

export default {
  getNeedReadTickets() {
    return apiArk.get("junifer/tickets/need_read_tickets")
  },
  getNeedReadPhoneTickets() {
    return apiArk.get("junifer/tickets/need_read_phone")
  },
  closeTicket(ticket_id, payload) {
    return apiArk.put("junifer/tickets/" + ticket_id + "/close", { payload })
  },
  updateTicketStepBulk(csv) {
    return apiArk2.post("/tickets/updateBulk", csv)
  },
}
