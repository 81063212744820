<template>
  <div id="app">
    <div
      v-if="isStagingPreview"
      class="staging-banner__container"
      role="alert"
      aria-live="polite"
    >
      <p>
        <b>Staging preview</b>: Some changes may be unfinished. Please use the
        <a href="https://ark.sandbox.soenergy.co/">sandbox environment</a>.
      </p>
    </div>
    <Navbar @open="openSidebarPanel" />
    <SidebarSlideMenu
      :show-panel="showSidebarPanel"
      @close="closeSidebarPanel"
    />
    <router-view></router-view>
  </div>
</template>

<script>
const separator = {
  template: `<hr style="border-color: rgba(0, 0, 0, 0.1); margin: 20px;">`,
}

import Navbar from "@/components/Menu/Navbar"
import SidebarSlideMenu from "@/components/Menu/SidebarSlideMenu"
import authentication from "./authentication"
export default {
  name: "App",
  components: {
    Navbar,
    SidebarSlideMenu,
  },
  data() {
    return {
      showSidebarPanel: false,
    }
  },
  computed: {
    isStagingPreview() {
      return process.env.VUE_APP_VERCEL_URL?.includes("staging")
    },
    isAuthenticated() {
      return authentication.isAuthenticated()
    },
  },
  watch: {
    $route() {
      this.closeSidebarPanel()
    },
  },
  methods: {
    openSidebarPanel() {
      this.showSidebarPanel = true
    },
    closeSidebarPanel() {
      this.showSidebarPanel = false
    },
    logOut() {
      authentication.signOut()
    },
  },
}
</script>

<style lang="scss" scoped>
.staging-banner {
  &__container {
    height: 40px;
    width: 100%;
    padding: 8px;
    text-align: center;
    color: black;
    background-color: $yellow-300;
    font-size: $size-7;
    overflow: hidden;

    & a {
      color: inherit;
    }
  }
}
</style>
