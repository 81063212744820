<template>
  <div>
    <div align="left">
      <b-jumbotron
        style="min-width: 600px"
        class="white header-width"
        :header="title"
        :lead="summary"
      >
        <a v-b-toggle.collapse-3 href="#" class="header-toggle"
          >More information</a
        >
        <b-collapse id="collapse-3">
          <b-card class="header-width">
            <div class="header-description">
              {{ description }}
            </div>
            <div class="description-body">
              <slot name="description-body"> </slot>
            </div>
          </b-card>
        </b-collapse>
      </b-jumbotron>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "summary", "description"],
}
</script>

<style lang="scss">
.header-width {
  max-width: 1200px;
}

.header-width h1 {
  font-weight: $weight-bold;
}

.header-toggle {
  display: inline-block;
  margin: 0 0 $spacing-5 0;
}
.header-toggle:hover {
  color: $day;
}

.description-body {
  color: $night;
}

.description-body p {
  margin-bottom: $spacing-2;
}

.description-body .description-body__text-block {
  margin-bottom: $spacing-6;
}

.description-body h5 {
  display: block;
  font-weight: $weight-bold;
  text-decoration: underline;
  font-size: $size-5;
  margin: 0 0 $spacing-2 0;
}

.description-body ul,
.description-body ol {
  margin: 0 0 $spacing-6 $spacing-5;
  padding: 0;
}

.description-body ul li {
  margin: 0 0 $spacing-1 0;
}

.description-body table {
  margin: 0 0 $spacing-6 0;
}
</style>
