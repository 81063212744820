<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Blacklist accounts"
        summary="Removing accounts from email distribution lists"
      >
        <template v-slot:description-body> </template>
      </header-block>
    </b-row>

    <page-section v-if="contentTemplates.length > 0" title="">
      <template v-slot:content>
        <b-row>
          <b-col>
            <b-form-group
              label-cols-sm="6"
              label="Account Number"
              label-align-sm="right"
            >
              <b-form-input
                v-model="accountNumber"
                style="display: inline; float: left"
              />
            </b-form-group>
          </b-col>
          <b-col class="text-left">
            <b-form-group>
              <table>
                <tr v-for="template in contentTemplates" :key="template.id">
                  <td>{{ template.name }}</td>
                  <td>
                    <b-form-checkbox
                      :id="'checkbox-' + template.id"
                      v-model="template.selected"
                    />
                  </td>
                  <td>
                    <b-form-input
                      :id="'to_date-' + template.id"
                      v-model="template.to_date"
                      type="date"
                    />
                  </td>
                </tr>
              </table>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align="center">
          <b-col>
            <b-button
              variant="outline-success"
              class="btn-block"
              :disabled="
                isBusy || accountNumber === null || accountNumber.length !== 8
              "
              @click="createBlacklist()"
            >
              <span v-if="isBusy"><b-spinner small /></span>
              <span v-else>Create Blacklist</span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </page-section>

    <page-section v-if="blacklist.length > 0" title="">
      <template v-slot:content>
        <div class="float-right">
          <b-form inline>
            <input
              id="accountsearch"
              v-model="filter"
              type="text"
              :disabled="isBusy"
              placeholder="Account number...."
              style="width: 300px"
              class="form-control mb-2 mr-sm-2 mb-sm-0"
            />
            <p class="m-2">Total: {{ filteredResults.length }}</p>
          </b-form>
        </div>

        <b-table
          hover
          small
          responsive
          :busy="isBusy"
          :items="dataTable.items"
          :fields="dataTable.fields"
          :per-page="perPage"
          :current-page="currentPage"
          class="mt-5"
        >
          <template v-slot:cell(show)="row">
            <b-button
              variant="outline-primary"
              @click="fetchCompleteRecord(row.item)"
            >
              {{ row.detailsShowing ? "Hide" : "Show" }}
            </b-button>
          </template>

          <template slot="row-details" slot-scope="row">
            <b-row class="text-left">
              <b-col>
                <table>
                  <tr
                    v-for="template in row.item.templates"
                    :key="template.communication_content_id"
                  >
                    <td>{{ template.name }}</td>
                    <td>
                      <b-form-checkbox
                        v-model="template.selected"
                        :disabled="!row.item.editable"
                      />
                    </td>
                    <td>
                      <span v-if="row.item.editable">
                        <b-form-input
                          v-model="template.to_date"
                          style="width: 200px"
                          type="date"
                        />
                      </span>
                      <span v-else>
                        <b-form-input
                          v-show="
                            template.to_date !== '9999-12-31' &&
                            template.to_date !== undefined
                          "
                          v-model="template.to_date"
                          style="width: 200px"
                          :disabled="!row.item.editable"
                          type="date"
                        />
                      </span>
                    </td>
                  </tr>
                </table>

                <b-button
                  variant="outline-dark"
                  @click="row.item.editable = !row.item.editable"
                >
                  {{ row.item.editable ? "Save" : "Edit" }}
                </b-button>
              </b-col>
            </b-row>
          </template>

          <template v-slot:cell(delete)="row">
            <b-button
              variant="outline-danger"
              @click="deleteBlacklist(row.item)"
            >
              Delete
            </b-button>
          </template>
        </b-table>

        <div v-if="filteredResults.length > perPage">
          <b-pagination
            v-model="currentPage"
            :total-rows="filteredResults.length"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>

          <p class="mt-3">
            Page: {{ currentPage }} /
            {{ Math.ceil(filteredResults.length / perPage) }}
          </p>
        </div>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Communication from "@/api/Communication"
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
const titleize = require("titleize")

export default {
  components: {
    headerBlock,
    pageSection,
  },
  data() {
    return {
      filter: null,
      accountNumber: null,
      isBusy: false,
      blacklist: [],
      perPage: 20,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters("comms", ["templates"]),
    filteredResults() {
      let filteredAccounts = this.blacklist
      if (this.filter !== null) {
        filteredAccounts = this.blacklist.filter((comms) => {
          return comms.account_number.includes(this.filter)
        })
      }
      return filteredAccounts
    },
    contentTemplates() {
      var arr = []
      var that = this
      this.templates.forEach(function (x) {
        x.name = that.formatName(x.name)
        x.selected = false
        x.to_date = null
        arr.push(x)
      })
      return arr
    },
    dataTable() {
      var accounts = {
        fields: [
          { key: "account_number", label: "Account Number", sortable: false },
          { key: "show", label: "", sortable: false },
          { key: "delete", label: "", sortable: false },
        ],
        items: this.filteredResults,
      }
      return accounts
    },
    editedArrays() {
      var arr = []
      this.blacklist.forEach(function (x) {
        arr.push(x.editable)
      })
      return arr
    },
  },
  watch: {
    editedArrays: {
      handler: function (newValue, oldValue) {
        var that = this
        var index = this.compareArrays(newValue, oldValue)
        if (index !== undefined && newValue[index] === false) {
          var updatedRecord = that.blacklist[index]
          var activeBlacklist = updatedRecord.templates.filter(
            (s) => s.selected
          )
          var disabledBlacklist = updatedRecord.templates.filter(
            (s) => !s.selected && s.id !== undefined
          )
          console.log(activeBlacklist, disabledBlacklist, index)
          if (activeBlacklist.length > 0) {
            var arr = []
            activeBlacklist.forEach(function (y) {
              const obj = {}
              obj.id = y.communication_content_id
              obj.to_date = y.to_date
              arr.push(obj)
            })
            console.log(arr)
            Communication.createBlacklist(updatedRecord.account_number, arr)
          }
          if (disabledBlacklist.length > 0) {
            disabledBlacklist.forEach(function (x) {
              Communication.deleteBlacklistTemplate(x.id)
            })
          }
        }
      },
    },
  },
  async beforeMount() {
    await this.getTemplates()
  },
  mounted() {
    this.getBlacklist()
  },
  methods: {
    ...mapActions("comms", ["getTemplates"]),
    formatName(name) {
      return titleize(name.replace(/-/g, " "))
    },
    fetchCompleteRecord(row) {
      row._showDetails = !row._showDetails
      if (row._showDetails === true) {
        this.fetchRowInfo(row)
      }
    },
    compareArrays(arr1, arr2) {
      var chgValueIndex
      arr1.forEach(function (x, index) {
        if (x !== arr2[index] && arr2[index] !== undefined) {
          chgValueIndex = index
        }
      })
      return chgValueIndex
    },
    fetchRowInfo(row) {
      this.isBusy = true
      Communication.getAccountBlacklists(row.account_number)
        .then((response) => {
          row.templates = response.data

          var newTemplates = this.templates
          newTemplates.forEach(function (x) {
            x.selected = false
            x.show_date = false
            if (x.to_date !== "9999-12-31") {
              x.show_date = true
            }
          })
          newTemplates.forEach(function (x) {
            var blacklisted = row.templates.filter(
              (s) => s.communication_content_id == x.id
            )
            if (blacklisted.length > 0) {
              var index = row.templates.findIndex(
                (s) => s.id == blacklisted[0].id
              )
              if (row.templates[index].name === undefined) {
                row.templates[index].selected = true
                row.templates[index].communication_content_id = x.id
                row.templates[index].name = x.name
                row.templates[index].show_date = false
              }
            } else {
              var obj = {
                selected: false,
                communication_content_id: x.id,
                name: x.name,
              }
              row.templates.push(obj)
            }
          })
          this.isBusy = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteBlacklist(row) {
      this.isBusy = true
      Communication.deleteBlacklist(row.account_number).catch((error) => {
        console.log(error)
      })
      var index = this.blacklist.findIndex(
        (s) => s.account_number == row.account_number
      )
      this.blacklist.splice(index, 1)
      this.isBusy = false
    },
    getBlacklist() {
      this.isBusy = true
      Communication.getBlacklist()
        .then((response) => {
          var arr = []
          response.data.forEach(function (x) {
            var obj = {
              account_number: x,
              editable: false,
              _showDetails: false,
            }
            arr.push(obj)
          })
          this.blacklist = arr
          this.isBusy = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    createBlacklist() {
      this.isBusy = true
      var selected = this.contentTemplates.filter((x) => x.selected === true)
      var selectedArr = selected.map((x) => {
        const obj = {}
        ;(obj.id = x.id), (obj.to_date = x.to_date)
        return obj
      })
      Communication.createBlacklist(this.accountNumber, selectedArr)
        .then(() => {
          this.getBlacklist()
          this.isBusy = false
          this.accountNumber = null
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
