import email from "@/constants/email"
import { unbilledChecks } from "./unbilledCheck"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import isBetween from "dayjs/plugin/isBetween"
dayjs.extend(advancedFormat)
dayjs.extend(isBetween)

export function fetchTemplate(e) {
  try {
    const nominalValueLimit = 167
    var unbilledReadingCost_table,
      PsrReadingText,
      payment_change_calculation,
      paymentAmountText,
      currentPayments,
      accountBalance_table,
      paymentChange,
      oneOffPayment,
      payAdj,
      personalProjection,
      refundText,
      pp_wording,
      unbilled_line,
      acc_bal_line,
      calc_wording,
      introWording,
      paymentChangeNotification,
      calc_wording_adding

    //Current payment amount
    var ddamount_equal = Math.round(e.current_payment_amount)

    //Customer
    var primaryContacts = e.account.contacts.filter(
      (contact) => contact.junifer_primary_contact
    )
    if (primaryContacts.length > 0) {
      var firstName = primaryContacts[0].first_name
    } else {
      var firstName = ""
    }
    var accountNumber = e.account.junifer_account_number

    //Next payment date
    var today = dayjs(new Date())
    var nextPaymentDate = dayjs(e.current_payment_next_dt)
    const nextPaymentDateOctNov = dayjs(nextPaymentDate).isBetween(
      "2022-10-01",
      "2022-11-31",
      "day",
      "[]"
    )
    const nextPaymentDateDecMar = dayjs(nextPaymentDate).isBetween(
      "2022-12-01",
      "2023-03-31",
      "day",
      "[]"
    )
    var dayDifference = nextPaymentDate.diff(today, "days")

    if (dayDifference < 21) {
      e.suggested_payment_next_dt = nextPaymentDate
        .add(1, "months")
        .format("YYYY-MM-DD")
    } else {
      e.suggested_payment_next_dt = nextPaymentDate.format("YYYY-MM-DD")
    }
    var paymentdt = dayjs(e.suggested_payment_next_dt).format("Do MMMM")

    if (e.unbilled_readings_cost > 0) {
      unbilledReadingCost_table = `&pound${Number(
        e.unbilled_readings_cost
      ).toFixed(2)} DR`
    } else if (e.unbilled_readings_cost < 0) {
      unbilledReadingCost_table = `&pound${-Number(
        e.unbilled_readings_cost
      ).toFixed(2)} CR`
    } else {
      unbilledReadingCost_table = `&pound0`
    }

    if (e.account_balance > 0) {
      accountBalance_table = `&pound${Number(e.account_balance).toFixed(2)} DR`
    } else if (e.account_balance < 0) {
      accountBalance_table = `&pound${(-Number(e.account_balance)).toFixed(
        2
      )} CR`
    } else {
      accountBalance_table = `&pound0`
    }

    //If junifer review or So Energy adhoc
    if (e.payment_schedule_id !== null) {
      introWording = `Periodically we review your Direct Debit payment amount to make sure you're not paying too much or too little. 
      We do this so that no unexpected large debit or credit builds up on your account.<br><br>`
    } else {
      introWording = ``
    }

    unbilledChecks(e)

    //Current payment amount
    var currPayAmount = Math.round(e.current_payment_amount)
    var currPayAmount_summer = (e.current_payment_amount * 0.75).toFixed(2)
    var currPayAmount_winter = (e.current_payment_amount * 1.25).toFixed(2)

    if (e.current_payment_seasonal_fl) {
      currentPayments = `summer payments &pound;${currPayAmount_summer} (April to September) and winter payments of &pound;${currPayAmount_winter} (October to March).`
      payAdj = `Your seasonal payments are 25% less in summer and 25% more in winter.`
    } else {
      currentPayments = `&pound;${currPayAmount}`
      payAdj = ``
    }

    if (e.follow_up_fl) {
      var suggPayAmount = Math.round(e.suggested_payment_amount_on_follow_up)
      var suggPayAmount_summer = (suggPayAmount * 0.75).toFixed(2)
      var suggPayAmount_winter = (suggPayAmount * 1.25).toFixed(2)

      if (e.projection_cost_type === "Personal Projection") {
        personalProjection = Number(e.junifer_personal_projection).toFixed(0)
        pp_wording = "Estimated Annual Energy Cost"
      } else {
        personalProjection = Number(e.current_tariff_annual_cost).toFixed(0)
        pp_wording = "Annual Energy Cost on Current Tariff"
      }
    } else {
      var suggPayAmount = Math.round(e.suggested_payment_amount)
      var suggPayAmount_summer = (suggPayAmount * 0.75).toFixed(2)
      var suggPayAmount_winter = (suggPayAmount * 1.25).toFixed(2)

      if (e.projection_cost_type === "Personal Projection") {
        personalProjection = Number(e.junifer_personal_projection).toFixed(0)
        pp_wording = "Estimated Annual Energy Cost"
      } else {
        personalProjection = Number(e.current_tariff_annual_cost).toFixed(0)
        pp_wording = "Annual Energy Cost on Current Tariff"
      }
    }

    var refund = Number(e.suggested_refund).toFixed(2)

    //Set template
    var bodyTextStyle = email.textStyle

    // If on PSR then chnage amount ( iF already mid-process then proceed as before )
    PsrReadingText = `If you are unable to read the meter due to disability or having a special requirement, you may like to add 
    your details to our <b><a href='https://help.so.energy/support/solutions/articles/7000023268-priority-services-register-psr-' target='_blank'>
    Priority Services Register (PSR)</a></b>. Services can include quarterly meter readings, or assistance if you have difficulty 
    reading your statements.`

    //Set email links
    var faqLink =
      "https://help.so.energy/support/solutions/articles/7000046258-how-do-you-review-my-payments-"
    var howToReadMeterUrl =
      "https://help.so.energy/support/solutions/articles/7000046463"
    var submitReadingsUrl = "https://www.so.energy/accounts/readings"

    if (
      !e.has_meter_readings &&
      (e.follow_up_reads_check === undefined || !e.follow_up_reads_check) &&
      !e.psr
    ) {
      // Without readings
      if (
        (e.perc_change > 0 && e.perc_change < 150) ||
        (isNaN(e.perc_change) &&
          e.nominal_change > 0 &&
          e.nominal_change < nominalValueLimit)
      ) {
        if (e.current_payment_seasonal_fl) {
          paymentAmountText = `summer payments &pound;${suggPayAmount_summer} (April - September) and winter payments &pound;${suggPayAmount_winter} (October - March)`
        } else {
          paymentAmountText = `&pound;${suggPayAmount} per month`
        }
        e.templateContent = `<html>
                              <body> 
                                <div style='${bodyTextStyle}'>
                                  Hello ${firstName},
                                  <br><br>
                                  ${introWording}
                                  So that our review is as accurate as possible, please submit a meter reading over <b><u>the next 7 days</u></b>. 
                                  You can also make a one-off payment through your 
                                  <a href='https://www.so.energy/accounts/payments' target='_blank'>online account</a> or by contacting us directly. 
                                  As it currently stands, we would need to increase your Direct Debit amount to ${paymentAmountText}. 
                                  Your meter reading and your new account balance will be used to review your payments again after 7 days.
                                  <br><br>
                                  The easiest way to give us your meter readings is by submitting them via 
                                  your <a href='${submitReadingsUrl}' target='_blank'><b>Online Account</b></a>, 
                                  alternatively you can send them to us by email <a href='mailto:help@so.energy'>help@so.energy</a> or 
                                  just giving us a call on <b>0330 111 5050</b> (our lines are open from 9am till 5pm, Monday to Friday).  
                                  If you're unsure as to how to read your meter we've put together a 
                                  guide: <a href='${howToReadMeterUrl}' target='_blank'><b>How to read your meter</b></a>.
                                  <br><br>
                                  ${PsrReadingText}
                                  <br><br>
                                  If you have any questions in the meantime, please don't hesitate to ask. You may be asked for 
                                  your So Energy Account Number which is ${accountNumber}.
                                </div>
                              </body>
                            </html>`
      } else {
        e.templateContent = `<html>
                              <body> 
                                <div style='${bodyTextStyle}'>
                                  Hello ${firstName},
                                  <br><br>
                                  ${introWording}
                                  So that our review is as accurate as possible, please submit an up to date meter reading over <b><u>the next 7 days</u></b>. 
                                  If we don't receive a meter read from you, we will not be able to accurately adjust your Direct Debit.
                                  <br><br>
                                  The easiest way to give us your meter readings is by submitting them via your 
                                  <a href='${submitReadingsUrl}' target='_blank'><b>Online Account</b></a>, alternatively you can send them to us 
                                  by email <a href='mailto:help@so.energy'>help@so.energy</a> or just giving us a call on <b>0330 111 5050</b> 
                                  (our lines are open 9am to 5pm, Monday to Friday). If you're unsure as to how to read your meter we've put together a 
                                  guide: <a href='${howToReadMeterUrl}' target='_blank'><b>How to read your meter</b></a>.
                                  <br><br>
                                  ${PsrReadingText}
                                  <br><br>
                                  If you have any questions in the meantime, please don't hesitate to ask. You may be asked for your Account Number 
                                  which is ${accountNumber}.
                                </div>
                              </body>
                            </html>`
      }

      e.emailSubject = "Can we have a meter reading please?"
      e.freshdesk_sub_1 = "Direct Debit Review"
      e.emailTag = ["paymentreview_nocalc", "Dispatch'r to Junifer (Outbound)"]
      e.paymentChange = false
      e.seven_day_follow_up = true
      e.seven_day_follow_up_date = dayjs(today).add(7, "days")
    } else {
      //With readings

      if (e.follow_up_fl) {
        introWording = e.followUpIntroWording
      }

      if (e.perc_change < 0 || (isNaN(e.perc_change) && e.nominal_change < 0)) {
        if (e.current_payment_seasonal_fl) {
          paymentAmountText = `We have reviewed your monthly payment amount and we are letting you know that we will be reducing your monthly payment amount to summer payments &pound;${suggPayAmount_summer} (April - September) and winter payments &pound;${suggPayAmount_winter} (October - March) <b><u>from ${paymentdt} onwards</u></b>.`
        } else {
          paymentAmountText = `We have reviewed your monthly payment amount and we are letting you know that we will be reducing your monthly payment amount from &pound;${ddamount_equal} to &pound;${suggPayAmount} which will be <b><u>from ${paymentdt} onwards</u></b>.`
        }

        if (refund > 500) {
          refundText = `<br><br> We also recommend a refund of &pound;${refund}. Before we can action this refund, we will need a <b>photo of your meter(s)</b>. 
          You can just reply to this email attaching your photos.  Once we have checked your readings, we'll process the refund.`
        } else if (refund > 0 && refund < 500) {
          refundText = `<br><br> We also recommend a refund of &pound;${refund}. If you would like this refund, please just let us know by replying to this email, 
          otherwise this credit will remain in your account.`
        } else {
          refundText = ``
        }

        e.templateContent = `<html>
                              <body> 
                                <div style='${bodyTextStyle}'>
                                Hello ${firstName},
                                <br><br>
                                ${introWording}
                                ${paymentAmountText}
                                ${refundText}
                                <br><br>
                                If you have any questions in the meantime, please don't hesitate to ask. If you get in touch, 
                                you may be asked for your Account Number which is ${accountNumber}.
                                </div>
                              </body>
                            </html>`
        e.emailTag = [
          "paymentreview_nocalc",
          "Dispatch'r to Junifer (Outbound)",
        ]
        e.emailSubject = "We're reducing your Direct Debit"
        e.freshdesk_sub_1 = "Direct Debit Review"
        e.paymentChange = true
        e.seven_day_follow_up = false
      } else if (
        e.perc_change == 0 ||
        (isNaN(e.perc_change) && e.nominal_change == 0)
      ) {
        if (e.current_payment_seasonal_fl) {
          paymentAmountText = `summer payments &pound;${suggPayAmount_summer} (April - September) and winter payments &pound;${suggPayAmount_winter} (October - March), `
        } else {
          paymentAmountText = `&pound;${suggPayAmount} each month `
        }

        if (refund > 500) {
          refundText = `<br><br>We also recommend a refund of &pound;${refund} before we can do this, we will 
          need a <b>photo of your meter(s)</b>. You can just 
          reply to this email attaching your photos. Once we have checked your readings, we'll process the refund.`
          e.emailSubject =
            "We're able to issue you a refund, can we have a photo?"
        } else if (refund > 0 && refund < 500) {
          refundText = `<br><br> We also recommend a refund of &pound;${refund}. If you would like this refund, please just let us know by replying to this email, 
          otherwise this credit will remain in your account.`
          e.emailSubject = "We're able to issue you a refund"
        } else {
          refundText = ``
          e.emailSubject = "We have reviewed your Direct Debit amount"
        }

        e.templateContent = `<html>
                               <body> 
                                 <div style='${bodyTextStyle}'>
                                 Hello ${firstName},
                                 <br><br>
                                 ${introWording}
                                 We have reviewed your monthly payment amount and we are letting you know that it looks like you're on track by paying ${paymentAmountText} so we're keeping it the same.
                                 ${refundText}
                                 <br><br>
                                 If you have any questions in the meantime, please don't hesitate to ask. If you get in touch, you may be asked for your 
                                 Account Number which is ${accountNumber}.
                                 </div>
                               </body>
                             </html>`
        e.emailTag = [
          "paymentreview_nocalc",
          "Dispatch'r to Junifer (Outbound)",
        ]
        e.freshdesk_sub_1 = "Direct Debit Review"
        e.paymentChange = false
        e.seven_day_follow_up = false
      } else if (
        e.perc_change > 0 ||
        (isNaN(e.perc_change) && e.nominal_change > 0)
      ) {
        //If the suggested payment amount has been amended, the calcs wont add up
        var ex_acc_bal =
          e.current_payment_amount > Number(personalProjection) / 12 &&
          Number(e.unbilled_readings_cost) + Number(e.account_balance) > 0
        if (ex_acc_bal) {
          var calculatedTotal = Number(personalProjection).toFixed(2)
        } else {
          var calculatedTotal = (
            Number(personalProjection) +
            Number(e.unbilled_readings_cost) +
            Number(e.account_balance)
          ).toFixed(2)
        }
        var calcValid =
          Number(suggPayAmount) === Math.round(calculatedTotal / 12)

        if (!calcValid) {
          if (e.current_payment_seasonal_fl) {
            paymentChangeNotification = `We have reviewed your monthly payment amount and letting you know that we will be increasing your monthly Direct Debit 
            payment to summer payments &pound;${suggPayAmount_summer} (April - September) and winter payments &pound;${suggPayAmount_winter} (October - March) <b><u>from ${paymentdt} onwards</u></b>`
          } else {
            paymentChangeNotification = `We have reviewed your monthly payment amount and letting you know that we will be increasing your monthly Direct Debit 
            payment to &pound;${suggPayAmount} per month <b><u>from ${paymentdt} onwards</u></b>`
          }

          e.templateContent = `<html>
                                 <body> 
                                   <div style='${bodyTextStyle}'>
                                     Hello ${firstName},
                                     <br><br>
                                     ${introWording} ${paymentChangeNotification}.
                                     <br><br>
                                     Your new payment amount is calculated by adding your total estimated annual energy cost, 
                                     your account balance, and any unbilled cost from meter readings received since your last 
                                     statement. This total is divided into 12 equal payments. ${payAdj}
                                     <br><br>
                                     Remember that your tariff is not changing, and that you are still paying the same price for fuel. You are only charged 
                                     for what you use, any remaining account balance can be settled at the end of your contract. 
                                     For more information please see our <a href='${faqLink}' target='_blank'><b>Direct Debit Review Guide</b></a>.
                                     <br><br>
                                     If you would like to speak to us or have any questions, please don't hesitate to ask. 
                                     You may be asked for your So Energy Account Number which is ${accountNumber}.
                                   </div>
                                 </body>
                               </html>`

          e.emailTag = [
            "paymentreview_nocalc",
            "Dispatch'r to Junifer (Outbound)",
          ]
          e.freshdesk_sub_1 = "Direct Debit Review"
          e.emailSubject = "We have reviewed your Direct Debit amount"
          e.paymentChange = true
        } else {
          if (e.unbilled_readings_fl) {
            unbilled_line = `<tr>
                                 <td>Unbilled Reading Costs:</td>
                                 <td>${unbilledReadingCost_table}</td>
                               </tr>`
          } else {
            unbilled_line = ``
          }

          if (ex_acc_bal) {
            // If there is a credit balance and we need to increase
            acc_bal_line = ``
            unbilled_line = ``
            calc_wording = ``
            calc_wording_adding = `taking `
          } else {
            acc_bal_line = `<tr>
                              <td>Account Balance</td>
                              <td>${accountBalance_table}</td>
                            </tr>`
            calc_wording = `, account balance
            and any unbilled meter readings since your last statement`
            calc_wording_adding = `adding `
          }

          var totalTableLine = ``

          totalTableLine = `<tr style='border-top:1px solid black;'>
                                <td>Total</td>
                                <td>&pound;${calculatedTotal}</td>
                              </tr>`

          if (e.current_payment_seasonal_fl) {
            payment_change_calculation = `Your new payment amount is calculated by ${calc_wording_adding} your total estimated annual energy cost${calc_wording}. This total is divided into 12 equal payments. ${payAdj} Our calculation is shown below:
                                                <br><br>
                                                <table>
                                                <tr>
                                                    <td>${pp_wording}</td>
                                                    <td>&pound${personalProjection}</td>
                                                </tr>
                                                ${acc_bal_line}
                                                ${unbilled_line}
                                                ${totalTableLine}
                                                </table>
                                                <br><br>
                                                Calculation of new payment amount:
                                                <br>
                                                &pound;${calculatedTotal} / 12 = &pound;${suggPayAmount} (rounded to the nearest pound)
                                                <br>Winter payments: &pound;${suggPayAmount} x 125% = &pound;${suggPayAmount_winter}
                                                <br>Summer payments: &pound;${suggPayAmount} x 75% = &pound;${suggPayAmount_summer}
                                                <br>`
            paymentChange = `summer payments &pound;${suggPayAmount_summer} (April - September) and winter payments &pound;${suggPayAmount_winter} (October - March)`
          } else {
            payment_change_calculation = `Your new payment amount is calculated by ${calc_wording_adding} your estimated annual energy cost${calc_wording}. This total is divided into 12 equal payments. Our calculation is shown below:
                                                <br><br>
                                                <table>
                                                  <tr>
                                                      <td>${pp_wording}</td>
                                                      <td>&pound${personalProjection}</td>
                                                  </tr>
                                                  ${acc_bal_line}
                                                  ${unbilled_line}
                                                  ${totalTableLine}
                                                </table>
                                                <br><br>
                                                Calculation of new payment amount:
                                                <br>
                                                  &pound;${calculatedTotal} / 12 = &pound;${suggPayAmount} (rounded to the nearest pound)
                                                <br>`
            paymentChange = `&pound;${suggPayAmount} per month`
          }

          if (
            e.perc_change <= 100 ||
            (isNaN(e.perc_change) && e.nominal_change <= nominalValueLimit)
          ) {
            oneOffPayment = ``
            if (e.current_payment_seasonal_fl) {
              paymentChangeNotification = `We have reviewed your monthly payment amount and we are letting you know that we will be increasing your monthly Direct Debit payment to summer payments &pound;${suggPayAmount_summer} (April - September) and winter payments &pound;${suggPayAmount_winter} (October - March) <b><u>from ${paymentdt} onwards</u></b>.`
            } else {
              paymentChangeNotification = `We have reviewed your monthly payment amount and we are letting you know that we will be increasing your monthly Direct Debit payment to &pound;${suggPayAmount} per 
              month <b><u>from ${paymentdt} onwards</u></b>.`
            }

            e.paymentChange = true
            e.seven_day_follow_up = false
          } else {
            oneOffPayment = `If you would prefer to make a one-off payment towards your energy usage or wish to discuss your account, please get in touch.`

            //If over 200% and follow up, make change immediately
            if (e.follow_up_fl) {
              paymentChangeNotification = `We have reviewed your monthly payment amount and we are letting you know that your current monthly payments of ${currentPayments} are not suitable for your usage. 
              We will be increasing your monthly Direct Debit payment to ${paymentChange} <b><u>from ${paymentdt} onwards</u></b>.`
              e.paymentChange = true
            } else {
              paymentChangeNotification = `We have reviewed your monthly payment amount and we are letting you know that your current monthly payments of ${currentPayments} are not suitable for your usage.
              We will be looking to increase your monthly Direct Debit payment to ${paymentChange}. If we do not hear from you within <b><u>the next 7 days</u></b>, we will make this change.
              <br/><br/>
              If you wish to have a lower monthly payment, then please complete a one-off payment in the next 7 days.
              You can make a payment through your So Energy online account <a href='https://www.so.energy/accounts/payments' target='_blank'>here</a> or by contacting us directly.
              We will review your payments again after 7 days and be in touch to let you know a more suitable amount.`
              e.seven_day_follow_up = true
              e.seven_day_follow_up_date = dayjs(today).add(7, "days")
            }
          }

          e.templateContent = `<html>
                                 <body> 
                                   <div style='${bodyTextStyle}'>
                                   Hi ${firstName},
                                   <br><br>
                                   ${introWording} ${paymentChangeNotification}
                                   <br><br>
                                   ${payment_change_calculation}
                                   <br><br>
                                   Remember that your tariff is not changing, and that you are still paying the same price for fuel. You are only charged 
                                   for what you use, any remaining account balance can be settled at the end of your contract. 
                                   For more information please see our <a href='${faqLink}' target='_blank'><b>Direct Debit Review Guide</b></a>.
                                   <br><br>
                                   ${oneOffPayment}
                                   If you do get in touch, you may be asked for your Account Number which is ${accountNumber}.
                                   </div>
                                 </body>
                               </html>`
          e.emailTag = [
            "paymentreview_withcalc",
            "Dispatch'r to Junifer (Outbound)",
          ]
          e.emailSubject = "We have reviewed your Direct Debit amount"
          e.freshdesk_sub_1 = "Direct Debit Review"
        }
      }
    }
  } catch (error) {
    console.log(error)
  }
}
