<template>
  <b-container fluid>
    <b-row class="ml-5">
      <header-block
        title="Account Finder"
        description="By setting values in the request it will respond with a
        list of accounts matching the specified values. The list is presented
        in a table format, you can also download the JSON file which can be
        imported into Excel."
        summary="Finds accounts with specific attributes."
      ></header-block>
    </b-row>
    <page-section title="Request" class="has-text-left">
      <template v-slot:content>
        <b-container class="float-left">
          <b-form-row>
            <b-col>Fuel</b-col>
            <b-col>Smart Meter</b-col>
            <b-col>Multiple Meters</b-col>
            <b-col>Seasonal Flag</b-col>
            <b-col>Direct Debit</b-col>
            <b-col>Elec Two Rate</b-col>
            <b-col>Limit</b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-select
                id="inline-form-custom-select-pref"
                v-model="request.fuel"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="importFuelOptions"
              >
              </b-form-select>
            </b-col>
            <b-col>
              <b-form-select
                id="inline-form-custom-select-pref"
                v-model="request.smartMeter"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="importNullBooleanOptions"
              >
              </b-form-select>
            </b-col>
            <b-col>
              <b-form-select
                id="inline-form-custom-select-pref"
                v-model="request.multipleMeters"
                class="mb-2 mr-sm-2 mb-sm-0"
                :disabled="request.fuel == null"
                :options="importBooleanOptions"
              >
              </b-form-select>
            </b-col>
            <b-col>
              <b-form-select
                id="inline-form-custom-select-pref"
                v-model="request.seasonalFlag"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="importNullBooleanOptions"
              ></b-form-select>
            </b-col>
            <b-col>
              <b-form-select
                id="inline-form-custom-select-pref"
                v-model="request.directDebit"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="importNullBooleanOptions"
              ></b-form-select>
            </b-col>
            <b-col>
              <b-form-select
                id="inline-form-custom-select-pref"
                v-model="request.elecTwoRate"
                class="mb-2 mr-sm-2 mb-sm-0"
                :disabled="request.fuel === 'GAS'"
                :options="importNullBooleanOptions"
              ></b-form-select>
            </b-col>
            <b-col>
              <b-form-input
                v-model="request.limit"
                :type="'number'"
              ></b-form-input>
            </b-col>
          </b-form-row>
          <b-button
            class="mb-2 mr-sm-2 mb-sm-0"
            variant="outline-success"
            :disabled="isBusy"
            @click="runAccountFinder()"
          >
            Request
          </b-button>
          <img
            v-if="isBusy"
            id="loading_circle_icon"
            class="button__icon"
            src="@/assets/icons/loading-circle.gif"
            alt="loading..."
          />
          <p v-if="error != null" class="error-msg">{{ error }}</p>
        </b-container>
      </template>
    </page-section>
    <page-section title="List of Accounts" v-if="jsonBlob">
      <template v-slot:content>
        <b-container fluid>
          <b-row>
            <b-col cols="6">
              <table class="text-left">
                <tr>
                  <th>Account ID</th>
                  <th>Account Number</th>
                </tr>
                <tr v-for="account in results.accounts" :key="account">
                  <td>{{ account.accountId }}</td>
                  <td>{{ account.accountNumber }}</td>
                </tr>
              </table>
            </b-col>
            <b-col>
              <b-button
                :disabled="isBusy"
                @click="downloadJsonFile"
              >
                <slot>
                  <span>Download JSON </span>
                </slot>
                <i class="icon icon-download"></i>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </page-section>
  </b-container>
</template>

<script>
import headerBlock from "@/components/header"
import pageSection from "@/components/pageSection"
import Accounts from "@/api/Accounts"

export default {
  components: {
    pageSection,
    headerBlock,
  },
  data() {
    return {
      Accounts: Accounts,
      isBusy: false,
      error: null,
      jsonBlob: null,
      request: {
        fuel: null,
        elecTwoRate: null,
        smartMeter: null,
        multipleMeters: false,
        seasonalFlag: null,
        directDebit: null,
        limit: 10,
      },
      results: {
        accounts: [{}],
      },
      importFuelOptions: [null, "GAS", "ELECTRICITY", "DUAL"],
      importNullBooleanOptions: [null, true, false],
      importBooleanOptions: [false, true],
    }
  },
  watch: {
    "request.fuel": function (newValue) {
      if (newValue == null || newValue === "DUAL") {
        this.request.multipleMeters = null
      }
      if (newValue !== "ELECTRICITY") {
        this.request.elecTwoRate = null
      }
    },
  },
  methods: {
    async runAccountFinder() {
      this.isBusy = true
      this.error = null
      this.results = null

      try {
        const response = await Accounts.accountFinder(this.request)

        if (response.status !== 200) {
          this.isBusy = false
          return
        }

        this.results = response.data
        this.jsonBlob = new Blob([JSON.stringify(response.data)], {
          type: "plain/text",
        })
        this.isBusy = false
      } catch (e) {
        this.isBusy = false
        this.error = e
        console.error(e)
      }
    },
    downloadJsonFile() {
      let link = document.createElement("a")
      link.href = window.URL.createObjectURL(this.jsonBlob)
      link.download = "accountFinder.json"
      link.click()
      link.remove()
      this.$emit("download")
    },
  },
}
</script>

<style lang="scss" scoped>
.error-msg {
  color: $fire;
  font-weight: $weight-medium;
}
#loading_circle_icon {
  width: 2%;
  height: 2%;
}
</style>
