import email from "@/constants/email"

export function gasOpeningReadTemplate(e) {
  try {
    var footer = email.footer
    var textBodyStyle = email.textStyle

    if (e.template === "OutofTolerance") {
      e.templateContent = `<html>
                                <body>
                                  <div style='${textBodyStyle}'>
                                      Hello ${e.customer_forename},
                                      <br>
                                      <br>Account Number: ${e.account_number}
                                      <br>MPRN: ${e.mprn}
                                      <br>
                                      <br>We're contacting you today because your opening gas reading failed <a href='https://www.so.energy/so/help-and-advice/faq/switching-to-us/Opening-Read-Validation' target='_blank'><b>validation</b></a>.
                                      <br>
                                      <br>You provided the following reading: ${e.urs_read}${e.unit}
                                      <br>
                                      <br>We have been provided with an estimate to use instead. Estimates are given to us by industry based on your meter reading history and estimated energy usage.
                                      <br>
                                      <br>We will be opening your account with an estimated read: ${e.mbr_read}${e.unit}.
                                      <br>
                                      <br>If you are happy with the estimated reading no action is necessary.
                                      The same estimate has been given to your previous supplier as your final reading
                                      to be used on your final bill, so you will not be charged twice for any energy.
                                      Their final bill should be with you in the next couple of weeks.
                                      Please contact your old supplier first if you are concerned that this is taking a long time.
                                      <br>
                                      <br><i>Is the opening reading you gave us correct?</i>
                                      <br>
                                      <br>If you think your opening read is correct and this validation process isn't working properly then we'd love to help out.
                                      We can raise a 'dispute' on your reading and get the reading you provided amended.
                                      To do so, we will need clear photographs of your gas meter,
                                      showing the full front of the meter including serial number (a series of letters and/or numbers printed on the meter).
                                      You can just reply to this email with the photographs attached.
                                      <br>
                                      <br>You can also read our handy guide which explains this process: <a href='https://www.so.energy/so/help-and-advice/faq/switching-to-us/disputing-reads' target='_blank'><b>My opening or closing reading is wrong</b></a>.
                                      <br>
                                      <br>If you have any queries, please do get in touch.
                                    </div>
                                    ${footer}
                                </body>
                              </html>`
    } else {
      e.templateContent = `<html>
                              <body>
                                <div style='${textBodyStyle}'>
                                    Hello ${e.customer_forename},
                                    <br>
                                    <br>Account Number: ${e.account_number}
                                    <br>MPRN: ${e.mprn}
                                    <br>
                                    <br>We're contacting you today because your opening gas reading failed <a href='https://www.so.energy/so/help-and-advice/faq/switching-to-us/Opening-Read-Validation' target='_blank'><b>validation</b></a>.
                                    <br>
                                    <br>You provided the following reading: ${e.urs_read}${e.unit}
                                    <br>
                                    <br>We have been provided with an estimate to use instead. Estimates are given to us by industry based on your meter reading history and estimated energy usage.
                                    <br>
                                    <br>We will be opening your account with an estimated read: ${e.mbr_read}${e.unit}.
                                    <br>
                                    <br>
                                    The same estimate has been given to your previous supplier as your final reading
                                    to be used on your final bill, so you will not be charged twice for any energy.
                                    Their final bill should be with you in the next couple of weeks.
                                    Please contact your old supplier first if you are concerned that this is taking a long time.
                                    <br>
                                    <br>The estimate we have been given is very similar to, or 'within tolerance' of the reading you gave us.
                                    This means, as much as we would prefer to use your reading (as we're sure you would too!) the energy industry will not allow
                                    us to dispute the estimate. At this point So Energy and your previous supplier must use this reading for your switch.
                                    The estimate is sensible and could reasonably have been taken around the time of your switch,
                                    and the important thing to remember is that you will not be charged twice for the same energy.
                                    <br>
                                    <br>If you have any queries, please do get in touch.
                                  </div>
                                  ${footer}
                              </body>
                            </html>`
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
