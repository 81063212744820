import apiArk from "./Ark"

export default {
  // Blacklist
  getBlacklist() {
    return apiArk.get("communications/blacklist/all")
  },
  deleteBlacklist(account_number) {
    return apiArk.delete("communications/blacklist/" + account_number)
  },
  createBlacklist(account_number, payload) {
    return apiArk.post(
      "communications/blacklist/" + account_number + "/create",
      { payload }
    )
  },
  getAccountBlacklists(account_number) {
    return apiArk.get("communications/blacklist/" + account_number)
  },
  deleteBlacklistTemplate(id) {
    return apiArk.delete("communications/blacklist/template/" + id)
  },

  // Communications
  getAllComms() {
    return apiArk.get("communications/all")
  },
  lookupAccount(account_number) {
    return apiArk.get("communications/account/" + account_number)
  },
  getAllContentTemplates() {
    return apiArk.get("communications/templates")
  },
  templateLookup(id) {
    return apiArk.get("communications/templates/" + id)
  },
  getSentCommunications() {
    return apiArk.get("communications/chart")
  },

  //Comments
  addComment(id, payload) {
    return apiArk.post("communications/templates/" + id + "/comment/create", {
      payload,
    })
  },
}
