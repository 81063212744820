import dayjs from "dayjs"

export function isTodayCheck(date) {
  var today = dayjs(new Date())
  var days = dayjs(date).diff(today, "days")
  if (days === 0) {
    return true
  } else {
    return false
  }
}
